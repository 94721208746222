import { gql } from "@apollo/client";

export const DELETE_USER = gql `
    mutation deleteUser(
        $id : UUID!
    ){
        deleteUser(
            id : $id
        ){
            status
            statusCode
            message  
        }
    }

`