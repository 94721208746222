import React, { useEffect, useState } from 'react';
import '../ApprovingAuthorities/styles.css';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import spyglass from '../../assets/search.svg';
import { Button, message } from 'antd';
import { convertStaff } from '../../utilities/convertArray';

const ApprovingAuthorities = ({ values, setValues, staff, handleChange, setCurrent, current }) => {

    let approvals = values.noOfApproval;
    let concurrence = values.noOfconcurrence;
    const [authorizing, setAuthorizing] = useState([]);
    const [authId, setAuthId] = useState([]);
    const [concurrenceList, setConcurrentList] = useState([]);
    const [conID, setConId] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [disableCon, setDisabledCon] = useState(false);

    useEffect(() => {
        if(authorizing.length == approvals){
            setDisabled(true);
        }else{
            setDisabled(false);
        }
        if(concurrenceList.length == concurrence){
            setDisabledCon(true);
        }else{
            setDisabledCon(false);
        }
    },[approvals, authorizing.length, concurrence, concurrenceList.length])

    const handleInput = () => {
        var val = document.getElementById("input").value;
        let opts = document.getElementById('data').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(data)
            setAuthorizing(prevState => [...prevState, { staffid : data }])
            setAuthId(prevState => [...prevState, {staffid : dataId}])
            break;
            }
        }
        val = ""
    }
    
    const handleInput2 = () => {
        let val = document.getElementById("input2").value;
        let opts = document.getElementById('data2').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setConcurrentList(prevState => [...prevState, { staffid : data}])
            setConId(prevState => [...prevState, { staffid : dataId }])
            val = ""
            break;
            }
        }
        
    }
 
    const handleRemove = (e) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        const newList = authorizing.filter((item, index) => index !== Number(id));
        setAuthorizing(newList);
    }
    const handleRemove2 = (e) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        const newList = concurrenceList.filter((item, index) => index !== Number(id));
        setConcurrentList(newList)
    }
    const handleSave = (e) => {
        e.preventDefault()
        setValues({...values, concurrence : conID, approvingStaff : authId, approvingData : authorizing, concurrenceData : concurrenceList});
        if(values.concurrence !== "" && values.approvingStaff !== "" && values.reviewBy !== ""){
            setCurrent(current + 1);
        }else{
            message.success('Complete the fields!')
        }
    }

    const prev = () => {
        setCurrent(current - 1);
    };

    const stafflist = convertStaff(staff);
    
  return (
    <div className='approving-authorities'>
        <ToastContainer closeButton={true} position="top-right" />
        <div className="document-type mb-4">
            <div className="content mt-3">
                <div className="form-group">
                    <label htmlFor="">Reviewed by</label>
                    <Select
                    name="to"
                    options={stafflist}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => setValues({...values, reviewBy : data.value, reviewData : data.label})} 
                />
                </div>
                <div className="form-group">
                    <label htmlFor=""> Number of Concurrence</label>
                    <div className="approval-wrapper">
                        <select onChange={handleChange} name='noOfconcurrence' value={values.noOfconcurrence} >
                            <option value="" disabled>select concurrence number</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>

                        <div className="input-wrapper mt-2">
                            <img src={spyglass} alt="spy glasss icon" width="20px" />
                            <input list="data2" id='input2' onInput={handleInput2} placeholder="search concurrence staff name" disabled={disableCon}/>
                        </div>
                        <datalist id="data2">
                            {staff.map(({id, name}) =>
                                <option key={id} value={name} id={id}>{name}</option>
                            )}
                        </datalist>
                        {
                            (concurrenceList.length >= 1) ? 
                            <div className="selected-list">
                                {
                                    concurrenceList.map(({staffid}, index) => {
                                        return <div className="item" key={index}> 
                                            <p>{staffid}</p>
                                            <span id={index} onClick={handleRemove2}>x</span>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                        
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="">Number of Approvals</label>
                    <div className="approval-wrapper">
                        <select onChange={handleChange} name='noOfApproval' value={values.noOfApproval} >
                            <option value="" className='grey' disabled>select approving number</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                        </select>

                        <div className="input-wrapper mt-2">
                            <img src={spyglass} alt="spy glasss icon" width="20px" />
                            <input list="data" id='input' onInput={handleInput} placeholder="search approving staff name" disabled={disabled}/>
                        </div>
                        <datalist id="data">
                            {staff.map(({id, name}) =>
                                <option key={id} value={name} id={id}>{name}</option>
                            )}
                        </datalist>
                        {
                            (authorizing.length >= 1) ?
                            <div className="selected-list">
                            {
                                authorizing.map(({staffid}, index) => {
                                    return <div className="item" key={index}> 
                                        <p>{staffid}</p>
                                        <span id={index} onClick={handleRemove}>x</span>
                                    </div>
                                })
                            }
                            </div> : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
        <Button type='primary' onClick={handleSave}>Next</Button>
        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>Previous</Button>
    </div>
  )
}

export default ApprovingAuthorities;