import React, { useEffect, useState } from 'react';
import { Button, message, Steps } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../Document/Document.css';
import DocumentType from '../../components/DocumentType/DocumentType';
import Tags from '../../components/Tags/Tags';
import FormFileds from '../../components/FormFields/FormFileds';
import SupportingDocument from '../../components/SupportingDocument/SupportingDocument';
import ApprovingAuthorities from '../../components/ApprovingAuthorities/ApprovingAuthorities';
import Header from '../../components/header/header';
import { userSelector } from '../../feature/Login/loginSlice';
import Preview from '../../components/Preview/Preview';
import { documentBaseUrl, irsBaseUrl } from '../../common/apis/userApi';

const { Step } = Steps;

const Document = () => {
  const [current, setCurrent] = useState(0);
  const { token, user } = useSelector(userSelector);
  const [department, setDepartment] = useState([]);
  const [staff, setStaff] = useState([]);
  const [office, setOffice] = useState([]);
  const [filedata, setFiledata] = useState([]);
  const [valid, setValid] = useState(false);
  
  const [formData, setFormData] = useState({
      from : "",
      to : "",
      toData : "",
      fromData : "",
      docType : "",
      subject : "",
      background : "",
      Request: "",
      Justification: "",
      noOfApproval: "",
      noOfconcurrence: "",
      reviewBy: "",
      approvingStaff : [],
      concurrence : [],
      memoType : "",
      approvingData : [],
      concurrenceData : [],
      reviewData : ""
  })

  useEffect(() => {
    const fetchingData = async () => {
        try {
            const fetching = await axios.options(`${documentBaseUrl}/department`);
            const response = fetching.data.response;
            setDepartment(response.data);

            const fetchingOffices = await axios.options(`${documentBaseUrl}/office`);
            const allOffices = fetchingOffices.data.response;
            setOffice(allOffices.data);

            const query = await axios({
                method : "GET",
                url : `${irsBaseUrl}/staff/all`,
                headers : {
                  Authorization : `Bearer ${token}`
                }
            })
            const results = query.data;
            setStaff(results.data)

        } catch (error) {
            console.log(error)
        }
    }
    fetchingData();
  },[token])

  const handleChange = (e) => {
      setFormData({...formData, [e.target.name] : e.target.value});
  }
      
  const steps = [
    {
      title: 'Doc Type',
      content: <DocumentType 
                values={formData}
                setValues={setFormData}
                department={department}
                office={office}
                handleChange={handleChange}
                setValid={setValid}
              />,
    },
    {
      title: 'Validations',
      content: <FormFileds
                handleChange={handleChange}
                values={formData}
                setValid={setValid}
              />,
    },
    {
      title: 'Add File',
      content:  <SupportingDocument 
                  setfileData={setFiledata}
                  setValid={setValid}
                />,
    },
    {
      title: 'Actors',
      content: <ApprovingAuthorities
                  handleChange={handleChange}
                  values={formData}
                  staff={staff}
                  setValues={setFormData}
                  setCurrent={setCurrent}
                  current={current}
              />,
    },
    {
      title: 'Preview',
      content: <Preview
                  handleChange={handleChange}
                  values={formData}
                  staff={staff}
                  setValues={setFormData}
                  setCurrent={setCurrent}
                  current={current}
                  user={user}
                  formData={formData}
                  filedata={filedata}
                />,
    },
  ];

  const next = () => {
    if(valid){
      setCurrent(current + 1);
      setValid(false)
    }else{
      message.success('Complete the fields!')
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
      <div className="document-approval">
        <Header />
        <div className="container_">
          <Tags />
          <div className="form-wrapper py-3 px-5">
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action" style={{display : (current === 3 || current === 4) ? "none" : "block"}}>
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={() => message.success('Processing complete!')}>
                  Done
                </Button>
              )}
              {current > 0 && (
                <Button onClick={() => prev()}>
                  Previous
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Document





// const Document = () => {

//   const { token, user } = useSelector(userSelector);
//   const [department, setDepartment] = useState([]);
//   const [staff, setStaff] = useState([]);
//   const [office, setOffice] = useState([]);
//   const [filedata, setFiledata] = useState([]);
//   const [disabled, setDisabled] = useState(false);

//   const history = useNavigate();
  
//   const [formData, setFormData] = useState({
//       from : "",
//       to : "",
//       toData : "",
//       formData : "",
//       docType : "",
//       subject : "",
//       background : "",
//       Request: "",
//       Justification: "",
//       noOfApproval: "",
//       noOfconcurrence: "",
//       reviewBy: "",
//       approvingStaff : [],
//       concurrence : [],
//       memoType : "",
//       aprrovingData : [],
//       concurrenceData : [],
//       reviewData : ""
//   })

//   useEffect(() => {
//     const fetchingData = async () => {
//         try {
//             const fetching = await axios.options(`${documentBaseUrl}/department`);
//             const response = fetching.data.response;
//             setDepartment(response.data);

//             const fetchingOffices = await axios.options(`${documentBaseUrl}/office`);
//             const allOffices = fetchingOffices.data.response;
//             setOffice(allOffices.data);

//             const query = await axios({
//                 method : "GET",
//                 url : `${irsBaseUrl}/staff/all`,
//                 headers : {
//                   Authorization : `Bearer ${token}`
//                 }
//             })
//             const results = query.data;
//             setStaff(results.data)

//         } catch (error) {
//             console.log(error)
//         }
//     }
//     fetchingData();
//   },[token])

//   const handleChange = (e) => {
//       e.persist();
//       setFormData({...formData, [e.target.name] : e.target.value});
//   }
//   const [step, setStep] = useState(1);

//   const nextStep = () => {
//     setStep(step + 1);
//   };

//   const prevStep = () => {
//     setStep(step - 1);
//   };

//   const handlePrint = (e) => {
//     e.preventDefault();
//     window.print();
// }

// const showAlert = (e) => {
//     // const { id } = e.currentTarget;

//     Swal.fire({
//         title: 'Download/Print Document',
//         text: "Would you like to download/print this document type?",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, Save it!',
//       }).then((result) => {
//         if (result.isConfirmed) {
//             handlePrint();
//           Swal.fire(
//             'Success!',
//             'Your file has been downloaded.',
//             'success'
//           )
//         }
//       })
// }

//   const handleSubmit = async (e) => {
//       e.preventDefault();

//       setDisabled(true);

//       const JsonData = JSON.stringify({
//             "app_type": formData.docType,
//             "dept_id": formData.from,
//             "office_id": formData.to,
//             "subject": formData.subject,
//             "background": formData.background,
//             "memo_request": formData.Request,
//             "justification": formData.Justification,
//             "requester_id" : user.id,
//             "no_of_approval": formData.noOfApproval,
//             "no_of_concurrence": formData.noOfconcurrence,
//             "review_by_id": formData.reviewBy,
//             "approvingStaffs": formData.approvingStaff,
//             "concurrence": formData.concurrence,
//             "watermark" : formData.memoType 
//       })
//       try {
//           const query = await axios({
//               method : "POST",
//               url : `${documentBaseUrl}/memoapp`,
//               data : JsonData,
//               headers : {
//                 'Content-Type': 'application/json'
//               }
//           })
          
//           const response = query.data;
//           if(response.code === 200){
//             const memoID = response?.response?.data.applicationId;
//             uploadingDoc(memoID);
//             setTimeout(() => {
//               history('/my-applications')
//             }, 3000)
//           }
//         } catch (error) {
//           setDisabled(false);
//           const errorMsg = error.response.data
//           if(errorMsg.code === 400){
//             toast.error(errorMsg.response.data.message);
//           }else{
//             toast.error(errorMsg.response.data.message)
//           }
            
//           console.log(errorMsg)
//       }
//   }

//   const uploadingDoc = async (memoID) => {
            
//     const newForm = new FormData();
//     newForm.append("document", filedata)

//     try {
//       const sendingImage = await axios.post(`${documentBaseUrl}/adddocument/${memoID}`, newForm );
//       const result = sendingImage.data;
//       console.log(result)
//       if(result.code === 200){
//         toast.success("successfully")
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   switch(step){
//       case 1 : 
//       return(
//           <div className="document-approval">
//               <Header />
//               <div className="container_">
//                   <Tags />
//                   <div className="form-wrapper">
//                       <DocumentType 
//                       nextStep={nextStep} 
//                       values={formData}
//                       setValues={setFormData}
//                       department={department}
//                       office={office}
//                       handleChange={handleChange}/>
//                   </div>
//               </div>
//           </div>
//       );
//       case 2 : 
//       return(
//         <div className="document-approval">
//             <Header />
//             <div className="container_">
//                 <Tags />
//                 <div className="form-wrapper">
//                     <FormFileds 
//                     nextStep={nextStep}
//                     prevStep={prevStep}
//                     handleChange={handleChange}
//                     values={formData}
//                      />
//                 </div>
//             </div>
//         </div>
//       );
//       case 3 : return(
//         <div className="document-approval">
//             <Header />
//             <div className="container_">
//                 <Tags />
//                 <div className="form-wrapper">
//                     <SupportingDocument 
//                     nextStep={nextStep} 
//                     prevStep={prevStep}
//                     setfileData={setFiledata}
//                     />
//                 </div>
//             </div>
//         </div>
//       );
//       case 4 : return(
//         <div className="document-approval approving">
//             <Header />
//             <div className="container_">
//                 <Tags />
//                 <div className="form-wrapper">
//                     <ApprovingAuthorities
//                     prevStep={prevStep}
//                     nextStep={nextStep}
//                     handleChange={handleChange}
//                     values={formData}
//                     staff={staff}
//                     setValues={setFormData}
//                     />
//                 </div>
//             </div>
//         </div>
//       );
//       case 5 : return(
//         <div className="document-approval">
//             <ToastContainer closeButton={true} position="top-right" />
//             <Header />
//             <div className="container_">
//                 <Tags />
//                 <div className="form-wrapper">
//                     <Preview
//                     prevStep={prevStep}
//                     handleChange={handleChange}
//                     values={formData}
//                     staff={staff}
//                     setValues={setFormData}
//                     handleSubmit={handleSubmit}
//                     disabled={disabled}
//                     />
//                 </div>
//             </div>
//         </div>
//       );
//       default : return(
//           <div className="document-approval">
//               <div className="container">
//                   <h1>Loading</h1>
//               </div>
//           </div>
//       )
//   }
// }

// export default Document;
