import { gql } from "@apollo/client";

export const USER_INFO = gql `
    query{
        allInfo{
            edges{
                node{
                    id
                    email
                    qrcodeUrl
                }
            }
        }
    }
`

export const FILTER_USER = gql `
    query allInfo($id: String!){
        allInfo(id : $id){
            edges{
                node{
                    id
                    email
                    qrcodeUrl
                }
            }
        }
    }
`