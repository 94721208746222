import React from 'react';
import '../AddSignature/addSignature.css';
import Tags from '../../components/Tags/Tags'
import UploadSignature from '../../components/UploadSignature/UploadSignature';
import Header from '../../components/header/header';

const AddSignature = () => {
  return (
    <div className='add-signature document-approval'>
      <Header />
      <div className="container_">
        <Tags />
        <UploadSignature />
      </div>
    </div>
  )
}

export default AddSignature