import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import { toast, ToastContainer } from 'react-toastify';
import '../CasaOperations/casadeposit.css';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import Swal  from 'sweetalert2';
import Button from '../../../common/button/button';

const CasaTransfer = () => {

    const [disabled, setDisabled] = useState(false);
    const [transfer, settransfer] = useState({
        debitAccount : "",
        debitbankcode : "",
        creditAccount : "",
        creditbankcode : "",
        creditAmount: ""
    })

    const [allbanks, setAllbanks] = useState([]);
    const [accountowner, setaccountowner] = useState({
        debitor : '',
        creditor : '',
        debitErrorMsg : false,
        creditErrorMsg : false
    });

    useEffect(() => {
        const fetchingBankList = async () => {
            try {
                const query = await axios.get(`${casaBaseurl}allbanks`);
                const response = query.data;
                if(response.code === 200) {
                    setAllbanks(response.body.data);
                }
            } catch (error) {
                console.log(error);
                toast.error('Error loading bank list')
            }
        }
        fetchingBankList();
    },[])

    const handleChange = (e) => {
        settransfer({...transfer, [e.target.name] : e.target.value})
    }

    const verifyDebitAccount = async (e) => {
        e.preventDefault();
        const bankcode = e.target.value;
        settransfer({...transfer, debitbankcode : bankcode});
        const { debitAccount } = transfer;

        const JsonData = {
            "bankcode": bankcode,
            "accountNo": debitAccount
        }

        try {
            const query = await axios.post(`${casaBaseurl}accountenquiry`, JsonData);
            const respone = query.data;
            setaccountowner({...accountowner, debitor : respone.body.data, debitErrorMsg : false});

        } catch (error) {
            const errorMsg = error.response.data.body.data;
            setaccountowner({...accountowner, debitErrorMsg : true, debitor : ""});
            toast.error(errorMsg);
            console.log(errorMsg)
        }
        
    }

    const verifyCreditAccount = async (e) => {
        e.preventDefault();
        const bankcode = e.target.value;
        settransfer({...transfer, creditbankcode : bankcode});
        const { creditAccount } = transfer;

        const JsonData = {
            "bankcode": bankcode,
            "accountNo": creditAccount
        }
        try {
            const query = await axios.post(`${casaBaseurl}accountenquiry`, JsonData);
            const respone = query.data;
            setaccountowner({...accountowner, creditor : respone.body.data, creditErrorMsg : false});
        } catch (error) {
            const errorMsg = error.response.data.body.data;
            setaccountowner({...accountowner, creditErrorMsg : true, creditor : ""});
            toast.error(errorMsg);
        }
        
    }

    const handleTransfer = async (e) => {
        setDisabled(true);
        const { debitAccount, debitbankcode, creditAccount, creditAmount, creditbankcode} = transfer;

        const JsonData = {
            "debitAccount": debitAccount,
            "debitbankcode": debitbankcode,
            "creditAccount": creditAccount,
            "creditbankcode": creditbankcode,
            "creditAmount": creditAmount
        }

        try {
            const query = await axios.post(`${casaBaseurl}fundtransfer`, JsonData);
            const response  = query.data;
            console.log(response);
            if(response.code === 200){
                Swal.fire(
                    'Completed!',
                    'Transaction Success!',
                    'success'
                  )
            }
            setDisabled(false);
            settransfer({debitAccount : "", debitbankcode : "", creditAccount : "", creditbankcode : "", creditAmount: ""})
        } catch (error) {
            setDisabled(false);
            const errorMesssage = error.response.data.body.data;
            if(errorMesssage.code === 400){
                toast.error(errorMesssage.message.info);
            }else{
                toast.error(errorMesssage)
            }
            console.log(errorMesssage);
        }
    }

    const showAlert = (e) => {
        e.preventDefault();
        const amount = Number(transfer.creditAmount);
        const stringAmount = amount.toLocaleString();

        // let alertText = <p><span>{`${transfer.debitAccount}`}</span> wants to transfer <span>{`${stringAmount}naira`}</span> to <span>{`${transfer.creditAccount}`}</span></p>

        Swal.fire({
            title: 'Transaction Confirmation',
            text: `${accountowner.debitor} wants to transfer ${stringAmount}naira to ${accountowner.creditor}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, proceed!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleTransfer()
            }
        })
    }

    var result = Object.keys(allbanks).map((key) => [key, allbanks[key]]);
    
  return (
    <div className='transfer deposit'>
        <Header />
        <div className="content d-flex">
            <CasaTags />
            <div className="withdraw-section">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-sm-12">
                        <form action="" onSubmit={showAlert}>
                            <h4>Transfer Funds </h4>
                            <div className="form-group">
                                <label htmlFor="">From (Account)</label>
                                <input type="number" name='debitAccount' onChange={handleChange} value={transfer.debitAccount} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Depositor Bank</label>
                                <select value={transfer.debitbankcode} 
                                    name="debitbankcode" 
                                    onChange={verifyDebitAccount} 
                                    style={{border : accountowner.debitErrorMsg ? "1px solid red" : "1px solid #d8d6d6"}}
                                    required
                                >
                                    <option value="" disabled>Select bank name</option>
                                    {
                                        result && result.map((item, index)=> {
                                            return <option value={item[0]} key={index}>{item[1]}</option>
                                        })
                                    }
                                </select>
                                <span className='account-name' style={{display : accountowner.debitor ? "flex" : "none"}}><p>{accountowner.debitor}</p></span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="number" value={transfer.creditAmount} name="creditAmount" onChange={handleChange}  required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">To (Account)</label>
                                <input type="number" value={transfer.creditAccount} name="creditAccount" onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor=""> Creditor Bank</label>
                                <select name="creditbankcode" 
                                value={transfer.creditbankcode} 
                                onChange={verifyCreditAccount}
                                style={{border : accountowner.creditErrorMsg ? "1px solid red" : "1px solid #d8d6d6"}} 
                                required>
                                    <option value="" disabled>Select bank name</option>
                                    {
                                        result && result.map((item, index)=> {
                                            return <option value={item[0]} key={index}>{item[1]}</option>
                                        })
                                    }
                                </select>
                                <span className='account-name' style={{display : accountowner.creditor ? "flex" : "none"}}><p>{accountowner.creditor}</p></span>
                            </div>
                            <div className="withdraw-btn">
                                <Button value="Send Funds" disabled={disabled}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaTransfer