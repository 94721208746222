import React, { useState } from 'react'
import Header from '../../components/header/header'
import Tags from '../../components/Tags/Tags'
import '../AllDocumentApplication/alldocumentapplication.css';
import '../MyDocumentAppplications/myapplicationpreview.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { userSelector } from '../../feature/Login/loginSlice';
import { documentBaseUrl } from '../../common/apis/userApi';
import QueuePreview from '../../components/QueuePreview/QueuePreview';
import { Link, useNavigate } from 'react-router-dom';
import editIcon from '../../assets/pencil (1).png';
import addfolder from '../../assets/add-folder.png';
import userimg from '../../assets/user.png';
import SupportingDocumentModal from '../../components/SupportingDocumentModal/SupportingDocumentModal';
import EditDocumentApplication from '../../components/EditDocumentApplication/EditDocumentApplication';

const medium = {
    backgroundColor : '#FEF6EF',
    color : 'rgb(168 130 77)',
    borderRadius : "20px",
    textAlign : "center",
    padding : '5px 10px',
    cursor : 'pointer'
}
const low = {
    backgroundColor : '#DEFCF8',
    color : "rgb(76 156 143)",
    borderRadius : "20px",
    textAlign : "center",
    padding : '5px 10px'
}
const high = {
    backgroundColor : '#FFEAF1',
    color : '#F094A9',
    borderRadius : "20px",
    textAlign : "center",
    padding : '5px 10px'
}


const MyDocumentPreview = () => {

    //comment
    const history = useNavigate();
    const { user } = useSelector(userSelector);
    const data = JSON.parse(sessionStorage.getItem("my-targetdoc"));

    const {additionalInfo, appType, applicationId, office_name, dept_id, dept_name, requester, subject, dateCreated, background, justification, memo_request, reviewerBy, staffConcur, approvingStaff, supportingDoc, watermark} = data[0];

    const renderDate = dateCreated.split("T")[0];
    const renderTime = dateCreated.split("T")[1].split('.')[0];

    const [concurReason, SetConcurReason] = useState("");
    const [approvalReason, setApprovalReason] = useState("")
    const [disabled, setDisabled] = useState(false);
    const [reviewComment, setReviewComment] = useState("");
    const [reviewerRejectText, setReviewerRejectText] = useState("");
    const [reviewMore, setReviewMore] = useState("");
    const [concurComment, setConcurComment] = useState("");
    const [approveComment, setApproveComment] = useState("");
    const [responseValue, setResponse] = useState("");

    const handleReviewComment = (e) => {
        setReviewComment(e.target.value);
    }
    const handleReviewMore = (e) => {
        setReviewMore(e.target.value);
    }
    const handleReviewReject = (e) => {
        setReviewerRejectText(e.target.value)
    }
    const handleConcurComment = (e) => {
        setConcurComment(e.target.value);
    }

    const handleApproveComment = (e) => {
        setApproveComment(e.target.value);
    }

    const handleResponse = (e) => {
        setResponse(e.target.value);
    }

    const submitReviewerComment = async (e) => {
        e.preventDefault();

        const jsonData = {
            response : reviewMore
        }

        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/info/${user.id}/${applicationId}/reviewer`, jsonData);
            const result = sendingdata.data;
            toast.success(result.response.data)
            setTimeout(() => {
                history(-1)
            }, 3000)
        } catch (error) {
            toast.error(error.response.data.response.data)
        }
    }
    const submitConcurComment = async (e) => {
        e.preventDefault();

        const jsonData = {
            response : concurComment
        }

        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/info/${user.id}/${applicationId}/concur`, jsonData);
            const result = sendingdata.data;
            toast.success(result.response.data)
            setTimeout(() => {
                history(-1)
            }, 3000)
        } catch (error) {
            toast.error(error.response.data.response.data)
        }
    }

    const submitApproveComment = async (e) => {
        e.preventDefault();

        const jsonData = {
            response : approveComment
        }
        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/info/${user.id}/${applicationId}/approval`, jsonData);
            const result = sendingdata.data;
            toast.success(result.response.data)
            setTimeout(() => {
                history(-1)
            }, 3000)
        } catch (error) {
            toast.error(error.response.data.response.data)
        }
    }

    const submitResponse = async (e) => {
        e.preventDefault();

        const { id } = e.currentTarget;

        const jsonData = {
            comment : responseValue
        }
        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/info/${applicationId}/${id}/respond`, jsonData);
            const result = sendingdata.data;
            toast.success(result.response.data)
            setTimeout(() => {
                history(-1)
            }, 3000)
        } catch (error) {
            toast.error(error.response.data.response.data)
        }
    }
    
    const approveReview = async (e) => {
        
        e.preventDefault();
        const { id } = e.currentTarget;
        const jsonData = {
            "comment" : reviewComment
        }
    
        try {
            const query = await axios.post(`${documentBaseUrl}/reviewer/${id}/${user.id}`, jsonData);
            const result = query.data.response.data;
            toast.success(result)
            setTimeout(() => {
                history('/my-applications')
                window.location.reload();
            }, 3000)
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
            console.log(errorMessage);
        }
    }

    const signConcurrence = async (e) => {
        
        const { id } = e.currentTarget;
    
        try {
            const query = await axios.get(`${documentBaseUrl}/concur/${id}/${user.id}`);
            const result = query.data.response.data;
            toast.success(result)
            setTimeout(() => {
                history('/document/concurrence-list')
                window.location.reload();
            }, 3000)
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
            console.log(errorMessage);
        }
    }

    const signApproval = async (e) => {
        
        const { id } = e.currentTarget;
    
        try {
            const query = await axios.get(`${documentBaseUrl}/approval/${id}/${user.id}`);
            const result = query.data;
            if(result.code === 200){
                const responsemsg = result.response.data;
                toast.success(responsemsg);
                setTimeout(() => {
                    history('/document/approval-list')
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
            console.log(errorMessage);
        }
    }

    const handleconcurRejection = (e) => {
        SetConcurReason(e.target.value);
    }

    const handleapprovalRejection = (e) => {
        setApprovalReason(e.target.value);
    }

    const rejectReviewer = async (e) => {
        
        e.preventDefault();
        const { id } = e.currentTarget;
    
        const jsondata = {
                "reason": reviewerRejectText,
            }
    
        try {
            const query = await axios.post(`${documentBaseUrl}/reviewer_reject/${id}/${user.id}`, jsondata);
            const result = query.data;
            if(result.code === 200){
                const responsemsg = result.response.data;
                toast.success(responsemsg);
                setTimeout(() => {
                    history('/document/approval-list')
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
            console.log(errorMessage);
        }
    }
    const rejectApproval = async (e) => {
        
        e.preventDefault();
        const { id } = e.currentTarget;
    
        const jsondata = {
                "reason": approvalReason,
            }
    
        try {
            const query = await axios.post(`${documentBaseUrl}/staffdeclineapproval/${id}/${user.id}`, jsondata);
            const result = query.data;
            if(result.code === 200){
                const responsemsg = result.response.data;
                toast.success(responsemsg);
                setTimeout(() => {
                    history('/document/approval-list')
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
            console.log(errorMessage);
        }
    }

    const rejectConcur = async (e) => {
        
        e.preventDefault();
        const { id } = e.currentTarget;
        const jsondata = {
            "reason": concurReason,
        }
    
        try {
            const query = await axios.post(`${documentBaseUrl}/staffdeclineconcur/${id}/${user.id}`, jsondata);
            const result = query.data;
            if(result.code === 200){
                const responsemsg = result.response.data;
                toast.success(responsemsg);
                setTimeout(() => {
                    history('/document/concurrence-list');
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            const errorMessage = error.response.data.response.data;
            toast.error(errorMessage);
        }
    }

  return (
    <div className='mydocument-preview all-document'>
        <Header />
        <div className="container_">
            <Tags />
            <div className="application-wrapper p-4">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="account-lists d-width bg-white p-4 rounded">
                    <h4 className='mb-4'>Document Details</h4>
                    <div className="account-body d-flex justify-content-between">
                        <div className='left-content'>
                            <div className="item">
                                <h5 className='title'>App Type</h5>
                                <p>{appType}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Subject</h5>
                                <p>{subject}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Background</h5>
                                <p className=''>{background}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Justification</h5>
                                <p className=''>{justification}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Memo Request</h5>
                                <p className=''>{memo_request}</p>
                            </div>
                            <div className="item w-40">
                                <h6>Reviewer</h6>
                                {
                                    reviewerBy.map(({staffName, reviewed, comment, review_staffid, reviewer_reject}, id) => {
                    
                                        let renderingText = "";
                                        if(reviewed){
                                            renderingText = <p style={low}>Reviewed</p>
                                        }else if(review_staffid === user.id && !reviewer_reject){
                                            renderingText = <div className="d-flex">
                                                    <button className='sign'>
                                                        <span className='edit text-center' type="button" data-bs-toggle="modal" data-bs-target={`#review-btn${id}`}>
                                                            Review
                                                        </span>
                                                    </button>
                                                    <button className='reject' id={applicationId}>
                                                        <span className='edit text-center' type="button" data-bs-toggle="modal" data-bs-target={`#reject-review${id}`}>
                                                            Reject
                                                        </span>
                                                    </button>
                                                    <button className='grey'>
                                                        <span type="button" data-bs-toggle="collapse" data-bs-target={`#reviewer-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                            More
                                                        </span>
                                                    </button>  
                                                </div>
                                        }else if(reviewer_reject){
                                            renderingText  = <p style={high}>Rejected</p>
                                        }else{
                                            renderingText = <p style={medium}>Review pending</p>
                                        }
                                        return(
                                            <div className='review-comment' key={id}>
                                                <div className="item">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className='title'>Name</h5>
                                                    </div>
                                                    <p>{staffName}</p>
                                                </div>
                                                {
                                                    comment && <div className='item'>
                                                        <h5 className='title'>Comment</h5>
                                                        <p>{comment}</p>
                                                    </div>
                                                }
                                                <div className="item">
                                                    {renderingText}
                                                    <div className="collapse" id={`reviewer-form${id}`}>
                                                        <div className="form-wrapper w-100">
                                                            <h4>Comment</h4>
                                                            <form className="w-100" onSubmit={submitReviewerComment} id={id}>
                                                                <textarea 
                                                                className='w-100'  
                                                                placeholder='Enter you comment here'
                                                                value={reviewMore} 
                                                                name="concurComment" 
                                                                onChange={handleReviewMore} 
                                                                col="10"
                                                                rows="4"
                                                                required
                                                                />
                                                                <button type='submit' id={id}>Submit</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`review-btn${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel"> Comment</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form action="">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Review comment(optional)</label>
                                                                        <textarea name="reviewComment" value={reviewComment} onChange={handleReviewComment} cols="10" rows="6"></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button type="submit" onClick={approveReview} id={applicationId} disabled={disabled}>Review Document</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`reject-review${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel"> Comment</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form action="">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Reason</label>
                                                                        <textarea name="reviewComment" value={reviewerRejectText} onChange={handleReviewReject} cols="10" rows="6"></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button type="submit" onClick={rejectReviewer} id={applicationId} disabled={disabled}>Reject Document</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="item w-40">
                                <h6>Concurrence Staff names</h6>
                                {
                                    staffConcur.map(({staffname, signed, decline, staffid}, id) => {
                                        let renderingConcurText = "";
                                        if(signed){
                                            renderingConcurText = <p style={low}>Signed</p>
                                        }else if(decline.length >= 1){
                                            renderingConcurText = <div className='item'>
                                                <p style={high} className="py-2">Rejected</p>
                                            </div>
                                        }else if(staffid === user.id){
                                            renderingConcurText = <div className="c-btns d-flex">
                                                    <button className='sign' onClick={signConcurrence} id={applicationId}>Concur</button>
                                                    <button className='reject' id={applicationId}>
                                                        <span className='edit text-center' type="button" data-bs-toggle="modal" data-bs-target={`#reject-concur${id}`}>
                                                            Reject
                                                        </span>
                                                    </button>
                                                    <button className='grey'>
                                                        <span type="button" data-bs-toggle="collapse" data-bs-target={`#comment-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                            More
                                                        </span>
                                                    </button>
                                            </div>
                                        }else{
                                            renderingConcurText = <p style={medium}>Pending Action</p>
                                        }
                                        return(
                                            <div className="mb-3" key={id}>
                                                <div className="item">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className='title'>{`Concurrence ${id+1}`}</h5>
                                                    </div>
                                                    <p>{staffname}</p>
                                                </div>
                                                {
                                                    (decline.length >= 1) && <div className="item">
                                                        <h5 className='title'>Reason</h5>
                                                        <p>{decline[0].reason}</p>
                                                    </div>
                                                }
                                                <div className="item">
                                                    {renderingConcurText}
                                                    
                                                    <div className="collapse" id={`comment-form${id}`}>
                                                        <div className="form-wrapper w-100">
                                                            <h4>Comment</h4>
                                                            <form className="w-100" onSubmit={submitConcurComment} id={id}>
                                                                <textarea 
                                                                className='w-100'  
                                                                placeholder='Enter you comment here'
                                                                value={concurComment} 
                                                                name="concurComment" 
                                                                onChange={handleConcurComment} 
                                                                col="10"
                                                                rows="4"
                                                                required
                                                                />
                                                                <button type='submit' id={id}>Submit</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="modal fade" id={`reject-concur${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" style={{minWidth : "350px", maxWidth : "500px"}}>
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Rejection Reason</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form action="">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Enter reason for rejection</label>
                                                                        <textarea name="concurReason" value={concurReason} onChange={handleconcurRejection} cols="10" rows="6"></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button type="submit" onClick={rejectConcur} id={applicationId} disabled={disabled}>Reject Concurrence</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="item w-40">
                                <h6>Approving Staff names</h6>
                                {
                                    approvingStaff.map(({staffname, signed, decline, staffid}, id) => {

                                        let renderingApprovalText = "";
                                        if(signed){
                                            renderingApprovalText = <p style={low}>Signed</p>
                                        }else if(decline.length >= 1){
                                            renderingApprovalText = <div>
                                                <p className='py-2' style={high}>Rejected</p>
                                            </div>
                                        }else if(staffid === user.id ){
                                            renderingApprovalText = <div className="c-btns d-flex">
                                                <button className='sign' onClick={signApproval} id={applicationId}>Approve</button>
                                                <button className='reject' id={applicationId}>
                                                    <span className='edit text-center' type="button" data-bs-toggle="modal" data-bs-target={`#reject-approval${id}`}>
                                                        Reject
                                                    </span>
                                                </button>
                                                <button className="grey">
                                                    <span type="button" data-bs-toggle="collapse" data-bs-target={`#approve-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                        More
                                                    </span>
                                                </button>
                                            </div>
                                        }else{
                                            renderingApprovalText = <p style={medium}>Pending Action</p>
                                        }

                                        return(
                                            <div key={id} className="mb-3">
                                                <div className="item">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className='title'>Name</h5>
                                                    </div>
                                                    <p>{staffname}</p>
                                                </div>
                                                {
                                                    (decline.length >= 1) && <div className="item">
                                                        <h5 className='title'>Reason</h5>
                                                        <p>{decline[0].reason}</p>
                                                    </div>
                                                }
                                                <div className="item">
                                                    {
                                                       renderingApprovalText
                                                    }
                                                    <div className="collapse" id={`approve-form${id}`}>
                                                        <div className="form-wrapper w-100">
                                                            <h4>Comment</h4>
                                                            <form className="w-100" onSubmit={submitApproveComment} id={id}>
                                                                <textarea 
                                                                className='w-100'  
                                                                placeholder='Enter you comment here'
                                                                value={approveComment} 
                                                                name="approveComment" 
                                                                onChange={handleApproveComment} 
                                                                col="10"
                                                                rows="4"
                                                                required
                                                                />
                                                                <button type='submit' id={id}>Submit</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`reject-approval${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered" style={{minWidth : "350px", maxWidth : "500px"}}>
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Rejection Reason</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form action="">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Enter reason for rejection</label>
                                                                        <textarea name="approvalReason" value={approvalReason} onChange={handleapprovalRejection} cols="10" rows="6"></textarea>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button type="submit" onClick={rejectApproval} id={applicationId} disabled={disabled}>Reject Approval</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {
                                (additionalInfo.length >= 1) && <div className='response'>

                                    <h4>Response Thread</h4>
                                    {
                                        additionalInfo.map(({requested_by, comment, date_requested, rid, response}) => {

                                            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                                            const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

                                            const newDate = new Date(date_requested);

                                            const [month, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
                                            const [hour, minutes] = [newDate.getHours(), newDate.getMinutes()];

                                            return(
                                                <div className='reply-wrapper mt-4' key={rid}>
                                                    <div className="reply-body">
                                                        <h5>{requested_by}</h5>
                                                        <p className='date'>{`${weekday[dy]} ${day} ${months[month]}, ${year} || ${hour}:${minutes}`}</p>
                                                        <p>{comment}</p>
                                                        <div className="response-list">
                                                            {
                                                                response && <div className='d-flex'>
                                                                                <div className="image">
                                                                                    <img src={userimg} alt="" />
                                                                                </div>
                                                                                <div className="response-body">
                                                                                    <h4>Response</h4>
                                                                                    <p>{response}</p>
                                                                                </div>
                                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="reply-btn">
                                                            <button>
                                                                <span type="button" data-bs-toggle="collapse" data-bs-target={`#reply-form${rid}`}aria-expanded="false" aria-controls="collapseExample">
                                                                    Reply
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="collapse" id={`reply-form${rid}`}>
                                                            <div className="form-wrapper w-100">
                                                                <form className="w-100" onSubmit={submitResponse} id={rid}>
                                                                    <textarea 
                                                                    className='w-100'  
                                                                    placeholder='Enter you comment here'
                                                                    value={responseValue} 
                                                                    name="responseValue" 
                                                                    onChange={handleResponse} 
                                                                    col="10"
                                                                    rows="4"
                                                                    required
                                                                    />
                                                                    <button type='submit' id={rid}>Submit</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                        </div>
                        <div className="right-content">
                            <div className="item">
                                <h5 className='title'>Sender</h5>
                                <p>{requester[0].requesterName}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Receiver</h5>
                                <p>{office_name}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Application ID</h5>
                                <p>{applicationId}</p>
                            </div>
                            <div className="item">
                                <h5 className='title'>Date created</h5>
                                <p>{`${renderDate} | ${renderTime}`}</p>
                            </div>
                            <div className="item add-doc">
                                <h5 className='title'>Supporting document</h5>
                                <span className='text-center text-decoration-underline' data-bs-toggle="modal" data-bs-target={`#add-doc-modal`} role="button">
                                    <img src={addfolder} alt="add-folder" />
                                    Add
                                </span>
                                <ul>
                                    {
                                    supportingDoc && supportingDoc.map(({docsUrl}, id) => {
                                        return(
                                            <li className="docs-array my-3" key={id}>
                                                    <a href={docsUrl} target='_blank'>Attached Document</a>
                                            </li>
                                        )
                                    })
                                    }  
                                </ul> 
                            </div>
                            <div className="item">
                                <span className='text-center text-decoration-underline' data-bs-toggle="modal" data-bs-target={`#edit-doc`} role="button">
                                    <img src={editIcon} alt="add-folder" />
                                    Edit Application
                                </span>
                            </div>
                            <SupportingDocumentModal documentId={applicationId} />
                            <EditDocumentApplication 
                            applicationId={applicationId} 
                            subject={subject}
                            memo_request={memo_request}
                            justification={justification}
                            background={background}
                            app_type={appType}
                            dept={dept_id}
                            />
                        </div>
                    </div>
                </div>
                <div className="lower-btn mt-5">
                    <Link to='/preview/document-format' className='text-center text-decoration-underline text-primary'>
                        View in file format
                    </Link>   
                    {/* <span className='text-center text-decoration-underline text-primary' data-bs-toggle="modal" data-bs-target={`#docModal`} role="button">
                        View in file format
                    </span>    */}
                </div>

                <div className="modal fade" id={`docModal`} tabIndex="-1" aria-labelledby="docModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header no-print">
                                <h5 className="modal-title" id="docModalLabel">In Document view</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <QueuePreview 
                                appType={appType} 
                                applicationId={applicationId} 
                                office_name={office_name} 
                                requester={requester} 
                                dept_name={dept_name} 
                                subject={subject} 
                                dateCreated={dateCreated}
                                background={background} 
                                justification={justification} 
                                memo_request={memo_request} 
                                reviewerBy={reviewerBy} 
                                staffConcur={staffConcur} 
                                approvingStaff={approvingStaff}
                                watermark={watermark}
                                />
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyDocumentPreview;
