import React from 'react'

const SupportingDocument = ({ setfileData, setValid }) => {

    setValid(true);
    const handleChange = (e) => {
        setfileData(e.target.files[0]);
    }

  return (
    <div className='supporting-doc'>
        <div className="content">
            <div className="form-group">
                <label htmlFor="docfile">Attach supporting document (PDF only)</label>
                <input type="file" className='docfile' onChange={handleChange}/>
            </div>
        </div>
    </div>
  )
}

export default SupportingDocument