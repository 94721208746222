import React, { useState } from 'react'
import { Table, Tag, Pagination } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Header from '../../components/header/header'
import '../AllDocumentApplication/alldocumentapplication.css';
import '../MyDocumentAppplications/myapplicationpreview.css';
import Tags from '../../components/Tags/Tags'
import { documentBaseUrl } from '../../common/apis/userApi'
import { userSelector } from '../../feature/Login/loginSlice'
import view from '../../assets/navigation.png';

const MyDocumentApplications = () => {

    const history = useNavigate();

    const { user } = useSelector(userSelector);
    const [allApplication, setAllApplication] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [loading, setloading]  = useState(false);

    const fetchingData = async (page) => {
        setloading(true)
        try {
            const query = await axios.get(`${documentBaseUrl}/myapplication/${user.id}?page=${page}`);
            const result = query.data;
            setAllApplication(result);
            setpageCount(result.count);
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }

    useState(() => {
        fetchingData(1);
    }, []);

    const applicationList = allApplication.results

    const handlePreview = (id) => {
        const targetItem = applicationList.filter(item => item.applicationId === id);
        sessionStorage.setItem('my-targetdoc', JSON.stringify(targetItem));
        history(`/preview`);
    }

    const columns = [
        {
          title: 'Document ID',
          dataIndex: 'applicationId',
          key: 'applicationId',
          render: (text, record) => <p onClick={() => handlePreview(record.applicationId)}>{text}</p>,
        },
        {
          title: 'Subject',
          dataIndex: 'subject',
          key: 'subject',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render : (_, {reviewerBy, approvingStaff, staffConcur}) => {
                let statusText = "pending"
                let color = ""

                let approvalText = "pending"
                let approvalColor = ""

                const concurStatus = staffConcur.filter(item => item.signed === false);
                const concurSigned = staffConcur.filter(item => item.signed === true);
                const concurReject = staffConcur.filter(item => item.decline.length >= 1);

                if(concurStatus.length !== 0){
                    statusText = "Awaiting concurr"
                    color = "magenta"
                }else if(concurSigned && concurStatus.length === 0){
                    statusText = "Concurred"
                    color = "geekblue"
                }else if(concurReject && concurStatus.length !== 0){
                    statusText = "Rejected"
                    color ="volcano"
                }

                const approvalStatus = approvingStaff.filter(item => item.signed === false);
                const approvalSigned = approvingStaff.filter(item => item.signed === true);
                const approvalReject = approvingStaff.filter(item => item.decline.length >= 1);

                if(approvalStatus.length !== 0){
                    approvalText = "Awaiting approval"
                    approvalColor = "magenta"
                }else if(approvalSigned && approvalStatus.length === 0){
                    approvalText = "Approved"
                    approvalColor = "green"
                }else if(approvalReject && approvalStatus.length !== 0){
                    approvalText = "Rejected"
                    approvalColor = "volcano"
                }

            return(
                <>
                    {
                        reviewerBy.map(({reviewed, reviewer_reject}, id) => {

                            let statusText = ''
                            let color = ''
                            if(reviewed === false && reviewer_reject === false){
                                statusText = "Awaiting review"
                            }else if(reviewer_reject === true){
                                statusText = "Rejected"
                            }else{
                                statusText = "Reviewed"
                            }

                            if(statusText === "Awaiting review"){
                                color = "magenta"
                            }else if(statusText === "Reviewed"){
                                color = "orange"
                            }else if(statusText === "Rejected"){
                                color = "red"
                            }
                            return(
                                <Tag color={color} key={id} style={{marginBottom : "6px"}}>
                                    {statusText}
                                </Tag>
                            )
                        })
                    }
                    <Tag color={color}>
                        {statusText}
                    </Tag>
                    <Tag color={approvalColor}>
                        {approvalText}
                    </Tag>
                </>
            )
          },
        },
        {
          title: 'To',
          key: 'office_name',
          dataIndex: 'office_name',
        },
        {
          title: 'Action',
          dataIndex : "applicationList",
          key: 'applicationList',
          render: (item, record) => <p>
              <img src={view} onClick={() => handlePreview(record.applicationId)} className="eye" alt='eye'/>
          </p>,
        },
    ];
    
    const data = applicationList;

    // console

  return (
    <div className='all-document'>
        <Header />
        <div className="container_">
            <Tags />
            <div className="application-wrapper p-5">
                <div className="bg-white p-3">
                    <h4>Application History</h4>
                    <Table loading={loading} columns={columns} dataSource={data} pagination={false}/>
                    <div className="pagination">
                        <Pagination 
                            pageSize={5}
                            total={pageCount}
                            onChange={(page) => fetchingData(page)}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyDocumentApplications;