import React from 'react'
import '../HelpDeskComponent/help-desk-style.css';
import { Link } from 'react-router-dom';
import user from '../../assets/default.png';
import AdminDoughnutGraph from '../AdminDoughnutGraph/AdminDoughnutGraph';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import KYCTAB from '../../pages/KYC/KYCTAB';
ChartJS.register(ArcElement, Tooltip, Legend);

const medium = {
    backgroundColor : '#DBE9EF',
    color : '#274653',
    borderRadius : "15px",
    textAlign : "center"
}
const low = {
    backgroundColor : '#DBE9FE',
    color : "#7FB5FF",
    borderRadius : "15px",
    textAlign : "center"
}
const high = {
    backgroundColor : '#FDE2E1',
    color : '#F03737',
    borderRadius : "15px",
    textAlign : "center"
}

const HelpDeskComponent = ({tickets, open, pending, solved}) => {

    const ticketLists = tickets.data;
    const ticketcount = tickets.meta;

    const reversedArray = ticketLists && [...ticketLists].reverse();

    const data = {
        labels: ['Total tickets', 'Total Pending tickets', 'Total Resolved', "Open Tickets"],
        datasets: [
          {
            label: 'All transaction charts',
            data: [ticketcount?.total, pending, solved, open],
            backgroundColor: [
              '#E67E22',
              '#002D63',
              '#440062',
              '#FEF6E1'
            ],
          },
        ],
    };

  return (
    <div className="help-component m-hin">
        <KYCTAB menuFor="Prime Serve"/>
        <div className="prime_wrapper">
            <div className="d-flex justify-content-between align-items-center">
                <span>Staff</span>
                <img src={user} alt="" width="40px"/>
            </div>
            <h3 className='top-title mt-0'>Overview</h3>
            <div className="ticket-counts">
                {
                    ticketcount && <div className="pending" style={{background : "#E6ECFF"}}>
                        <div className="text">
                            <p>All Tickets</p>
                            <h3>{ticketcount.total}</h3>                    
                        </div>
                    </div>
                }
                <div className="open" style={{background : "#FEF6E1"}}>
                    <div className="text">
                        <p>Open Tickets</p>
                        <h3>{open || 0}</h3>                 
                    </div>
                </div>
                <div className="solved" style={{background : "#DAF3F1"}}>
                    <div className="text">
                        <p>Pending Tickets</p>
                        <h3>{pending || 0}</h3>   
                    </div>
                </div>
                <div className="answered" style={{background : "#FAE9E3"}}>
                    <div className="text">
                        <p>Resolved Tickets</p>
                        <h3>{solved || 0}</h3>
                    </div>
                </div>
            </div>
            <div className="lower-views d-flex">
                <AdminDoughnutGraph data={data} ticketCounts={ticketcount}/>
                <div className="tickets-table pb-5">
                    <div className="recent_tickets d-flex justify-content-between">
                        <p className='recent'>Recent Tickets</p>
                        <Link to="/helpdesk/ticket-assigned-to-me">
                            <span className='view-all'>view all</span>
                        </Link>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>Ticket Id</td>
                                <td className='category'>Category</td>
                                <td className='text-center'>Priority</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reversedArray && reversedArray?.filter((item, index) => (index < 4)).map(({id, category, priority, ticket_id }) => {

                                    let renderStyle;
                                    if(priority == "high"){
                                        renderStyle = high;
                                    }else if(priority == "medium"){
                                        renderStyle = medium
                                    }else if(priority == "low"){
                                        renderStyle = low
                                    }
                                    return(
                                        <tr key={ticket_id}>
                                            <td className='ticket_id'>{ticket_id}</td>
                                            <td className=''><p>{category?.name}</p></td>
                                            <td>
                                                <p style={renderStyle} className="priority">{priority}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                ticketLists?.length < 1 && <p className='mt-5' style={{ textAlign : "left", color : "GrayText"}}>No ticket found</p>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpDeskComponent;