import React, { useState } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../CasaOperations/casadeposit.css';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../../common/button/button';

const CasaDeposit = () => {

    const [disabled, setdisabled] = useState(false);
    const [deposit, setDeposit] = useState({
        amount : "",
        accountNo : "",
        narration : "",
        depositor : ""
    });

    const handleChange = (e) => {
        setDeposit({...deposit, [e.target.name] : e.target.value})
    }

    const handleDeposit = async (e) => {
        e.preventDefault();
        setdisabled(true)
        const { amount, accountNo, narration, depositor } = deposit
        const JsonData = {
            "amount": amount,
            "accountNo": accountNo,
            "naration": narration,
            "depositor": depositor
        }
        try {
            const query = await axios.post(`${casaBaseurl}deposit`, JsonData);
            const response = query.data;
            setdisabled(false);
            if(response.code === 200){
                toast.success("Transaction successfully")
                setDeposit({amount : "", accountNo : "", narration : "", depositor : ""})
            }
            console.log(response)
        } catch (error) {
            setdisabled(false)
            const errorMesssage = error.response.data.body.data;
            if(errorMesssage.code === 400){
                toast.error(errorMesssage.message.info)
            }else{
                toast.error(errorMesssage)
            }
        }
        
    }

  return (
    <div className='deposit'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="withdraw-section">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-sm-12">
                        <form action="" onSubmit={handleDeposit}>
                            <h4>Deposit </h4>
                            <div className="form-group">
                                <label htmlFor="">Depositor Name</label>
                                <input type="text" value={deposit.depositor} name="depositor" onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Account Number</label>
                                <input type="number" value={deposit.accountNo} name="accountNo" onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="number" value={deposit.amount} name="amount" onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Narration</label>
                                <textarea cols="30" rows="10" 
                                value={deposit.narration} 
                                name="narration" 
                                onChange={handleChange}
                                required
                                >
                                </textarea>
                            </div>
                            <div className="withdraw-btn w-100">
                                <Button 
                                className='w-100'
                                disabled={disabled} 
                                type={"submit"}
                                value={"Deposit"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaDeposit;