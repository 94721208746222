import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../../dahsboardHome/dashboard.css';
import '../../Menus/Menu.css';
import '../../Permissions/Permissions.css'
import HomeTags from '../../../components/HomeTags/HomeTags';
import { casaBaseurl } from '../../../common/apis/userApi';
import AddUsermodal from './AddUsermodal';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';
import searchimg from '../../../assets/search.svg';
import EditPermission from './EditPermission';

const PermissionList = () => {

    const { token } = useSelector(userSelector);
    const [users, setUsers] = useState([]);
    const [allStaff, setAllStaff] = useState([]);
    const [selectedId, setSelectedId] = useState("")

    const [search, setSearch] = useState("");

    const [active] = useState({
        dashboard : false,
        myProfile : false,
        menus : false,
        permission : true,
        user2fa : false,
        oldirs : false
    })

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const query = await axios.get(`${casaBaseurl}addstaff`);
                const response = query.data.body;
                setUsers(response)

                const allStaff = await getAxiosInstance(token).get(`/staff/all`)
                const result = allStaff.data;
                setAllStaff(result)
            } catch (error) {
                console.log(error)
            }
        }

        fetchingData();
     }, [token])

    const handleChange = (e) => {
        e.persist();
        setSearch(e.target.value)
    }
    
    const userList = users?.data;
    const filterArray = userList?.filter(item => item.staffname.toLowerCase().includes(search.toLowerCase()))

  return (
    <div className='menus dashboard_ permissionlist'>
        <div className="container___">
            <ToastContainer closeButton={true} position="top-right" />
            <div className="dashboard-wrapper">
                <HomeTags active={active}/>
                <div className="document-type-list">
                    <h3>CASA Permission List</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <form>
                            <input type="search" placeholder='filter' value={search} onChange={handleChange}/>
                            <img src={searchimg} alt="" />
                        </form>
                        <span 
                            className='edit text-center' 
                            type="button" 
                            data-bs-toggle="modal" 
                            data-bs-target="#add-user"
                            aria-expanded="false" 
                            aria-controls="collapseExample" 
                        >
                            Add User
                        </span>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Name</h5>
                            <h5 className=''>Email</h5>
                            <h5 className='text-cen'>Designation</h5>
                            <h5 className='text-center'>Permission</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            filterArray && filterArray.map(({ id, staffname, staffemail, designation,irs_id, permission }) => {
                               
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <div className='colored'>
                                                <p>{staffname}</p>
                                            </div>
                                            <p className='text-ce'>{staffemail}</p>
                                            <p className='text-ce'>{designation}</p>
                                            <p className='role'>
                                                {
                                                    permission.map((item, index) => {
                                                        return(
                                                            <span className='text-cent' key={index}>{item}</span>
                                                        )
                                                    })
                                                }
                                            </p>
                                            
                                            <div className="d-flex justify-content-center text-center">
                                                <span 
                                                className='edit text-center' 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target={`#edit-form${id}`}
                                                aria-expanded="false" 
                                                aria-controls="collapseExample" 
                                                onClick={() => setSelectedId(irs_id) }
                                                >
                                                    Edit
                                                </span>
                                            </div>
                                        </div>
                                        <EditPermission id={id}
                                        selectedId={selectedId}
                                        staffname={staffname}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </div>
                </div>
                <AddUsermodal allStaff={allStaff}/>
            </div>
        </div>
    </div>
  )
}

export default PermissionList




async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST',
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }