import React, { useEffect, useState } from 'react'
import PersonalInformation from './Newkyc/PersonalInformation'
import { useFormik } from 'formik';
import { Button, message, Spin } from 'antd';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import KYCTags from './KYCTags';
import './kyc.css';
import KYCTAB from './KYCTAB';
import arrow from '../../assets/Vector (1).svg';
import AccountInformation from './Newkyc/AccountInformation';
import BusinessInformation from './Newkyc/BusinessInformation';
import Officers from './Newkyc/Officers';
import { userSelector } from '../../feature/Login/loginSlice';
import axios from 'axios';
import { irsAuthBaseUrl } from '../../common/apis/userApi';
import { useNavigate } from 'react-router-dom';
import { getAxiosInstance } from '../../common/apis/createAxios';

const KYC = () => {

    const navigate = useNavigate()
    const [valid, setValid] = useState(false);
    const [current, setCurrent] = useState(0);
    const [staff, setStaff] = useState([]);
    const [opStaff, setOpStaff] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    // const [loading, setLoading] = useState(false);

    const { token, user } = useSelector(userSelector)

    const formik = useFormik({
        initialValues: {
            date_of_incorporation: "",
            company_name: "",
            rc_number : "",
            nature_of_business : "",
            industry : "",
            company_type : "",
            sector : "",
            tin : "",
            certificate_type : "",
            nos_of_directors : "",
            directors_bvn : "",
            director_bvn_two : "",
            directors_id_type : "",
            politically_exposed_person : "",
            company_address : "",
            telephone : "",
            source_of_funds : "",
            authorized_capital : "",
            estimated_monthly_turn_over: "",
            method_of_initial_deposit : "",
            antimoney_laundry_risk_rating : "",
            customer_type : "",
            address_visited : "",
            comments_on_location : "",
            name_of_visiting_staff : "",
            account_introducer : "",
            supervisorName : "",
            data_entry_officer : "",
            company_email : "",
            initial_deposit : "",
            company_others : "",
        },

        validationSchema: Yup.object({
            date_of_incorporation: Yup.string().required('Required'),
            company_name: Yup.string().required('required.'), 
            rc_number: Yup.string().required('Required.'), 
            nature_of_business: Yup.string().required('Required.'), 
            industry: Yup.string().required('Required.'), 
            tin: Yup.string().required('Required.'), 
            company_type: Yup.string().required('Required.'), 
            sector: Yup.string().required('Required.'), 
            certificate_type: Yup.string().required('Required.'), 
            nos_of_directors: Yup.number().required('Required.'), 
            directors_bvn: Yup.string().required('Required.'), 
            director_bvn_two: Yup.string().required('Required.'), 
            directors_id_type: Yup.string().required('Required.'), 
            politically_exposed_person: Yup.string().required('Required.'), 
            antimoney_laundry_risk_rating: Yup.string().required('Required.'),
            estimated_monthly_turn_over: Yup.number().required('Required.'), 
            initial_deposit : Yup.number().required('Required.'), 
            company_address : Yup.string().required('Required.'), 
            telephone: Yup.number().required('Required.'), 
            source_of_funds: Yup.string().required('Required.'), 
            authorized_capital: Yup.string().required('Required.'), 
            method_of_initial_deposit: Yup.string().required('Required.'), 
            customer_type: Yup.string().required('Required.'), 
            address_visited: Yup.string().required('Required.'), 
            comments_on_location : Yup.string().required('Required.'), 
            name_of_visiting_staff : Yup.string().required('Required.'), 
            account_introducer : Yup.string().required('Required.'), 
            supervisorName: Yup.string().required('Required.'), 
            data_entry_officer : Yup.string().required('Required.'), 
            company_email : Yup.string().email('Invalid Email Address').required('Required.'), 
            company_others : Yup.string(), 
        }),
        validateOnMount : false,     
        onSubmit : (values, onSubmitProps) => {
             handleSubmit(values, onSubmitProps)
            }
        }
    );

    useEffect(() => {
        const fetchingData = async () => {
            const allSupervisor = await axios.get(`${irsAuthBaseUrl}/kyc/corporate-account/supervisors`)
            const response = allSupervisor.data
            setStaff(response.data);

            const allStaff = await getAxiosInstance(token).get('/staff/active');
            const result = allStaff.data;
            setOpStaff(result.data)
        }
        fetchingData()
    }, [])

    const handleSubmit = async (values, onSubmitProps) => {

        let companyType = "";

        if(values.company_type === "others"){
            companyType = values.company_others;
        }else{
            companyType = values.company_type;
        }

        const formData = new FormData();
        formData.append("date_of_incorporation", values.date_of_incorporation);
        formData.append("company_name", values.company_name)
        formData.append("rc_number", values.rc_number)
        formData.append("nature_of_business", values.nature_of_business)
        formData.append("industry", values.industry)
        formData.append("company_type", values.company_type)
        formData.append("sector", values.sector)
        formData.append("certificate_type", values.certificate_type)
        formData.append("tin", values.tin)
        formData.append("nos_of_directors", values.nos_of_directors)
        formData.append("directors_bvn", values.directors_bvn)
        formData.append("director_bvn_two", values.director_bvn_two)
        formData.append("directors_id_type", values.directors_id_type)
        formData.append("politically_exposed_person", values.politically_exposed_person)
        formData.append("company_address", values.company_address)
        formData.append("telephone", values.telephone)
        formData.append("source_of_funds", values.source_of_funds);
        formData.append("authorized_capital", values.authorized_capital)
        formData.append("estimated_monthly_turn_over", values.estimated_monthly_turn_over)
        formData.append("method_of_initial_deposit", values.method_of_initial_deposit)
        formData.append("antimoney_laundry_risk_rating", values.antimoney_laundry_risk_rating)
        formData.append("customer_type", companyType)
        formData.append("address_visited", values.address_visited)
        formData.append("comments_on_location", values.comments_on_location)
        formData.append("name_of_visiting_staff", values.name_of_visiting_staff)
        formData.append("account_introducer", values.account_introducer)
        formData.append("officer_id", values.supervisorName)
        formData.append("sales_officer", values.data_entry_officer)
        formData.append("company_email", values.company_email)
        formData.append("initial_deposit", values.initial_deposit)
        formData.append("ro_signature", imageUrl)
        formData.append("requester_id", user.id.toString())

        try {
            const creatingKyc = await axios.post(`${irsAuthBaseUrl}/kyc/corporate-account/create`, formData);
            const response = creatingKyc.data;
            message.success(response.message)
            setTimeout(() => {
                navigate('/kyc/accounts')
            },1500)
        } catch (error) {
            const responseError = error.response.data.message
            if(responseError?.company_name){
                message.error(responseError?.company_name[0])
            }
            if(responseError?.rc_number){
                message.error(responseError?.rc_number[0])
            }
            if(responseError?.telephone){
                message.error(responseError?.telephone[0])
            }
            if(responseError?.tin){
                message.error(responseError?.tin[0])
            }
            if(responseError?.ro_signature){
                message.error(responseError?.ro_signature[0])
            }
            onSubmitProps.setSubmitting(false);
        }
    }

    const steps = [
        {
           content : <PersonalInformation formik={formik} setValid={setValid}/>
        },
        {
           content : <AccountInformation formik={formik} setValid={setValid}/>
        },
        {
           content : <BusinessInformation formik={formik} setValid={setValid}/>
        },
        {
           content : <Officers formik={formik} staff={staff} opStaff={opStaff} setImageUrl={setImageUrl}/>
        },
    ]

    const next = () => {
        // setCurrent(current + 1)
        if(valid){
          setCurrent(current + 1);
          setValid(false)
        }else{
          message.success('Complete the fields!')
        }
    };
    
    const prev = () => {
        setCurrent(current - 1);
    };

  return (
    <div className='kyc'>
        <div className="kyc-wrapper d-flex">
            <KYCTags />
            <div className="main-content">
                <KYCTAB menuFor={"Corporate KYC"}/>
                <div className="breadcrumbs">
                    <h4>Staff 
                        <img src={arrow} alt="" width="10px" height="10px"/>
                        <span>KYC Form</span>
                    </h4>
                    <p className='highlights'>
                        <span style={{color : current === 0 ? "blue" : "GrayText"}}>Registration Data</span>
                        <span style={{color : current === 1 ? "blue" : "GrayText"}}>Business Information</span>
                        <span style={{color : current === 2 ? "blue" : "GrayText"}}>Contact Information</span>
                        <span style={{color : current === 3 ? "blue" : "GrayText"}}>Approvers</span>
                    </p>
                </div>
                <div className="steps-wrapper">
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} className="cancel" onClick={() => prev()}>
                                Previous
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary"  className='next' onClick={() => next()}>
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" className='next' onClick={() => formik.submitForm()} disabled={formik.isSubmitting}>
                                <div className="d-flex">
                                    {
                                        formik.isSubmitting && <Spin />
                                    }
                                    Submit
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default KYC