import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Spin, Image, Button, Modal } from 'antd';
import '../KYC/AllAccounts/AccountPreview.css';
import KYCTAB from '../KYC/KYCTAB'
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../common/apis/createAxios';
import backArrow from '../../assets/Vector (6).svg';
import SupervisorButton from '../KYC/AllAccounts/SupervisorButton';
import DataentryButton from '../KYC/AllAccounts/DataentryButton';
import { month, weekday } from '../../common/months';
import tick from '../../assets/Vector (3).svg';
import pending from '../../assets/Vector (4).svg';
import decline from '../../assets/Vector (5).svg';
import axios from 'axios';
import { irsAuthBaseUrl } from '../../common/apis/userApi';
import PersonalTags from './PersonalTags';

const ViewKYC = () => {

    const {id, user_id, supervisor} = useParams();

    const [record, setRecords] = useState([])
    const { token, user} = useSelector(userSelector)
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState({
        supervisor_modal : false,
        data_entry_modal : false
    });
    const [staff, setStaff] = useState([]);

    const [visible, setVisible] = useState(false);
    const history = useNavigate();

    const fetchingData = async () => {
        setLoading(true)
        let data = "";
        if(user_id && typeof supervisor === "undefined"){
            const result1 = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/sales-officer/Preview/${id}/${user_id}`);
            data = result1?.data.data;
        }else if(user_id && supervisor){
            const result2 = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/supervisor-officer/Preview/${id}/${user_id}`)
            data = result2?.data.data
        }else{
            const result3 = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/view/${id}`);
            data = result3?.data.data;
        }
        setRecords(data)
        setLoading(false)

        const allStaff = await getAxiosInstance(token).get('/staff/active')
        const response2 = allStaff.data
        setStaff(response2.data);
    }

    useEffect(() => {
        fetchingData();
    },[id])

    const handleSupervisorOpen = () => {
        setOpen({...open, supervisor_modal : true});
    }
    const handleDataEntryOpen = () => {
        setOpen({...open, data_entry_modal : true});
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const data = record?.[0];
    const comments = data?.all_comments;

    const ro = staff?.filter(item => item.id === Number(data?.ro_id))[0];
    const Supervisor = staff?.filter(item => item.id === Number(data?.supervisor_officer))[0];
    const data_entry = staff?.filter(item => item.id === Number(data?.sales_officer_id))[0];
    const supervisor_comment = comments?.filter(item => item.user_id == data?.supervisor_officer)
    const data_entry_comment = comments?.filter(item => item.user_id == data?.sales_officer_id)

    const supervisorProps = {
        "api": 'kyc/fixed-deposit/supervisor-officer/Process',
        "isIndividual" : true,
        "file_name" : "supervisor_signature",
        "status" : "supervisor_status",
        "comment" : "supervisor_comment",
        "commentApi" : "kyc/fixed-deposit/comments/create"
    }

    const officerProps = {
        "api": 'kyc/fixed-deposit/sales-officer/Process',
        "isIndividual" : true,
        "file_name" : "sales_officer_signature",
        "status" : "sales_officer_status",
        "comment" : "sales_officer_comment",
        "commentApi" : "kyc/fixed-deposit/comments/create",
        "actionBy" : "Sales Officer"
    }
    console.log(record)

  return (
    <div className='account-preview'>
        <PersonalTags />
        <div className="account-wrapper">
            <KYCTAB />
            {
                loading && <div className="loader mt-5">
                    <Spin />
                </div>
            }
            {
                data && <div className="content p-4">
                <div className="back-arrow d-flex align-items-center mb-3">
                    <img src={backArrow} alt=""  onClick={() => history(-1)}/>
                    <h4 className='mb-0 ml-3'>Customer’s Profile</h4>
                </div>
                <div className="account-info-wrapper d-flex justify-content-between">
                    <div className="left-content">
                        <div className="data-info mb-4">
                            <h5 className='mb-3'>Personal Information</h5>
                            <div className="item">
                                <span>Title</span>
                                <span>{data?.title}</span>
                            </div>
                            <div className="item">
                                <span>Account Name</span>
                                <span>{data?.account_name}</span>
                            </div>
                            <div className="item">
                                <span>Gender</span>
                                <span>{data?.gender}</span>
                            </div>
                            <div className="item">
                                <span>Business/Profession</span>
                                <span>{data?.business_profession}</span>
                            </div>
                            <div className="item">
                                <span>Address Visited</span>
                                <span>{data?.address_visited}</span>
                            </div>
                            <div className="item">
                                <span>Color of building</span>
                                <span>{data?.color_of_building}</span>
                            </div>
                            <div className="item">
                                <span>Comment of Location landmarks</span>
                                <span>{data?.comment_on_location_landmarks}</span>
                            </div>
                            <div className="item">
                                <span>Documentation checked</span>
                                <span>{data?.documentation_checked}</span>
                            </div>
                        </div>
                        <div className="data-info">
                            <h5 className='mb-3'>Other Information</h5>
                            <div className="item">
                                <span>Estimated Monthly Salary</span>
                                <span>{data?.estimated_monthly_salary}</span>
                            </div>
                            <div className="item">
                                <span>Estimated Yearly Salary</span>
                                <span>{data?.estimated_annual_salary}</span>
                            </div>
                            <div className="item">
                                <span>ID Number</span>
                                <span>{data?.id_number}</span>
                            </div>
                            <div className="item">
                                <span>ID Type </span>
                                <span>{data?.id_type}</span>
                            </div>
                            <div className="item">
                                <span>Laundering Risk</span>
                                <span>{data?.laundering_risk}</span>
                            </div>
                            <div className="item">
                                <span>Name of visiting staff type</span>
                                <span>{data?.name_of_visiting_staff}</span>
                            </div>
                            <div className="item">
                                <span>Politically exposed person</span>
                                <span>{data?.pep}</span>
                            </div> 
                        </div>

                        <div className="data-info mt-4">
                            <h5 className='mb-3'>Next of kin</h5>
                            <div className="item">
                                <span>Next of kin name</span>
                                <span>{data?.kin_name}</span>
                            </div>
                            <div className="item">
                                <span>Next of kin address</span>
                                <span>{data?.kin_address}</span>
                            </div>
                            <div className="item">
                                <span>Next of kin occupation</span>
                                <span>{data?.kin_occupation}</span>
                            </div>
                            <div className="item">
                                <span>Next of kin phone </span>
                                <span>{data?.kin_phone}</span>
                            </div>
                            <div className="item">
                                <span>Next of kin relationship</span>
                                <span>{data?.kin_relationship}</span>
                            </div>
                            <div className="item">
                                <span>Bank name</span>
                                <span>{data?.bank_name}</span>
                            </div>
                            <div className="item">
                                <span>Account number</span>
                                <span>{data?.account_number}</span>
                            </div>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="data-info">
                            <h5 className='mb-3'>Account Information</h5>
                            <div className="item">
                                <span>BVN</span>
                                <span>{data?.bvn}</span>
                            </div>
                            <div className="item">
                                <span>Application Status</span>
                                <span>{data?.application_status}</span>
                            </div>
                            <div className="item">
                                <span>Account Type</span>
                                <span>{data?.type_of_acct}</span>
                            </div>
                            <div className="item">
                                <span>Customer Type</span>
                                <span>{data?.customer_type}</span>
                            </div>
                            <div className="item">
                                <span>Utility Bill</span>
                                <span>{data?.utility_bill}</span>
                            </div>
                            <div className="item">
                                <span>Utility File</span>
                                <a href={data?.utility_file} target="_blank">view file</a>  
                            </div>
                            <div className="item">
                                <span>Passport</span>
                                <a href={data?.customer_passport} target="_blank">view file</a>  
                            </div>
                            <div className="item">
                                <span>Date of visit</span>
                                <span>{data?.date_of_visit}</span>
                            </div>
                        </div>
                        <div className="data-info mt-4">
                            <h5>Approving Officers</h5>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Relationship officer</span>
                                    <p>{ro?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>signed</p>
                                        <div>
                                            <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                view signature
                                            </a>
                                            <Image
                                                style={{
                                                display: 'none'
                                                }}
                                                src={data?.ro_signature}
                                                preview={{
                                                visible,
                                                onVisibleChange: (value) => {
                                                    setVisible(value);
                                                },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="item">
                                    <span>Comment</span>
                                    <p className='view_comment'>view comment</p>
                                </div> */}
                            </div>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Supervisor</span>
                                    <p>{Supervisor?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>{(data?.supervisor_status === "Approve") ? <img src={tick} alt="tick"/> : (data?.supervisor_status === "Pending") ? <img src={pending} alt="pending"/> : <img src={decline} alt="pending" />}</p>
                                        {
                                            (data?.supervisor_status === "Pending" || data?.supervisor_status === "Reject") && <p style={{color : (data?.supervisor_status === "Reject") ? "#E25353" : (data?.supervisor_status === "Pending") ? "#FFB519" : (data?.supervisor_status === "Approve") ? "#00A85A" : "GrayText" }}>{data?.supervisor_status}</p>
                                        }
                                        {
                                            (data?.supervisor_status === "Approve") && <div>
                                                <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                    show signature
                                                </a>
                                                <Image
                                                    style={{
                                                    display: 'none'
                                                    }}
                                                    src={data?.supervisor_signature}
                                                    preview={{
                                                    visible,
                                                    onVisibleChange: (value) => {
                                                        setVisible(value);
                                                        },
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Comment</span>
                                    <p
                                        onClick={handleSupervisorOpen}
                                        className="view_comment"
                                    >
                                        view comment
                                    </p>
                                </div>
                            </div>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Branch</span>
                                    <p>{data_entry?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>{(data?.sales_officer_status === "Approve") ? <img src={tick} alt="tick"/> : (data?.sales_officer_status === "Pending") ? <img src={pending} alt="pending"/> : <img src={decline} alt="pending" />}</p>
                                        {
                                            (data?.sales_officer_status === "Pending" || data?.sales_officer_status === "Reject") && <p style={{color : (data?.sales_officer_status === "Reject") ? "#E25353" : (data?.sales_officer_status === "Pending") ? "#FFB519" : (data?.sales_officer_status === "Approve") ? "#00A85A" : "GrayText" }}>
                                            {data?.sales_officer_status}
                                        </p>
                                        }
                                        {
                                            (data?.sales_officer_status === "Approve") && <div>
                                                <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                    show signature
                                                </a>
                                                <Image
                                                    style={{
                                                    display: 'none'
                                                    }}
                                                    src={data?.sales_officer_signature}
                                                    preview={{
                                                    visible,
                                                    onVisibleChange: (value) => {
                                                        setVisible(value);
                                                    },
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Comment</span>
                                    <p onClick={handleDataEntryOpen} className="view_comment">
                                        view comment
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (user.id == user_id && data?.supervisor_status !== "Approve" && data?.officer_status !== "Reject" && typeof supervisor !== "undefined") && <SupervisorButton id={id} fetchingData={fetchingData} supervisorProps={supervisorProps}/>
                }
                {
                    (user.id == user_id && data?.sales_officer_status !== "Approve" && data?.sales_officer_status !== "Reject" && typeof supervisor === "undefined") && <DataentryButton id={id} fetchingData={fetchingData} officerProps={officerProps}/>
                }
            </div>
            }
        </div>
        <Modal
            open={open.supervisor_modal}
            title="Supervisor's Comment"
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            ]}
        >
            <div className="comment-thread">
                {
                    supervisor_comment && supervisor_comment.map(({id, comment, created_at}) => {

                        const newDate = new Date(created_at);

                        const [months, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
                        return(
                            <div className="comment" key={id}>
                                <div className="sender">
                                    <img src="" alt="" />
                                    <h5>{Supervisor?.name}</h5>
                                    <span>{`${weekday[dy]} ${day} ${month[months]} ${year}`}</span>
                                </div>
                                <p>{comment}</p>
                            </div>
                        )
                    })
                }
                {
                    <p>{(supervisor_comment?.length === 0) && "No comment yet"}</p>
                }
            </div>
        </Modal>
        <Modal
            open={open.data_entry_modal}
            title="Data entry officer's Comment"
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            ]}
        >
            <div className="comment-thread">
                {
                    data_entry_comment && data_entry_comment.map(({ id, comment, created_at }) => {

                        const newDate = new Date(created_at);
                        const [months, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
                        return(
                            <div className="comment" key={id}>
                                <div className="sender">
                                    <img src="" alt="" />
                                    <h5>{data_entry?.name}</h5>
                                    <span>{`${weekday[dy]} ${day} ${month[months]} ${year}`}</span>
                                </div>
                                <p>{comment}</p>
                            </div>
                        )
                    })
                }
                {
                    <p>{(data_entry_comment?.length === 0) && "No comment yet"}</p>
                }
            </div>
        </Modal>
    </div>
  )
}

export default ViewKYC;