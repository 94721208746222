import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearState } from '../../../feature/Login/loginSlice';
import profile from '../../../assets/user (1).png';
import roleLogo from '../../../assets/roles.png';
import logout from '../../../assets/logout 2.png';
import group from '../../../assets/group 2.png';
import '../../../components/HomeTags/HomeTags.css';

const PermissionTags = ({active}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(clearState());
        navigate('/');
    }
  return (
    <div className="tags__wrapper permissionTags">
        <div className="tag__list d-flex flex-column justify-content-between">
            <div className="hometags-wrapper">
                <h4 style={{paddingLeft : "20px", color : "#c2c7d1", fontSize : "18px"}}>Permission Menu</h4>
                <div className="menu-list">
                    <ul>
                        <li className='mb-3' style={{ background : active.role ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent"}} >
                            <Link to='/permission/roles'>
                                <img src={roleLogo} alt="dashboard" style={{opacity : active.role ? "1" : "0.8"}}/>
                                <span style={{color : active.role ? "#ffffff" : "#c2c7d1"}}>Roles</span>
                            </Link>
                        </li>
                        <li  className='mb-3' style={{ background : active.service ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }} >
                            <Link to='/permission/microservices'>
                                <img src={group} alt="" style={{opacity : active.service ? "1" : "0.8"}}/>
                                <span style={{color : active.service ? "#ffffff" : "#c2c7d1"}}>Permission</span>
                            </Link>
                        </li>
                        <li className='mb-3' style={{ background : active.user ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }}>
                            <Link to='/permission/user-role'>
                                <img src={group} alt="" style={{opacity : active.user ? "1" : "0.8"}}/>
                                <span style={{color : active.user ? "#ffffff" : "#c2c7d1"}}>User Roles</span>
                            </Link>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div className="logout d-flex align-items-center" onClick={handleLogout}>
                <img src={logout} alt="" />
                <p>Logout</p>
            </div>
        </div>
    </div>
  )
}

export default PermissionTags