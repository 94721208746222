import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';

const EditRole = ({ id, name, serviceList }) => {

    console.log(id)
    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false);

    const [role, setRole] = useState(name)
    const [permission, setPermission] = useState([]);

    const handleChange = (e) => {
        setRole(e.target.value)
    }

    const handlePermission = (e) => {
        const data = e.target.value;
        if(e.target.checked){
            setPermission([...permission, data]);
        }else{
            setPermission(permission.filter((item) => item !== data))
        }
    }
    const handleEdit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const { id } = e.currentTarget;

        const jsonData = JSON.stringify({
            "name" : role,
            "service_id" : permission,
        })

        try {
            const removeDoc = await getAxiosInstance(token).put(`/roles-core/permission/${id}`, jsonData);
            const result = removeDoc.data;
            // console.log(result)
            if(result){
                toast.success("Role updated successfully");
                // setTimeout(() => {
                //     window.location.reload();
                // },3000)
            }
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data.message;
            toast.error(apiMsg)
        }
    }

  return (
    <div className="modal fade" id={`edit-form${id}`}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Role</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="form-wrapper">
                      <form onSubmit={handleEdit}>
                          <div className="form-data">
                              <div className="form-group">
                                  <label htmlFor="">Role Name</label>
                                  <input type="text" name="role" value={role} onChange={handleChange}/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Pick Permission</label>
                                  <span className='row'>
                                      {
                                          serviceList?.map(({id, name}) => {
                                              return(
                                                  <span key={id} className="col-6 mb-2">
                                                    <input type="checkbox" name="services" value={id} onChange={handlePermission} />
                                                    <span>{name}</span>
                                                  </span>
                                                  
                                              )
                                          })
                                      }
                                    <span className="col-6 mb-2">
                                        <input type="checkbox" name="services" value="admin" onChange={handlePermission} />
                                        <span>Admin</span>
                                    </span>
                                  </span>
                              </div>
                              <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Add Role</button>
                          </div>
                      </form>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default EditRole