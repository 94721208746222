import React from 'react'
import { Table, Pagination, Dropdown, Badge, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';

const IndividualDataTable = ({ loading, allData, pageCount, fetchingData, user, officer}) => {

    const { user : currentUser } = useSelector(userSelector);
    const navigate = useNavigate();

    const handlePreview = async (slug) => {
  
        try {
            if(user && officer === ""){
                navigate('/kyc-individual/accounts/' + slug + user)
            }else if(user && officer){
                navigate('/kyc-individual/accounts/' + slug + user + officer)
            }else{
                navigate('/kyc-individual/accounts/' + slug)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit =  (record) => {
        console.log(record)
        if(record.ro_id == currentUser.id){
            navigate('/kyc-individual/edit/' + record.slug)
        }
    }

    const handleDelete = async (slug) => {
      
    }
    
    const columns = [
        {
          title: 'Account Name',
          dataIndex: 'account_name',
          key: 'account_name',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
                    {item}
                </a>,
        },
        {
          title: 'Customer Type',
          key: 'customer_type',
          dataIndex: 'customer_type',
          render: (item, record) => {
            return(
                <a onClick={() => handlePreview(record.slug)} >
                  {item}
                </a>
            )
          },
        },
        {
          title: 'Color of building',
          dataIndex : "color_of_building",
          key: 'color_of_building_type',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
              {item}
          </a>,
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'gender',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
              {item}
          </a>,
        },
        {
          title: 'Application Status',
          key: 'application_status',
          dataIndex: 'application_status',
          render: (item, record) => {

            let statusText = ""
            let textcolor = ""
            if(item === "Approved"){
              statusText = 'success'
              textcolor = "#00A85A"
            }
            if(item === "Rejected"){
              statusText = 'error'
              textcolor = '#E25353'
            }
            if(item === "Pending"){
              statusText = 'warning'
              textcolor = '#FFB519'
            }
          return(
                  <a onClick={() => handlePreview(record.slug)} >
                    <Badge status={statusText} />
                    <span style={{color : textcolor}}>
                      {item}
                    </span>
                  </a>
            )
          },
        },
        {
          title: 'Account Type',
          key: 'type_of_acct',
          dataIndex: 'type_of_acct',
          render: (item, record) => {
            return(
              <span>
                <Space style={{display : "flex", justifyContent : "space-between"}} size="medium">
                {item}
                  <Dropdown.Button
                  style={{marginRight : "14px"}}
                      overlay={
                        <div className='drop-down'>
                          <div className="dropdown-list">
                            <span onClick={() => handlePreview(record.slug)}>View</span>
                            <span 
                              style={{cursor : record.ro_id == currentUser.id ? "pointer" : "not-allowed", opacity : record.ro_id == currentUser.id ? "1" : "0.4"}}
                              onClick={() => handleEdit(record)}
                            >
                                Edit 
                            </span>
                            <span className='delete' onClick={() => handleDelete(record.slug)}>Delete</span>
                          </div>
                        </div>
                      }
                      trigger={['click']}
                    >
                    </Dropdown.Button>
                </Space>
              </span>
            )
          },
        },
    ];
    
    const data = allData;

  return (
    <div className='data-table'>
        <Table loading={loading} columns={columns} dataSource={data} pagination={false}/>
        <div className="pagination justify-content-end mt-3">
            <Pagination 
                pageSize={10}
                total={pageCount}
                onChange={(page) => fetchingData(page)}
            />
        </div>
    </div>
  )
}

export default IndividualDataTable