import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import axios from 'axios';
import { irsBaseUrl } from '../../../common/apis/userApi';
import '../MyAssignedTickets/myassignedticket.css';
import TicketComponent from '../../../components/TicketsComponent/TicketComponent';

const MyAssignedTickets = () => {

    const { token, user} = useSelector(userSelector);
    const [loading, setLoading] = useState(false)
    const [mytickets, setMytickets] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {
        fetchingData(1)
    },[])

    const fetchingData = async (page) => {
        setLoading(true)
        try {
            const staff = await authAxios.get(`${irsBaseUrl}/interdepartmental/tickets/assigned/${user.id}?page=${page}`);
            const stafflist = staff.data;
            setMytickets(stafflist);
            setTotalPage(stafflist.meta.total)
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

  return (
    <div className='ticket-list grey'>
        <TicketComponent 
        heading={"My Assigned Tickets"}
        tickets={mytickets}
        loading={loading}
        totalPage={totalPage}
        fetchingData={fetchingData}
        />
    </div>
  )
}

export default MyAssignedTickets