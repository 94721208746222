import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch("https://irsapi.koinsbank.com/api/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //   mode: 'no-cors', // no-cors, *cors, same-origin
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      });
      let data = await response.json();
      console.log(data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log("errror", error);
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginSlice = createSlice({
  name: "users",
  initialState: {
    user: null,
    isFetching: false,
    token: null,
    errorMessage: "",
    successMessage: "",
    status: "idle",
    role: null,
  },
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.user = null;
      state.errorMessage = "";
      state.successMessage = "";
      state.token = null;
      state.status = "idle";
      state.role = null;
    },
    saveUser: (state, { payload }) => {
      state.user = payload;
    },
    saveRole: (state, { payload }) => {
      state.role = payload;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.status = "loading";
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.isFetching = false;
      state.errorMessage = "";
      state.token = payload.access_token;
      state.status = "success";
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.user = null;
      state.token = null;
      state.successMessage = "";
      state.errorMessage = payload.message;
      state.status = "failed";
    },
  },
});

export const { clearState, saveUser, saveRole } = loginSlice.actions;
export const userSelector = (state) => state.users;
