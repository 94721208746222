import React from 'react'
import TextInput from '../../../common/TextInput/TextInput'
import './personal-info.css';

const PersonalInformation = ({formik, setValid}) => {

    if(formik.values.date_of_incorporation !== "" && formik.values.company_name !== "" && formik.values.rc_number !== "" && formik.values.nature_of_business !== "" && formik.values.industry !== "" && formik.values.company_type !== "" && formik.values.sector && formik.values.tin && formik.values.certificate_type !== ""){
        setValid(true)
    }

  return (
    <div className='personal-info'>
        <div className="d-flex mb-3">
            <div className="w-100">
                <TextInput 
                    label='Date of Incorporation'
                    type="date" 
                    placeholder='First name, Other name-Last name' 
                    name='date_of_incorporation' 
                    value={formik.values.date_of_incorporation} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.date_of_incorporation && formik.errors.date_of_incorporation) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                    label='RC Number'
                    type="text" 
                    placeholder='' 
                    name='rc_number' 
                    value={formik.values.rc_number} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.rc_number && formik.errors.rc_number) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="w-100 mb-3">
            <TextInput 
                label='Company Name'
                type="text" 
                placeholder='Enter company name' 
                name='company_name' 
                value={formik.values.company_name} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.company_name && formik.errors.company_name) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
            />
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Certificate Type</label>
                    <select 
                    name='certificate_type' 
                    value={formik.values.certificate_type} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.certificate_type && formik.errors.certificate_type) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                    >
                        <option value="" disabled selected>Select certificate</option>
                        <option value="Certificate of Incorporation ( Limited liability Companies)">Certificate of Incorporation ( Limited liability Companies)</option>
                        <option value="Certificate of Registration (Business Name/Enterprise)" >Certificate of Registration (Business Name/Enterprise)</option>
                        <option value="Certificate of Incorporation (Associations, NGOs, Foundations, etc.)" >Certificate of Incorporation (Associations, NGOs, Foundations, etc.)</option>
                        <option value="others">Others</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="w-100">
            <div className="form-group">
                <label htmlFor="">Industry</label>
                <select 
                name='industry' 
                value={formik.values.industry} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                style={{border : (formik.touched.industry && formik.errors.industry) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                >
                    <option value="" disabled selected>Select Industry</option>
                    <option value="Oil and Gas" >Oil and Gas</option>
                    <option value="Manufacturing" >Manufacturing</option>
                    <option value="Government" >Government </option>
                    <option value="General">General</option>
                    <option value="General Commerce">General Commerce</option>
                    <option value="Information and Communication">Information and Communication </option>
                    <option value="Real Estate Activities">Real Estate Activities</option>
                    <option value="Power and Energy">Power and Energy</option>
                    <option value="Finance and Insurance">Finance and Insurance</option>
                    <option value="Construction">Construction</option>
                    <option value="Agriculture, Forestry and Fishing">Agriculture, Forestry and Fishing</option>
                    <option value="Transportation and Storage">Transportation and Storage</option>
                    <option value="Capital Market">Captial Market</option>
                    <option value="Professional, Scientific and Technical Activities">Professional, Scientific and Technical Activities</option>
                    <option value="Education">Education</option>
                    <option value="Human Health and Social Work Activities">Human Health and Social Work Activities</option>
                    <option value="Administrative and Support Service Activities">Administrative and Support Service Activities</option>
                    <option value="Water Supply Sewerage, Waste Management and Remediation Activities">Water Supply Sewerage, Waste Management and Remediation Activities</option>
                    <option value="Mining and Quarrying">Mining and Quarrying</option>
                    <option value="Arts, Entertainment and Reacreation">Arts, Entertainment and Reacreation</option>
                    <option value="Activities of Extraterritorial Org and Bodies">Activities of Extraterritorial Org and Bodies</option>
                </select>
            </div>
        </div>
        <div className="d-flex my-4">
            <div className="w-100">
                <TextInput 
                    label='Tin'
                    type="text" 
                    placeholder='' 
                    name='tin' 
                    value={formik.values.tin} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.tin && formik.errors.tin) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                    label='Nature of business'
                    type="text" 
                    placeholder='' 
                    name='nature_of_business' 
                    value={formik.values.nature_of_business} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.nature_of_business && formik.errors.nature_of_business) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex my-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Company Type</label>
                    <select name="company_type" value={formik.values.company_type} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value="" disabled selected>Select option</option>
                        <option value="Limited Liability" >Limited Liability</option>
                        <option value="Public Sector" >Public Sector</option>
                        <option value="Business Enterprise" >Business Enterprise</option>
                        <option value="Incorporated Entities" >Incorporated Entities</option>
                        <option value="others">Others</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Sector</label>
                    <select name='sector' value={formik.values.sector}  onChange={formik.handleChange} onBlur={formik.handleBlur}
                    style={{border : (formik.touched.sector && formik.errors.sector) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    >
                        <option value="" disabled selected>Select option</option>
                        <option value="Trade/Retail" >Trade/Retail</option>
                        <option value="Manufacturing" >Manufacturing</option>
                        <option value="Service" >Service</option>
                        <option value="Mixed" >Mixed</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="w-100 mb-4" style={{display : formik.values.company_type === "others" ? "block" : "none"}}>
            <TextInput 
                label='Specify Others'
                type="text" 
                placeholder='' 
                name='company_others' 
                value={formik.values.company_others} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.company_others && formik.errors.company_others) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
            />
        </div>
    </div>
  )
}

export default PersonalInformation