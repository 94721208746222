import React, { useState } from 'react';
import '../HandbookComponent/handbookcomponent.css';
import '../QuartlyTrainingComponent/Quartly-style.css';
import image from '../../assets/slide.png';
import homework from '../../assets/homework.png';
import notebook from '../../assets/notebook.png';
import clock from '../../assets/alarm-clock.png';
import level from '../../assets/volume-control.png';

const QuartlyTrainingComponent = () => {
    const [show, setShow] = useState({
        description : true,
        curriculum : false,
        faq : false,
        announcement : false,
        reviews : false
    });
    const styles = {
        background : "white",
    }
    const handleClick = (e) => {
        e.preventDefault();
        setShow({description : true, curriculum : false, faq : false, announcement : false, reviews : false})
    }
    const handleCurriculum = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : true, faq : false, announcement : false, reviews : false})
    }
    const handleFaq = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : true, announcement : false, reviews : false})   
    }
    const handleAnnouncement = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : false, announcement : true, reviews : false})
    }
    const handleReviews = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : false, announcement : false, reviews : true})
    }
  return (
    <div className='quartly-component handbook-component'>
        <h4>Quarterly Training on Anti-Money Laundering / Combating the Financing of Terrorism</h4>
        <div className="lectures">
            <img src={notebook} alt="" />
            <p>Lectures : 15</p>
        </div>
        <div className="lectures">
            <img src={clock} alt="" />
            <p>Duration : 10</p>
        </div>
        <div className="lectures">
            <img src={level} alt="" />
            <p>Level : Intermediate</p>
        </div>
        <button>
            <a href='https://irs.primeramfbank.com/courses/quarterly-training-on-anti-money-laundering-combating-the-financing-of-terrorism/' target="_blank">Start course</a>
        </button>

        <div className="section-heading">
            <ul>
                <li onClick={handleClick} style={show.description ? styles : {}}>Description</li>
                <li onClick={handleCurriculum}>Curriculum</li>
                <li onClick={handleFaq}>FAQ</li>
                <li onClick={handleAnnouncement}>Announcement</li>
                <li onClick={handleReviews}>Reviews</li>
            </ul>
        </div>
        <div className="section-content">
            <div className="description" style={{display : show.description ? "block" : "none"}}>
                <img src={image} alt="primera MFB logo"/>
            </div>
            <div className="curriculum" style={{display : show.curriculum ? "block" : "none"}}>
                <div className="intro">
                    <h4>INTRODUCTION</h4>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>INTRODUCTION – AML</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>WHAT IS MONEY LAUNDERING</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>WHAT IS TERRORIST FINANCING</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>KNOW YOUR CUSTOMER</p>
                    </div>
                </div>
                <div className="customer">
                    <h4>CUSTOMER DUE DILIGENCE</h4>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>CATEGORIES OF CUSTOMER DUE DILIGENCE?</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>SIMPLIFIED DUE DILIGENCE</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>STANDARD DUE DILIGENCE</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>ENHANCED DUE DILIGENCE</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>CONDUCTING CUSTOMER DUE DILIGENCE</p>
                    </div>
                    <div className="item">
                        <img src={homework} alt="" />
                        <p>SUSPICION OF MONEY LAUNDERING OR TERRORIST FINANCING</p>
                    </div>
                </div>
                <div className="aml">
                    <h4>AML/CFT TRAINING QUESTIONS</h4>
                    <p>10 questions</p>
                </div>
            </div>
            <div className="faq" style={{display : show.faq ? "block" : "none"}}>
                <p>No item</p>
            </div>
            <div className="announcement" style={{display : show.announcement ? "block" : "none"}}>
                <p>Nothing for now</p>
            </div>
            <div className="review" style={{display : show.reviews ? "block" : "none"}}>
                <p>Be the first to add a review</p>
                <button>Add review</button>
            </div>
        </div>
    </div>
  )
}

export default QuartlyTrainingComponent;