import React from "react"

const FormButton = ({buttonText, disable, type, loading}) => {

    return (
      <div className='button w-100'>
          <button 
          className='w-100 py-3' 
          type={type} 
          disabled={disable}
          style={{opacity : (disable && loading === false) ? "0.4" : "1", cursor : disable ? "not-allowed" : "pointer"}}
          >
            {loading ? (
                  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              ) : null
              }
            {
                !loading && buttonText
            }
          </button>
      </div>
    )
  }
  
export default FormButton