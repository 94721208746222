import React from 'react';
import '../Preview/Preview.css';
import logo from '../../assets/primeralogo.jpeg';
import '../QueuePreview/queuepreview.css';

const ComponentToPrint = React.forwardRef((props, ref) => {

    const renderDate = props.dateCreated.split("T")[0];

  return (
    <div className='preview queue document-type' ref={ref}>
        <div className="content" id="to-print" >
            <div className="content-wrapper">
                <div className="logo-intro text-center">
                    <img src={logo} width="70px" alt="primera logo" />
                    <h3 className='mb-4'>Internal Memo</h3>
                </div>
                <div className="heading">
                    <div className="heading-item">
                        <h4>FROM</h4>
                        <input type="text" defaultValue={props.dept_name} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>TO</h4>
                        <input type="text" defaultValue={props.office_name} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>APP ID</h4>
                        <input type="text" defaultValue={props.applicationId} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>SUBJECT</h4>
                        <input type="text" defaultValue={props.subject} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>Date</h4>
                        <input type="text" defaultValue={renderDate} readOnly/>
                    </div>
                </div>
                <div className="body">
                    <div className="bg d-flex mb-3">
                        <h5>Background:</h5>
                        <p>{props.background}</p>
                    </div>
                    <div className="request d-flex mb-3">
                        <h5>Request:</h5>
                        <p>{props.memo_request}</p>
                    </div>
                    <div className="justification d-flex mb-3">
                        <h5>Justification:</h5>
                        <p>{props.justification}</p>
                    </div>
                </div>
                <div className="requester">
                    <h5>Requester:</h5>
                    {
                        props.requester.map(({signature, requesterName, requesterSatffid}) => {
                            return(
                                <div className="list-wrapper" key={requesterSatffid}>
                                    <div className="form_grouping">
                                        <input type="text" defaultValue={`${requesterName}`} className="text-center" readOnly/>
                                        <label htmlFor="">Name</label>
                                    </div>
                                    <div className="form_grouping">
                                        <span className='signature-img'>
                                        {
                                            signature !== "pending"  &&  <img src={signature} alt="" width="200px"/>
                                        }
                                        </span><br></br>
                                        <label htmlFor="">e-signature</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="requester">
                    <h5>Reviewed by:</h5>
                    {
                        props.reviewerBy.map(({staffName, signature}, index) => {
                            return(
                            <div className="list-wrapper" key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffName} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    <span className='signature-img'>
                                        {
                                            signature !== "pending"  &&  <img src={signature} alt="" width="200px"/>
                                        }
                                    </span><br></br>
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
                <div className="approval d-flex">
                    <h5>Concurrence</h5>
                    <div className="list-wrapper">
                    {
                        props.staffConcur.map(({staffname, signature, signed}, index) => {
                            return <div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffname} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    {
                                        signed ? <span className='signature-img'>
                                        <img src={signature} alt="" width="200px"/>
                                    </span> : <input type="text" />
                                    }
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="approval d-flex">
                    <h5>Approval</h5>
                    <div className="list-wrapper">
                    {
                        props.approvingStaff.map(({staffname, signature, signed}, index) => {
                            return<div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffname} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    {
                                        signed ? <span className='signature-img'>
                                            <img src={signature} alt="" width="200px"/>
                                        </span> : <input type="text" />
                                    }
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="memo-footer no-print">
                    <h4>PRIMERA MICROFINANCE BANK</h4>
                    <div className="address">
                        <p className=''>24, kofo Abayomi streeet, victoria Island, Lagos.</p>
                        <p className=''>T: +234 1 277 0827-32 </p>
                        <p className='website'>www.primerabank.com</p>
                    </div>
                </div>
                <div className="water-mark">
                    <p className='water-mark-text'>{props.watermark}</p>
                </div>
            </div>
        </div>
    </div>
  )
});

export default ComponentToPrint;