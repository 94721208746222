import React from 'react'

const AccountRender = ({newData}) => {
  return (
    <div className="identity-info mt-3 mb-5">
        {
            newData?.account.map(({accountno, acct_level, accttype, balance, active, t24custno, mnemonic}) => {
                return(
                <div key={mnemonic}>
                    <div className="title">
                        <p>ACCOUNT INFO</p>
                    </div>
                    <div className="item-row">
                        <div className='info-item'>
                            <h4>Account No</h4>
                            <span>{accountno}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>Account Level</h4>
                            <span>{acct_level}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>Account Type</h4>
                            <span>{accttype}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>Status</h4>
                            <span>{active && "Active"}</span>
                        </div> 
                    </div>
                    <div className="item-row">
                        <div className='info-item'>
                            <h4>Mnemonic</h4>
                            <span>{mnemonic}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>T24 No</h4>
                            <span>{t24custno}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>Balance</h4>
                            <span>{balance.toLocaleString()}</span>
                        </div> 
                        <div className='info-item'>
                            <h4>Account Officer</h4>
                            <span>{newData?.approve_by}</span>
                        </div> 
                    </div>
                </div>
                )
            })
        }
        <div className="title">
            <p>ACCOUNT APPROVAL STATUS</p>
        </div>
        <div className="item-row">
            <div className='info-item'>
                <h4>RO Status</h4>
                <span>{(newData?.ro_approval) ? "Approved" : "Not approved"}</span>
            </div> 
            <div className='info-item'>
                <h4>RO Approver</h4>
                <span>{newData?.ro_approve_by}</span>
            </div> 
            <div className='info-item'>
                <h4>Branch Status</h4>
                <span>{(newData?.branch_approval) ? "Approved" : "Not approved"}</span>
            </div> 
            <div className='info-item'>
                <h4>Branch Approver</h4>
                <span>{newData?.branch_approve_by}</span>
            </div> 
        </div>

        {
            (newData.account.length == null || newData.account.length < 1) && <p className='mt-5'>No account found</p>
        }
    </div>
  )
}

export default AccountRender