import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { toast, ToastContainer } from 'react-toastify';
import '../../CASA/updateStaff/updateStaff.css';
import '../HelpdeskCreateDepartment/helpdeskdepartment.css';
import { irsBaseUrl } from '../../../common/apis/userApi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useNavigate } from 'react-router-dom';

const CreateCheckList = () => {

    const { user, token} = useSelector(userSelector);

    const history = useNavigate();
    const [category, setCategory] = useState([])
    const [checklist, setChecklist] = useState({
        category_id : "", 
        title : "",
        staff_id : user.id, 
        status : ""
    })

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const departmentData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-category`);
                const response = departmentData.data;
                setCategory(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchingData()
    },[])

    const handleChange = (e) => {
        setChecklist({...checklist, [e.target.name] : e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { category_id, title, staff_id, status } = checklist;

        const jsonData = {
            "category_id" : category_id, 
            "title" : title,
            "staff_id" : staff_id, 
            "status" : status
        }

        try {
            const query = await authAxios.post(`${irsBaseUrl}/interdepartmental/ticket-checklist`, jsonData);
            const response = query.data;
            console.log(response)
            if(response.created_at){
                toast.success("Checklist created successfully!");
                setTimeout(() => {
                    history('/helpdesk/checklist')
                },3000)
            }
        } catch (error) {
         console.log(error)   
        }
    }

    console.log(checklist)

  return (
    <div className='checklist helpdesk-department'>
        <Header />
        <div className="container_">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="checklist-wrapper help-createdepartment">
                    <ToastContainer closeButton={true} position="top-right" />
                    <div className="help-createdepartment">
                        <div className="add-staff-form">
                            <div className="add-staff-wrapper">
                                <h4 className='mb-4'>Create Checklist</h4>
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="form-data">
                                        <div className="form-group">
                                            <label htmlFor="">Pick Category</label>
                                            <select name="category_id" value={checklist.category_id} onChange={handleChange}>
                                                <option value="" disabled>Select category</option>
                                                {
                                                    category && category.map(({id, name}) => {
                                                        return(
                                                            <option value={id}>{name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Checklist Name</label>
                                            <input type="text" placeholder='' name='title' value={checklist.title} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Checklist Status</label>
                                            <select name="status" id="" value={checklist.status} onChange={handleChange}>
                                                <option value="" disabled>Select status</option>
                                                <option value="Active">Active</option>
                                            </select>
                                        </div>
                                        <button type='submit' className='w-100'>Add Sub-unit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default CreateCheckList