import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';

const CreateMircroservice = ({permission, handleRole, allRoles}) => {

    const roleList = allRoles?.data;
    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { name, role_id, create, read, update, deleteItem, menu, other, status } = permission;

        const jsonData = JSON.stringify({
            "name" : name,
            "role_id" : [18, 19, 20],
            "create" : create,
            "read" : read,
            "update" : update,
            "delete" : deleteItem,
            "menu" : menu,
            "other" : other,
            "status" : status
        })

        try {
            const creatingMicroservice = await getAxiosInstance(token).post(`/microservice-permission-core`, jsonData);
            const response = creatingMicroservice.data;
            if(response){
                toast.success('Microservice created successfully')
                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000)
            }
        } catch (error) {
            console.log(error);
            setDisabled(false);
        }
    }
    
  return (
    <div className="modal fade" id="add-role">
      <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add New Service</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                  <div className="form-wrapper">
                      <form onSubmit={handleSubmit}>
                          <div className="form-data">
                              <div className="form-group">
                                  <label htmlFor="">Service Name</label>
                                  <input type="text" name="name" value={permission.name} onChange={handleRole}/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Select Role</label>
                                  <select name="role_id" onChange={handleRole}>
                                    <option value="">Select service</option>
                                    {
                                        roleList && roleList.map(({id, name,}) => {
                                            return(
                                                <option key={id} value={id}>{name}</option>
                                            )
                                        })
                                    }
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Create</label>
                                  <select name="create" onChange={handleRole}>
                                      <option value="" >Select permission</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Read</label>
                                  <select name="read" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Update</label>
                                  <select name="update" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Delete</label>
                                  <select name="deleteItem" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Menu Access</label>
                                  <input type="text" onChange={handleRole} value={permission.menu} name="menu" />
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Others</label>
                                  <select name="other" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Status</label>
                                  <select name="status" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="active">Active</option>
                                  </select>
                              </div>
                              <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Add Service</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default CreateMircroservice