import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { persistStore, persistReducer } from 'redux-persist';
import { loginSlice } from './Login/loginSlice';
import { menuList } from './Menus/Menus';

const rootReducer = combineReducers({
    users : loginSlice.reducer,
    menus : menuList.reducer
})

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['users']
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer
})

export default store;
export const persistor = persistStore(store);
