import React, { useState, useEffect } from 'react';
import menu1 from '../../assets/menu1.png'
import pending from '../../assets/pending.png'
import clipboard from '../../assets/clipboard.png'
import ticket from '../../assets/document.png';
import manangement from '../../assets/list.png';
import worker from '../../assets/user (3).png';
import customerservice from '../../assets/customer-service.png';
import '../HelpDeskTags/HelpDeskTags.css';
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../common/apis/createAxios';

let activeStyle = {
    background : "white",
    color : "#093263",
    width : "100%",
}

const HelpDeskTags = () => {

    const { user, role, token } = useSelector(userSelector)

    const [permission, setPermission] = useState([]);

    const userPermission = role?.find(item => {
        const isAuthorized =  item?.user_id === user?.id;
        return isAuthorized;
    })

    const userPermissionId = userPermission?.role_id

    useEffect(() => {
        const fetchingData = async () => {
            const allPermission = await getAxiosInstance(token).get(`/roles-core/view/${userPermissionId}`)
            const result = allPermission.data;
            setPermission(result)
        }

        if(typeof userPermissionId !== 'undefined'){
            fetchingData();
        }
    },[userPermissionId])

    const isPermitted = permission?.find(item => {
        return item.permission_id.toLowerCase().includes("admin")
    })

  return (
    <div className='helpdesk-tags'>
        <div className="content_">
            <div className="item d-flex">
                <h3>Prime Serve</h3>
                <img src={customerservice} alt=""  width={"22px"} height="22px"/>
            </div>
            <NavLink to='/prime-serves/dashboard'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={menu1} width="20px" alt="" />
                <span>Dashboard</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket/new'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={ticket} width="20px" alt="" />
                <span>Create new ticket</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket-assigned-to-me'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={ticket} width="20px" alt="" />
                <span>My Asssigned Tickets</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket/history'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={pending} width="20px" alt="" />
                <span>My Tickets History</span>
            </NavLink>
            
            {
                isPermitted && <div className="admin">
                <h4>Admin</h4>
                <NavLink to='/helpdesk/ticket/all' 
                    style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                        }>
                            <img src={clipboard} width="20px" alt="" />
                            <span>All Tickets</span>
                </NavLink>
                <NavLink to='/helpdesk/department'
                    style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                        }>
                            <img src={worker} width="20px" alt="" />
                            <span>All Departments</span>
                </NavLink>
                <NavLink to='/helpdesk/sub-unit'
                    style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                        }>
                            <img src={manangement} width="20px" alt="" />
                            <span>All Sub-units</span>
                </NavLink>
                <NavLink to='/helpdesk/category'
                    style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                        }>
                            <img src={pending} width="20px" alt="" />
                            <span>All Ticket Category</span>
                </NavLink>
                <NavLink to='/helpdesk/checklist'
                    style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                        }>
                            <img src={pending} width="20px" alt="" />
                            <span>All Checklist</span>
                </NavLink>
            </div>
            }
        </div>
    </div>
  )
}

export default HelpDeskTags;