import React, { useState, useEffect } from 'react'
import '../Register/register.css';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { stateList } from '../../common/stateList';
import logo from '../../assets/logo.svg';
import primeraBackground from '../../assets/image 2.png'
import TextInput from '../../common/TextInput/TextInput';
import { irsAuthBaseUrl } from '../../common/apis/userApi';


const Register = () => {

    const [depts, setDepts] = useState([]);
    const [units, setUnits] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const gettingData = async () => {
            const allDepartments = await axios.get(`${irsAuthBaseUrl}/staff/department`);
            const results =  allDepartments.data;
            setDepts(results);

            const allUnits = await axios.get(`${irsAuthBaseUrl}/staff/unit/all`);
            const response = allUnits.data;
            setUnits(response);
        }

        gettingData()
    }, [])
    
    const formik = useFormik({
        initialValues : {
            username : "", 
            email : "", 
            // date_registered : "", 
            // fullName : "", 
            password : "",
            password_confirmation : "", 
            firstName : "", 
            lastName : "", 
            location : "", 
            dob : "", 
            sex : "", 
            phone : "", 
            state_of_origin : "", 
            marital_status : "", 
            bio : "", 
            office_phone : "", 
            department_id : "", 
            unit_id : "", 
            level : "", 
            // designation : "", 
            international_passport : "", 
            emergency_person : "", 
            emergency_number : "", 
            date_employed : "", 
            salary_bank : "", 
            salary_account_no : "", 
            salary_account_name : "", 
            pension_pin : "",
            pension_admin : ""
        },
        validationSchema : Yup.object({
            username : Yup.string().required('Required'), 
            email : Yup.string().email('Invalid email address').required('Required'), 
            // date_registered : Yup.string().required('Required'), 
            // fullName : Yup.string().required('Required'), 
            password : Yup.string().required('Required'),
            password_confirmation : Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required(), 
            firstName : Yup.string().required('Required'), 
            lastName : Yup.string().required('Required'), 
            location : Yup.string().required('Required'), 
            dob : Yup.string().required('Required'), 
            sex : Yup.string().required('Required'), 
            phone : Yup.string().required('Required'), 
            state_of_origin : Yup.string().required('Required'), 
            marital_status : Yup.string().required('Required'), 
            bio : Yup.string().required('Required'), 
            office_phone : Yup.string(), 
            department_id : Yup.string().required('Required'), 
            unit_id : Yup.string().required('Required'), 
            level : Yup.string(), 
            // designation : Yup.string(), 
            international_passport : Yup.string(), 
            emergency_person : Yup.string().required('Required'), 
            emergency_number : Yup.string().required('Required'), 
            date_employed : Yup.string().required('Required'), 
            salary_bank : Yup.string().required('Required'), 
            salary_account_no : Yup.string().required('Required'), 
            salary_account_name : Yup.string().required('Required'), 
            pension_pin : Yup.string(),
            pension_admin : Yup.string(),
        }),
        validateOnMount : false,     
        onSubmit : (values, onSubmitProps) => {
             handleSubmit(values, onSubmitProps)
            }
        }
    )

    const handleSubmit = async (values, onSubmitProps) => {

        const jsonBody = {
            "username" : values.username,
            // date_registered : values.date_registered,
            "email" : values.email,
            "name" : values.fullName,
            "password" : values.password,
            "password_confirmation" : values.password_confirmation,
            "first_name" : values.firstName,
            "last_name" : values.lastName,
            "location" : values.location,
            "dob" : values.dob,
            "sex" : values.sex,
            "phone" : values.phone,
            "state_of_origin" : values.state_of_origin,
            "marital_status" : values.marital_status,
            "bio" : values.bio,
            "office_phone" : values.office_phone,
            "department_id" : values.department_id,
            "unit_id" : values.unit_id,
            "level" : values.level,
            "designation" : values.designation,
            "intl_passport_no" : values.international_passport,
            "emergency_contact_person" : values.emergency_person,
            "emergency_contact_phone" : values.emergency_number,
            "date_employed" : values.date_employed,
            "salary_account_bank" : values.salary_bank,
            "salary_account_name" : values.salary_account_name,
            "salary_account_no" : values.salary_account_no,
            "pension_pin" :values.pension_pin,
            "pension_admin" : values.pension_admin,
          }

        try {
            const registering = await axios.post(`${irsAuthBaseUrl}/register`, jsonBody)
            const response = registering.data;
            if(response.status === 1){
                toast.success(response.message);
                setTimeout(() => {
                    navigate('/')
                },2500)
            }
        } catch (error){
            const errorMessage = error.response.data.message;
            if(errorMessage.email){
                toast.error(errorMessage.email[0])
            }
            if(errorMessage.username){
                toast.error(errorMessage.username[0]);
            }
            onSubmitProps.setSubmitting(false);
        }
    }

  return (
    <div className='register'>
        <div className="main-content">
            <div className="form-header">
                <h3>Welcome to Primera Internal Resource Stream (IRS)</h3>
            </div>
            <div className="row justify-content-center">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="col-lg-8 col-sm-10">
                    <div className="white_wrapper">
                        <form action="" onSubmit={formik.handleSubmit}>
                            <div className="logo">
                                <img src={logo} alt="primera logo"  width="100px"/>
                                <p className='text-center'>kindly fill in the accurate information.</p>
                            </div>
                            <div className="login-detail">
                                <h4>Account details</h4>
                                <div className="row_">
                                    <TextInput 
                                    label="Username"
                                    type="text" 
                                    placeholder='Johnny' 
                                    name='username' 
                                    value={formik.values.username} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.username && formik.errors.username) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    <TextInput 
                                    label="Email"
                                    type="email" 
                                    placeholder='jdoe@primerabank.com' 
                                    name='email' 
                                    value={formik.values.email} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.email && formik.errors.email) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                </div>
                                <div className="row_">
                                    <TextInput 
                                    label="Password"
                                    type="password" 
                                    placeholder='************' 
                                    name='password' 
                                    value={formik.values.password} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.password && formik.errors.password) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    <TextInput 
                                    label="Confirm Password"
                                    type="password" 
                                    placeholder='************' 
                                    name="password_confirmation" 
                                    value={formik.values.password_confirmation} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.password_confirmation && formik.errors.password_confirmation) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                </div>
                            </div>
                            <div className="user-detail">
                                <h4>Profile details</h4>
                                <div className="row_">
                                    <TextInput 
                                    label="First Name"
                                    type="text" 
                                    placeholder='John' 
                                    name="firstName" 
                                    value={formik.values.firstName} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.firstName && formik.errors.firstName) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    <TextInput 
                                    label="Last Name"
                                    type="text" 
                                    placeholder='Joe' 
                                    name="lastName" 
                                    value={formik.values.lastName} 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    style={{border : (formik.touched.lastName && formik.errors.lastName) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                </div>
                                <div className="row_">
                                    <div className="form-group">
                                        <label htmlFor="">Sex</label>
                                        <select name="sex" 
                                        value={formik.values.sex} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        style={{border : (formik.touched.sex && formik.errors.sex) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                        >
                                            <option value="" disabled>Select an option</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        {formik.touched.sex && formik.errors.sex ? (
                                            <div className='error-message'>{formik.errors.sex}</div>
                                        ) : null}
                                    </div>
                                    <div className="flex-column">
                                        <TextInput 
                                        label="Birthday"
                                        type="date" 
                                        placeholder='' 
                                        name="dob" 
                                        value={formik.values.dob} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.dob && formik.errors.dob) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                        />
                                        {/* <p className='hint'>Note:  We will only use it for the Birthday Notification, so we can celebrate everyone's birthday. Others cannot see your age. this has been set to be invisible to others except you.</p> */}
                                        </div>
                                </div>

                                <div className="row_">
                                    <div className="form-group">
                                        <label htmlFor="">Enter Address</label>
                                        <textarea name="location" cols="10" rows="5" value={formik.values.location} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                        <p className='hint'>Note: Another staff cannot see your location(private), please make sure this address is valid for Google Map.</p>
                                    </div>
                                </div>
                                <div className="row_">
                                    <div className="form-group">
                                        <label htmlFor="">State of Origin</label>
                                        <select name='state_of_origin' value={formik.values.state_of_origin} onChange={formik.handleChange} onBlur={formik.handleBlur}> 
                                            {
                                                stateList.map(({ code, name }) => {
                                                    return (<option value={name} key={code}>{name}</option>)
                                                })
                                            }
                                        </select>
                                        {formik.touched.state_of_origin && formik.errors.state_of_origin ? (
                                            <div className='error-message'>{formik.errors.state_of_origin}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Marital Status</label>
                                        <select name="marital_status" value={formik.values.marital_status} onChange={formik.handleChange} onBlur={formik.handleBlur}> 
                                            <option value="" selected disabled>Select an option</option> 
                                            <option value="single">Single</option> 
                                            <option value="Married">Married</option>
                                            <option value="Divorced">Divorced</option>
                                        </select>
                                        {formik.touched.marital_status && formik.errors.marital_status ? (
                                            <div className='error-message'>{formik.errors.marital_status}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row_">
                                    <TextInput 
                                        label="Phone Number"
                                        type="number" 
                                        placeholder='0810334533' 
                                        name="phone" 
                                        value={formik.values.phone} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.phone && formik.errors.phone) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    <TextInput 
                                        label="Office Number"
                                        type="number" 
                                        placeholder='0810334533' 
                                        name="office_phone" 
                                        value={formik.values.office_phone} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.office_phone && formik.errors.office_phone) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                </div>
                                <div className="row_">
                                    <div className="form-group">
                                        <label htmlFor="">Department</label>
                                        <select name="department_id" required value={formik.values.department_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> 
                                            <option value="" selected disabled>select an option</option> 
                                            {
                                                depts && depts.map(({id, name}) => {
                                                    return(
                                                        <option value={id} key={id}>{name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {formik.touched.department_id && formik.errors.department_id ? (
                                            <div className='error-message'>{formik.errors.department_id}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Unit</label>
                                        <select name="unit_id" required value={formik.values.unit_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> 
                                            <option value="" selected disabled>select an option</option> 
                                            {
                                                units && units.map(({id, name}) => {
                                                    return(
                                                        <option value={id} key={id}>{name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {formik.touched.unit_id && formik.errors.unit_id ? (
                                            <div className='error-message'>{formik.errors.unit_id}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row_">
                                    {/* <div className="form-group">
                                        <label htmlFor="">Designation</label>
                                        <select name="designation" value={formik.values.designation} onChange={formik.handleChange} onBlur={formik.handleBlur}> 
                                            <option value="" selected disabled>select an option</option> 
                                            <option value="ICT">ICT</option> 
                                            <option value="Marketing">Marketing</option>
                                            <option value="sales">Sales</option>
                                        </select>
                                    </div> */}
                                    <TextInput 
                                        label="Grade"
                                        type="text" 
                                        placeholder='' 
                                        name="level" 
                                        value={formik.values.level} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.level && formik.errors.level) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                </div>
                                <div className="row_">
                                    <TextInput 
                                        label="International Passport Number"
                                        type="text" 
                                        placeholder='' 
                                        name="international_passport" 
                                        value={formik.values.international_passport} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.international_passport && formik.errors.international_passport) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    <TextInput 
                                        label="Date Employed"
                                        type="date" 
                                        placeholder='' 
                                        name="date_employed" 
                                        value={formik.values.date_employed} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        formik={formik}
                                        style={{border : (formik.touched.date_employed && formik.errors.date_employed) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}
                                    />
                                    
                                </div>
                                <div className="row_">
                                    <div className="form-group">
                                        <label htmlFor="">Bio / Interest <br></br><span> (Something interesting about yourself/ Hobbies/ Slogan)</span> </label>
                                        <textarea name="bio" cols="10" rows="5" value={formik.values.bio} onChange={formik.handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="emergencies">
                                <h4>Emergency Contact Details</h4>
                                <div className="row_">
                                    <TextInput
                                    label="Full Name" 
                                    type="text" 
                                    name="emergency_person" 
                                    value={formik.values.emergency_person} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                    {/* <p>Another staff cannot see this (Private)</p> */}
                                    <TextInput 
                                    label="Mobile Number"
                                    type="number" 
                                    name="emergency_number" 
                                    value={formik.values.emergency_number} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                    {/* <p>Another staff cannot see this (Private)</p> */}
                                </div>
                                
                            </div>
                            <div className="bank-details">
                                <h4>Salary Account Details</h4>
                                <div className="row_">
                                    <TextInput 
                                    label="Bank Name (Salary)"
                                    type="text" 
                                    name="salary_bank" 
                                    value={formik.values.salary_bank} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                   <TextInput 
                                   label="Bank Account Name"
                                   type="text" 
                                   name="salary_account_name" 
                                   value={formik.values.salary_account_name} 
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   formik={formik}
                                   />
                                </div>
                                <div className="row_">
                                    <TextInput 
                                    label="Bank Number"
                                    type="number" 
                                    name="salary_account_no" 
                                    value={formik.values.salary_account_no} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                    <div></div>
                                </div>
                                <div className="row_">
                                    <TextInput 
                                    label="Pension Pin Number"
                                    type="text" 
                                    name="pension_pin" 
                                    value={formik.values.pension_pin} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                    <TextInput 
                                    label="Pension Fund Administrative (PFA)"
                                    type="text" 
                                    name="pension_admin" 
                                    value={formik.values.pension_admin} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="form-btns">
                                <button className='mt-5' type='submit'>Register</button>
                                <button>
                                    <Link to='/'>Back to login</Link>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="copyright">
                        <p> © 2022 Primera Microfinancebank. All rights reserved </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="outer-bg align-items-center">
            <div className="bg-container">
                <div className="blank"></div>
                <div className="prim-logo">
                    <img src={primeraBackground} alt="primera logo" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register;