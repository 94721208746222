import React, { useState } from 'react'
import '../../common/TextInput/TextInput.css';
import invisible from '../../assets/visibility.png';
import visibility from '../../assets/Eye.svg';

const TextInputTwo = ({label, name, type, onChange, placeholder, value, required, isReadOnly}) => {

    const [visible, setvisible] = useState(invisible);

    const handleClick = (e) => {
        const x = document.getElementById(name);
        console.log(x)
        if (x.type === "password") {
            x.type = "text";
            setvisible(visibility);
          } else {
            x.type = "password";
            setvisible(invisible);
          }
    }

  return (
    <div className='text-input w-100'>
        <div className="form-group">
            {
              label && <label className={required}>{label}</label>
            }
            <div className="input-wrapper">
                <input 
                type={type} 
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                id={name}
                readOnly = {isReadOnly}
                />
                {
                  (type === "password") && <img src={visible} alt="eye" onClick={handleClick}/>
                }
            </div>
            
        </div>
    </div>
  )
}

export default TextInputTwo