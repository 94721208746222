import React, { useEffect, useState } from 'react';
import '../DocumentComponent/document.css';
import {  useSelector } from 'react-redux';
import {  userSelector } from '../../feature/Login/loginSlice';
import { menuSelector, fetchingMenu } from '../../feature/Menus/Menus';
import { useDispatch } from 'react-redux';
import speaker from '../../assets/image 4.svg';
import { getAxiosInstance } from '../../common/apis/createAxios';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard'
import Tag from '../../components/Tag/Tag';
import Header from '../../components/header/header';
import intersectionBy from 'lodash.intersectionby';
import { convertArray } from '../../utilities/convertArray';
import { Link } from 'react-router-dom';
import PlaceholderComponent from '../../components/PlaceholderComponent/PlaceholderComponent';

const WorkTool = () => {

    const dispatch = useDispatch();
    const { menu } = useSelector(menuSelector)
    const { token, role, user } = useSelector(userSelector);
    const [permission, setPermission] = useState([]);
    // const [services, setServices] = useState([]);

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [])

    const allWorkToolMenus = menu && menu.filter(item => item.parent_id === 8 && item.is_parent === 0);

    const userPermission = role?.find(item => {
      const isAuthorized =  item?.user_id === user?.id;
      return isAuthorized;
  })
  const userPermissionId = userPermission?.role_id
   
  useEffect(() => {
      const fetchingData = async () => {
          const findPermission = await getAxiosInstance(token).get(`/roles-core/view/${userPermissionId}`)
          const result = findPermission.data;
          setPermission(result)

      }
      if(typeof userPermissionId !== 'undefined'){
          fetchingData();
      }
  },[userPermissionId])

  const newArray = convertArray(permission)
  const newArrayMenu = intersectionBy(allWorkToolMenus, newArray, "icon");

  console.log(newArrayMenu)
  
  return (
    <div className='worktool'>
      <div className='irs-component'>
        <div className="irs-wrapper">
            <Header />
            <div className="dashboard-content">
                <div className="inner-container">
                    <Tag />
                    <div className='dashboard-item'>
                      <div className="heading">
                          <h5>WORK TOOL</h5>
                      </div>
                      <div className="document-tab-wrapper">
                          <div className="document-tabs">
                              {
                                  newArrayMenu && newArrayMenu.map(({title, route, is_external}, id) => {

                                      return(
                                          <div className='box-blue' key={id} >
                                              {
                                                  (is_external === 1) && <a href={route} target="_blank" rel="noreferrer">
                                                      <p>{title.toUpperCase()}</p>
                                                  </a>
                                              }
                                              {
                                                  (is_external === 0) && <Link to={route}>
                                                      <p>{title.toUpperCase()}</p>
                                                  </Link>
                                              }
                                          </div>
                                      )
                                  })
                              }
                              {
                                allWorkToolMenus && allWorkToolMenus?.map(({title, route, is_external, child_order}, id) => {
                                  return(
                                      <div className='box-blue' key={id} 
                                      style={{display : (child_order !== 1) ? "flex" : "none"}}
                                      >
                                          {
                                              (is_external === 1) && <a href={route} target="_blank" rel="noreferrer">
                                                  <p>{title.toUpperCase()}</p>
                                              </a>
                                          }
                                          {
                                              (is_external === 0) && <Link to={route}>
                                                  <p>{title.toUpperCase()}</p>
                                              </Link>
                                          }
                                      </div>
                                  )
                                })
                              }
                          </div>
                          {
                              allWorkToolMenus === null && <div className="mb-5">
                                  <PlaceholderComponent />
                              </div>
                          }
                          <div className="lower-message d-flex align-items-center">
                              <img src={speaker} width="70px" alt="loud speaker icon" />
                              <p>Remember Primera's information and data security depends on you. Kindly ensure all data and information are properly handled with due diligence.</p>
                          </div>
                      </div>
                  </div>
                    <BirthdayCard />
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default WorkTool