/**
 * It takes an array of objects and returns an array of objects with only the id property
 * @param arr - The array of objects that you want to convert.
 * @returns An array of objects with the key "id" and the value of the permission_id.
 */
export const convertArray = (arr) => {
    return arr?.map(item => {
        return {
            "icon" : item?.permission_id
        }
    })
}

export const convertStaff = (arr) => {
    return arr?.map(item => {
        return{
            "label" : item?.name,
            "value" : item?.id
        }
    })
}

export const convertDepartment = (arr) => {
    return arr?.map(item => {
        return{
            "label" : item?.office_name,
            "value" : item?.id
        }
    })
}