import React from 'react';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Member from '../../components/Member/Member';
import '../StaffListPage/staffListPage.css';

const StaffListPage = () => {
  return (
    <div className='staff-list'>
        <Header/>
        <div className="container">
            <div className="content">
                <Member/>
                <div className="spacer"></div>
                <BirthdayCard/>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default StaffListPage