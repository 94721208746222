import React, { useState, useEffect } from 'react'
import KYCTAB from '../KYCTAB'
import KYCTags from '../KYCTags'
import '../AllAccounts/AllAccounts.css';
import TopMenu from './TopMenu';
import DataTable from './DataTable';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../../common/apis/createAxios';

const AllAccounts = () => {

    const { token } = useSelector(userSelector);
    const [loading, setloading] = useState(false);
    const [allAccounts, setAccounts] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [active, setActive] = useState({
        all : true,
        approved : false,
        pending : false,
        reject : false
    })
    
    const fetchingData = async (page) => {
        try {
            setloading(true);
            setActive({all : true, approved : false, pending : false, reject : false})
            const allAccount = await getAxiosInstance(token).get(`/kyc/corporate-account/paginate?page=${page}`)
            const response = allAccount.data;
            setAccounts(response.data)
            setpageCount(response.meta.total)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    const fetchingCounts = async (status) => {
        try {
            setloading(true);
            if(status === 'Approved'){
                setActive({all : false, approved : true, pending : false, reject : false})
            }
            if(status === 'Pending'){
                setActive({all : false, approved : false, pending : true, reject : false})
            }
            if(status === 'Rejected'){
                setActive({all : false, approved : false, pending : false, reject : true})
            }
            const allPending = await getAxiosInstance(token).get(`/kyc/corporate-account/application_status/${status}`)
            const result = allPending.data;
            setAccounts(result.data)
            setpageCount(result.meta.total)
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }
    
    useEffect(() => {
        fetchingData(1)
    },[])

  return (
    <div className='all-accounts'>
        <KYCTags />
        <div className="dash-wrapper">
            <KYCTAB menuFor={"Corporate KYC"}/>
            <TopMenu setAccounts={setAccounts} setloading={setloading} type="corporate" setpageCount={setpageCount}/>
            <div className="px-4 my-2">
                <div className="bread-wrapper">
                    <div 
                    className="bread-item"
                    style={{
                        background : active.all ? "#0C2C60" : "white",
                        color : active.all ? "white" : "black"
                    }}
                    onClick={() => fetchingData(1)}
                    >
                        All Application
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.approved ? "#0C2C60" : "white",
                        color : active.approved ? "white" : "black"
                    }}
                     onClick={() =>fetchingCounts("Approved")}>
                         Approved Request
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.pending ? "#0C2C60" : "white",
                        color : active.pending ? "white" : "black"
                    }}
                    onClick={() =>fetchingCounts("Pending")}
                    >
                        Awaiting Request
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.reject ? "#0C2C60" : "white",
                        color : active.reject ? "white" : "black"
                    }}
                    onClick={() =>fetchingCounts("Rejected")}
                    >
                        Declined Request
                    </div>
                </div>
            </div>
            <div className="px-4">
                <DataTable 
                allData={allAccounts} 
                loading={loading} 
                pageCount={pageCount}
                fetchingData={fetchingData}
                user={''}
                officer={''}
                />
            </div>
        </div>
    </div>
  )
}

export default AllAccounts