import React from 'react'
import TextInput from '../../../common/TextInput/TextInput';
import './personal-info.css';

const AccountInformation = ({formik, setValid}) => {

    if(formik.values.source_of_funds !== "" && formik.values.nos_of_directors !== "" && formik.values.directors_bvn !== ""  && formik.values.director_bvn_two !== "" && formik.values.directors_id_type !== "" && formik.values.politically_exposed_person !== "" && formik.values.antimoney_laundry_risk_rating !== "" && formik.values.estimated_monthly_turn_over !== "" && formik.values.initial_deposit !== "" && formik.values.company_email !== "" ){
        setValid(true)
    }
  return (
    <div className='account-info personal-info'>
        <div className="d-flex mb-4">
            <div className="w-100">
                <TextInput 
                label='Source of funds'
                type="text" 
                placeholder='' 
                name='source_of_funds' 
                value={formik.values.source_of_funds} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.source_of_funds && formik.errors.source_of_funds) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                label='Number of Directors'
                type="number" 
                placeholder='' 
                name='nos_of_directors' 
                value={formik.values.nos_of_directors} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.nos_of_directors && formik.errors.nos_of_directors) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex my-4">
            <div className="w-100">
                <TextInput 
                    label='Director 1 BVN'
                    type="text" 
                    placeholder='Director 1 BVN' 
                    name='directors_bvn' 
                    value={formik.values.directors_bvn} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.directors_bvn && formik.errors.directors_bvn) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                    label='Director 2 BVN'
                    type="text" 
                    placeholder='Director 2 BVN' 
                    name='director_bvn_two' 
                    value={formik.values.director_bvn_two} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.director_bvn_two && formik.errors.director_bvn_two) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Directors Id type</label>
                    <select 
                    name='directors_id_type' 
                    value={formik.values.directors_id_type} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.directors_id_type && formik.errors.directors_id_type) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}>
                        <option value="" disabled selected>Select director ID Type</option>
                        <option value="International Passport">International Passport</option>
                        <option value="National ID Card (NIMC)">National ID Card (NIMC)</option>
                        <option value="Permanent Voter’s Card">Permanent Voter’s Card</option>
                        <option value="Driver’s License">Driver’s License</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <TextInput 
                    label='Company Email'
                    type="text" 
                    placeholder='' 
                    name='company_email' 
                    value={formik.values.company_email} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.company_email && formik.errors.company_email) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex my-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Politically exposed person</label>
                    <select 
                    name='politically_exposed_person' 
                    value={formik.values.politically_exposed_person} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.politically_exposed_person && formik.errors.politically_exposed_person) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}>
                        <option value="" disabled selected>Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Anti money laundering risk rating</label>
                    <select name="antimoney_laundry_risk_rating" onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value="" disabled selected>Select your option</option>
                        <option value="Low" >Low</option>
                        <option value="Medium" >Medium</option>
                        <option value="High" >High</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="d-flex mb-4">
            <div className="w-100">
                <TextInput 
                label='estimated_monthly_turn_over'
                type="number" 
                placeholder='' 
                name='estimated_monthly_turn_over' 
                value={formik.values.estimated_monthly_turn_over} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.estimated_monthly_turn_over && formik.errors.estimated_monthly_turn_over) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                label='Initial deposit'
                type="number" 
                placeholder='' 
                name='initial_deposit' 
                value={formik.values.initial_deposit} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.initial_deposit && formik.errors.initial_deposit) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
    </div>
  )
}

export default AccountInformation