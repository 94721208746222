import React, { useState , useEffect} from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../HelpdeskCreateDepartment/helpdeskdepartment.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { irsBaseUrl } from '../../../common/apis/userApi';
import Swal  from 'sweetalert2';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useSelector } from 'react-redux';
import spyglass from '../../../assets/search.svg';
import ReactPaginate from 'react-paginate';


const AllUnit = () => {

    const { token } = useSelector(userSelector);
    const [subunit, setSubunit] = useState([]);
    const [staff, setStaff] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [editUnit, setEditUnit] = useState({
        name : "",
        team_lead_id : "",
        group_email : "", 
        hod : ""
    })

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(5);
    
    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const departmentData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`);
                const response = departmentData.data;
                setSubunit(response.data);

                const allStaff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const result = allStaff.data;
                setStaff(result.data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchingData()
    },[])

  
    useEffect(() => {
  
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(subunit.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(subunit.length / itemsPerPage));
  
    }, [itemOffset, itemsPerPage, subunit, searchField]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % subunit.length;
      setItemOffset(newOffset);
    };

    const handleChange = (e) => {
        e.persist();
        setSearchField(e.target.value);
    }

    const handleEditUnit = async (e) => {
        e.preventDefault();

        const { name, team_lead_id, hod, group_email} = editUnit;
        const { id } = e.currentTarget;
        const jsonData = {
            name : name,
            team_lead_id : team_lead_id,
            group_email : group_email, 
            hod : hod
        }

        try {
            const removeDoc = await authAxios.put(`${irsBaseUrl}/interdepartmental/ticket-sub-unit/${id}`, jsonData);
            const result = removeDoc.data;
            if(result.created_at){
                toast.success("Department updated successfully!");
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }
            console.log(result)

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error(apiMsg.data)
        }
    }

    const handleEditChange = (e) => {
        e.persist();
        setEditUnit({...editUnit, [e.target.name] : e.target.value});
    }

    const unitInputTL = (e) => {
        const val = e.target.value;
        let opts = document.getElementById('editunitTL').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setEditUnit({...editUnit, team_lead_id : dataId})
            break;
            }
        }
    }
    const unitInputHOD = (e) => {
        const val = e.target.value;
        let opts = document.getElementById('editunitHOD').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setEditUnit({...editUnit, hod : dataId})
            break;
            }
        }
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemoveUnit(id)
            }
        })
    }
    const handleRemoveUnit = async (id) => {
        
        try {
            const removeDoc = await authAxios.delete(`${irsBaseUrl}/interdepartmental/ticket-sub-unit/${id}`);
            const result = removeDoc.data;
            if(result.status == "success"){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  ).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    },2000)
                })
            }

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error(apiMsg.data)
        }  
    }

    const handleSearch = () => {
        const filteredList = subunit.filter(item => item.name.toLowerCase().includes(searchField.toLowerCase()));
        setCurrentItems(filteredList)
    }

  return (
    <div className='all-unit casa-documentlist'>
        <Header />
        <div className="container_">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="document-type-list help-createdepartment p-3">
                    <h3>All Sub-unit</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <div className="search-wrapper">
                            <input type="search" name='searchField' placeholder='filter' value={searchField} onChange={handleChange}/>
                            <img src={spyglass} alt="" onClick={handleSearch}/>
                        </div>
                        <Link to='/helpdesk/create-subunit'>New Sub-unit</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Unit name</h5>
                            <h5 className='text-center'>Unit Id</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            currentItems && currentItems.map(({ id, name }) => {
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <p className='colored'>{name}</p>
                                            <p className='text-center'>{id}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                                <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target={`#unit-form${id}`}>
                                                    Edit
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal fade" id={`unit-form${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Edit Sub unit</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-wrapper">
                                                            <form action="" onSubmit={handleEditUnit} id={id} className="d-flex flex-column align-items-center">
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="">Name</label>
                                                                <input type="text" placeholder='' name="name" value={editUnit.name} onChange={handleEditChange} className="w-100" required/>  
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>Team Lead</label>
                                                                <div className="input-wrapper w-100" >
                                                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                                                    <input list="editunitTL" id='inputunitTL' onKeyUp={unitInputTL} placeholder="search staff" required/>
                                                                </div>
                                                                <datalist id="editunitTL">
                                                                    {staff.map(({id, name}) =>
                                                                        <option key={id} value={name} id={id}>{name}</option>
                                                                    )}
                                                                </datalist>
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>Group Email</label>
                                                                <input type="email" placeholder='' value={editUnit.group_email} name="group_email" onChange={handleEditChange} required className="w-100"/>
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>HOD</label>
                                                                <div className="input-wrapper w-100" >
                                                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                                                    <input list="editunitHOD" id='inputunitHOD' onKeyUp={unitInputHOD} placeholder="search staff" required/>
                                                                </div>
                                                                <datalist id="editunitHOD">
                                                                    {staff.map(({id, name}) =>
                                                                        <option key={id} value={name} id={id}>{name}</option>
                                                                    )}
                                                                </datalist>
                                                            </div>
                                                            <button type='submit' id={id}>Edit Unit</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< prev"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AllUnit