import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';


const EditMicroservice = ({ id, name, menu, roleList }) => {

    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false);

    const [services, setServices] = useState({
        name : name,
        role_id : "",
        create : "",
        read : "",
        update : "",
        deleteItem : "",
        menu : menu,
        other : "",
        status : ""
    })

    const handleChange = (e) => {
        setServices({...services, [e.target.name] : e.target.value})
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const { id } = e.currentTarget;
        const { name, role_id, create, read, update, deleteItem, menu, other, status } = services;

        const jsonData = JSON.stringify({
            "name" : name,
            "role_id" : role_id,
            "create" : create,
            "read" : read,
            "update" : update,
            "delete" : deleteItem,
            "menu" : menu,
            "other" : other,
            "status" : status
        })

        try {
            const removeDoc = await getAxiosInstance(token).put(`/microservice-permission-core/${id}`, jsonData);
            const result = removeDoc.data;
            if(result){
                toast.success("User Permission updated successfully");
                // setTimeout(() => {
                //     window.location.reload();
                // },3000)
            }
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data.message;
            toast.error(apiMsg)
        }
    }

  return (
    <div className="modal fade" id={`edit-form${id}`}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Microservice</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form action="" id={id} onSubmit={handleEdit}>
                            <div className="form-data">
                                <div className="form-group">
                                    <label htmlFor="">Service Name</label>
                                    <input type="text" name="name" value={services.name} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Select Role</label>
                                    <select name="role_id" onChange={handleChange}>
                                        <option value="">Select service</option>
                                        {
                                            roleList && roleList.map(({id, name,}) => {
                                                return(
                                                    <option key={id} value={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Create</label>
                                    <select name="create" onChange={handleChange}>
                                        <option value="" >Select permission</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Read</label>
                                    <select name="read" onChange={handleChange}>
                                        <option value="">Select permission</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Update</label>
                                    <select name="update" onChange={handleChange}>
                                        <option value="">Select permission</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Delete</label>
                                    <select name="deleteItem" onChange={handleChange}>
                                        <option value="">Select permission</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="">Menu Access</label>
                                  <input type="text" onChange={handleChange} value={services.menu} name="menu" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Others</label>
                                    <select name="other" onChange={handleChange}>
                                        <option value="">Select permission</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Status</label>
                                    <select name="status" onChange={handleChange}>
                                        <option value="">Select permission</option>
                                        <option value="active">Active</option>
                                    </select>
                                </div>
                                <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Edit Menu</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditMicroservice;