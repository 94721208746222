import React from 'react';
import '../Hamburger/Hamburger.css';
import { Link } from 'react-router-dom';
import hamburger from '../../assets/Group 12.svg';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';

const Hamburger = ({handleLogout}) => {

    const { user} = useSelector(userSelector);

  return (
    <div className="dropdown">
        <button className="btn dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={hamburger} alt="" className='hamburger'/>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li className='profile'>
                <div className="preview-user d-flex align-items-center">
                    <img src={user?.avatar} alt="user profile" />
                    <div className="preview-details">
                        <h4>{user?.name}</h4>
                        <p>{user?.designation}</p>
                    </div>
                </div>
                <button className="dropdown-item" type="button">
                    <Link to='/user-profile'>View Profile</Link>
                </button>
            </li>
            <li>
                <button className="dropdown-item" type="button">
                    <Link to='/edit-profile'>Edit Profile</Link>
                </button>
            </li>
            <li>
                <button className="dropdown-item" type="button">
                    <p onClick={handleLogout}>Sign out</p>
                </button>
            </li>
        </ul>
    </div>
  )
}

export default Hamburger