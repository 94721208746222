import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../../dahsboardHome/dashboard.css';
import '../../Menus/Menu.css';
import '../../Permissions/Permissions.css'
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import searchimg from '../../../assets/search.svg';
import loader from '../../../assets/insider-loading.gif';
import CreateRole from './CreateRole';
import PermissionTags from '../PermissionTags/PermissionTags';
import EditRole from './EditRole';
import Swal  from 'sweetalert2';
import Header from '../../../components/header/header';

const Roles = () => {

    const { token } = useSelector(userSelector);
    const [allRoles, setAllRoles] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [allPermission, setAllpermission] = useState([]);

    const [search, setSearch] = useState("");

    const [active] = useState({
        role : true,
        service : false,
        user : false,
    })

    useEffect(() => {

        const fetchingData = async () => {
            try {
                const allrole = await getAxiosInstance(token).get(`/roles-core`)
                const result = allrole.data;
                setAllRoles(result);

                const services = await getAxiosInstance(token).get(`/microservice-permission-core`)
                const res = services.data;
               setAllServices(res)

                const permission = await getAxiosInstance(token).get(`/roles-core/lists`);
                const response = permission.data;
                setAllpermission(response);

            } catch (error) {
                console.log(error)
            }
        }

        fetchingData();
     }, [token])

    const handleChange = (e) => {
        e.persist();
        setSearch(e.target.value)
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })
    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await getAxiosInstance(token).delete(`/roles-core/${id}`);
            if(removeDoc.status === 204){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                ).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    },1000)
                })
            }
        } catch (error) {
            const apiMsg = error.response.data.message;
            console.log(apiMsg)
            toast.error("Removal Failed!")
        }  
    }

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const roleList = allRoles.data;
    const serviceList = allServices.data;

    // const filterArray = userList?.filter(item => item.staffname.toLowerCase().includes(search.toLowerCase()))

    // console.log(roleList)
  return (
    <div className='menus dashboard_ permissionlist'>
        <Header />
        <div className="container___" style={{paddingTop : "80px"}}>
            <ToastContainer closeButton={true} position="top-right" />
            <div className="dashboard-wrapper">
                <PermissionTags active={active}/>
                <div className="document-type-list roles">
                    <h3>Permission Roles</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <form>
                            <input type="search" placeholder='filter' value={search} onChange={handleChange}/>
                            <img src={searchimg} alt="" />
                        </form>
                        <span 
                            className='edit text-center' 
                            type="button" 
                            data-bs-toggle="modal" 
                            data-bs-target="#add-role"
                            aria-expanded="false" 
                            aria-controls="collapseExample" 
                        >
                            New Role
                        </span>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Name</h5>
                            <h5 className=''>Permissions</h5>
                            <h5 className=''>Status</h5>
                            <h5 className=''>Date</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            typeof roleList === 'undefined' && <div className='w-100 text-center'>
                                <img src={loader} alt="" width="80px"/>
                             </div>
                        }
                        {
                            roleList && roleList?.map(({ id, name, status, created_at }) => {

                                const newDate = new Date(created_at);
                                const [month, day, year] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];

                                const rolePermission = allPermission?.filter(item => Number(item?.role_id) === id);
                            
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <div className='colored'>
                                                <p>{name}</p>
                                            </div>
                                            <ul>
                                                {
                                                    rolePermission.map(({ id, permission_id}) => {
                                                        const permissionName = serviceList?.filter(item => item.id === Number(permission_id))[0];
                                                        return(
                                                            <li key={id}>{permissionName?.name}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <p className='text-ce'>{status}</p>
                                            <p>{`${day} ${months[month]} ${year}`}</p>
                                            <div className="d-flex justify-content-center">
                                                <span 
                                                className='edit text-center' 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target={`#edit-form${id}`}
                                                aria-expanded="false" 
                                                aria-controls="collapseExample" 
                                                >
                                                    Edit
                                                </span>
                                                <span 
                                                id={id}
                                                onClick={showAlert}
                                                className="remove"
                                                style={{marginLeft : "10px"}}
                                                >
                                                    Delete
                                                </span>
                                            </div>
                                        </div>
                                        <EditRole 
                                        id={id}
                                        name={name}
                                        serviceList={serviceList}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </div>
                </div>
                <CreateRole 
                serviceList={serviceList}
                />
            </div>
        </div>
    </div>
  )
}

export default Roles;