import React, { useRef } from 'react';
// import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import '../Preview/Preview.css';
import logo from '../../assets/primeralogo.jpeg';
import '../QueuePreview/queuepreview.css';
import ComponentToPrint from './ComponentToDownload';

const QueuePreview = ({ applicationId, office_name, requester, dept_name, subject, dateCreated, background, justification, memo_request, reviewerBy, staffConcur, approvingStaff, watermark}) => {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

    // const handlePrint = (e) => {
    //     const domElement = document.querySelector('#to-print')
    //     html2canvas(domElement, {onclone : (document) => {
    //         document.querySelector('.btn').style.visibility = 'hidden'
    //     }}).then((canvas) => {
    //         const img = canvas.toDataURL('image/jpeg');
    //         const pdf = new jsPDF();
    //         pdf.addImage(img, 'JPEG', 0, 0, 210, 280);
    //         pdf.save('document.pdf')
    //     })
    // }

    console.log(componentRef)
    const renderDate = dateCreated.split("T")[0];

  return (
    <div className='preview queue document-type'>
        <div className="content" id="to-print" >
            <div className="content-wrapper">
                <div className="logo-intro text-center">
                    <img src={logo} width="70px" alt="primera logo" />
                    <h3 className='mb-4'>Internal Memo</h3>
                </div>
                <div className="heading">
                    <div className="heading-item">
                        <h4>FROM</h4>
                        <input type="text" defaultValue={`${dept_name}`} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>TO</h4>
                        <input type="text" defaultValue={`${office_name}`} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>APP ID</h4>
                        <input type="text" defaultValue={applicationId} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>SUBJECT</h4>
                        <input type="text" defaultValue={subject} readOnly/>
                    </div>
                    <div className="heading-item">
                        <h4>Date</h4>
                        <input type="text" defaultValue={renderDate} readOnly/>
                    </div>
                </div>
                <div className="body">
                    <div className="bg d-flex mb-3">
                        <h5>Background:</h5>
                        <p>{background}</p>
                    </div>
                    <div className="request d-flex mb-3">
                        <h5>Request:</h5>
                        <p>{memo_request}</p>
                    </div>
                    <div className="justification d-flex mb-3">
                        <h5>Justification:</h5>
                        <p>{justification}</p>
                    </div>
                </div>
                <div className="requester">
                    <h5>Requester:</h5>
                    {
                        requester.map(({signature, requesterName, requesterSatffid}) => {
                            return(
                                <div className="list-wrapper" key={requesterSatffid}>
                                    <div className="form_grouping">
                                        <input type="text" defaultValue={`${requesterName}`} className="text-center" readOnly/>
                                        <label htmlFor="">Name</label>
                                    </div>
                                    <div className="form_grouping">
                                        <span className='signature-img'>
                                        {
                                            signature !== "pending"  &&  <img src={signature} alt="" width="200px"/>
                                        }
                                        </span><br></br>
                                        <label htmlFor="">e-signature</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="requester">
                    <h5>Reviewed by:</h5>
                    {
                        reviewerBy.map(({staffName, signature}, index) => {
                            return(
                            <div className="list-wrapper" key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffName} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    <span className='signature-img'>
                                        {
                                            signature !== "pending"  &&  <img src={signature} alt="" width="200px"/>
                                        }
                                    </span><br></br>
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
                <div className="approval d-flex">
                    <h5>Concurrence</h5>
                    <div className="list-wrapper">
                    {
                        staffConcur.map(({staffname, signature, signed}, index) => {
                            return <div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffname} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    {
                                        signed ? <span className='signature-img'>
                                        <img src={signature} alt="" width="200px"/>
                                    </span> : <input type="text" />
                                    }
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="approval d-flex">
                    <h5>Approval</h5>
                    <div className="list-wrapper">
                    {
                        approvingStaff.map(({staffname, signature, signed}, index) => {
                            return<div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffname} className="text-center" readOnly/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    {
                                        signed ? <span className='signature-img'>
                                            <img src={signature} alt="" width="200px"/>
                                        </span> : <input type="text" />
                                    }
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="memo-footer no-print">
                    <h4>PRIMERA MICROFINANCE BANK</h4>
                    <div className="address">
                        <p className=''>24, kofo Abayomi streeet, victoria Island, Lagos.</p>
                        <p className=''>T: +234 1 277 0827-32 </p>
                        <p className='website'>www.primerabank.com</p>
                    </div>
                </div>
                <div className="water-mark">
                    <p className='water-mark-text'>{watermark}</p>
                </div>
            </div>
        </div>
        <div className="form-group d-flex flex-row btns no-print">
        {/* <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
        /> */}
        {/* <ComponentToPrint 
            componentRef={componentRef} 
            // appType={appType} 
            applicationId={applicationId} 
            office_name={office_name} 
            requester={requester} 
            dept_name={dept_name} 
            subject={subject} 
            dateCreated={dateCreated}
            background={background} 
            justification={justification} 
            memo_request={memo_request} 
            reviewerBy={reviewerBy} 
            staffConcur={staffConcur} 
            approvingStaff={approvingStaff}
            watermark={watermark}
        /> */}
        
        <button onClick={handlePrint} ref={componentRef}>Print this out!</button>
            {/* <button className='btn' onClick={handlePrint} > Download</button> */}
        </div>
    </div>
  )
}

export default QueuePreview;