import React, { useState } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags';
import '../CasaOperations/casadeposit.css';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import Swal  from 'sweetalert2';
import Button from '../../../common/button/button';

const CasaWithdrawal = () => {

    const [disabled, setdisabled] = useState(false);
    const [withdraw, setWithdraw] = useState({
        amount : "",
        accountNo : "",
        narration : "",
        bywho : ""
    });

    const handleChange = (e) => {
        setWithdraw({...withdraw, [e.target.name] : e.target.value})
    }

    const handleWithdraw = async (e) => {
        setdisabled(true)
        const { amount, accountNo, narration, bywho } = withdraw
        const JsonData = {
            "amount": amount,
            "accountNo": accountNo,
            "naration": narration,
            "byWho": bywho
        }
        try {
            const query = await axios.post(`${casaBaseurl}withdrawal`, JsonData);
            const response = query.data;
            console.log(response)
            setdisabled(false);
            if(response.code === 200){
                Swal.fire(
                    'Completed!',
                    'Transaction Success!',
                    'success'
                  )
            }
            setWithdraw({amount : "", accountNo : "", narration : "", bywho : ""})
        } catch (error) {
            setdisabled(false);
            const errorMesssage = error.response.data.body.data;
            console.log(errorMesssage)
            toast.error(errorMesssage)
        }
    }

    const showAlert = (e) => {
        e.preventDefault();
        const amount = Number(withdraw.amount);
        const stringAmount = amount.toLocaleString();

        Swal.fire({
            title: 'Transaction Confirmation',
            text: `${withdraw.bywho} wants to withdraw ${stringAmount}naira from ${withdraw.accountNo}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, proceed!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleWithdraw()
            }
        })
    }

  return (
    <div className='deposit'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="withdraw-section">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-sm-12">
                        <form action="" onSubmit={showAlert}>
                            <h4>Widthdrawal </h4>
                            <div className="form-group">
                                <label htmlFor=""> Name</label>
                                <input type="text" value={withdraw.bywho} name="bywho" onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Account Number</label>
                                <input type="number" value={withdraw.accountNo} name="accountNo" onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="number" value={withdraw.amount} name="amount" onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Narration</label>
                                <textarea cols="30" rows="10" value={withdraw.narration} name="narration" onChange={handleChange}></textarea>
                            </div>
                            <div className="withdraw-btn">
                                <Button 
                                value={"Withdraw"}
                                type={"submit"}
                                disabled={disabled}
                                />

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaWithdrawal