import React, { useState, useEffect } from "react";
import "../updateStaff/updateStaff.css";
import Header from "../../../components/header/header";
import CasaTags from "../CasaTags/CasaTags";
import axios from "axios";
import { casaBaseurl } from "../../../common/apis/userApi";
import { toast, ToastContainer } from "react-toastify";
import spyglass from "../../../assets/search.svg";
import { useSelector } from "react-redux";
import { userSelector } from "../../../feature/Login/loginSlice";
import { useNavigate } from "react-router-dom";

const AddStaff = () => {
  const history = useNavigate();
  const { token } = useSelector(userSelector);
  const [staff, setStaff] = useState([]);
  const [formData, setFormData] = useState({
    staffName: "",
    staffId: "",
    permission: "",
  });
  const authAxios = axios.create({
    baseURL: casaBaseurl,
  });

  useEffect(() => {
    const fetchingData = async () => {
      try {
        const fetching = await axios({
          method: "GET",
          url: "https://irsapi.koinsbank.com/api/v3/staff/all",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const response = fetching.data;
        setStaff(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchingData();
  }, [token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInput = () => {
    var val = document.getElementById("inputVal").value;
    let opts = document.getElementById("staffdata").childNodes;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        const dataId = opts[i].id;
        const data = opts[i].value;
        setFormData({ ...formData, staffId: dataId, staffName: data });
        break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jsonData = {
        irs_id: formData.staffId,
        permission: [],
      };
      const postingData = await authAxios.post("/addstaff", jsonData);
      const response = postingData.data;
      console.log(response);
      if (response.code === 200) {
        toast.success("Staff added successfully!");
        setTimeout(() => {
          history("/staff/all");
        }, 2000);
      }
    } catch (error) {
      const errorMsg = error.response.data.body.data;
      toast.error(errorMsg);
    }
  };

  console.log(formData);

  return (
    <div className="add-staff">
      <Header />
      <div className="content w-full d-flex">
        <CasaTags />
        <div className="add-staff-form">
          <ToastContainer closeButton={true} position="top-right" />
          <div className="add-staff-wrapper">
            <h4 className="mb-4">Add Staff</h4>
            <form action="" onSubmit={handleSubmit}>
              <div className="form-data">
                <div className="form-group">
                  <label htmlFor="">Staff Name</label>
                  <div className="input-wrapper w-100">
                    <input
                      list="staffdata"
                      id="inputVal"
                      placeholder="search staff"
                      onInput={handleInput}
                      required
                    />
                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                  </div>
                  <datalist id="staffdata">
                    {staff.map(({ id, name }) => (
                      <option key={id} value={name} id={id}>
                        {name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="form-group">
                  <label htmlFor="">Permission</label>
                  <select
                    name="permission"
                    id=""
                    value={formData.permission}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select staff permission
                    </option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
                <button type="submit">Add Staff</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
