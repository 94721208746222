import './App.css'
import Login from './pages/Login/Login';
import { Routes, Route } from 'react-router-dom';
import ForgetPassword from './pages/forget-password/ForgetPassword';
import Register from './pages/Register/register';
import Profile from './pages/profile/profile';
import Dashboard from './pages/dahsboardHome/dashboard';
import DocumentComponent from './pages/DocumentComponent/DocumentComponent';
import PrimeraAcademy from './pages/PrimeraAcademy/PrimeraAcademy';
import Staff from './pages/staff/Staff';
import WorkTool from './pages/workTool/WorkTool';
import Compliance from './pages/compliance/Compliance';
import IDCARD from './pages/IDCARD/IDCARD';
import Appraisal from './pages/Appraisal/Appraisal';
import TokenizeCard from './pages/TokenizeCard/TokenizeCard';
import Leave from './pages/Leave/Leave';
import BoardroomBorading from './pages/BoardroomBoarding/BoardroomBorading';
import SuggestionPortal from './pages/SuggestionPortal/SuggestionPortal';
import AssetDeposit from './pages/AssetDeposit/AssetDeposit';
import HelpDeskDashboard from './pages/HelpDeskDashboard/HelpDeskDashboard';
import Interview from './pages/Interview/Interview';
import StaffListPage from './pages/StaffListPage/StaffListPage';
import StaffHandBook from './pages/StaffHandbook/StaffHandBook';
import QuartlyTraining from './pages/QuartlyTraining/QuartlyTraining';
import CreateLeave from './pages/CreateLeave/CreateLeave';
import CreateTicket from './pages/CreateTicket/CreateTicket';
import TicketList from './pages/TicketList/TicketList';
import PendingTickets from './pages/HelpDeskPages/PendingTickets/PendingTickets';
import SupervisorLeave from './pages/SupervisorLeave/SupervisorLeave';
import EditProfileImage from './pages/EditProfileImage/EditProfileImage';
import EditProflie from './pages/EditProfile/EditProflie';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProtectedRoute from './common/ProtectedRoute';
import KoinsPreview from './pages/KoinsPreview/KoinsPreview';
import Document from './pages/Document/Document';
import CreateDocName from './pages/CreateDocName/CreateDocName';
import EditDocName from './components/EditDocName/EditDocName';
import AddSignature from './pages/AddSignature/AddSignature';
import Casa from './pages/CASA/Casa';
import AddCustomer from './pages/CASA/AddCustomer/AddCustomer';
import AddStaff from './pages/CASA/AddStaff/AddStaff';
import CreateCasaDoc from './pages/CASA/CasaDocument/CreateCasaDoc';
import CasaStaffList from './pages/CASA/CasaStaffList/CasaStaffList';
import CasaCustomerList from './pages/CASA/CasaCustomerList/CasaCustomerList';
import CasaCustomerPreview from './pages/CASA/CasaCustomerList/casaCustomerPreview';
import CasaAccounts from './pages/CASA/CasaAccounts/CasaAccounts';
import CasaAllDocumentList from './pages/CASA/CasaDocument/CasaAllDocumentList';
import PendingApproval from './pages/CASA/CasaDocument/PendingApproval';
import ApprovalDeferral from './pages/CASA/CasaDocument/ApprovalDeferral';
import AllDocumentApplication from './pages/AllDocumentApplication/AllDocumentApplication';
import Products from './pages/Products/Products';
import MyDocumentApplications from './pages/MyDocumentAppplications/MyDocumentApplications';
import ExemptionList from './pages/CASA/ExemptionList/ExemptionList';
import ExemptionPreview from './pages/CASA/ExemptionList/ExemptionPreview';
import MyPendingApprovals from './pages/CASA/MyPendingApprovals/MyPendingApprovals';
import MyDocumentPreview from './pages/MyDocumentAppplications/MyDocumentPreview';
import CasaTransactions from './pages/CASA/CasaOperations/CasaTransactions';
import CasaWithdrawal from './pages/CASA/CasaOperations/CasaWithdrawal';
import CasaDeposit from './pages/CASA/CasaOperations/CasaDeposit';
import EditCustomer from './pages/CASA/EditCustomer/EditCustomer';
import CasaTransfer from './pages/CASA/CasaOperations/CasaTransfer';
import UpdateStaff from './pages/CASA/updateStaff/UpdateStaff';
import HelpDeskTicketPreview from './components/HelpDeskComponent/HelpDeskTicketPreview';
import HelpdeskCreateDepartment from './pages/HelpDeskPages/HelpdeskCreateDepartment/HelpdeskCreateDepartment';
import CreateUnit from './pages/HelpDeskPages/CreateUnit/CreateUnit';
import CreateCategory from './pages/HelpDeskPages/CreateCategory/CreateCategory';
import CreateCheckList from './pages/HelpDeskPages/CreateCheckList/CreateCheckList';
import Alldepartments from './pages/HelpDeskPages/HelpdeskCreateDepartment/Alldepartments';
import AllUnit from './pages/HelpDeskPages/CreateUnit/AllUnit';
import AllCategories from './pages/HelpDeskPages/CreateCategory/AllCategories';
import AllChecklist from './pages/HelpDeskPages/CreateCheckList/AllChecklist';
import MyAssignedTickets from './pages/HelpDeskPages/MyAssignedTickets/MyAssignedTickets';
import ConcurrenceList from './pages/ConcurrenceList/ConcurrenceList';
import ApprovalList from './pages/ApprovalList/ApprovalList';
import DocumentDepartment from './pages/DocumentDepartment/DocumentDepartment';
import CreatedDepartment from './pages/CreateDepartment/CreatedDepartment';
import CreateOffice from './pages/Createoffice/CreateOffice';
import DocumentOffice from './pages/DocumentOffice/DocumentOffice';
import Menus from './pages/Menus/Menus';
import AddMenu from './pages/AddMenu/AddMenu';
import Register2FAUserList from './pages/Register2FAUserList/Register2FAUserList';
import RegisterNewUser from './pages/Register2FAUserList/RegisterNewUser';
import OldIRS from './pages/OldIRS/OldIRS';
import Permissions from './pages/Permissions/PermissionMenu';
import PermissionList from './pages/Permissions/CasaPermissions/PermissionList';
import CasaDocumentType from './pages/CASA/CasaDocument/CasaDocumentType';
import Roles from './pages/Permissions/Roles/Roles';
import Mircoservice from './pages/Permissions/Microservice/Mircroservice';
import UserRole from './pages/Permissions/UserRole/UserRole';
import Activate2FA from './pages/Activate2FA/Activate2FA';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import PageNotFound from './pages/404Page/404Page';
import HelpDeskDashboardTwo from './pages/HelpDeskDashboard/HelpDeskDashboardTwo';
import KYC from './pages/KYC/KYC';
import AllAccounts from './pages/KYC/AllAccounts/AllAccounts';
import AccountPreview from './pages/KYC/AllAccounts/AccountPreview';
import SupervisorList from './pages/KYC/SupervisorList/SupervisorList';
import DataOfficerList from './pages/KYC/DataOfficerList/DataOfficerList';
import EditKyc from './pages/KYC/EditKyc';
import RequestHistory from './pages/KYC/RquestHistory/RequestHistory';
import PersonalKYC from './pages/PersonalKYC/PersonalKYC';
import RequesterList from './pages/PersonalKYC/RequesterList';
import ViewKYC from './pages/PersonalKYC/ViewKYC';
import PersonalSupervisor from './pages/PersonalKYC/PersonalSupervisor';
import PersonalBranchList from './pages/PersonalKYC/PersonalBranchList';
import MyRequestHistory from './pages/PersonalKYC/MyRequestHistory';
import EditPersonalKyc from './pages/PersonalKYC/EditPersonalKyc';
import ComponentToPrint from './components/QueuePreview/ComponentToDownload';
import Test from './components/QueuePreview/Test'
import Example from './components/QueuePreview/Example';
import MyDocumentViewFormat from './pages/MyDocumentAppplications/MyDocumentViewFormat';


function App() {

  return (  
    <div className="App">
      <Routes>
        <Route path='/' element={<Login />}/>
        <Route path='/register' element={<Register />}/>
        <Route exact path='/forgot-password' element={<ForgetPassword />}/>
        <Route exact path='/activate-2fa' element={<Activate2FA />}/>
        <Route exact path='/reset-password' element={<ResetPassword /> } />
        <Route exact path='/change-password' element={ <ProtectedRoute Component = {ChangePassword}/>}/>
        <Route exact path='/dashboard' element={<ProtectedRoute Component={Dashboard} />}/>
        <Route exact path='/menus' element={<ProtectedRoute Component={Menus} />}/>
        <Route exact path='/menus/create' element={<ProtectedRoute Component={AddMenu} />}/>
        <Route exact path='/user-profile' element={<ProtectedRoute Component={Profile} />}/>
        <Route exact path='/old-irs' element={<ProtectedRoute Component={OldIRS} />}/>
        <Route exact path='/permissions' element={<ProtectedRoute Component={Permissions} />}/>
        <Route exact path='/permissions/all-users' element={<ProtectedRoute Component={PermissionList} />}/>
        <Route exact path='/primera-academy' element={<ProtectedRoute Component={PrimeraAcademy} />}/>
        <Route exact path='/document' element={<ProtectedRoute Component={DocumentComponent} />}/>
        <Route exact path='/worktool' element={<ProtectedRoute Component={WorkTool} />}/>
        <Route exact path='/staff' element={<ProtectedRoute Component={Staff} />}/>
        <Route exact path='/complaince' element={<ProtectedRoute Component={Compliance}/>}/>
        <Route exact path='/id-card' element={<ProtectedRoute Component={IDCARD}/>}/>
        <Route exact path='/appraisal' element={<ProtectedRoute Component={Appraisal}/>}/>
        <Route exact path='/tokenize-card' element={<ProtectedRoute Component={TokenizeCard}/>}/>
        <Route exact path='/leave' element={<ProtectedRoute Component={Leave}/>}/>
        <Route exact path='/leave/approve' element={<ProtectedRoute Component={SupervisorLeave}/>}/>
        <Route exact path='/boardroom-booking' element={<ProtectedRoute Component={BoardroomBorading}/>}/>
        <Route exact path='/suggestion-portal' element={<ProtectedRoute Component={SuggestionPortal}/>}/>
        <Route exact path='/asset-disposal' element={<ProtectedRoute Component={AssetDeposit}/>}/>
        <Route exact path='/prime-serve/dashboard' element={<ProtectedRoute Component={HelpDeskDashboard}/>}/>
        <Route exact path='/prime-serves/dashboard' element={<ProtectedRoute Component={HelpDeskDashboardTwo}/>}/>
        <Route exact path='/interview-assessment-form' element={<ProtectedRoute Component={Interview}/>}/>
        <Route exact path='/staff/member' element={<ProtectedRoute Component={StaffListPage}/>}/>
        <Route exact path='/courses/handbook' element={<ProtectedRoute Component={StaffHandBook}/>}/>
        <Route exact path='/course/quartly-training' element={<ProtectedRoute Component={QuartlyTraining}/>}/>
        <Route exact path='/apply-for-leave' element={<ProtectedRoute Component={CreateLeave}/>}/>
        <Route exact path='/helpdesk/ticket/new' element={<ProtectedRoute Component={CreateTicket}/>}/>
        <Route exact path='/helpdesk/ticket/all' element={<ProtectedRoute Component={TicketList}/>}/>
        <Route exact path='/helpdesk/ticket/history' element={<ProtectedRoute Component={PendingTickets}/>}/>
        <Route exact path='/profile/edit-profile-picture' element={<ProtectedRoute Component={EditProfileImage}/>}/>
        <Route exact path='/edit-profile' element={<ProtectedRoute Component={EditProflie}/>}/>
        <Route exact path='/koins/customer/preview' element={<ProtectedRoute Component={KoinsPreview}/>}/>
        <Route exact path='/document-approval' element={<ProtectedRoute Component={Document}/>}/>
        <Route exact path='/create-document-name' element={<ProtectedRoute Component={CreateDocName}/>}/>
        <Route exact path='/edit-document-name' element={<ProtectedRoute Component={EditDocName}/>}/>
        <Route exact path='/signature/add' element={<ProtectedRoute Component={AddSignature}/>}/>
        <Route exact path='/applications' element={<ProtectedRoute Component={AllDocumentApplication}/>}/>
        <Route exact path='/casa' element={<ProtectedRoute Component={Casa}/>}/>
        <Route exact path='/customer/create' element={<ProtectedRoute Component={AddCustomer}/>}/>
        <Route exact path='/staff/edit' element={<ProtectedRoute Component={UpdateStaff}/>}/>
        <Route exact path='/staff/new' element={<ProtectedRoute Component={AddStaff}/>}/>
        <Route exact path='/document/create' element={<ProtectedRoute Component={CreateCasaDoc}/>}/>
        <Route exact path='/staff/all' element={<ProtectedRoute Component={CasaStaffList}/>}/>
        <Route exact path='/customer/all' element={<ProtectedRoute Component={CasaCustomerList}/>}/>
        <Route exact path='/customer/edit' element={<ProtectedRoute Component={EditCustomer}/>}/>
        <Route exact path='/customer/preview' element={<ProtectedRoute Component={CasaCustomerPreview}/>}/>
        <Route exact path='/customer/accounts' element={<ProtectedRoute Component={CasaAccounts}/>}/>
        <Route exact path='/exemption-list' element={<ProtectedRoute Component={ExemptionList}/>}/>
        <Route exact path='/exemption/preview' element={<ProtectedRoute Component={ExemptionPreview}/>}/>
        <Route exact path='/document/document-types' element={<ProtectedRoute Component={CasaDocumentType}/>}/>
        <Route exact path='/document/customer-document' element={<ProtectedRoute Component={CasaAllDocumentList}/>}/>
        <Route exact path='/document/pending-document' element={<ProtectedRoute Component={PendingApproval}/>}/>
        <Route exact path='/document/approval-deferral' element={<ProtectedRoute Component={ApprovalDeferral}/>}/>
        <Route exact path='/document/mypending-approval' element={<ProtectedRoute Component={MyPendingApprovals}/>}/>
        <Route exact path='/products' element={<ProtectedRoute Component={Products}/>}/>
        <Route exact path='/my-applications' element={<ProtectedRoute Component={MyDocumentApplications}/>}/>
        <Route exact path='/preview' element={<ProtectedRoute Component={MyDocumentPreview}/>}/>
        <Route exact path='/preview/document-format' element={<ProtectedRoute Component={MyDocumentViewFormat}/>}/>
        <Route exact path='/document/concurrence-list' element={<ProtectedRoute Component={ConcurrenceList}/>}/>
        <Route exact path='/document/approval-list' element={<ProtectedRoute Component={ApprovalList}/>}/>
        <Route exact path='/document/department' element={<ProtectedRoute Component={DocumentDepartment}/>}/>
        <Route exact path='/document/new-department' element={<ProtectedRoute Component={CreatedDepartment}/>}/>
        <Route exact path='/document/new-office' element={<ProtectedRoute Component={CreateOffice}/>}/>
        <Route exact path='/document/office' element={<ProtectedRoute Component={DocumentOffice}/>}/>
        <Route exact path='/casa/transactions' element={<ProtectedRoute Component={CasaTransactions}/>}/>
        <Route exact path='/casa/withdrawal' element={<ProtectedRoute Component={CasaWithdrawal}/>}/>
        <Route exact path='/casa/deposit' element={<ProtectedRoute Component={CasaDeposit}/>}/>
        <Route exact path='/casa/transfer' element={<ProtectedRoute Component={CasaTransfer}/>}/>
        <Route exact path='/helpdesk/ticket/preview' element={<ProtectedRoute Component={HelpDeskTicketPreview}/>}/>
        <Route exact path='/helpdesk/create-department' element={<ProtectedRoute Component={HelpdeskCreateDepartment}/>}/>
        <Route exact path='/helpdesk/department' element={<ProtectedRoute Component={Alldepartments}/>}/>
        <Route exact path='/helpdesk/sub-unit' element={<ProtectedRoute Component={AllUnit}/>}/>
        <Route exact path='/helpdesk/create-subunit' element={<ProtectedRoute Component={CreateUnit}/>}/>
        <Route exact path='/helpdesk/create-category' element={<ProtectedRoute Component={CreateCategory}/>}/>
        <Route exact path='/helpdesk/category' element={<ProtectedRoute Component={AllCategories}/>}/>
        <Route exact path='/helpdesk/checklist' element={<ProtectedRoute Component={AllChecklist}/>}/>
        <Route exact path='/helpdesk/create-checklist' element={<ProtectedRoute Component={CreateCheckList}/>}/>
        <Route exact path='/helpdesk/ticket-assigned-to-me' element={<ProtectedRoute Component={MyAssignedTickets}/>}/>
        <Route exact path='/2fa-userlist' element={<ProtectedRoute Component={Register2FAUserList}/>}/>
        <Route exact path='/register-2fa-user' element={<ProtectedRoute Component={RegisterNewUser}/>}/>
        <Route exact path='/permission/roles' element={<ProtectedRoute Component={Roles}/>}/>
        <Route exact path='/permission/microservices' element={<ProtectedRoute Component={Mircoservice}/>}/>
        <Route exact path='/permission/user-role' element={<ProtectedRoute Component={UserRole}/>}/>
        <Route path='/kyc/home' element={<ProtectedRoute Component={KYC}/>}/>
        <Route exact path='/kyc/accounts' element={<ProtectedRoute Component={AllAccounts}/>}/>
        <Route exact path='/kyc/accounts/:id' element={<ProtectedRoute Component={AccountPreview}/>}/>
        <Route exact path='/kyc/accounts/:id/:user_id' element={<ProtectedRoute Component={AccountPreview}/>}/>
        <Route exact path='/kyc/accounts/:id/:user_id/:supervisor' element={<ProtectedRoute Component={AccountPreview}/>}/>
        <Route exact path='/kyc/edit/:slug' element={<ProtectedRoute Component={EditKyc}/>}/>
        <Route exact path='/kyc/supervisor' element={<ProtectedRoute Component={SupervisorList}/>}/>
        <Route exact path='/kyc/data-entry-request' element={<ProtectedRoute Component={DataOfficerList}/>}/>
        <Route exact path='/kyc/request-history' element={<ProtectedRoute Component={RequestHistory}/>}/>
        <Route exact path='/kyc-individual' element={<ProtectedRoute Component={PersonalKYC}/>}/>
        <Route exact path='/kyc/requester' element={<ProtectedRoute Component={RequesterList}/>}/>
        <Route exact path='/kyc-individual/edit/:slug' element={<ProtectedRoute Component={EditPersonalKyc}/>}/>
        <Route exact path='/kyc-individual/accounts/:id' element={<ProtectedRoute Component={ViewKYC}/>}/>
        <Route exact path='/kyc-individual/accounts/:id/:user_id' element={<ProtectedRoute Component={ViewKYC}/>}/>
        <Route exact path='/kyc-individual/accounts/:id/:user_id/:supervisor' element={<ProtectedRoute Component={ViewKYC}/>}/>
        <Route exact path='/individual/supervisorlist' element={<ProtectedRoute Component={PersonalSupervisor}/>}/>
        <Route exact path='/individual/branchlist' element={<ProtectedRoute Component={PersonalBranchList}/>}/>
        <Route exact path='/individual/my-history' element={<ProtectedRoute Component={MyRequestHistory}/>}/>
        <Route exact path='*' element={<PageNotFound/>}/>
      </Routes>
    </div>
  )
}

export default App;
