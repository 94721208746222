import React, { useState, useEffect} from 'react';
import { Pagination } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../../TicketList/TicketList.css';
import '../CasaStaffList/casaStafflist.css';
import next from '../../../assets/next.png';
import { casaBaseurl } from '../../../common/apis/userApi';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import search from '../../../assets/search.svg';

const ExemptionList = () => {

    const { user } = useSelector(userSelector)

    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [exemption, setExemption] = useState([]);
    const [pageCount, setpageCount] = useState(1);

    useEffect(() => {
        fetchingData(1);
    },[])

    const fetchingData = async (page) => {
        const query = await axios.get(`${casaBaseurl}allcustomer/${user.id}?page=${page}`);
        const result = query.data;
        setExemption(result);
        setpageCount(result.count)
    }

    const exemptionData = exemption.results;

    const previewHandle = (e) => {
        const { id } = e.currentTarget;
        const targetData = exemptionData.filter(item => item.id === id);
        sessionStorage.setItem("targetData", JSON.stringify(targetData));
    }

    const authAxios = axios.create({
        baseURL : casaBaseurl,
    }) 

    const handleChange = (e) => {
        if(e.target.value !== ""){
            setState({...state, search : e.target.value})
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        const { search } = state;

        const query = await authAxios.get(`/filter/${search}`);
        const result = query.data;
        setExemption(result);
    }

    
  return (
    <div className='exemption-list casa-customerlist casa-stafflist'>
        <Header />
        <div className="content d-flex">
            <CasaTags />
            <div className="casa-customer-wrapper casa-staff-wrapper">
            <div className="tickets-table p-4">
                <h3 className='mb-3'>My Approval list</h3>
                <div className="search-staff-box">
                    <form>
                        <input type="search" placeholder='search by name, account no or customer no' onChange={handleChange}/>
                        <img src={search} alt="search" onClick={handleSearchSubmit}/>
                    </form>
                    
                </div>
                <table className=''>
                    <thead>
                        <tr>
                            <td>Customer No</td>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Mobile No</td>
                            <td>State</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            exemptionData && exemptionData.map(({ id, custno, firstname, lastname, email_address, mobileno, state}) => {

                                return <tr key={id}>
                                        <td>{custno}</td>
                                        <td>
                                            <div className="d-flex">
                                                <span>{`${firstname} ${lastname}`}</span>
                                            </div>
                                        </td>
                                        <td>{email_address}</td>
                                        <td>{mobileno}</td>
                                        <td>
                                            <p className='mb-0'>{state}</p>
                                        </td>
                                        <td className='next text-center'>
                                            <Link to='/customer/preview' id={id} onClick={previewHandle} className="text-center">
                                                <img src={next} alt=""/>
                                            </Link>
                                        </td>
                                    </tr>
                            })
                        }
                    </tbody>
                </table>
                <Pagination 
                    pageSize={10}
                    total={pageCount}
                    onChange={(page) => fetchingData(page)}
                />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExemptionList