import React from 'react'
import TextInput from '../../common/TextInput/TextInput';
import '../KYC/Newkyc/personal-info.css';

const PersonalInformationData = ({formik, setValid}) => {

    if(formik.values.title !== "" && formik.values.gender !== "" && formik.values.account_name !== "" && formik.values.business_profession !== "" && formik.values.id_type !== "" && formik.values.id_number !== "" && formik.values.laundering_risk !== "" && formik.values.estimated_annual_salary !== ""){
        setValid(true)
    }

  return (
    <div className='personal-info'>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Title</label>
                    <select 
                    name='title' 
                    value={formik.values.title} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.title && formik.errors.title) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    >
                        <option value="" disabled selected>Select Title</option>
                        <option value="Mr" >Mr</option>
                        <option value="Mrs" >Mrs</option>
                        <option value="Miss" >Miss</option>
                        <option value="Dr">Dr</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Gender</label>
                    <select 
                    name='gender' 
                    value={formik.values.gender} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.gender && formik.errors.gender) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    >
                        <option value="" disabled selected>Select Gender</option>
                        <option value="Male" >Male</option>
                        <option value="Female" >Female</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="w-100 mb-3">
            <TextInput 
                label='Account Name'
                type="text" 
                placeholder='Enter account name' 
                name='account_name' 
                value={formik.values.account_name} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.account_name && formik.errors.account_name) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
            />
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <TextInput 
                    label='PROFESSION/LINE OF BUSINESS'
                    type="text" 
                    placeholder='Enter line of business/profession' 
                    name='business_profession' 
                    value={formik.values.business_profession} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.business_profession && formik.errors.business_profession) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">ID Type</label>
                    <select 
                    name='id_type' 
                    value={formik.values.id_type} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.id_type && formik.errors.id_type) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    >
                        <option value="" disabled selected>Select ID card</option>
                        <option value="Driver's License" >Driver's License</option>
                        <option value="NIN(National Identity Number)">NIN(National Identity Number)</option>
                        <option value="Permanent Voters card">Permanent Voters card</option>
                        <option value="International passport">International passport</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <TextInput 
                    label='ID Number'
                    type="text" 
                    placeholder='' 
                    name='id_number' 
                    value={formik.values.id_number} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.id_number && formik.errors.id_number) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex my-4">
            <div className="w-100">
            
                <div className="form-group">
                    <label htmlFor="">ANTI MONEY LAUNDERING RISK RATING</label>
                    <select 
                    name='laundering_risk' 
                    value={formik.values.laundering_risk} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    style={{border : (formik.touched.laundering_risk && formik.errors.laundering_risk) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    >
                        <option value="" disabled selected>Select Type</option>
                        <option value="Low">Low</option>
                        <option value="Meduim">Medium</option>
                        <option value="High">High</option>
                    </select>
                </div>
            </div>
            <div className="w-100">
                <TextInput 
                    label='Estimated Yearly salary'
                    type="text" 
                    placeholder='' 
                    name='estimated_annual_salary' 
                    value={formik.values.estimated_annual_salary} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.estimated_annual_salary && formik.errors.estimated_annual_salary) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="w-100 mb-4" style={{display : formik.values.company_type === "others" ? "block" : "none"}}>
            <TextInput 
                label='Specify Others'
                type="text" 
                placeholder='' 
                name='company_others' 
                value={formik.values.company_others} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.company_others && formik.errors.company_others) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
            />
        </div>
    </div>
  )
}

export default PersonalInformationData