import React from 'react'
import TextInput from '../../common/TextInput/TextInput';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../KYC/Newkyc/personal-info.css';

const BusinessInformationData = ({formik, setValid, setBillUrl}) => {

    if(formik.values.customer_type !== "" && formik.values.kyc_level !== "" && formik.values.type_of_acct !== "" && formik.values.documentation_checked !== "" && formik.values.utility_bill !== "" && formik.values.pep !== "" && formik.values.estimated_monthly_salary && formik.values.customer_email && formik.values.employment_status ){
        setValid(true)
    }

    const dummyRequest = async ({ file, onSuccess }) => {    
        setTimeout(() => {
           onSuccess("ok");
        }, 0);
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: dummyRequest,
        beforeUpload : (info) => {
            setBillUrl(info)
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file selected`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    
    const uploadButton = (
        <div style={{
            border : "1px solid #cccccc", 
            borderRadius : "4px", 
            display : 'flex', 
            alignItems : "center",
            padding : ".4em 3em"
            }}>
            <Button icon={<UploadOutlined />}></Button>
            <div style={{background : "none"}}>
                Upload
            </div>
      </div>
    );

  return (
    <div className='personal-info'>
        <div className="">
            <div className="d-flex mb-3">
                <div className="w-100">
                    <div className="form-group">
                        <TextInput 
                            label='Customer Email'
                            type="text" 
                            placeholder='' 
                            name='customer_email' 
                            value={formik.values.customer_email} 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            formik={formik}
                            style={{border : (formik.touched.customer_email && formik.errors.customer_email) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                        />
                    </div>
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <TextInput 
                            label='Employement status'
                            type="text" 
                            placeholder='' 
                            name='employment_status' 
                            value={formik.values.employment_status} 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            formik={formik}
                            style={{border : (formik.touched.employment_status && formik.errors.employment_status) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex mb-3">
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Customer Type</label>
                        <select name="customer_type" value={formik.values.customer_type} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.customer_type && formik.errors.customer_type) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}}>
                            <option value="" disabled selected></option>
                            <option value="walk-in">Walk-in</option>
                            <option value="marketed">Marketed</option>
                        </select>
                    </div>
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">KYC Level</label>
                        <select name="kyc_level" value={formik.values.kyc_level} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.kyc_level && formik.errors.kyc_level) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                            <option value="" disabled selected></option>
                            <option value="Level 1">Level 1</option>
                            <option value="Level 2">Level 2</option>
                            <option value="Level 3">Level 3</option>
                        </select>
                    </div>
                </div>   
            </div>
            <div className="d-flex mb-3">
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Type of Account</label>
                        <select name="type_of_acct" value={formik.values.type_of_acct} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.type_of_acct && formik.errors.type_of_acct) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                            <option value="" disabled selected></option>
                            <option value="Corporate">Corporate</option>
                            <option value="Current Individual">Current Individual</option>
                            <option value="Savings">Savings</option>
                            <option value="Fixed Deposit">Fixed Deposit</option>
                            <option value="Settlement account">Settlement account</option>
                        </select>
                    </div>
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Documentation Checked</label>
                        <select name="documentation_checked" value={formik.values.documentation_checked} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.documentation_checked && formik.errors.documentation_checked) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                            <option value="" disabled selected></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>    
            </div>
            <div className="d-flex my-4">
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Politically Exposed person</label>
                        <select name="pep" value={formik.values.pep} onChange={formik.handleChange} onBlur={formik.handleBlur}
                        style={{border : (formik.touched.pep && formik.errors.pep) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                        >
                            <option value="" disabled selected></option>
                            <option value="Yes" >Yes</option>
                            <option value="No" >No</option>
                        </select>
                    </div>
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <TextInput 
                            label='Estimated Monthly Salary'
                            type="text" 
                            placeholder='' 
                            name='estimated_monthly_salary' 
                            value={formik.values.estimated_monthly_salary} 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            formik={formik}
                            style={{border : (formik.touched.estimated_monthly_salary && formik.errors.estimated_monthly_salary) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex mb-3">
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Utility Bill Selected</label>
                        <select name="utility_bill" value={formik.values.utility_bill} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.utility_bill && formik.errors.utility_bill) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                            <option value="" disabled selected></option>
                            <option value="PHCN">PHCN</option>
                            {/* <option value="No">No</option> */}
                        </select>
                    </div>
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Utility File</label>
                        <Upload
                            {...props}
                            name="avatar"
                            >
                            {
                                uploadButton
                            }
                        </Upload>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BusinessInformationData