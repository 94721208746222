import React from 'react';
import '../CreateDocName/createdocName.css';
import CreateDocNameForm from '../../components/CreateDocNameForm/CreateDocNameForm';
import Header from '../../components/header/header';
import Tags from '../../components/Tags/Tags';

const CreateDocName = () => {
  return (
    <div className='createdoc-name'>
        <Header />
        <div className="container_">
            <Tags />
            <CreateDocNameForm />
        </div>
    </div>
  )
}

export default CreateDocName