import  { gql } from '@apollo/client';

export const VERIFY_USER = gql`
    mutation verifyUser(
        $code : String!, 
        $email : String!, 
        $type : String!){
        verifyUser(
            code : $code, 
            email : $email, 
            type : $type){
            status
            statusCode
            message  
        }
    }
`