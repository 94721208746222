import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags';
import '../CasaStaffList/casaStafflist.css';
import '../CasaCustomerList/casacustomerpreview.css';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import {  userSelector } from '../../../feature/Login/loginSlice';
import { useNavigate, Link } from 'react-router-dom';
import userimg from '../../../assets/Vector.svg';
import arrowRight from '../../../assets/navigation (1).png';
import email from '../../../assets/email (1).png';
import image from '../../../assets/user (4).png';
import edit from '../../../assets/edit.png';
import BranchRejectModal from '../BranchRejectModal/BranchRejectModal';
import AuthorizerRejectModal from '../AuthorizerRejectModal/AuthorizerRejectModal';
import SelectAccountType from '../SelectAccountType/SelectAccountType';
import AccountRender from '../AccountRender/AccountRender';
import CredentialRender from '../CredentialRender/CredentialRender';
import { PersonalInfoRender } from '../PersonalInfoRender/PersonalInfoRender';
import IdentityRender from '../IdentityRender/IdentityRender';


const CasaCustomerPreview = () => {

    const history = useNavigate();
    const [pageRender, setPageRender] = useState({
        personal_info : true,
        other : false,
        account : false,
        credential : false
    })
    const [showModal, setShowModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [users, setUsers] = useState([]);
    const data = JSON.parse(sessionStorage.getItem("targetData"));
    const newData = data[0];
    const [accountEntries, setAccountEntries] = useState({
        accountType : "",
        accountOfficer : ""
    })
  
    const [rejection, setRejection] = useState('');

    const { user, token } = useSelector(userSelector);

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const query = await axios.get(`${casaBaseurl}addstaff`);
                const response = query.data.body;
                setUsers(response.data)

            } catch (error) {
                console.log(error)
            }
        }

        fetchingData();
    }, [token])

    const handleAccountType = (e) => {
        setAccountEntries({...accountEntries, [e.target.name] : e.target.value});
    }
    
    const handlePop = (e) => {
        setShowModal(true)
    }

    const handleClose = (e) => {
        setShowModal(false)
    }
    const helperFunction = (error) => {
        const T24error = error?.message?.info?.error;
        if(T24error.type === "BUSINESS"){
            toast.error(T24error.errorDetails[0].message);
            console.log(T24error.errorDetails[0].message)
            return T24error.errorDetails[0].message;
        }
        // else if(error == T24error){
        //     toast.error(T24error[0].message);
        //     return T24error.message;
        // }else if(error){
        //     toast.error(error)
        //     return error;
        // }else{
        //     toast.error('Process failed')
        // }
    }

    const roApprove = async () => {
        try {
            const approving = await axios.get(`${casaBaseurl}roapproval/${user.id}/${newData?.id}`);
            const result = approving.data
            if(result.code === 200){
                toast.success(result.body.data, {
                    toastId : "accountsuccess1"
                })
                setTimeout(() => {
                    history('/customer/all')
                }, 2000)
            }
        } catch (err) {
            const errorMessage = err.response.data;
            if(errorMessage.code == 400){
                toast.error(errorMessage.body.data, {
                    toastId : 'accounterror1'
                });   
            }else if(errorMessage.code === "ERR_BAD_REQUEST"){
                toast.error(errorMessage); 
            }else{
                console.log(errorMessage);
            }
        }   
    }

    const branchApprove = async (e) => {
        e.preventDefault()
        try {
            const approving = await axios.get(`${casaBaseurl}branchapproval/${user.id}/${newData?.id}`);
            const result = approving.data
            if(result.code === 200){
                toast.success(result.body.data)
                setTimeout(() => {
                    history('/customer/all')
                }, 2000)
            }
        } catch (err) {
            const errorMessage = err.response.data;
            console.log(errorMessage)
            if(errorMessage.code == 400){
                toast.error(errorMessage.body.data);   
            }else if(errorMessage.code === "ERR_BAD_REQUEST"){
                toast.error(errorMessage); 
            }else{
                console.log(errorMessage);
            }
        }   
    }

    const submitAccountType = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const jsonData = {
            "acctType": accountEntries.accountType,
            "accountOfficer" : accountEntries.accountOfficer
        }
        try {
            const approvingAccount = await axios.post(`${casaBaseurl}approve_customer/${newData.custno}/${user.id}`, jsonData);
            const response = approvingAccount.data;
            setShowModal(false);
            if(response.code === 200){
                toast.success("account created successfully");
                setInterval(() => {
                    history('/customer/all')
                    window.location.reload();
                }, 2000);
            }
            console.log(response)
        } catch (error) {
            setDisabled(false);
            console.log(error.response.data)
            if(error){
                const errorResponse =  error.response.data.body.data;
                helperFunction(errorResponse);
            }else{
                toast.error("Process failed");
            }
        }
    }

    const handleReject = (e) => {
        setRejection(e.target.value)
    }

    const permitted = users?.find(item => item.irs_id === user.id)
    const roPermission = permitted?.permission?.find(item => item.includes("ro"))
    const branchPermission = permitted?.permission?.find(item => item.includes("branch"))
    const authorisePermission = permitted?.permission?.find(item => item.includes("authorizer"))

  return (
    <div className='casa-customer-info casa-stafflist'>
        <Header />
        <div className="content d-flex w-100">
            <CasaTags />
            <div className="preview-wrapper casa-staff-wrapper">
                <ToastContainer closeButton={true} position="top-right" autoClose={1000} />
                <div className="inner-content-wrapper">
                    <div className="d-flex bg-white">
                        <div className="left-tabs">
                            <div className="heading d-flex">
                                <img src={userimg} alt="" />
                                <div className="heading-name">
                                    <h5>{`${newData?.firstname} ${newData?.lastname}`}</h5>
                                    <p>{newData?.mobileno}</p>
                                </div>
                            </div>
                            <div className="user_sidebar">
                                <div className="sidebar_item" 
                                onClick={() => setPageRender({
                                    personal_info : true, 
                                    other : false, 
                                    account : false,
                                    credential : false
                                    })}>
                                    <div className="sidebar_text d-flex">
                                        <img src={image} alt="" />
                                        <p style={{color : pageRender.personal_info ? "blue" : "#858B96"}}>Personal Information</p>
                                    </div>
                                    <img src={arrowRight} alt="" />
                                </div>
                                <div className="sidebar_item" onClick={() => setPageRender({
                                    personal_info : false, 
                                    other : true,
                                    account : false,
                                    credential : false
                                    })}>
                                    <div className="sidebar_text d-flex">
                                        <img src={email} alt="" />
                                        <p style={{color : pageRender.other ? "blue" : "#858B96"}}>Identification & Employer</p>
                                    </div>
                                    <img src={arrowRight} alt="" />
                                </div>
                                <div className="sidebar_item" onClick={() => setPageRender({
                                    personal_info : false, 
                                    other : false, 
                                    account : true,
                                    credential : false
                                    })}>
                                    <div className="sidebar_text d-flex">
                                        <img src={email} alt="" />
                                        <p style={{color : pageRender.account ? "blue" : "#858B96"}}>Account</p>
                                    </div>
                                    <img src={arrowRight} alt="" />
                                </div>
                                <div className="sidebar_item" onClick={() => setPageRender({
                                    personal_info : false, 
                                    other : false, 
                                    account : false,
                                    credential : true
                                    })}>
                                    <div className="sidebar_text d-flex">
                                        <img src={email} alt="" />
                                        <p style={{color : pageRender.credential ? "blue" : "#858B96"}}>Credentials</p>
                                    </div>
                                    <img src={arrowRight} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="right_item">
                            <div className="d-flex justify-content-between">
                                <div className="top-title">
                                    <h3>Personal Information</h3>
                                    <p>View the personal information of this customer</p>
                                </div>
                                <div className="edit-btn">
                                    <Link to='/customer/edit' className='d-flex'>
                                        <img src={edit} alt="" />
                                    </Link>
                                </div>
                            </div>
                            {
                                pageRender.personal_info && <PersonalInfoRender newData={newData}/>
                            }
                            {
                                pageRender.other && <IdentityRender newData={newData}/>
                            }
                            {
                                pageRender.account && <AccountRender newData={newData}/>
                            }
                            {
                                pageRender.credential && newData?.credentials.map(({id, mode_of_id, is_approved, is_rejected, identityimage, idno, passport, profile}) => {
                                    return(
                                        <CredentialRender 
                                        id={id}
                                        mode_of_id={mode_of_id}
                                        is_approved={is_approved}
                                        is_rejected={is_rejected}
                                        identityimage={identityimage}
                                        idno={idno}
                                        passport={passport}
                                        profile={profile}
                                        />
                                    )
                                }) 
                            }
                            {
                                pageRender.credential && (newData.credentials.length < 1 ) && <p className='mt-5'>No credential uploaded</p>
                            }
                        </div>
                    </div>
                    {
                        permitted && <>
                                <div className="btn d-flex">
                                    {
                                        (newData?.ro_approval === false && roPermission) && <button onClick={roApprove}>Approve as RO</button>
                                    }
                                    
                                </div>
                                {
                                    branchPermission && <div className="btn d-flex">
                                        {
                                            (newData?.branch_approval === false && newData?.branch_reject === false) && <button onClick={branchApprove}>Approve as Branch</button>
                                        }
                                        {
                                            (newData?.branch_approval === false && newData?.branch_reject === false) && <button className='reject-btn'>
                                                <span className='text-center' 
                                                data-bs-toggle="modal" 
                                                data-bs-target='#branchrejectModal' 
                                                role="button">
                                                Reject as Branch
                                                </span>
                                            </button>
                                        }
                                    </div>
                                }
                                {
                                    (authorisePermission && newData?.branch_reject === false) && <div className="btn d-flex">
                                        <button className=''>
                                            <span className='text-center' 
                                            data-bs-toggle="modal" 
                                            data-bs-target='#exampleModal' 
                                            role="button" 
                                            onClick={handlePop}
                                            >
                                                Add Account
                                            </span>
                                        </button>
                                        <button 
                                        className='reject-btn' 
                                        style={{display : newData?.is_approved || newData?.is_rejected ? "none" : "block"}}
                                        >
                                            <span className='text-center' 
                                            data-bs-toggle="modal" 
                                            data-bs-target='#rejectModal' 
                                            role="button"
                                            >
                                                Reject Customer
                                            </span>
                                        </button>
                                        
                                    </div>   
                                }
                        </>
                    }
                </div>
                <SelectAccountType 
                disabled={disabled}
                showModal={showModal}
                handleClose={handleClose}
                handleAccountType={handleAccountType}
                submitAccountType={submitAccountType}
                accountEntries={accountEntries}
                />

                <BranchRejectModal 
                newData={newData}
                user={user}
                handleReject={handleReject} 
                rejection={rejection}
                disabled={disabled}
                />
                
                <AuthorizerRejectModal 
                newData={newData}
                user={user}
                handleReject={handleReject}
                rejection={rejection}
                disabled={disabled}
                />
            </div>
        </div>
    </div>
  )
}

export default CasaCustomerPreview;