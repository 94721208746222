import axios from 'axios';
import { irsBaseUrl } from './userApi';


export const getAxiosInstance = (token) => {

    return axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type" : "application/json",
        }
    }) 
}