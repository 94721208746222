import React from 'react';
import '../CreateTicket/createTicket.css';
import CreateTicketForm from '../../components/CreateTicketForm/CreateTicketForm'
import HelpDeskTags from '../../components/HelpDeskTags/HelpDeskTags';

const CreateTicket = () => {
  return (
    <div className='create-ticket'>
        <div className="content">
            <HelpDeskTags/>
            <CreateTicketForm />
        </div>
    </div>
  )
}

export default CreateTicket