import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import Header from "../../../components/header/header";
import CasaTags from "../CasaTags/CasaTags";
import "../CasaAccounts/casaAccounts.css";
import "../CasaStaffList/casaStafflist.css";
import "../CasaDocument/casaAllDocument.css";
import tick from "../../../assets/read (1).png";
import next from "../../../assets/down-arrow.png";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { casaBaseurl, deferralBaseurl } from "../../../common/apis/userApi";
import { useSelector } from "react-redux";
import { userSelector } from "../../../feature/Login/loginSlice";
import search from "../../../assets/search.svg";
import DocumentCollapse from "./DocumentCollapse";
import UploadDocumentModal from "./UploadDocumentModal";
import DefferalModal from "./DefferalModal";

const medium = {
  backgroundColor: "#FEF6EF",
  color: "rgb(168 130 77)",
  borderRadius: "20px",
  textAlign: "center",
};
const low = {
  backgroundColor: "#DEFCF8",
  color: "rgb(76 156 143)",
  borderRadius: "20px",
  textAlign: "center",
};
const high = {
  backgroundColor: "#FFEAF1",
  color: "#F094A9",
  borderRadius: "20px",
  textAlign: "center",
};
const CasaAllDocumentList = () => {
  const { token } = useSelector(userSelector);
  const [allDocs, setAllDocs] = useState([]);
  const [staff, setStaff] = useState([]);
  const [doctype, setDocType] = useState([]);
  const [state, setState] = useState({
    search: "",
    searchField: [],
  });
  const [pageCount, setpageCount] = useState(1);

  useEffect(() => {
    fetchingData(1);
  }, [token]);

  const fetchingData = async (page) => {
    const query = await axios.get(
      `${casaBaseurl}customerwithaccount?page=${page}`
    );
    const result = query.data;
    setAllDocs(result);
    setpageCount(result.count);

    const docs = await axios.get(`${deferralBaseurl}/alldoctype`);
    const docResult = docs.data.body;
    setDocType(docResult.data);

    const fetching = await axios({
      method: "GET",
      url: "https://irsapi.koinsbank.com/api/v3/staff/all",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const response = fetching.data;
    const allStaff = response.data;
    setStaff(allStaff);
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      setState({ ...state, search: e.target.value });
    } else if (e.target.value === "") {
      window.location.reload();
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const { search } = state;

    const query = await axios.get(`${casaBaseurl}filter/${search}`);
    const result = query.data;
    setAllDocs(result);
  };

  const newDataList = allDocs.results;

  return (
    <div className="casa-allDocument casa-account casa-stafflist">
      <Header />
      <div className="content w-100 d-flex">
        <CasaTags />
        <div className="all-document casa-account-wrapper casa-staff-wrapper">
          <div className="account-heading">
            <ToastContainer closeButton={false} position="top-right" />
            <h3 className="mb-3">All KYC Documents</h3>
            <div className="search-staff-box">
              <form>
                <input
                  type="search"
                  placeholder="search by customer name or customer number"
                  name="search"
                  onChange={handleSearch}
                />
                <img src={search} alt="" onClick={handleSearchSubmit} />
              </form>
            </div>
            <div className="list-wrapper bg-white">
              <div className="list-table">
                <div className="titles p-3">
                  <span>Document Name</span>
                  <span className="text-center">Document Status</span>
                  <span className="text-center">Document Upload</span>
                  <span className="text-center">Deferral</span>
                  <span className="text-center">Action</span>
                </div>
                <div className="account-list mb-4">
                  <div className="accounts">
                    {newDataList &&
                      newDataList.map(
                        ({ id, firstname, lastname, credentials, custno }) => {
                          let status = "No document Attached";

                          let uploadDoc = (
                            <span
                              className="text-center text-decoration-underline text-primary"
                              data-bs-toggle="modal"
                              data-bs-target={`#exampleModal${id}`}
                              role="button"
                            >
                              Upload Document
                            </span>
                          );

                          let deferInitate = (
                            <span
                              className="text-center text-decoration-underline text-primary"
                              data-bs-toggle="modal"
                              data-bs-target={`#exampleModal1${id}`}
                              role="button"
                            >
                              Initiate deferral
                            </span>
                          );

                          credentials.map(({ is_approve, is_reject }) => {
                            if (is_approve) {
                              status = (
                                <p className="p-2 text-center" style={low}>
                                  Document Approved
                                </p>
                              );
                              uploadDoc = (
                                <img
                                  src={tick}
                                  alt=""
                                  width="20px"
                                  className="text-center"
                                />
                              );
                              deferInitate = (
                                <img src={tick} alt="" width="20px" />
                              );
                            }
                            if (!is_approve) {
                              status = (
                                <p className="p-2 text-center" style={medium}>
                                  Pending approval
                                </p>
                              );
                              uploadDoc = (
                                <img src={tick} alt="" width="20px" />
                              );
                              deferInitate = (
                                <span
                                  className="text-center text-decoration-underline text-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#exampleModal1${id}`}
                                  role="button"
                                >
                                  Initiate deferral
                                </span>
                              );
                            }
                            if (is_reject) {
                              status = (
                                <p className="p-2 text-center" style={high}>
                                  Document Rejected
                                </p>
                              );
                              uploadDoc = (
                                <span
                                  className="text-center text-decoration-underline text-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#exampleModal${id}`}
                                  role="button"
                                >
                                  Upload Document
                                </span>
                              );

                              deferInitate = deferInitate = (
                                <span
                                  className="text-center text-decoration-underline text-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#exampleModal1${id}`}
                                  role="button"
                                >
                                  Initaite Deferral
                                </span>
                              );
                            }
                          });

                          return (
                            <div className="grid px-3" key={id}>
                              <div className="account-brief">
                                <div className="brief-details my-3">
                                  <h4 className="name">{`${firstname} ${lastname}`}</h4>
                                  <span className="status text-center">
                                    {status}
                                  </span>
                                  <span className="text-center">
                                    {uploadDoc}
                                  </span>
                                  <span className="text-center">
                                    {deferInitate}
                                  </span>
                                  <span
                                    className="edit text-center"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#edit-icon${id}`}
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    <img src={next} alt="" />
                                  </span>
                                </div>
                              </div>

                              <DocumentCollapse
                                id={id}
                                credentials={credentials}
                              />
                              <UploadDocumentModal id={id} custno={custno} />
                              <DefferalModal
                                id={id}
                                custno={custno}
                                staff={staff}
                                doctype={doctype}
                              />
                            </div>
                          );
                        }
                      )}
                    {newDataList?.length < 1 && (
                      <p className="mt-5 no-records"> No records found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              pageSize={10}
              total={pageCount}
              onChange={(page) => fetchingData(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasaAllDocumentList;
