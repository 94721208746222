import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import arrowbtn from '../../assets/arrow.svg';
import '../LeaveDashboard/leavedashboard.css';
import ProgressBar from '../ProgressBar/ProgressBar';
import group1 from '../../assets/Group 1.svg';
import group2 from '../../assets/Group 2.svg';
import group3 from '../../assets/Group 3.svg';

const SupervisorLeaveComponent = () => {

    const [data, setData] = useState({
        bgcolor: "#6a1b9a", 
        completed: 60
    })
    const [showOption, setShowOption] = useState(false);
    const toggleOptions = (e) => {
        e.preventDefault();
        setShowOption(!showOption)
    }
  return (
    <div className='leave-dashboard'>
        <div className="heading mt-3">
         <p>LEAVE</p>
        </div>
        <div className="grey-wrapper">
            <div className="top-tabs">
                <div className="leave-db">
                    <h5>2</h5>
                    <Link to='' style={{marginLeft : "10px"}}>
                        <img src={group1} alt="" />   
                        <p>PENDING LEAVE APPROVAL</p>
                    </Link>
                </div>
                <div className="apply-leave">
                    <h5>0</h5>
                    <Link to=''>
                        <img src={group2} alt="" />
                        <p>COMPLETED LEAVE</p>
                    </Link>
                </div>
                <div className="leave-record">
                    <h5>10</h5>
                    <Link to=''>
                        <img src={group3} alt="" />
                        <p>TOTAL LEAVE RECORD</p>
                    </Link>
                </div>
            </div>
            <div className="leave-application-count">
                <div className="list-heading">
                    <div className="list-title">
                        <p>Leave Approval/Rejection</p>
                    </div>
                    <div className="options">
                        <p>Print</p>
                        <p>Copy</p>
                        <p>PDF</p>
                        <p>CSV</p>
                        <p>Excel</p>
                        <p>Columns</p>
                    </div>
                    <div className="mobile-options" style={{display : showOption ? "flex" : "none"}}>
                        <p>Print</p>
                        <p>Copy</p>
                        <p>PDF</p>
                        <p>CSV</p>
                        <p>Excel</p>
                        <p>Columns</p>
                    </div>
                    <div className="three-dots" onClick={toggleOptions}>...</div>
                </div>
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th className='name'>Name</th>
                                <th>Year</th>
                                <th>Manager/Team Lead</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Jane</td>
                                <td>2021</td>
                                <td>Awele Akemu</td>
                                <td>20/01/2022</td>
                                <td>Pending</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupervisorLeaveComponent;