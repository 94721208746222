import React, { useRef } from 'react'
import Header from '../../components/header/header'
import Tags from '../../components/Tags/Tags'
import '../AllDocumentApplication/alldocumentapplication.css';
import '../MyDocumentAppplications/myapplicationpreview.css';
import '../../components/QueuePreview/queuepreview.css';
import '../../components/Preview/Preview.css';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from '../../components/QueuePreview/ComponentToDownload';

const MyDocumentViewFormat = () => {

    const componentRef = useRef();
    const data = JSON.parse(sessionStorage.getItem("my-targetdoc"));

    const { applicationId, office_name, dept_name, requester, subject, dateCreated, background, justification, memo_request, reviewerBy, staffConcur, approvingStaff, watermark} = data[0];

  return (
    <div className='all-document'>
        <Header />
        <div className="container_">
            <Tags />
            <div className="application-wrapper p-5">
                <div>
                    <ComponentToPrint 
                    ref={componentRef}
                    applicationId={applicationId} 
                    office_name={office_name} 
                    requester={requester} 
                    dept_name={dept_name} 
                    subject={subject} 
                    dateCreated={dateCreated}
                    background={background} 
                    justification={justification} 
                    memo_request={memo_request} 
                    reviewerBy={reviewerBy} 
                    staffConcur={staffConcur} 
                    approvingStaff={approvingStaff}
                    watermark={watermark}
                    />
                    <ReactToPrint
                        trigger={() => <button>Print this out!</button>}
                        content={() => componentRef.current}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyDocumentViewFormat