import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../CASA/casa.css';
import Header from '../../components/header/header'
import CasaDashboard from './CasaDashboard/CasaDashboard'
import CasaTags from './CasaTags/CasaTags';
import { casaBaseurl } from '../../common/apis/userApi';
import casalogo from '../../assets/casa.png';

const Casa = () => {

  const [customers, setCustomers] = useState([]);
  const [exemptionCount, setExemptionCount] = useState([]);
  const [transactions, setTransaction] = useState([]);
  const [accountCount, setAccountcount] = useState([]);

  const authAxios = axios.create({
    baseURL : casaBaseurl
  })  
  
  useEffect(()=> {

    const fetchingData = async () => {
      const fetchingAllCustomer = await authAxios.get('/customer');
      const result = fetchingAllCustomer.data;
      setCustomers(result);

      const transactions = await authAxios.get('/transactions');
      const tnxData = transactions.data;
      setTransaction(tnxData);

      const accounts = await authAxios.get('/customerwithaccount');
      const accountsData = accounts.data;
      setAccountcount(accountsData);

      const allexemptedCustomers = await authAxios.get('/exemptedcustomer');
      const exemptionData = allexemptedCustomers.data;
      const count = exemptionData.count;
      setExemptionCount(count);
    }
    fetchingData();

  }, [])

  const transactionsCount = transactions.count;

  return (
    <div className="">
      {
        (transactions.results !== undefined) ?   <div className='casa'>
            <Header />
            <div className="content d-flex w-100">
                <CasaTags />
                <CasaDashboard 
                customers={customers} 
                transactionsCount={transactionsCount}
                accountCount={accountCount}
                exemptionCount={exemptionCount}
                />
            </div>
        </div> : <div className="casa-loader">
          <img src={casalogo} alt="" width="160px"/>
        </div>
      }
    </div>
  )
}

export default Casa;