import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import '../EditDocNameForm/editdocname.css';
import bullet from '../../assets/check (1).png';
import Swal from 'sweetalert2';
import { documentBaseUrl } from '../../common/apis/userApi';

const EditDcoNameForm = () => {

    const [doc, setDoc] = useState({
        docName : "",
        id : ""
    })
    const [doctype, setDoctype] = useState([]);

    const handleChange = (e) => {
        const { id, value } = e.currentTarget;
        setDoc({docName : value, id : id})
    }

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const query = await axios({
                    method : "GET",
                    url : `${documentBaseUrl}/doctype`
                })
                const response = query.data;
                console.log(response)
                setDoctype(response.results);

            } catch (error) {
                console.log(error)
            }
        }
        fetchingData();
    },[])


    const handleSubmit = async (e)=> {
        e.preventDefault();
        const newform = new FormData();
        newform.append("documentName", doc.docName);
        try {
            const submittingData = await axios({
                url : `${documentBaseUrl}/doctype/${doc.id}`,
                method : "PATCH",
                data : newform
            })
            const response = submittingData.data.response.data;
            toast.dismiss();
            toast.success(response.message)
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        } catch (error) {
            console.log(error)
            toast.dismiss();
            toast.error(error)
        } 
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id)
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          }).then(() => {
            setTimeout(() => {
                window.location.reload();
            }, 2000)
          })
    }

    const handleDelete = async (id) => {
        
        try {
            const deleting = await axios({
                url : `${documentBaseUrl}/doctype/${id}`,
                method : "DELETE",
            })
            const response = deleting.data.response.data;
            console.log(response);
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div className='editdoc-form createdoc-form'>
        <div className="content">
            <h4 className='mb-5'>All Document Types</h4>
            <ToastContainer closeButton={false} position="top-right" />
            <div className="list">
                {
                    doctype.map(({id, documentName}) => {
                        return <ul key={id}>
                                <li id={id} className="d-flex justify-content-between mb-3">
                                    <div className="text">
                                        <img src={bullet} alt="bullet icon" width="40px" />
                                        {documentName}
                                    </div>
                                    <div className="action_btns d-flex">
                                        <button className='edit' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-${id}`} aria-expanded="false" aria-controls="collapseExample">
                                            Edit
                                        </button>
                                        <button className='delete' id={id} onClick={showAlert}>Delete</button>
                                    </div>
                                </li>
                                <div className='collapse' id={`edit-${id}`}>
                                    <div className="form-group">
                                        <input type="text" placeholder='e.g memo' id={id} value={doc.docName} name="docname" required onChange={handleChange}/>
                                        <button onClick={handleSubmit} id={id} className="submit-btn mt-3 mb-2">Submit</button>
                                    </div>
                                </div>
                                
                            </ul>
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default EditDcoNameForm