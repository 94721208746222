import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { irsBaseUrl } from '../../common/apis/userApi';
import { convertStaff } from '../../utilities/convertArray';

const ReassignTIcket = ({ allStaff, id, authAxios}) => {

    const [disabled, setDisabled] = useState(true)
    const [reassign, setAssign] = useState({
        ticket_id: id,
        assigned_to: ""
    });

    useEffect(() => {
        if(reassign.assigned_to !== ""){
            setDisabled(false)
        }
    },[reassign.assigned_to !== ""])

    const ReassignSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true)
        const jsonData = {

            "ticket_id": reassign.ticket_id,
            "assigned_to": Number(reassign.assigned_to)
        }
        try {
            const postingData  = await authAxios.post(`${irsBaseUrl}/interdepartmental/tickets/reassign`, jsonData);
            const response = postingData.data;
            if(response.status === "success"){
                toast.success(response.message);
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }
            console.log(response)
        } catch (error) {
            console.log(error)
            setDisabled(false)
        }    
    }

    const staffList = convertStaff(allStaff);

  return (
    <div className="modal fade" id="reassign-ticket" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reassign Ticket</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form onSubmit={ReassignSubmit}className="d-flex flex-column align-items-center">
                            <div className="form-group d-flex flex-column  w-100">
                                <label htmlFor="" className='text-left'>Select Staff</label>
                                {/* <select name="assigned_to" value={reassign.assigned_to} onChange={reassignhandle} className="w-100" required>
                                    <option value="" disabled>Select staff name</option>
                                    {
                                        allStaff && allStaff.map(({id, name}) => {
                                            return(
                                                <option value={id} key={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                                <Select
                                    name="assigned_to"
                                    options={staffList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onFocus={false}
                                    onChange={(data) => setAssign({...reassign, assigned_to : data.value})} 
                                />
                            </div>
                            <button 
                            type='submit' 
                            disabled={disabled}
                            style={{cursor : disabled ? "not-allowed" : "pointer", opacity : disabled ? "0.5" : "1"}}
                            >Reassign ticket</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReassignTIcket