import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Tag, Space, Dropdown } from 'antd';
import 'antd/dist/antd.min.css'
import '../../pages/TicketList/TicketList.css';
import '../../components/HelpDeskComponent/helpdeskpreview.css'
import HelpDeskTags from '../../components/HelpDeskTags/HelpDeskTags';
import spyglass from '../../assets/search.svg';
import KYCTAB from '../../pages/KYC/KYCTAB';

const TicketComponent = ({heading, tickets, loading, totalPage, fetchingData}) => {

    const navigate = useNavigate();
    const ticketLists = tickets.data;
    const [filterArray, setfilterAray] = useState([]);
    const [searchField, setSetField] = useState("");
    
    useEffect(() => {
        setfilterAray(ticketLists) 
    },[ticketLists, searchField])

    const handleClick = (id) => {
        const targetData = ticketLists.filter(item => item.id === Number(id));
        sessionStorage.setItem("ticket_id", JSON.stringify(targetData));
        navigate('/helpdesk/ticket/preview')
    }

    const handleChange = (e) => {
        setSetField(e.target.value)
    }

    const reversedArray = filterArray && [...filterArray].reverse()

    const handleSearch = async () => {
        const filterList =  filterArray.filter(item => item.ticket_id == searchField || item.department.name.toLowerCase().
        includes(searchField.toLowerCase()))  
        setfilterAray(filterList)
    }

    const handleDelete = async () => {

    }

    const columns = [
        {
          title: 'Ticket ID',
          dataIndex: 'ticket_id',
          key: 'ticket_id',
          render: (text, record) => <a onClick={() => handleClick(record.id)} className="hoverText">{text}</a>,
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
          render : (item) => {
            return(
                <p>{item.name}</p>
            )
          },
        },
        {
          title : "Department",
          dataIndex : "department",
          key : "department",
          render : (item) => {
              return(
                  <p>{item.name}</p>
              )
          }
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          render : (item) => {
              let color = ""
              if(item ==="pending"){
                color = "magenta"
              }else if(item === "solved"){
                  color = "green"
              }
              return(
                <Tag color={color}>
                    {item}
                </Tag>
              )
            }
        },
        {
          title: 'Priority',
          key: 'priority',
          dataIndex: 'priority',
          render : (item) => {
              let color = ""
              if(item ==="low"){
                color = "magenta"
              }else if(item === "medium"){
                  color = "geekblue"
              }else if(item === 'high'){
                  color = "volcano"
              }
              return(
                <Tag color={color}>
                    {item}
                </Tag>
              )
            }
        },
        {
          title: 'Action',
          dataIndex : "currentItem",
          key: 'currentItem',
          render: (item, record) => {
            return(
              <span>
                <Dropdown.Button
                    overlay={
                      <div className='drop-down'>
                        <div className="dropdown-list">
                          <span onClick={() => handleClick(record.id)}>View</span>
                          {/* <span 
                            style={{cursor : record.requester_id == currentUser.id ? "pointer" : "not-allowed", opacity : record.requester_id == currentUser.id ? "1" : "0.4"}}
                            onClick={
                              (record.requester_id == currentUser.id) ? 
                              () => handleEdit(record.slug) : ""
                              }
                              >
                              Edit 
                          </span> */}
                          <span className='delete' onClick={() => handleDelete(record.slug)}>Delete</span>
                        </div>
                      </div>
                    }
                    trigger={['click']}
                  >
                  </Dropdown.Button>
              </span>
            )
          },
        },
    ];

    const data = filterArray;

  return (
      <div className="container_">
          <div className="content">
              <HelpDeskTags />
              <div className="tickets-table">
                  <KYCTAB menuFor={"Prime Serve"}/>
                  <div className="ticketlist-container">
                    <div className="top-display d-flex align-items-center justify-content-between mb-4">
                        <h3 className='w-50 text-left'>{heading}</h3>
                        <div className="search_box">
                            <input type="search" name="searchField" value={searchField} placeholder='search by Ticket id or department' onChange={handleChange} />
                            <img src={spyglass} alt="" onClick={handleSearch}/>
                        </div>
                    </div>
                    <div className="ticketlist-wrapper">
                        <Table loading={loading} columns={columns} dataSource={data} 
                        pagination={{
                            pageSize : 15,
                            total : totalPage,
                            onChange : ((page) => {
                                fetchingData(page)
                            })
                        }}/>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default TicketComponent;
