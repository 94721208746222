import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import "../EditProfileImage/editprofileImage.css";
import avatar from "../../assets/avatar2.png";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, saveUser } from "../../feature/Login/loginSlice";
import Button from "../../common/button/button";

const EditProfileImage = () => {
  const { token } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [imageUrl, setimageUrl] = useState(null);
  const [previewImg, setPreviewImg] = useState();
  const imageChange = (e) => {
    setimageUrl(e.target.files[0]);
  };

  useEffect(() => {
    if (imageUrl < 1) return;
    if (imageUrl !== undefined) {
      const file__ = URL.createObjectURL(imageUrl);
      setPreviewImg(file__);
    }
  }, [imageUrl]);

  const getCurrentUser = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://irsapi.koinsbank.com/api/v3/current_user",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      dispatch(saveUser(data.user_details));
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const fetchingData = async () => {
      try {
        const newData = new FormData();
        newData.append("avatar", imageUrl);
        const response = await axios({
          method: "post",
          url: "https://irsapi.koinsbank.com/api/v3/update-avatar",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: newData,
        });
        const data = response.data;
        if (response.status === 200) {
          toast.success(data.message);
          getCurrentUser();
          setDisabled(false);
          document.getElementById("input").value = null;
        } else {
          return;
        }
      } catch (error) {
        console.log("errrr", error);
        toast.error("image upload failed");
      }
    };
    fetchingData();
  };

  return (
    <div className="edit-image">
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-10">
            <div className="content">
              <ToastContainer closeButton={false} position="top-right" />
              <form action="" onSubmit={handleSubmit}>
                <h4 className="mt-3">Update Profile Picture</h4>
                <div className="img-wrapper">
                  {previewImg !== undefined ? (
                    <img src={previewImg} alt="" />
                  ) : (
                    <img src={avatar} alt="avatar" />
                  )}
                </div>
                <p className="mt-3">
                  only png and jpeg files format are allowed
                </p>
                <input
                  type="file"
                  id="input"
                  accept="image/png, image/jpeg"
                  required
                  className="mt-3"
                  onChange={imageChange}
                />

                <Button type={"submit"} value={"Upload"} disabled={disabled} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditProfileImage;
