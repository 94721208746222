import React from 'react'
import TextInput from '../../common/TextInput/TextInput';
import '../KYC/Newkyc/personal-info.css';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ContactInformationData = ({formik, setValid, setPassport}) => {

    if(formik.values.color_of_building !== "" && formik.values.date_of_visit !== "" && formik.values.address_visited !== "" && formik.values.comments_on_location_landmarks !== "" && formik.values.name_of_visiting_staff !== "" && formik.values.building_description !== ""){
        setValid(true)
    }

    const dummyRequest = async ({ file, onSuccess }) => {    
        setTimeout(() => {
           onSuccess("ok");
        }, 0);
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: dummyRequest,
        beforeUpload : (info) => {
            setPassport(info)
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file selected`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadButton = (
        <div style={{
            border : "1px solid #cccccc", 
            borderRadius : "4px", 
            display : 'flex', 
            alignItems : "center",
            padding : ".4em 3em"
            }}>
            <Button icon={<UploadOutlined />}></Button>
            <div style={{background : "none"}}>
                Upload
            </div>
      </div>
    );
  return (
    <div className='personal-info'>
        <div className="">
            <div className="d-flex mb-3">
                <div className="w-100">
                    <TextInput 
                    label='Color of building '
                    type="text" 
                    placeholder='' 
                    name='color_of_building' 
                    value={formik.values.color_of_building} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.color_of_building && formik.errors.color_of_building) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
                <div className="w-100">
                    <TextInput 
                    label='Date of Visit'
                    type="date" 
                    placeholder='' 
                    name='date_of_visit' 
                    value={formik.values.date_of_visit} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.date_of_visit && formik.errors.date_of_visit) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                    label='Address Visited'
                    type="text" 
                    placeholder='' 
                    name='address_visited' 
                    value={formik.values.address_visited} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.address_visited && formik.errors.address_visited) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                    label='Comments on location landmark'
                    type="text" 
                    placeholder='' 
                    name='comments_on_location_landmarks' 
                    value={formik.values.comments_on_location_landmarks} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.comments_on_location_landmarks && formik.errors.comments_on_location_landmarks) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                    label='Building Description'
                    type="text" 
                    placeholder='' 
                    name='building_description' 
                    value={formik.values.building_description} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.building_description && formik.errors.building_description) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="d-flex mb-3">
                <div className="w-100">
                    <TextInput 
                        label='Name of visiting Staff'
                        type="text" 
                        placeholder='' 
                        name='name_of_visiting_staff' 
                        value={formik.values.name_of_visiting_staff} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.name_of_visiting_staff && formik.errors.name_of_visiting_staff) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Passport</label>
                        <Upload
                            {...props}
                            name="avatar"
                            >
                            {
                                uploadButton
                            }
                        </Upload>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default ContactInformationData