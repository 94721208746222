import React from 'react';
import logo2 from '../../assets/image 3.svg';

const ValueInformation = () => {
  return (
    <div className="irs-value-card">
        <div className="content"> 
            <img src={logo2} alt="primera logo" width="120px" />
            <p>is a one stop solution for employees where all banking operations will be conducted. All the bank’s existing applications such as the core banking, communication, loan processing and disbursement will be assessed on IRS.</p>
            <p className='mb-2'>This solution also serves as a work flow tool that automates business processes related to Human Resources, Administration, IT, Finance, Communication, Documentation and Project Management activities. Launched October 2018.</p>
        </div>
    </div>
  )
}

export default ValueInformation