import React from 'react';
import '../QuartlyTraining/quartlytraining.css';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import QuartlyTrainingComponent from '../../components/QuartlyTrainingComponent/QuartlyTrainingComponent';

const QuartlyTraining = () => {
  return (
    <div className='quartly-training'>
        <Header/>
        <div className="container">
            <div className="content">
                <QuartlyTrainingComponent />
                <BirthdayCard />
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default QuartlyTraining