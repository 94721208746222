import React, { useEffect, useState } from "react";
import "../EditProfile/editProfile.css";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { saveUser, userSelector } from "../../feature/Login/loginSlice";
import { stateList } from "../../common/stateList";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TextInput from "../../common/TextInput/TextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "../../common/FormButton/FormButton";

const EditProflie = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { user, token } = useSelector(userSelector);
  const [dept, setDept] = useState([]);
  const [unit, setUnit] = useState([]);

  const formik = useFormik({
    initialValues: {
      displayName: user.display_name || "",
      username: user.username || "",
      marital_status: user.old_marital || "",
      bio: user.old_bio || "",
      sex: user.sex || "",
      dob: user.dob || "",
      location: user.old || "",
      state_of_origin: user.old_stateoforigin || "",
      phone: user.old_phone || "",
      office_phone: user.old_em_work_phone || "",
      department_id: user.old_department || "",
      unit_id: user.old_sub_unit || "",
      level: user.level || "",
      designation: user.designation || "",
      international_passport: user.old_inter_passport || "",
      emergency_person: user.old_em_name || "",
      emergency_number: user.old_em_phone || "",
      date_employed: user.old_date_employed || "",
      salary_bank: user.old_salary_bank || "",
      salary_account_no: user.old_salary_acc || "",
      salary_account_name: "",
      pension_pin: user.old_pfa_no || "",
      pension_admin: user.old_pfa || "",
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required("Required"),
      username: Yup.string().required("Required"),
      marital_status: Yup.string().required("Required"),
    }),
    isInitialValid: false,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    },
  });

  useEffect(() => {
    const fetchingData = async () => {
      const query = await axios({
        url: "https://irsapi.koinsbank.com/api/v3/staff/department",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = query.data;
      if (query.status === 200) {
        setDept(data);
      } else {
        console.log("error");
      }

      const response = await axios({
        url: "https://irsapi.koinsbank.com/api/v3/interdepartmental/ticket-sub-unit",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const newData = response.data;
      if (response.status === 200) {
        setUnit(newData.data);
      } else {
        console.log("error");
      }
    };
    fetchingData();
  }, [token]);

  const getCurrentUser = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://irsapi.koinsbank.com/api/v3/current_user",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      dispatch(saveUser(data.user_details));
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handleSubmit = async (values, onSubmitProps) => {
    try {
      const response = await fetch(
        "https://irsapi.koinsbank.com/api/v3/update-profile",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            first_name: values.displayName,
            username: values.username,
            marital_status: values.marital_status,
            bio: values.bio,
            sex: values.sex,
            dob: values.dob,
            location: values.location,
            state_of_origin: values.state_of_origin,
            phone: values.phone,
            office_phone: values.office_phone,
            department_id: values.department_id,
            unit_id: values.unit_id,
            level: values.level,
            designation: values.designation,
            intl_passport_no: values.international_passport,
            emergency_contact_person: values.emergency_person,
            emergency_contact_phone: values.emergency_number,
            date_employed: values.date_employed,
            salary_account_bank: values.salary_bank,
            salary_account_name: values.salary_account_name,
            salary_account_no: values.salary_account_no,
            pension_pin: values.pension_pin,
            pension_admin: values.pension_admin,
          }),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        getCurrentUser();
        toast.success(data.message);
        setTimeout(() => {
          history("/user-profile");
        }, 3000);
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      onSubmitProps.setSubmitting(false);
    }
  };

  return (
    <div className="edit-profile">
      <Header />
      <div className="container_">
        <div className="content-wrapper">
          <div className="left-wrap">
            <h3>Account</h3>
            <div className="account-info">
              <img src={user?.avatar} alt="" style={{ borderRadius: "50%" }} />
              <p className="name">{user.name}</p>
              <p> {user?.designation}</p>
              <p>
                <span>Level :</span> {user?.level}
              </p>
              <p>
                <span>Birthday :</span> {user?.dob}
              </p>
            </div>
          </div>
          <div className="right-wrap">
            <ToastContainer closeButton={true} position="top-right" />
            <form onSubmit={formik.handleSubmit}>
              <div className="form-title">
                <h3>Profile Edit</h3>
                <p>Some of this information will be displayed publicly</p>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <TextInput
                    label="Display name"
                    type="text"
                    placeholder=""
                    name="displayName"
                    value={formik.values.displayName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    required="yes"
                    style={{
                      border:
                        formik.touched.displayName && formik.errors.displayName
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <TextInput
                    label="User Name"
                    type="text"
                    placeholder=""
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    required="yes"
                    style={{
                      border:
                        formik.touched.username && formik.errors.username
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="">Marital Status</label>
                    <select
                      name="marital_status"
                      value={formik.values.marital_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" selected disabled>
                        Select marital status
                      </option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group">
                    <label htmlFor="">About</label>
                    <textarea
                      name="bio"
                      cols="20"
                      rows="4"
                      value={formik.values.bio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    <p className="text-left">
                      Brief description for your profile{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <div className="content">
                    <div className="form-group">
                      <label htmlFor="">Sex</label>
                      <select
                        name="sex"
                        value={formik.values.sex}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option selected disabled>
                          Select sex
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <TextInput
                    label="Date of birth"
                    type="date"
                    placeholder=""
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.dob && formik.errors.dob
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <TextInput
                    label="Location"
                    type="text"
                    placeholder=""
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.location && formik.errors.location
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="">State</label>
                    <select
                      name="state_of_origin"
                      value={formik.values.state_of_origin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {stateList.map(({ code, name }) => {
                        return (
                          <option key={code} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <TextInput
                    label="Phone Number"
                    type="number"
                    placeholder="080 333 2222 11"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.phone && formik.errors.phone
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <TextInput
                    label="Office Phone Number"
                    type="number"
                    placeholder="080 333 2222 11"
                    name="office_phone"
                    value={formik.values.office_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.office_phone &&
                        formik.errors.office_phone
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <div className="content">
                    <div className="form-group">
                      <label htmlFor="">Select Department</label>
                      <select
                        name="department_id"
                        value={formik.values.department_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select department</option>
                        {dept.map(({ id, name }) => {
                          return (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="">Select Unit</label>
                    <select
                      name="unit_id"
                      value={formik.values.unit_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select unit</option>
                      {unit
                        .filter(
                          (item) =>
                            item.department_id == formik.values.department_id
                        )
                        .map(({ id, name }) => {
                          return (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <TextInput
                    label="Level"
                    type="text"
                    placeholder=""
                    name="level"
                    value={formik.values.level}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.level && formik.errors.level
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <TextInput
                    label="Designation"
                    name="designation"
                    placeholder=""
                    type="text"
                    value={formik.values.designation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.designation && formik.errors.designation
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
              </div>

              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                  <TextInput
                    label="International Passport Number"
                    type="text"
                    placeholder=""
                    name="international_passport"
                    value={formik.values.international_passport}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.international_passport &&
                        formik.errors.international_passport
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <TextInput
                    label="Date Employed"
                    type="date"
                    placeholder=""
                    name="date_employed"
                    value={formik.values.date_employed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{
                      border:
                        formik.touched.date_employed &&
                        formik.errors.date_employed
                          ? "1px solid rgb(245, 102, 102)"
                          : "1px solid #A1A1A1",
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <h4>Emergency Contact Details</h4>
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6">
                    <TextInput
                      label="Full Name"
                      type="text"
                      placeholder=""
                      name="emergency_person"
                      value={formik.values.emergency_person}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.emergency_person &&
                          formik.errors.emergency_person
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextInput
                      label="Mobile Number"
                      type="number"
                      placeholder=""
                      name="emergency_number"
                      value={formik.values.emergency_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.emergency_number &&
                          formik.errors.emergency_number
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <h4>Salary Account Details</h4>
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6">
                    <TextInput
                      label="Bank Name"
                      type="text"
                      placeholder=""
                      name="salary_bank"
                      value={formik.values.salary_bank}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.salary_bank &&
                          formik.errors.salary_bank
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextInput
                      label="Bank Account Name"
                      type="text"
                      placeholder=""
                      name="salary_account_name"
                      value={formik.values.salary_account_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.salary_account_name &&
                          formik.errors.salary_account_name
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <TextInput
                      label="Bank account Number"
                      type="number"
                      placeholder=""
                      name="salary_account_no"
                      value={formik.values.salary_account_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.salary_account_no &&
                          formik.errors.salary_account_no
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                </div>
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6">
                    <TextInput
                      label="Pension Pin Number"
                      type="text"
                      placeholder=""
                      name="pension_pin"
                      value={formik.values.pension_pin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.pension_pin &&
                          formik.errors.pension_pin
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextInput
                      label="Pension Fund Administrative (PFA)"
                      type="text"
                      placeholder=""
                      name="pension_admin"
                      value={formik.values.pension_admin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formik={formik}
                      style={{
                        border:
                          formik.touched.pension_admin &&
                          formik.errors.pension_admin
                            ? "1px solid rgb(245, 102, 102)"
                            : "1px solid #A1A1A1",
                      }}
                    />
                  </div>
                </div>
              </div>
              <FormButton
                type="submit"
                buttonText="Save Profile"
                loading={formik.isSubmitting}
                disable={!formik.isValid || formik.isSubmitting}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProflie;
