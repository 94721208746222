import React from 'react'
import ImagePopUpModal from '../../../components/ImagePopUpModal/ImagePopUpModal'

const CredentialRender = ({id, mode_of_id, idno, identityimage, is_approved, is_rejected, passport, profile}) => {
  return (
    <div className="employer-info mt-3 mb-5" key={id}>
        <div className="title">
            <p>KYC DOCUMENTS</p>
        </div>
        <div className="item-row">
            <div className='info-item'>
                <h4>Mode of ID</h4>
                <span>{mode_of_id}</span>
            </div> 
            <div className='info-item'>
                <h4>ID No</h4>
                <span>{idno}</span>
            </div> 
            <div className='info-item'>
                <h4>ID Photo</h4>
                <span className='' 
                data-bs-toggle="modal" 
                data-bs-target={`#image-modal${id}`} 
                role="button"
                >
                    <img src={identityimage} alt="" />
                </span>
            </div> 
            <div className='info-item last-item'>
                <h4>Approval Status</h4>
                <span>{is_approved ? "Yes" : "No"}</span>
            </div> 
        </div>
        <div className="title">
            <p>KYC DOCUMENTS</p>
        </div>
        <div className="item-row">
            <div className='info-item'>
                <h4>Rejection Status</h4>
                <span>{is_rejected ? "Yes" : "No"}</span>
            </div> 
            <div className='info-item'>
                <h4>Passport</h4>
                <span>{passport}</span>
            </div> 
            <div className='info-item'>
                <h4>Profile No</h4>
                <span>{profile}</span>
            </div> 
        </div>
        <ImagePopUpModal identityimage={identityimage} id={id} />
    </div>
  )
}

export default CredentialRender