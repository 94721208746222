import React from 'react'
import EditDcoNameForm from '../EditDocNameForm/EditDcoNameForm'
import Header from '../header/header'
import Tags from '../Tags/Tags'

const EditDocName = () => {
  return (
    <div className='edit-docname createdoc-name'>
        <Header />
        <div className="container_">
            <Tags />
            <EditDcoNameForm />
        </div>
    </div>
  )
}

export default EditDocName