import React from 'react'
import menu1 from '../../assets/menu1.png'
import { NavLink } from 'react-router-dom'
import '../KYC/kyc-tags.css';
import { IndividualData } from '../../utilities/menuList';

const PersonalTags = () => {

    let activeStyle = {
        background : "white",
        color : "#093263",
        width : "100%",
    }

  return (
    <div className='kyc-tags'>
      {
        IndividualData.title === "Individual KYC" && <>
          <h3>{IndividualData.title}</h3>
          {
            IndividualData.data.map(({menu, link}, index) => {
              return(
                <div className="list" key={index}>
                  <NavLink to={link}
                      style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                      }
                  >
                      <img src={menu1} alt="" />
                      {menu}
                  </NavLink>
                </div>
              )
            })
          }
        </>
      }
    </div>
  )
}

export default PersonalTags