import React, { useState } from 'react'
import { irsBaseUrl } from '../../common/apis/userApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const EditTicketModal = ({department, editTicket, allcategory, alldepartment, setEditTicket, units, id, authAxios, ticket_id }) => {

    const history = useNavigate();
    const [imageUrl, setimageUrl] = useState(null);
    const handleChangeTicket = (e) => {
        setEditTicket({...editTicket, [e.target.name] : e.target.value});
    }

    const imageChange = (e) => {
        setimageUrl(e.target.files[0])
    }

    const submitEditTicket = async (e) => {
        e.preventDefault();

        const { user_id, ticket_id, title, dept, unit, category, description, priority, assigned_to, is_assigned, is_on_behalf, is_approved, status_id, closed_by, solved_at } = editTicket

        const jsonData = {
            "user_id": user_id,
            "ticket_id": ticket_id,
            "title": title,
            "department_id": dept,
            "unit_id": unit,
            "category_id": category,
            "message": description,
            "attachment": imageUrl,
            "priority": priority,
            "assigned_to": assigned_to,
            "is_assigned": is_assigned,
            "is_on_behalf": is_on_behalf,
            "is_approved": is_approved,
            "status_id": status_id,
            "closed_by": closed_by,
            "solved_at": solved_at
        }

        try {
            const sendingdata = await authAxios.put(`${irsBaseUrl}/interdepartmental/inter-departmental-tickets/${id}`, jsonData);
            const response = sendingdata.data;
            if(response.updated_at){
                toast.success("Ticket update successfully!!");
                setTimeout(() => {
                    history('/helpdesk/ticket');
                    window.location.reload();
                }, 2000)
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div className="modal fade" id="edit-ticket" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Ticket</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form onSubmit={submitEditTicket}className="d-flex flex-column align-items-center">
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">Ticket Id</label>
                                <input type="text" value={ticket_id} readOnly/>
                            </div>
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">Title</label>
                                <input type="text" name='title' value={editTicket.title} onChange={handleChangeTicket} required/>
                            </div>
                            <div className="form-group d-flex flex-column  w-100">
                                <label htmlFor="" className='text-left'>Select Department</label>
                                <select name="dept" value={editTicket.dept} onChange={handleChangeTicket} className="w-100">
                                    <option value="" disabled>Select staff name</option>
                                    {
                                        alldepartment && alldepartment.map(({id, name}) => {
                                            return(
                                                <option value={id} key={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group d-flex flex-column  w-100">
                                <label htmlFor="">Sub department</label>
                                <select name="unit" value={editTicket.unit} onChange={handleChangeTicket}>
                                    <option value="" disabled>Select an option</option>
                                    {
                                        units && units.filter(item => item.department_id == department.id)
                                        .map(({id, name}) => {
                                            return <option key={id} value={id}>{name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group d-flex flex-column  w-100">
                                <label htmlFor="">Category</label>
                                <select name="category" value={editTicket.category} onChange={handleChangeTicket}>
                                    <option value="" disabled>Select an option</option>
                                    {
                                        allcategory && allcategory.filter(item => item.department_id == department.id)
                                        .map(({id, name}) => {
                                            return <option key={id} value={id}>{name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">PRIORITY</label>
                                <select name="priority" value={editTicket.priority} onChange={handleChangeTicket}>
                                    <option value="" disabled>select</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                            </div>
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">Are you creating this ticket for external customers</label>
                                <select name="is_on_behalf" value={editTicket.is_on_behalf} onChange={handleChangeTicket}>
                                    <option value="" disabled>select</option>
                                    <option value="0">Yes</option>
                                    <option value="1">No</option>
                                </select>
                            </div>
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">Message</label>
                                <textarea name="description" value={editTicket.description} onChange={handleChangeTicket} cols="10" rows="4">
                                </textarea>
                            </div>
                            <div className="form-group  d-flex flex-column  w-100">
                                <label htmlFor="">Attachment</label>
                                <input type="file" onChange={imageChange}/>
                            </div>
                            <button type='submit'>Edit ticket</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditTicketModal


// accept="image/png, image/jpeg"