import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import '../forget-password/forget-password.css';
import logo from '../../assets/logo.svg';
import primeraBackground from '../../assets/image 2.png'
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import arrowBack from '../../assets/left-arrow.png';
import TextInput from '../../common/TextInput/TextInput';
import FormButton from '../../common/FormButton/FormButton';
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '../../graphql/mutations/RegisterUser';
import axios from 'axios';

const Activate2FA = () => {
  
    const formik = useFormik({
        initialValues : {
            email : ""
        },
        validationSchema : Yup.object({
            email : Yup.string().email("Invalid Email Address").required("Email is required")
        }),
        isInitialValid : false,
        onSubmit :  (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps)
        }
    })

    const [createCode, { error }] = useMutation(REGISTER_USER);

    const handleSubmit = async (values, onSubmitProps) => {
        
        createCode({
            variables : {
                email : values.email
            }
        }).then(({data : { createCode }}) => {

            const { status, statusCode, message } = createCode;
            if(statusCode === 200 && status === true ){
                toast.success(message)
                onSubmitProps.setSubmitting(false)
            }else if(statusCode === 400){
                toast.error(message)
                onSubmitProps.setSubmitting(false)
            }
        })
        if(error){
            console.log(error);
            onSubmitProps.setSubmitting(false)   
        }
    }
    
  return (
    <div className='forget-password'>
        <div className="main-wrapper">
            <ToastContainer closeButton={true} position="top-right" />
            <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-8">
                    <h3>Register 2FA</h3>
                    <div className="white-wrapper">
                        <ToastContainer closeButton={false} position="top-right" />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                            <p className='mb-4'>Enter your email and we'll send you a link to activate the 2FA.</p>
                            <TextInput 
                            type="email" 
                            placeholder='Email-address' 
                            name='email' 
                            value={formik.values.email} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            formik={formik}
                            style={{border : (formik.touched.name && formik.errors.name) ? "1px solid rgb(245, 102, 102)" : "initial"}}
                            />

                            <FormButton 
                            type="submit"
                            buttonText="Register"
                            loading={formik.isSubmitting}
                            disable={!formik.isValid || formik.isSubmitting} 
                            />
                        
                            <div className="return-to-home">
                                <img src={arrowBack} alt="arrow-left" />
                                <Link to='/'>Back to login</Link>
                            </div>
                        </form>
                    </div>
                    <div className="copyright">
                        <p> © 2022 Primera Microfinancebank. All rights reserved </p>
                    </div>
                </div>
            </div>
            <div className="outer-bg align-items-center">
                <div className="bg-container">
                    <div className="blank"></div>
                    <div className="prim-logo">
                        <img src={primeraBackground} alt="primera logo" />
                    </div>
                </div>  
            </div>
        </div>
    </div>
  )
}

export default Activate2FA;