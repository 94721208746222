import React, { useState } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags';
import '../CasaStaffList/casaStafflist.css';
import '../CasaCustomerList/casacustomerpreview.css';
import Button from '../../../common/button/button';
import axios from 'axios';
import { casaBaseurl, token } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import {  userSelector } from '../../../feature/Login/loginSlice';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ExemptionPreview = () => {

    const history = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const data = JSON.parse(sessionStorage.getItem("targetData"));
    const newData = data[0];
    const [accountType, setAccountType] = useState([]);

    const { user } = useSelector(userSelector);

    const handleAccountType = (e) => {
        setAccountType(e.target.value);
    }

    const submitAccountType = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const jsonData = {
            "acctType": accountType
        }

        try {
            const approvingAccount = await axios.post(`${casaBaseurl}approve_customer/${newData.custno}/${user.id}`, jsonData);
            const response = approvingAccount.data;
            if(response.code === 200){
                // toast.success(response.body.data)
                // setInterval(() => {
                //     history('/customer/all')
                // }, 2000);
            }
            console.log(response)
        } catch (error) {
            const errMsg = error.response.data.body;
            setDisabled(false);
            toast.error(errMsg.data);
            console.log(errMsg)
        }
    }

    const handleDelete = async (id) => {
        try {
            const deleteUser = await axios.delete(`${casaBaseurl}exemptedcustomer/${id}`);
            const response = deleteUser.data;
            if(response.code === 200){
                toast.success(response.body.data)
                setInterval(() => {
                    history('/exemption-list')
                }, 1000);
            }
        console.log(response)
        } catch (error) {
            const errMsg = error.response.data.body;
            setDisabled(false);
            toast.error(errMsg.data);
            console.log(errMsg)
        } 
    }
    
    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id)
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
    }

  return (
    <div className='casa-customer-info casa-stafflist'>
        <Header />
        <div className="content d-flex w-100">
            <CasaTags />
            <div className="preview-wrapper casa-staff-wrapper">
                <div className="inner-content-wrapper">
                    <fieldset className='p-4 border rounded-md mb-5 bg-white'>
                        <legend className='float-none w-auto p-2'>Basic Information</legend>
                        <ul>
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Title</h4>
                               <span>{newData.title}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Full Name</h4>
                               <span>{`${newData.firstname} ${newData.lastname}`}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Initials</h4>
                               <span>{newData.initials}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Gender</h4>
                               <span>{newData.gender}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Maiden Name</h4>
                               <span>{newData?.maiden_name}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Date of birth</h4>
                               <span>{newData.dob}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Marital Status</h4>
                               <span>{newData.marital_status}</span>
                            </li> 
                        </ul>
                    </fieldset>
                    <fieldset className='p-4 mb-5 border rounded-md bg-white'>
                        <legend className='float-none w-auto p-2'>Contact Information</legend>
                        <ul>
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Phone Number</h4>
                               <span>{newData.mobileno}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Alt Phone Number</h4>
                               <span>{newData.alt_mobileno}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>State</h4>
                               <span>{newData.state}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Country</h4>
                               <span>{newData.country}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>LGA</h4>
                               <span>{newData.lga}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Address</h4>
                               <span>{newData.address}</span>
                            </li> 
                        </ul>
                    </fieldset>
                    <fieldset className='border rounded-md mb-5 p-4 bg-white'>
                        <legend className='float-none w-auto p-2'>Identity Information</legend>
                        <ul>
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Customer Number</h4>
                               <span>{newData.custno}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>BVN</h4>
                               <span>{newData.bvn}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>Reason for Rejection</h4>
                               <span className='rejection'>{newData.rejectreason}</span>
                            </li> 
                           <li className='d-flex align-items-center justify-content-between'>
                               <h4>ID Number</h4>
                               <span>{newData.idpassportno}</span>
                            </li> 
                        </ul>
                    </fieldset>
                    
                    <div className="btn w-100">
                        <button className='w-100' onClick={showAlert} id={newData.custno}
                            style={{background : '#e35252'}}>
                            Delete user
                        </button>
                    </div>
                </div>

                <div className="modal fade" id='exampleModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Account Type</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form action="" onSubmit={submitAccountType} >
                                    <div className="form-group">
                                        <label htmlFor="">Select Account Type</label><br></br>
                                        <select value={accountType} name="accountType" onChange={handleAccountType}
                                        required className='w-100 mt-4'>
                                            <option value="" disabled>Select account type</option>
                                            <option value="savings">Savings</option>
                                            <option value="current">Current</option>
                                        </select>
                                    </div>
                                    
                                    <div className="form-group mt-3">
                                        <Button value="Upload Account" type="submit" disabled={disabled}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExemptionPreview;