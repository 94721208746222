import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { irsBaseUrl } from '../../../common/apis/userApi';
import { userSelector } from '../../../feature/Login/loginSlice';

const CreateRole = ({serviceList}) => {

    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false);

    const [roleName, setRoleName] = useState("")
    const [permission, setPermission] = useState([]);

    const handleChange2 = (e) => {
        setRoleName(e.target.value);
    }

    const handlePermission = (e) => {
        const data = e.target.value;
        if(e.target.checked){
            setPermission([...permission, data]);
        }else{
            setPermission(permission.filter((item) => item !== data))
        }
    }

    const handleSubmit = async(e) => {

        e.preventDefault();
        setDisabled(true)
        const jsonData = JSON.stringify({
            "name" : roleName,
            "service_id" : permission,
        })

        try {
            const creatingRole = await getAxiosInstance(token).post(`${irsBaseUrl}/roles-core/permission`, jsonData);
            const result = creatingRole.data;
            
            if(result){
                toast.success("New role created successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            console.log(error)  
            setDisabled(false) 
        }
    }

  return (
    <div className="modal fade" id="add-role">
      <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Role Permission</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                  <div className="form-wrapper">
                      <form onSubmit={handleSubmit}>
                          <div className="form-data">
                              <div className="form-group">
                                  <label htmlFor="">Role Name</label>
                                  <input type="text" name="roleName" value={roleName} onChange={handleChange2}/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Pick Permission</label>
                                  <span className='row'>
                                      {
                                          serviceList?.map(({id, name}) => {
                                              return(
                                                  <span key={id} className="col-6 mb-2">
                                                    <input type="checkbox" name="services" value={id} onChange={handlePermission} />
                                                    <span>{name}</span>
                                                  </span>
                                                  
                                              )
                                          })
                                      }
                                    <span className="col-6 mb-2">
                                        <input type="checkbox" name="services" value="admin" onChange={handlePermission} />
                                        <span>Admin</span>
                                    </span>
                                  </span>
                              </div>
                              <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Add Role</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default CreateRole