import React, { useState } from 'react'
import Button from '../../../common/button/button'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { casaBaseurl } from '../../../common/apis/userApi'

const AuthorizerRejectModal = ({ newData, user, handleReject, rejection }) => {

    const history = useNavigate();

    const [disabled, setDisabled] = useState(false);
    const submitRejection = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const jsonData = {
            "reason": rejection
        }
        try {
            const approvingAccount = await axios.post(`${casaBaseurl}rejectcustomer/${newData.custno}/${user.id}`, jsonData);
            const response = approvingAccount.data;
            if(response.code === 200){
                history('/customer/all');
                window.location.reload();
            }
        } catch (error) {
            const errMsg = error.response.data.body;
            setDisabled(false);
            toast.error(errMsg.data);
            console.log(errMsg);
        }
    }

  return (
    <div className="modal fade" id='rejectModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reject Application</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form action="" onSubmit={submitRejection} >
                        <div className="form-group">
                            <label htmlFor="">Enter Reason for rejection</label><br></br>
                                <textarea onChange={handleReject} value={rejection} className="w-100  p-3"/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <Button value="Reject Request" type="submit" disabled={disabled}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AuthorizerRejectModal