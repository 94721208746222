import React from 'react';
import '../FormFields/formfield.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const FormFileds = ({ values, handleChange, setValid}) => {
    
    if(values.Request !== "" && values.Justification !== "" && values.background !== ""){
        setValid(true)
    }
  return (
    <div className='form-field'>
        <ToastContainer closeButton={false} position="top-right" />
        <div className="content mt-1">
            <div className="form-group">
                <label htmlFor="">Background</label>
                <textarea name="background" cols="20" rows="2" value={values.background} onChange={handleChange}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="">Request</label>
                <textarea name="Request" cols="20" rows="2" value={values.Request} onChange={handleChange}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="">Justification</label>
                <textarea name="Justification" value={values.Justification} onChange={handleChange} cols="20" rows="3"></textarea>
            </div>
        </div>
    </div>
  )
}

export default FormFileds