import React from 'react';
import '../Notification/notification.css';
import close from '../../assets/close.png';
import notificationicon from '../../assets/Notification.png';

const Notification = ({notification, handleClose}) => {
  return (
    <div className='notification' style={{display : notification ? "block" : "none", transition : notification ? "ease-in" : "none"}}>
        <div className="notification-wrapper">
            <div className="notification-card">
                <div className="notification-list">
                    <div className="close-btn">
                        <img src={close} onClick={handleClose} alt="close button icon" />
                    </div>
                    <div className="notification-item d-flex align-items-center justify-content-betwen">
                        <div className="notification-mg d-flex align-items-center">
                            <img src={notificationicon} alt="notification bell icon" />
                            <p>You have a new message</p>
                        </div>
                        {/* <img src="" alt="" /> */}
                    </div>
                    <div className="notification-item d-flex align-items-center justify-content-betwen">
                        <div className="notification-mg d-flex align-items-center">
                            <img src={notificationicon} alt="notification bell icon" />
                            <p>You have a new message</p>
                        </div>
                        {/* <img src="" alt="" /> */}
                    </div>
                    <div className="notification-item d-flex align-items-center justify-content-betwen">
                        <div className="notification-mg d-flex align-items-center">
                            <img src={notificationicon} alt="notification bell icon" />
                            <p>You have a new message</p>
                        </div>
                        {/* <img src="" alt="" /> */}
                    </div>
                    <div className="notification-item d-flex align-items-center justify-content-betwen">
                        <div className="notification-mg d-flex align-items-center">
                            <img src={notificationicon} alt="notification bell icon" />
                            <p>You have a new message</p>
                        </div>
                        {/* <img src="" alt="" /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Notification