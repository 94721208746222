import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { clearState } from '../../feature/Login/loginSlice';
import '../CASA/CasaDocument/casaDocumentType.css';
import Notification from '../../components/Notification/Notification';
import '../dahsboardHome/dashboard.css';
import '../Menus/Menu.css';
import { useMutation, useQuery } from '@apollo/client';
import { FILTER_USER, USER_INFO } from '../../graphql/queries/userInfo';
import HomeTags from '../../components/HomeTags/HomeTags';
import { DELETE_USER } from '../../graphql/mutations/DeleteUser';
import { toast, ToastContainer } from 'react-toastify';

const Register2FAUserList = () => {

    const [notification, setNotification] = useState(false);
    const [state, setState] = useState({
        search : "",
        searchField : []
    })

    const [active] = useState({
        dashboard : false,
        myProfile : false,
        menus : false,
        permission : false,
        user2fa : true,
        oldirs : false
    })

    const handleChange = (e) => {
        e.persist();
        setState({...state, search : e.target.value})
    }

    const handleClose = () => {
        setNotification(!notification)
    }

    const { loading, data, refetch } = useQuery(USER_INFO);

    // const [getUser] = useQuery(FILTER_USER);

    const handleSubmit = (e) => {
        e.preventDefault();
        // getUser({variables : state.search})
        // .then((data => console.log(data)))
    }

    const [deleteUser, { error : deleteError }] = useMutation(DELETE_USER);

    const handleDelete = (e) => {
        const { id } = e.currentTarget
        deleteUser({
            variables : {
                id : id
            }
        }).then(({data : { deleteUser }}) => {
            const { status, statusCode, message } = deleteUser;
            if(statusCode === 200 && status === true ){
                toast.success(message)
                refetch();
            }else if(statusCode === 400){
                toast.error(message)
            }
        })

        if(deleteError){
            console.log(deleteError);   
        }
    }
    
    const allArray = data && data.allInfo.edges;

  return (
    <div className='menus dashboard_'>
        <div className="container___">
            <ToastContainer closeButton={true} position="top-right" />
            <div className="dashboard-wrapper">
                <HomeTags handleClose={handleClose} active={active}/>
                <Notification notification={notification} handleClose={handleClose}/>
                <div className="document-type-list">
                    <h3>2FA Users</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <form onSubmit={handleSubmit}>
                            <input type="search" placeholder='filter' value={state.search} onChange={handleChange}/>
                        </form>
                        <Link to='/register-2fa-user'>Add new staff</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Email</h5>
                            <h5 className='text-center'>ID</h5>
                            <h5 className='text-center'>QR image</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            allArray && allArray.map(({node}) => {
                                const { email, id, qrcodeUrl} = node;

                                return(
                                    <div className="doc-details userlist" key={id}>
                                        <div className="item">
                                            <p className='blue-text'>{email}</p>
                                            <p>{id}</p>
                                            <p>{qrcodeUrl}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                {/* <a href={qrcodeUrl} target="_blank" className='primary-text underline'>scan QRcode</a> */}
                                                <span id={id} onClick={handleDelete}>Remove Staff</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register2FAUserList