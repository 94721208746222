import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { documentBaseUrl } from '../../common/apis/userApi';
import Header from '../../components/header/header'
import Tags from '../../components/Tags/Tags'
import '../CASA/updateStaff/updateStaff.css';
import '../Createoffice/CreateOffice.css';

const CreateOffice = () => {

    const history = useNavigate();
    const [office, setOffice] = useState("");

    const handleChange = (e) =>{
        setOffice(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const jsonData = {
            "office_name" : office
        }

        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/office`, jsonData);
            const response = sendingdata.data.response;
            toast.success(response.data);
            setTimeout(() => {
                history('/document/office')
            },2000)
        } catch (error) {
            const apiMsg = error.response.data.response;
            toast.error(apiMsg.data)
        }
    }

  return (
    <div className='create-office'>
        <Header />
        <div className="content d-flex w-100">
            <Tags />
            <div className="add-staff-form">
                <ToastContainer closeButton={true} position="top-right" />
                <div className="add-staff-wrapper">
                    <h4 className='mb-4'>Create Office</h4>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-data">
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <input type="text" placeholder='technology' name='name' value={office} onChange={handleChange}/>
                            </div>
                            <button type='submit'>Create Office</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateOffice