 import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { userSelector } from '../feature/Login/loginSlice';

const ProtectedRoute = ({ Component }) => {

    const { token } = useSelector(userSelector);
    return token ? <Component /> : <Navigate to="/" />

};

export default ProtectedRoute;