import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';

const EditUserRole = ({ id, name, roleList, user_id }) => {

    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false);

    const [role, setRole] = useState({
        name : name,
        role_id : "",
        user_id : user_id,
    })

    const handleChange = (e) => {
        setRole({...role, [e.target.name] : e.target.value})
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const { id } = e.currentTarget;

        const { name, role_id, user_id } = role;
        const status_data = roleList?.filter(item => item.id == role.role_id)[0];

        const jsonData = JSON.stringify({
            "name" : name,
            "role_id" : role_id,
            "user_id" : user_id,
            "status" : status_data?.name,
        })

        try {
            const removeDoc = await getAxiosInstance(token).put(`/user-roles-core/${id}`, jsonData);
            const result = removeDoc.data;
            if(result){
                toast.success("User Permission updated successfully");
                setTimeout(() => {
                    window.location.reload();
                },3000)
            }
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data.message;
            toast.error(apiMsg)
        }
    }

  return (
    <div className="modal fade" id={`edit-user-role${id}`}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Role</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper user-role">
                        <form action="" id={id} onSubmit={handleEdit}>
                            <div className="form-data">
                                <div className="form-group">
                                    <label htmlFor="">Staff Name</label>
                                    <input type="text" name='name' value={name} disabled/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Select Role</label>
                                    <select name="role_id" onChange={handleChange}>
                                        <option value="">Select service</option>
                                        {
                                            roleList && roleList.map(({id, name,}) => {
                                                return(
                                                    <option key={id} value={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                
                                <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Edit Role</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditUserRole