import React from 'react';
import '../DocumentComponent/document.css';
import '../HelpDesk/Helpdesks.css';
import primeserve from '../../assets/PrimeServe.jpeg';
import loader from '../../assets/loading.gif';

const HelpDesk = () => {
  return (
    <div className='prime'>
        <img src={primeserve} alt="prime serve" width='100%' className='prime-img' />
        <div className="loader">
            <img src={loader} alt="" />
        </div>
    </div>
  )
}

export default HelpDesk
