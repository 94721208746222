import React, { useState, useEffect } from 'react'
import KYCTAB from '../KYC/KYCTAB';
import '../KYC/AllAccounts/AllAccounts.css';
import TopMenu from '../KYC/AllAccounts/TopMenu';
import axios from 'axios';
import { irsAuthBaseUrl } from '../../common/apis/userApi';
import IndividualDataTable from './IndividualDataTable';
import PersonalTags from './PersonalTags';

const RequesterList = () => {

    const [loading, setloading] = useState(false);
    const [allAccounts, setAccounts] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [active, setActive] = useState({
        all : true,
        approved : false,
        pending : false,
        reject : false
    })
    
    const fetchingData = async (page) => {
        try {
            setloading(true);
            setActive({all : true, approved : false, pending : false, reject : false})
            const allAccount = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/paginate?page=${page}`)
            const response = allAccount.data;
            setAccounts(response.data)
            setpageCount(response.meta.total)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    const fetchingCounts = async (status) => {
        try {
            setloading(true);
            if(status === 'Approved'){
                setActive({all : false, approved : true, pending : false, reject : false})
            }
            if(status === 'Pending'){
                setActive({all : false, approved : false, pending : true, reject : false})
            }
            if(status === 'Rejected'){
                setActive({all : false, approved : false, pending : false, reject : true})
            }
            const allPending = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/application_status/${status}`)
            const result = allPending.data;
            setAccounts(result.data)
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }
    
    useEffect(() => {
        fetchingData(1)
    },[])

  return (
    <div className='all-accounts'>
        <PersonalTags />
        <div className="dash-wrapper">
            <KYCTAB menuFor={"Individual KYC"}/>
            <TopMenu setAccounts={setAccounts} setloading={setloading} type={"individual"}/>
            <div className="px-4 my-2">
                <div className="bread-wrapper">
                    <div 
                    className="bread-item"
                    style={{
                        background : active.all ? "#0C2C60" : "white",
                        color : active.all ? "white" : "black"
                    }}
                    onClick={() => fetchingData(1)}
                    >
                        All Application
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.approved ? "#0C2C60" : "white",
                        color : active.approved ? "white" : "black"
                    }}
                     onClick={() =>fetchingCounts("Approved")}>
                         Approved Request
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.pending ? "#0C2C60" : "white",
                        color : active.pending ? "white" : "black"
                    }}
                    onClick={() =>fetchingCounts("Pending")}
                    >
                        Awaiting Request
                    </div>
                    <div 
                    className="bread-item" 
                    style={{
                        background : active.reject ? "#0C2C60" : "white",
                        color : active.reject ? "white" : "black"
                    }}
                    onClick={() =>fetchingCounts("Rejected")}
                    >
                        Declined Request
                    </div>
                </div>
            </div>
            <div className="px-4">
                <IndividualDataTable
                    allData={allAccounts} 
                    loading={loading} 
                    pageCount={pageCount}
                    fetchingData={fetchingData}
                    user={''}
                    officer={''}
                />
            </div>
        </div>
    </div>
  )
}

export default RequesterList;