import React,{ useEffect, useState } from 'react';
import '../BirthdayCard/birthdayCard.css';
import birthday from '../../assets/Birthday icon.svg';
import birthdayMix from '../../assets/confetti.png';
import user from '../../assets/image 12.svg';
import { getAxiosInstance } from '../../common/apis/createAxios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { month } from '../../common/months';
import loading from '../../assets/loading.gif'
import axios from 'axios';
import { irsBaseUrl } from '../../common/apis/userApi';

const BirthdayCard = () => {

    const { token } = useSelector(userSelector);
    const [userlist, setUserlist] = useState([]);

    const d = new Date();
    const currentMonthNo = d.getMonth();
    const currentMonth = month[currentMonthNo];
    const splitMonth = currentMonth?.slice(0, 3);

    useEffect(() => {
        const fetchingUserData = async () => {
            try {
                const userData = await getAxiosInstance(token).get(`all-user`)
                const response = userData.data;
                setUserlist(response);

                // const userdob = await axios.get(`${irsBaseUrl}/dob`);
            } catch (error) {
                console.log(error)
            }
        }
        fetchingUserData()
    },[])

    const dobData = userlist?.filter(item => {
        return item.dob.includes(splitMonth);
    })

  return (
    <div className='birthday-card'>
        <div className="birthday_card">
            <div className="heading d-flex align-items-center">
                <img src={birthday} alt="birthday cake with candle icon" />
                <h4>Upcoming <br></br>Birthdays</h4>
            </div>
            <div className="birthday-list">
                {
                    dobData.filter((item, index) => index < 5).map(({display_name, dob}, id) => {
                        return(
                            <div className="birthday d-flex align-items-center" key={id}>
                                <div className="info-wrapper d-flex align-items-center">
                                    <img src={user} alt="a woman" />
                                    <div className="user-info">
                                        <p className='name'>{display_name}</p>
                                        <p className='dob'>{dob}</p>
                                    </div>
                                </div>
                                <img src={birthdayMix} className="birthday-mix" alt="birthday mix with candle icon" />
                            </div>
                        )
                    })
                }
                {
                    dobData?.length === 0 && <img src={loading} alt="" width={40} />
                }
            </div>
        </div>
    </div>
  )
}

export default BirthdayCard