import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import next from '../../../assets/down-arrow.png';
import '../CasaAccounts/casaAccounts.css';
import '../CasaDocument/pendingApproval.css';
import view from '../../../assets/visibility.png';
import { deferralBaseurl } from '../../../common/apis/userApi';
import axios from 'axios';
import search from '../../../assets/search.svg';

const PendingApproval = () => {

    const low = {
        backgroundColor : '#DEFCF8',
        color : "rgb(76 156 143)",
        borderRadius : "20px",
        textAlign : "center"
    }
    const high = {
        backgroundColor : 'rgb(242 215 224)',
        color : '#F094A9',
        borderRadius : "20px",
        textAlign : "center"
    }

    const [pendingDef, setPendingDef] = useState([]);

    useEffect(() => {
        const fetchingData = async () => {
            const query = await axios.get(`${deferralBaseurl}/pendingdeferral`);
            const result = query.data;
            setPendingDef(result);
        }
        fetchingData();
    },[])

    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : pendingDef.next,
        })
        const data = response.data;
        setPendingDef(data);
        window.scrollTo(0, 0);
    }
    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : pendingDef.previous,
        })
        const data = response.data;
        setPendingDef(data);
        window.scrollTo(0, 0);
    }

    const pendingData = pendingDef.results;

    //page count
    let totalCounts = Math.ceil(pendingDef.count/10);
    let currentCount = ''
    if(pendingDef.next != null){
        let next = pendingDef.next;
        currentCount = next.split("=")[1] - 1;
    }else if(pendingDef.next == null){
        currentCount = totalCounts;
    }

  return (
    <div className='pending-approval casa-account casa-stafflist'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="pending-list-wrapper">
                <h3 className='mb-3'>Pending Deferrer's Requests</h3>
                <div className="search-staff-box mb-3">
                    <form>
                        <input type="search" placeholder='filter' />
                        <img src={search} alt="" />
                    </form>
                </div>
                <div className="pending-list">
                    <div className="pending-table bg-white">
                        <div className="pending-titles px-3">
                            <h4>Pending Document</h4>
                            <h4 className='text-center'>Deferral Days</h4>
                            <h4 className='text-center'>Approval Status</h4>
                            <h4 className='text-center'>Action</h4>
                        </div>
                        {
                            pendingData && pendingData.map(({id, approved, customerInfo, date_created, date_elapse, docname, approving_staffname, timeline}) => {
                                
                                return(
                                    <div className="data-wrapper px-3" key={id}>
                                        <div className="pending-data">
                                            <p className='doc-name'>
                                                { docname } <br></br>
                                                {`for ${customerInfo[0].firstname} ${customerInfo[0].lastname}`}
                                            </p>
                                            <p className='date text-center'>{`${date_created} - ${date_elapse}`} <br></br><span>0 days left</span></p>

                                            <p className='text-center'>
                                                <span className='status' style={approved ? low : high}>{approved === true ? "Approved" : "pending approval"}</span>
                                            </p>

                                            <span className='edit text-center' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-icon${id}`} aria-expanded="false" aria-controls="collapseExample">
                                                <img src={next} alt="" />
                                            </span>  
                                        </div>
                                        <div className='collapse mt-4' id={`edit-icon${id}`}>
                                            <div className="account-detail">
                                                <div className="account-headings">
                                                    <h5>Assignee</h5>
                                                    <h5 className='text-center'>Timeline</h5>
                                                    <h5 className='text-center'>Status</h5>
                                                    <h5 className='text-center'>Action</h5>
                                                </div>
                                                <div className="account-data">
                                                    <p>{approving_staffname}</p>
                                                    <p className='text-center'>{timeline}</p>
                                                    <p className='text-center'>{approved === true ? "Approved" : "pending approval"}</p>
                                                    <span className='text-center'>
                                                        <img src={view} alt="" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            pendingData?.length < 1 && <p className='mt-4 no-records'>No record found</p>
                        }
                    </div>
                </div>
                {
                    totalCounts > 1 && <>
                        <div className="nav-btns mt-5">
                            <button onClick={fetchingPrev}>Prev</button>
                            <button onClick={fetchingNext}>Next</button>
                        </div>
                        <p className='pb-4 text-center'>{`Page ${currentCount} of ${totalCounts}`}</p>
                    </>
                }
                
            </div>
        </div>
    </div>
  )
}

export default PendingApproval