import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import team from '../../assets/teams.svg';
import innovation from  '../../assets/frameColla.svg';
import frame1 from '../../assets/frame1.svg';
import frame2 from '../../assets/frame2.svg';
import frame3 from '../../assets/framecert.svg';

const Valuecard = () => {
  return (
    <div>
        <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={true} showArrows={false}>
            <div className="item">
                <img src={team} alt="" />
                <h4>Teamwork </h4>
                <p> We foster an entrepreneurial culture that recognizes individual effort while encouraging teamwork</p>
            </div>
            <div className="item">
                <img src={frame1} alt="" />
                <h4>Integrity</h4>
                <p> We have a strong moral principle that guide our actions, expectations and methods</p>
            </div>
            <div className="item">
                <img src={frame2} alt="" />
                <h4>Respect</h4>
                <p> We promote courtesy, fairness and respect to internal and external customers at all times</p>
            </div>
            <div className="item">
                <img src={frame3} alt="" />
                <h4>Accountability</h4>
                <p> We take responsibility as a unit, and strive to perform as individuals, as a group</p>
            </div>
            <div className="item">
                <img src={innovation} alt="" />
                <h4>Innovation</h4>
                <p> We are the forefront of creating new products, systems, and processes to add value to the life of our clients</p>
            </div>
            <div className="item">
                <img src={team} alt="" />
                <h4>Excellence</h4>
                <p>We strive to creatively deliver exceptional service to our clients</p>
            </div>
        </Carousel>
    </div>
  )
}

export default Valuecard;