import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.css";
import logo from "../../assets/logo.svg";
import core from "../../assets/Group 1.png";
import shapes from "../../assets/Frame.svg";
import arrow from "../../assets/Polygon.png";
import star from "../../assets/Star 1.svg";
import circle from "../../assets/Ellipse 1.png";
import { Link, useNavigate } from "react-router-dom";
import {
  loginUser,
  saveRole,
  saveUser,
  userSelector,
} from "../../feature/Login/loginSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { VERIFY_USER } from "../../graphql/mutations/verifyUser";
import TextInput from "../../common/TextInput/TextInput";
import FormButton from "../../common/FormButton/FormButton";

const Login = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { user, errorMessage, token, isFetching } = useSelector(userSelector);

  const [verifyUser, { error }] = useMutation(VERIFY_USER);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Password required."),
      code: Yup.string().required("OTP required."),
    }),
    isInitialValid: false,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    },
  });

  useEffect(() => {
    if (errorMessage && isFetching === false) {
      toast.dismiss();
      toast.error(errorMessage);
      formik.setSubmitting(false);
    }
  }, [errorMessage, isFetching]);

  useEffect(() => {
    if (token && !user) {
      getCurrentUser();
      getUserRole();
    }
  }, [token, user]);

  useEffect(() => {
    if (token && user) {
      history("/dashboard");
    }
  }, [token, user, history]);

  const getCurrentUser = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://irsapi.koinsbank.com/api/v3/current_user",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      dispatch(saveUser(data.user_details));
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const getUserRole = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://irsapi.koinsbank.com/api/v3/user-roles-core",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      dispatch(saveRole(data));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, onSubmitProps) => {
    verifyUser({
      variables: {
        code: values.code,
        email: values.email,
        type: "oauth",
      },
    }).then(({ data: { verifyUser } }) => {
      const { status, statusCode } = verifyUser;
      if (statusCode === 200 && status === true) {
        dispatch(loginUser(values));
      } else if (statusCode === 400) {
        toast.error("Invalid OTP Code");
        onSubmitProps.setSubmitting(false);
      }
    });

    if (error) {
      console.log(error);
    }
    // dispatch(loginUser(values))
  };

  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="form-card">
          <ToastContainer closeButton={false} position="top-right" />
          <div className="form-wrapper">
            <div className="logo">
              <img src={logo} alt="primera logo" />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <p>kindly login with your username and password.</p>
              <TextInput
                type="email"
                placeholder="email address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                style={{
                  border:
                    formik.touched.email && formik.errors.email
                      ? "1px solid rgb(245, 102, 102)"
                      : "1px solid #A1A1A1",
                }}
              />
              <TextInput
                type="password"
                placeholder="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                style={{
                  border:
                    formik.touched.password && formik.errors.password
                      ? "1px solid rgb(245, 102, 102)"
                      : "1px solid #A1A1A1",
                }}
              />
              <TextInput
                type="password"
                placeholder="OTP code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                style={{
                  border:
                    formik.touched.code && formik.errors.code
                      ? "1px solid rgb(245, 102, 102)"
                      : "1px solid #A1A1A1",
                }}
              />

              <FormButton
                type="submit"
                buttonText="Sign in"
                loading={formik.isSubmitting}
                disable={!formik.isValid || formik.isSubmitting}
              />

              <Link to="/forgot-password" className="forget">
                Forgot Password?
              </Link>

              <button className="register">
                <Link to="/activate-2fa">Activate 2FA</Link>
              </button>
              <button className="register">
                <Link to="/register">
                  New on IRS, Please click here to register.
                </Link>
              </button>
            </form>
          </div>
        </div>
        <div className="detail-card">
          <div className="content">
            <h3>
              Welcome to Primera <br></br>{" "}
              <span>Internal Resource Stream (IRS)</span>
            </h3>
            <img src={core} alt="primera core values" className="core" />
            <p className="core-text">
              Our core <br></br> values
            </p>
          </div>
          <div className="shapes">
            <img src={shapes} alt="" className="circle" />
            <img src={arrow} alt="" width="20px" className="arrow" />
            <img src={circle} alt="" className="shade" />
            <img src={star} alt="" className="star" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
