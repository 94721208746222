import React, { useEffect } from 'react';
import '../staff/staff.css';
import { useDispatch } from 'react-redux';
import {  useSelector } from 'react-redux';
import {  userSelector } from '../../feature/Login/loginSlice';
import { menuSelector, fetchingMenu } from '../../feature/Menus/Menus';
import DashBoardIRSComponent from '../../components/DashboardIRSComponent/DashBoardIRSComponent';

const Staff = () => {

    const dispatch = useDispatch();
    const { menu } = useSelector(menuSelector)
    const { token } = useSelector(userSelector)

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [token, dispatch])

    const staffChildren = menu && menu.filter(item => item.parent_id === 1 && item.is_parent === 0);
    
  return (
        <div className='staff'>
            <DashBoardIRSComponent data={staffChildren} title="STAFF"/>
        </div>
  )
}

export default Staff