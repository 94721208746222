import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import '../CreateTicketForm/createTicketForm.css';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { irsBaseUrl } from '../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../common/button/button';
import KYCTAB from '../../pages/KYC/KYCTAB';
import { convertStaff } from '../../utilities/convertArray';

const CreateTicketForm = () => {

    const history = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const { token } = useSelector(userSelector);
    const [dept, setDept] = useState([])
    const [unit, setUnit] = useState([]);
    const [categories, setCategories] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [staff, setStaff] = useState([]);
    const [imageUrl, setimageUrl] = useState(null);

    const [ticket, setTicket] = useState({
        dept : "",
        unit : "",
        priority : "",
        category : "",
        subject : "",
        description : "",
        checklist : "",
        additionalEmail : "",
        is_on_behalf : ""
    })
    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type" : "application/json",
        }
    })  

    useEffect(() => {
        const fetchingData = async () => {
            const query = await authAxios.get(`${irsBaseUrl}/staff/department`);
            const data = query.data;
            if(query.status === 200){
                setDept(data)
            }else{
                console.log('error')
            }

            const response = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`);
            const newData = response.data;
            if(response.status === 200){
                setUnit(newData.data);
            }

            const category = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-category`);
            const results = category.data;
            setCategories(results);

            const checklistData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-checklist`);
            const allChecklist = checklistData.data;
            setChecklist(allChecklist.data);

            const staffs = await authAxios.get(`${irsBaseUrl}/staff/all`)
            const staffList = staffs.data;
            setStaff(staffList.data)
        }
        fetchingData();
    },[token])

    const imageChange = (e) => {
        setimageUrl(e.target.files[0])
    }

    const handleChange = (e) => {
        setTicket({...ticket, [e.target.name] : e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true)
        const { subject, category, dept, unit, priority, description, additionalEmail } = ticket;

        const newData = new FormData();
        newData.append('title', subject);
        newData.append('department_id', dept);
        newData.append('unit_id', unit);
        newData.append('category_id', category);
        newData.append('is_on_behalf', 0);
        newData.append('additional_user', additionalEmail);
        newData.append('priority', priority);
        newData.append('message', description);
        newData.append('attachment', imageUrl);

        try {
            const uploadingTicket = await authAxios.post(`${irsBaseUrl}/interdepartmental/tickets/store`, newData);
            const response = uploadingTicket.data;
            if(response.status === true){
                const apiMsg = response.message;
                toast.success(apiMsg);
                setDisabled(false)
                setTimeout(() => {
                    history('/helpdesk/ticket/history')
                }, 2000)
            }
            console.log(response)
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data;
            toast.error(apiMsg.message);
            console.log(apiMsg);
        } 
    }

    const handleChange1 = (e) => {
        setTicket({...ticket, dept : e.target.value, unit : "", category : ""});
    }

    const handleChange2 = (e) => {
        setTicket({...ticket, unit : e.target.value, category : ""});
    }

    const staffList = convertStaff(staff);

  return (
      <div className="create-wrapper">
        <KYCTAB menuFor="Prime Serve"/>
    <div className='create-ticket-form'>
        <h3>Create a new ticket</h3>
        <div className="row">
            <div className="col-lg-9 col-sm-12">
                <ToastContainer closeButton={true} position="top-right" autoClose={1000}/>
                <form onSubmit={handleSubmit}>
                    <div className="select-category d-flex align-items-center justify-content-between">
                        <div className="form-group">
                            <label htmlFor="">DEPARTMENT</label>
                            <select name="dept" value={ticket.dept} onChange={handleChange1} required>
                                <option value="" disabled>Select an option</option>
                                {
                                    dept && dept.map(({id, name}) =>{
                                        return <option key={id} value={id}>{name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">SUB DEPARTMENT</label>
                            <select name="unit" value={ticket.unit} onChange={handleChange2} required>
                                <option value="" disabled>Select an option</option>
                                {
                                    unit && unit?.filter(item => item.department_id === Number(ticket.dept))
                                    .map(({id, name}) => {
                                        return <option key={id} value={id}>{name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">PRIORITY</label>
                            <select name="priority" value={ticket.priority} onChange={handleChange} required>
                                <option value="" disabled>select</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">CATEGORY</label>
                        <select name="category" value={ticket.category} onChange={handleChange} required>
                            <option value="" disabled>Select a category</option>
                            {
                                categories?.filter(item => item.unit_id === Number(ticket.unit)).map(({id, name, status}) => {
                                    return(
                                        <option value={id} key={id}>{name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">CHECKLIST</label>
                        <p className='info'>Please select only checklist(s) that matches your ticket task</p>
                        {
                            checklist && checklist?.filter(item => item.category_id === Number(ticket.category))
                            .map(({id, title}) => {
                                
                                return(
                                    <div className="checklist mt-2 d-flex align-items-center" key={id}>
                                        <input type="checkbox" name="checklist" value={ticket.checklist} onChange={handleChange}/>
                                        <label htmlFor="">{title}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Subject</label>
                        <input type="text" placeholder='Ticket title' name='subject' value={ticket.subject} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">DESCRIPTION</label>
                        <textarea name="description" value={ticket.description} onChange={handleChange} cols="10" rows="4" placeholder='Enter your message' required></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">ADDITIONAL EMAIL <span>(who else would you want to notify of this ticket?)</span></label>
                        <Select
                            name="additionalEmail"
                            options={staffList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onFocus={false}
                            onChange={(data) => setTicket({...ticket, additionalEmail : data.value})} 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">ARE YOU CREATING THIS TICKET FOR EXTERNAL CUSTOMER?</label>
                        <div className="select d-flex align-items-center">
                            <label htmlFor="">No</label>
                            <input type="radio" name="is_on_behalf" />
                        </div>
                        <div className="select d-flex align-items-center">
                            <label htmlFor="">Yes</label>
                            <input type="radio" name="external" id="" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Upload a File</label>
                        <input type="file" onChange={imageChange}/>
                    </div>
                    <Button type='submit' value='Create Ticket' disabled={disabled}/>
                </form>
            </div>
        </div>
    </div>
    </div>
  )
}

export default CreateTicketForm;