import React, { useState } from 'react';
import { Drawer } from 'antd';
import hamburger from '../../assets/hamburger.png'
import '../MobileMenu/MobileMenu.css'
import menu1 from '../../assets/menu1.png'
import { NavLink } from 'react-router-dom'
import ticket from '../../assets/document.png';
import pending from '../../assets/pending.png'
// import '../../pages/KYC/kyc-tags.css'

const MobileMenu = ({menuFor}) => {

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    let activeStyle = {
        background : "white",
        color : "#093263",
        width : "100%",
    }


  return (
    <div className='mobile-menu'>
        <img src={hamburger} alt="hamburger" width="26px" onClick={showDrawer}/>
        <Drawer title={menuFor} placement="right" onClose={onClose} open={open}>
        {
            menuFor === "Prime Serve" && <div className="list">
            <NavLink to='/prime-serves/dashboard'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={menu1} width="20px" alt="" />
                <span>Dashboard</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket/new'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={ticket} width="20px" alt="" />
                <span>Create new ticket</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket-assigned-to-me'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={ticket} width="20px" alt="" />
                <span>My Asssigned Tickets</span>
            </NavLink>
            <NavLink to='/helpdesk/ticket/history'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
            }>
                <img src={pending} width="20px" alt="" />
                <span>My Tickets History</span>
            </NavLink>
        </div>
        }
        {
            menuFor === "Corporate KYC" && <div className="list">
            <NavLink to='/kyc/home'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
                }
            >
                <img src={menu1} alt="" />
                Create KYC
            </NavLink>
            <NavLink to='/kyc/accounts'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span >All Kyc Requests</span>
            </NavLink>
            <NavLink to='/kyc/request-history'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>RO History</span>
            </NavLink >
            <NavLink to='/kyc/supervisor'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>Supervisor Request</span>
            </NavLink>
            <NavLink to='/kyc/data-entry-request'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>Data Entry Request</span>
            </NavLink>
        </div>
        }
        {
            menuFor === "Individual KYC" && <div className="list">
            <NavLink to='/kyc-individual'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
                }
            >
                <img src={menu1} alt="" />
                Create KYC
            </NavLink>
            <NavLink to='/kyc/requester'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span >All Kyc Requests</span>
            </NavLink>
            <NavLink to='/individual/my-history'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>My Request History</span>
            </NavLink >
            <NavLink to='/individual/supervisorlist'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>Supervisor Request</span>
            </NavLink>
            <NavLink to='/individual/branchlist'
                style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }
            >
                <img src={menu1} alt="" />
                <span>Data Entry Request</span>
            </NavLink>
        </div>
        }
        </Drawer>
    </div>
  )
}

export default MobileMenu