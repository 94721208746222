import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import '../DocumentType/Document-type.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { documentBaseUrl } from '../../common/apis/userApi';
import { convertDepartment, convertStaff } from '../../utilities/convertArray';

const DocumentType = ({ handleChange, values, setValues, department, office, setValid}) => {

    const [doctype, setDoctype] = useState([]);

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const query = await axios({
                    method : "GET",
                    url : `${documentBaseUrl}/doctype`
                })
                const response = query.data;
                setDoctype(response.results);
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchingData();
    },[])

    if(values.docType !== "" && values.memoType !== "" && values.subject !== ""){
        setValid(true)
    }

    const departmentlist = convertStaff(department)
    const officeList = convertDepartment(office)

  return (
    <div className='document-type'>
        <ToastContainer closeButton={false} position="top-right" />
        <div className="content">
            <div className="doc d-flex">
                <div className="form-group">
                    <div className="inner-wrapper d-flex flex-column">
                        <label htmlFor=""> Select Document Type</label>
                        <select name="docType" 
                        value={values.docType} 
                        onChange={handleChange} 
                        >
                            <option value="" disabled></option>
                            {
                                doctype.map(({id, documentName}) => {
                                    return <option key={id} value={id}>{documentName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <div className="inner-wrapper d-flex flex-column">
                        <label htmlFor="">Select Document Classification</label>
                        <select name="memoType" 
                        value={values.memoType} 
                        onChange={handleChange}
                        >
                            <option value="" disabled></option>
                            <option value="Internal Use Only">Internal Use Only</option>
                            <option value="Restricted Use Only">Restricted</option>
                            <option value="Confidential Use">Confidential</option>
                            <option value="Public Use Only">Public</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="">From</label>
                <Select
                    name="from"
                    options={departmentlist}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onFocus={false}
                    onChange={(data) => setValues({...values, from : data.value, fromData : data.label})} 
                />
                {/* <div className="input-wrapper w-60">
                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                    <input list="data" id='input' onInput={handleInput} placeholder="search department" />
                </div>
                <datalist id="data">
                    {department.map(({id, name}) =>
                        <option key={id} value={name} id={id}>{name}</option>
                    )}
                </datalist> */}
            </div>
            <div className="form-group">
                <label htmlFor="">To</label>
                <Select
                    name="to"
                    options={officeList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) =>  setValues({...values, to : data.value, toData : data.label})} 
                />
                {/* <div className="input-wrapper w-60">
                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                    <input list="dataTo" id='input2' onInput={handleInput2} placeholder="search offices" />
                </div>
                <datalist id="dataTo">
                    {office.map(({id, office_name}) =>
                        <option key={id} value={office_name} id={id}>{office_name}</option>
                    )}
                </datalist> */}
            </div>
            <div className="form-group">
                <label htmlFor="">Subject</label>
                <input type="text" name="subject" 
                value={values.subject} 
                onChange={handleChange} 
                required/>
            </div>
        </div>
    </div>
  )
}

export default DocumentType