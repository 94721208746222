import React from 'react'
import Button from '../../../common/button/button'
import { toast } from 'react-toastify'
import axios from 'axios'
import { casaBaseurl } from '../../../common/apis/userApi'
import { useNavigate } from 'react-router-dom'


const BranchRejectModal = ({newData, user, handleReject, rejection, disabled}) => {

    const history = useNavigate();
    const rejectBranch = async (e) => {
        e.preventDefault()
        const jsonData = {
            'reason' : ""
        }
        try {
            const approving = await axios.post(`${casaBaseurl}branchreject/${user.id}/${newData?.id}`, jsonData);
            const result = approving.data
            if(result.code === 200){
                toast.success(result.body.data)
                setTimeout(() => {
                    history('/customer/all')
                }, 2000)
            }
        } catch (err) {
            const errorMessage = err.response.data;
            console.log(errorMessage)
            if(errorMessage.code == 400){
                toast.error(errorMessage.body.data);   
            }else if(errorMessage.code === "ERR_BAD_REQUEST"){
                toast.error(errorMessage); 
            }else{
                console.log(errorMessage);
            }
        }   
    }

  return (
    <div className="modal fade" id='branchrejectModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reject Application by branch</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form onSubmit={rejectBranch} >
                        <div className="form-group">
                            <label htmlFor="">Enter Reason for rejection</label><br></br>
                                <textarea onChange={handleReject} value={rejection} className="w-100  p-3"/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <Button value="Reject Request" type="submit" disabled={disabled}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BranchRejectModal