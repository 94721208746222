import axios from 'axios';
import React, { useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import search from '../../../assets/Vector (2).svg';
import tick from '../../../assets/Vector (3).svg';
import pending from '../../../assets/Vector (4).svg';
import decline from '../../../assets/Vector (5).svg';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { irsAuthBaseUrl } from '../../../common/apis/userApi';
import { userSelector } from '../../../feature/Login/loginSlice';
import './TopMenu.css';

const TopMenu = ({setAccounts, setloading, type, setpageCount}) => {

    const [allApproved, setApproved] = useState([]);
    const [allRejected, setRejected] = useState([]);
    const [allPending, setPending] = useState([]);
    const [searchword, setSearchword] = useState("");

    const { token } = useSelector(userSelector)

    const fetchingCounts = async () => {
        try {
            if(type === "corporate"){
                const allPending = await getAxiosInstance(token).get('/kyc/corporate-account/application_status/Pending')
                const result = allPending.data;
                setPending(result.meta)
    
                const allApproved = await getAxiosInstance(token).get('/kyc/corporate-account/application_status/Approved')
                const approve_data = allApproved.data
                setApproved(approve_data.meta)
    
                const allRejected = await getAxiosInstance(token).get('/kyc/corporate-account/application_status/Rejected')
                const response = allRejected.data
                setRejected(response.meta)

            }else if(type === "individual"){
                const allPending = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/application_status/Pending`)
                const result = allPending.data;
                setPending(result.meta)

                const allApproved = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/application_status/Approved`)
                const approve_data = allApproved.data
                setApproved(approve_data.meta)

                const allRejected = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/application_status/Rejected`)
                const response = allRejected.data
                setRejected(response.meta)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = async () => {
        try {
            setloading(true)
            if(type === "corporate") {
                const searching = await getAxiosInstance(token).get(`kyc/corporate-account/find/${searchword}`);
                const result = searching.data;
                setAccounts(result.data);
                setpageCount(result.meta.total)
            }else if(type === "individual"){
                const searching = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/find/${searchword}`);
                const result = searching.data;
                setAccounts(result.data)
                setpageCount(result.meta.total)
            }
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }

    const handleChange = (e) => {
        setSearchword(e.target.value)
    }

    useEffect(() => {
        fetchingCounts()
    },[])

  return (
    <div className='top-menu d-flex align-items-center justify-content-between py-3 px-4'>
        <div className="search-box d-flex">
            <input type="text" placeholder='Search by customer name' value={searchword} onChange={handleChange}/>
            <button onClick={() => handleSearch()}>
                <img src={search} alt="" />
                <span>Search</span>
            </button>
        </div>
        <div className="summary d-flex">
            <p>Summary :</p>
            <div className="item">
                <img src={tick} alt="" />
                <div className="text">
                    <p>Approved</p>
                    <p>{`${allApproved?.total || 0 } Docs`}</p>
                </div>
            </div>
            <div className="item">
                <img src={pending} alt="" />
                <div className="text">
                    <p>Pending</p>
                    <p>{`${allPending?.total || 0 } Docs`}</p>
                </div>
            </div>
            <div className="item">
                <img src={decline} alt="" />
                <div className="text">
                    <p>Declined</p>
                    <p>{`${allRejected?.total || 0 } Docs`}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopMenu