import React, { useEffect, useState } from 'react'
import { Button, message, Spin, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import KYCTAB from '../KYC/KYCTAB';
import PersonalTags from './PersonalTags';
import '../KYC/EditKyc.css'
import backArrow from '../../assets/Vector (6).svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getAxiosInstance } from '../../common/apis/createAxios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import TextInputTwo from '../../components/TextInputTwo/TextInputTwo';
import axios from 'axios';
import { irsAuthBaseUrl } from '../../common/apis/userApi';


const EditPersonalKyc = () => {

    const history = useNavigate();
    const [data, setData] = useState([]);
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);
    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [dataEntryEmail, setDataEntryEmail] = useState('');
    const { token, user } = useSelector(userSelector);
    const { slug } = useParams();

    useEffect(() => {
        const getRecord = async() => {
            try {
                const kycRecords = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/view/${slug}`); 
                const results = kycRecords.data;
                setData(results.data[0]);    
                
                const allStaff = await getAxiosInstance(token).get(`/staff/active`);
                const response = allStaff.data;
                setStaff(response.data);
            } catch (error) {
                message.error('unable to fetch record... Please refresh page');
            }
        }
        getRecord();
    },[])

    const [formData, setFormdata] = useState({
        title: data.title || "",
        gender: data.gender || "",
        account_name : data.account_name || "",
        business_profession : data.business_profession || "",
        id_type : data.id_type || "",
        id_number : data.id_number || "" ,
        laundering_risk : data.laundering_risk || "",
        pep : data.pep || "",
        estimated_monthly_salary : data.estimated_monthly_salary || "",
        customer_type : data.customer_type || "",
        kyc_level : data.kyc_level || "",
        type_of_acct : data.type_of_acct || "",
        documentation_checked : data.documentation_checked || "",
        utility_bill : data.utility_bill || "",
        utility_file : data.utility_bill || "",
        address_visited : data.address_visited || "",
        comment_on_location_landmarks : data.comments_on_location_landmarks || "",
        authorized_capital : data.authorized_capital || "",
        color_of_building: data.color_of_building || "",
        building_description : data.building_description || "",
        name_of_visiting_staff : data.name_of_visiting_staff || "",
        date_of_visit : data.date_of_visit || "",
        sales_officer_id : data.sales_officer_id || "",
        supervisor_officer : data.supervisor_officer || "",
        estimated_annual_salary : data.estimated_annual_salary || "",
        ro_signature : data.ro_signature || ""
    })
    
    useEffect(() => {
        if(data.length !== 0){
            setFormdata({...formData, title: data.title, gender : data.gender, account_name : data.account_name, business_profession : data.business_profession, id_type : data.id_type, id_number : data.id_number, laundering_risk : data.laundering_risk, pep : data.pep, estimated_monthly_salary : data.estimated_monthly_salary, customer_type : data.customer_type, kyc_level : data.kyc_level, type_of_acct : data.type_of_acct, documentation_checked : data.documentation_checked, utility_bill : data.utility_bill, utility_file : data.utility_file, address_visited : data.address_visited, comment_on_location_landmarks : data.comment_on_location_landmarks, color_of_building : data.color_of_building, building_description : data.building_description, name_of_visiting_staff : data.name_of_visiting_staff, date_of_visit : data.date_of_visit, sales_officer_id : data.sales_officer_id, supervisor_officer : data.supervisor_officer, estimated_annual_salary : data.estimated_annual_salary, ro_signature : data.ro_signature})
        }

    },[data])
    
    useEffect(() => {
        if(formData.supervisor_officer !== ""){
            const selectedStaff = staff.filter(item => item.id == formData.supervisor_officer)
            setSupervisorEmail(selectedStaff[0]?.staff?.email)
        }
    },[formData.supervisor_officer])
    
    useEffect(() => {
        if(formData.sales_officer_id !== ""){
            const selectedStaff = staff.filter(item => item.id == formData.sales_officer_id)
            setDataEntryEmail(selectedStaff[0]?.staff?.email)
        }
    },[formData.sales_officer_id])

    const handleChange = (e) => {
        setFormdata({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true)
        const values = formData;

        const formData2 = new FormData();
        formData2.append("title", values.title);
        formData2.append("gender", values.gender)
        formData2.append("account_name", values.account_name)
        formData2.append("business_profession", values.business_profession)
        formData2.append("id_type", values.id_type)
        formData2.append("id_number", values.id_number)
        formData2.append("laundering_risk", values.laundering_risk)
        formData2.append("pep", values.pep)
        formData2.append("estimated_monthly_salary", values.estimated_monthly_salary)
        formData2.append("customer_type", values.customer_type)
        formData2.append("kyc_level", values.kyc_level)
        formData2.append("type_of_acct", values.type_of_acct)
        formData2.append("documentation_checked", values.documentation_checked)
        formData2.append("utility_bill", "newy")
        formData2.append("utility_file", values.utility_file)
        formData2.append("address_visited", values.address_visited)
        formData2.append("comment_on_location_landmarks", values.comment_on_location_landmarks);
        formData2.append("color_of_building", values.color_of_building)
        formData2.append("building_description", values.building_description)
        formData2.append("name_of_visiting_staff", values.name_of_visiting_staff)
        formData2.append("date_of_visit", values.date_of_visit)
        formData2.append("ro_id", user.id.toString())
        formData2.append("ro_signature", values.ro_signature)
        formData2.append("ro_comment", "Okay")
        formData2.append("sales_officer_id", values.sales_officer_id)
        formData2.append("supervisor_officer", values.supervisor_officer)
        formData2.append("estimated_annual_salary", values.estimated_annual_salary)


        try {
            const editForm = await getAxiosInstance(token).post(`kyc/fixed-deposit/update/${slug}`, formData2);
            const result = editForm.data;
            console.log(result)
            if(result.status === "success"){
                message.success(result.message)
                setTimeout(() => {
                    history(-1)
                },1500)
            }
        } catch (error) {
            setLoading(false)
            const responseError = error.response.data.message
            if(responseError?.company_name){
                message.error(responseError?.company_name[0])
            }
            if(responseError?.company_email){
                message.error(responseError?.company_email[0])
            }
            if(responseError?.initial_deposit){
                message.error(responseError?.initial_deposit[0])
            }
            if(responseError?.rc_number){
                message.error(responseError?.rc_number[0])
            }
            if(responseError?.telephone){
                message.error(responseError?.telephone[0])
            }
            if(responseError?.tin){
                message.error(responseError?.tin[0])
            }
            if(responseError?.ro_signature){
                message.error(responseError?.ro_signature[0])
            }
        } 
    }

    const dummyRequest = async ({ file, onSuccess }) => {    
        setTimeout(() => {
           onSuccess("ok");
        }, 0);
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: dummyRequest,
        beforeUpload : (info) => {
            setFormdata({...formData, utility_file : info})
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file selected`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadFileButton = (
        <div style={{
            border : "1px solid #cccccc", 
            borderRadius : "4px", 
            display : 'flex', 
            alignItems : "center",
            padding : ".3em 3em"
            }}>
            <Button icon={<UploadOutlined />} style={{marginTop : 0, marginRight : "10px"}}></Button>
            <div style={{background : "none"}}>
                Upload
            </div>
      </div>
    );

    // const beforeFileUpload =  (file) => {
    //     setFormdata({...formData, utility_file : file})
    // }

    const uploadButton = (
        <div>
            <Button icon={<PlusOutlined />}></Button>
            <div style={{ marginTop: 8}}>
            Upload
            </div>
      </div>
    );

    const beforeUpload =  (file) => {
        setFormdata({...formData, ro_signature : file})
    }


  return (
    <div className='edit-kyc'>
        <PersonalTags />
        <div className="kyc-wrapper">
            <KYCTAB menuFor={"Individual KYC"}/>
            <div className="edit-main-wrapper">
                <div className="heading">
                    <div className="back-arrow d-flex align-items-center mb-3">
                        <img src={backArrow} alt=""  onClick={() => history(-1)}/>
                        <h3 className='mb-0 ml-3'>Edit Customer’s Data</h3>
                    </div>
                </div>
                <div className="kyc-editform-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <select value={formData.title} name="title" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="Mr" >Mr</option>
                                        <option value="Miss" >Miss</option>
                                        <option value="Mrs" >Mrs</option>
                                        <option value="Dr" >Dr</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Gender</label>
                                    <select value={formData.gender} name="gender" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="Male" >Male</option>
                                        <option value="Female" >Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Account Name'
                                type="text" 
                                placeholder='Enter company name' 
                                name='account_name' 
                                value={formData.account_name} 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">ID Type</label>
                                    <select value={formData.id_type} name="id_type" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="Driver's Licence" >Driver's Licence</option>
                                        <option value="National ID" >National ID</option>
                                        <option value="Voter's Card" >Voter's Card</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='ID Number'
                                    type="text" 
                                    placeholder='' 
                                    name='id_number' 
                                    value={formData.id_number} 
                                    onChange={handleChange} 
                                
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">ANTI MONEY LAUNDERING RISK RATING</label>
                                    <select value={formData.id_type} name="laundering_risk" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="High" >High</option>
                                        <option value="Medium" >Medium</option>
                                        <option value="Low" >Low</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Estimated Yearly salary'
                                    type="text" 
                                    placeholder='' 
                                    name='estimated_annual_salary' 
                                    value={formData.estimated_annual_salary} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Politically Exposed person</label>
                                    <select name="pep" value={formData.pep} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Yes" >Yes</option>
                                        <option value="No" >No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Estimated Monthly salary'
                                    type="text" 
                                    placeholder='' 
                                    name='estimated_monthly_salary' 
                                    value={formData.estimated_monthly_salary} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Customer Type</label>
                                    <select name="customer_type" value={formData.customer_type} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Walked-in" >Walked-in</option>
                                        <option value="Marketed" >Marketed</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">KYC Level</label>
                                    <select name="kyc_level" value={formData.kyc_level} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Level 1" >Level 1</option>
                                        <option value="Level 2" >Level 2</option>
                                        <option value="Level 3" >Level 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Account Type</label>
                                    <select name="type_of_acct" value={formData.type_of_acct} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Saving" >Saving</option>
                                        <option value="Joint" >Joint</option>
                                        <option value="Fixed deposit" >Fixed deposit</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Documentation Checked</label>
                                    <select name="documentation_checked" value={formData.documentation_checked} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Yes" >Yes</option>
                                        <option value="No" >No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Utility bill selected</label>
                                    <select name="utility_bill" value={formData.utility_bill} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Yes" >Yes</option>
                                        <option value="No" >No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group"
                                style={{display : "flex", flexDirection : "column"}}
                                >
                                    <label htmlFor="">Utility File</label>
                                    <Upload
                                        {...props}
                                        name="avatar"
                                        // beforeUpload={beforeFileUpload}
                                        >
                                        {
                                            uploadFileButton
                                        }
                                    </Upload>
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Address visited'
                                    type="text" 
                                    placeholder='' 
                                    name='address_visited' 
                                    value={formData.address_visited} 
                                    onChange={handleChange} 
                                />
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='comment_on_location_landmark'
                                    type="text" 
                                    placeholder='' 
                                    name='comment_on_location_landmarks' 
                                    value={formData.comment_on_location_landmarks} 
                                    onChange={handleChange} 
                                />
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Color of building'
                                type="text" 
                                placeholder='' 
                                name='color_of_building' 
                                value={formData.color_of_building} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                label='Date of visit'
                                type="date" 
                                placeholder='' 
                                name='date_of_visit' 
                                value={formData.date_of_visit} 
                                onChange={handleChange} 
                                 
                                />
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Building description'
                                type="text" 
                                placeholder='' 
                                name='building_description' 
                                value={formData.building_description} 
                                onChange={handleChange} 
                                
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Name of visiting staff'
                                type="text" 
                                placeholder='' 
                                name='name_of_visiting_staff' 
                                value={formData.name_of_visiting_staff} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Supervisor Name</label>
                                    <select name="supervisor_officer" value={formData.supervisor_officer} onChange={handleChange}>
                                        <option value="" disabled selected>Select suspervisor</option>
                                        {
                                            staff && staff.map(({ staff }) => {
                                                const { name, id} = staff;

                                                return(
                                                    <option value={id} key={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Supervisor Email</label>
                                    <input type="text" disabled value={supervisorEmail} className="email_input"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Data Entry Officer</label>
                                    <select name="sales_officer_id" value={formData.sales_officer_id} onChange={handleChange}>
                                        <option value="" disabled selected>Select entry officer</option>
                                        {
                                            staff && staff.map(({ staff }) => {
                                                const { name, id} = staff;

                                                return(
                                                    <option value={id} key={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Data Entry officer name</label>
                                    <input type="text" disabled value={dataEntryEmail} className="email_input"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">RO signature</label>
                                    <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    beforeUpload={beforeUpload}
                                    >
                                        {
                                            uploadButton
                                        }
                                    </Upload>
                                </div>
                            </div>
                            <div className="w-100"></div>
                        </div>
                        <button type='submit' disabled={loading} className="edit-kyc-btn w-100" style={{cursor : loading ? "not-allowed" : "pointer", opacity : loading ? "0.6" : "1"}}>
                            {
                                loading && <Spin />
                            }
                            <span>
                                Edit Request
                            </span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditPersonalKyc
