import axios from 'axios'
import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { documentBaseUrl } from '../../common/apis/userApi';

const CreateDocNameForm = () => {
    const [docname, setDocname] = useState([])

    const handleChange = (e) => {
        setDocname(e.target.value)
    }

    const handleSubmit = async (e)=> {
        e.preventDefault();
        const newform = new FormData();
        newform.append("documentName", docname);

        try {
            const submittingData = await axios({
                url : `${documentBaseUrl}/doctype`,
                method : "POST",
                data : newform
            })
            const response = submittingData.data.response.data;
            console.log(response)
            toast.dismiss();
            toast.success(response.message)
        } catch (error) {
            console.log(error)
            toast.dismiss();
            toast.error(error)
        }
        
    }

  return (
    <div className='createdoc-form'>
        <div className="content">
            <ToastContainer closeButton={false} position="top-right" />
            <div className="form-group">
                <label htmlFor="">Create New Document Type</label>
                <input type="text" placeholder='e.g memo' value={docname} name="docname" required onChange={handleChange}/>
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    </div>
  )
}

export default CreateDocNameForm