import React from 'react';
import '../KoinsPreview/KoinsPreview.css';
import Header from '../../components/header/header'
import KoinsTag from '../../components/koinsTag/KoinsTag'
import KoinsUserPreview from '../../components/KoinsUserPreview/KoinsUserPreview'

const KoinsPreview = () => {
  return (
    <div className='koins-preview'>
        <Header />
        <div className="content">
            <KoinsTag />
            <KoinsUserPreview />
        </div>
    </div>
  )
}

export default KoinsPreview