import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, userSelector } from '../../feature/Login/loginSlice';
import profile from '../../assets/user (1).png';
import dashboard from '../../assets/menu.png';
import logo from '../../assets/image 1.png';
import logout from '../../assets/logout 2.png';
import galary from '../../assets/copy.png';
import login from '../../assets/login.png';
import userlist from '../../assets/key-lock.png';
import '../HomeTags/HomeTags.css';
import { getAxiosInstance } from '../../common/apis/createAxios';

const HomeTags = ({active}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { role, user, token } = useSelector(userSelector);
    const [permission, setPermission] = useState([]);
    
    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(clearState());
        navigate('/');
    }

    const userPermission = role?.find(item => {
        const isAuthorized =  item?.user_id === user?.id;
        return isAuthorized
    })

    const userPermissionId = userPermission?.role_id

    useEffect(() => {
        const fetchingData = async () => {
            const allPermission = await getAxiosInstance(token).get(`/roles-core/view/${userPermissionId}`)
            const result = allPermission.data;
            setPermission(result)
        }

        if(typeof userPermissionId !== 'undefined'){
            fetchingData();
        }
    },[userPermissionId])


    const isPermitted = permission?.find(item => {
        return item.permission_id.toLowerCase().includes("admin")
    })
    
  return (
    <div className="tags__wrapper">
        <div className="tag__list d-flex flex-column justify-content-between">
            <div className="hometags-wrapper">
                <img src={logo} width="80px" alt="primera-logo" className='logo'/>
                <div className="menu-list">
                    <ul>
                        <li style={{ background : active.dashboard ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent"}} >
                            <Link to='/dashboard'>
                                <img src={dashboard} alt="dashboard" style={{opacity : active.dashboard ? "1" : "0.4"}}/>
                                <span style={{color : active.dashboard ? "#0A3061" : "#858A95"}}> Dashboard</span>
                            </Link>
                        </li>
                        <li style={{ background : active.myProfile ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }} >
                            <Link to='/user-profile'>
                                <img src={profile} alt="" style={{opacity : active.myProfile ? "1" : "0.4"}}/>
                                <span style={{color : active.myProfile ? "#0A3061" : "#858A95"}}>My Profile</span>
                            </Link>
                        </li>
                        {
                            isPermitted && <li style={{ background : active.menus ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }}>
                                            <Link to='/menus'>
                                                <img src={galary} alt="" style={{opacity : active.menus ? "1" : "0.4"}}/>
                                                <span style={{color : active.menus ? "#0A3061" : "#858A95"}}>Menus</span>
                                            </Link>
                                        </li>
                        }
                        {
                            isPermitted && <li style={{ background : active.permission ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }}>
                                    <Link to='/permissions'>
                                        <img src={login} alt="" style={{opacity : active.permission ? "1" : "0.4"}}/>
                                        <span style={{color : active.permission ? "#0A3061" : "#858A95"}}>Permissions</span>
                                    </Link>
                                </li>
                        }
                        {
                            isPermitted && <li style={{ background : active.user2fa ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }}> 
                                    <Link to='/2fa-userlist'>
                                        <img src={userlist} alt="" style={{opacity : active.user2fa ? "1" : "0.4"}}/>
                                        <span style={{color : active.user2fa ? "#0A3061" : "#858A95"}}>2FA Userlist</span>
                                    </Link>
                                </li>
                        }
                        
                        {/* <li style={{ background : active.oldirs ? "linear-gradient(90deg,rgba(88, 155, 248, 0.5) .89%,rgba(196,221,255,0))" : "transparent" }}>
                            <Link to='/old-irs'>
                                <img src={profile} alt="" style={{opacity : active.oldirs ? "1" : "0.4"}}/>
                                <span style={{color : active.oldirs ? "#0A3061" : "#858A95"}}>old IRS</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="logout d-flex align-items-center" onClick={handleLogout}>
                <img src={logout} alt="" />
                <p>Logout</p>
            </div>
        </div>
    </div>
  )
}

export default HomeTags