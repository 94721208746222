import React from 'react'
import '../DashboardIRSComponent/DashboardIRSComponent.css';
import BirthdayCard from '../BirthdayCard/BirthdayCard'
import DashboardTabItem from '../DashboardTabItem/DashboardTabItem'
import Header from '../header/header'
import Tag from '../../components/Tag/Tag';


const DashBoardIRSComponent = ({data, title}) => {
  return (
    <div className='irs-component'>
        <div className="irs-wrapper">
            <Header />
            <div className="dashboard-content">
                <div className="inner-container">
                    <Tag />
                    <DashboardTabItem item={data} title={title}/>
                    <BirthdayCard />
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashBoardIRSComponent