import React,{ useState, useEffect } from 'react'
import '../HelpDeskDashboard/HelpDeskDashboard.css';
import HelpDeskComponent from '../../components/HelpDeskComponent/HelpDeskComponent'
import HelpDeskTags from '../../components/HelpDeskTags/HelpDeskTags';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { irsBaseUrl } from '../../common/apis/userApi';
import HelpDesk from '../HelpDesk/HelpDesk';

const HelpDeskDashboard = () => {

  const { token } = useSelector(userSelector);
  const [tickets, setTickets] = useState([]);
  const [pending, setPending] = useState("")
  const [open, setOpen] = useState("")
  const [solved, setSolved] = useState("")
  const [loading, setloading] = useState(false)

  const authAxios = axios.create({
    baseURL : irsBaseUrl,
      headers : {
          Authorization : `Bearer ${token}`,
      }
  }) 

  const fetchingTickets = async () => {
    setloading(true)
      try {
          const queryData = await authAxios.get('/interdepartmental/tickets/paginate');
          const results = queryData.data;
          setTickets(results)

          const pendingCount = await authAxios.get(`/interdepartmental/ticket-status/1`);
          const response = pendingCount.data;
          setPending(response.totalCounts);

          const solvedCount = await authAxios.get(`/interdepartmental/ticket-status/2`);
          const response2 = solvedCount.data;
          setSolved(response2.totalCounts);

          const openCount = await authAxios.get(`/interdepartmental/ticket-status/5`);
          const responseCount = openCount.data;
          setOpen(responseCount.totalCounts);

      } catch (error) {
          console.log(error)
          setloading(false)
      }
  }

  useEffect(() => {
      fetchingTickets()
  }, []);
  

  return (
    <div className='helpdesk-dashobard'>
        <div className="container__">
          {
            (pending === "") ? 
                    <HelpDesk />
                     : <div className="content">
                        <HelpDeskTags />
                        <HelpDeskComponent tickets={tickets} open={open} pending={pending} solved={solved}/> 
                      </div>
          }
        </div>
    </div>
  )
}

export default HelpDeskDashboard