import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CasaDocument/casaDocument.css';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import axios from 'axios';
import { deferralBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';

const CreateCasaDoc = () => {

    const history = useNavigate();
    const [createdoc, setCreatedoc] = useState({
        docname : "",
        days : ""
    })
    const authAxios = axios.create({
        baseURL : deferralBaseurl
    }) 

    const handleChange = (e) => {
        setCreatedoc({...createdoc, [e.target.name] : e.target.value});
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const { docname, days } = createdoc
        const jsonData = {
            "docName": docname,
            "days": days
        }
        try {
            const creatingDoc = await authAxios.post('/deferraldoctype', jsonData);
            const result = creatingDoc.data.body;
            toast.success(result.data);
            setTimeout(() => {
                history('/document/document-types')
            }, 2000);
        } catch (error) {
            console.log(error.response.data)
        }

    }

    console.log(createdoc)
  return (
    <div className='create-casa-doc'>
        <Header />
        <div className="content w-full d-flex">
            <CasaTags />
            <div className="create-doc">
                <ToastContainer closeButton={false} position="top-right" />
                <form action="" onSubmit={handleSubmit}>
                    <h4 className='mb-4'>Create Document Type</h4>
                    <div className="form-group">
                        <label htmlFor="">Document Name</label>
                        <input type="text"  placeholder='e.g passport' onChange={handleChange} name="docname" value={createdoc.docname}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Days</label>
                        <input type="text"  placeholder='e.g 10' name='days' value={createdoc.days} onChange={handleChange}/>
                    </div>
                    <button type='submit'>Create Document Type</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default CreateCasaDoc