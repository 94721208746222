import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../common/apis/createAxios";


//recursive function
async function getPage(page, token) {
    let response = await getAxiosInstance(token).get(`menus?page=${page}`);
    let data = await response.data;
    return data;
}

async function getPages(startPage, endPage, token) {
    let currentPage = startPage;
    let finalArr = [];
    while(currentPage <= endPage) {
        const pageData = await getPage(currentPage, token);
        finalArr.push(...pageData.data);
        currentPage++;
    }
    
    return finalArr;
}

export const fetchingMenu = createAsyncThunk('menus/list', async(token, thunkAPI) => {

    try {
        return getPages(1, 5, token);
       
    } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error)
    }

})

export const menuList = createSlice({
    name : "menus",
    initialState : {
        menu : null,
        status : "idle",
        isFetching: false,
        errorMessage : "",
    },
    extraReducers : {
        [fetchingMenu.pending] : (state) => {
            state.isFetching = true;
            state.status = "loading";
        },
        [fetchingMenu.fulfilled] : (state, { payload }) => {
            state.isFetching = false;
            state.menu = payload;
            state.status = "success";
            state.errorMessage = "";
        },
        [fetchingMenu.rejected] : (state, { payload }) => {
            state.isFetching = false;
            state.menu = null;
            state.errorMessage = payload.message;
            state.status = "failed";
        }
    }
})

export const menuSelector = state => state.menus;