import React,{ useState, useEffect} from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { irsBaseUrl } from '../../../common/apis/userApi';
import Swal  from 'sweetalert2';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useSelector } from 'react-redux';
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../HelpdeskCreateDepartment/helpdeskdepartment.css';
import spyglass from '../../../assets/search.svg';
import ReactPaginate from 'react-paginate';
import { convertStaff } from '../../../utilities/convertArray';
import EditCategoryModal from './EditCategoryModal';

const AllCategories = () => {

    const { token } = useSelector(userSelector);
    const [categories, setCategories] = useState([]);
    const [department, setDepartment] = useState([])
    const [unit, setUint] = useState([])
    const [staff, setStaff] = useState([]);
    const [searchField, setSearchField] = useState("");

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(5);

    

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const departmentData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-category`);
                const response = departmentData.data;
                setCategories(response);

                const department = await authAxios.get(`${irsBaseUrl}/staff/department`);
                const responseD = department.data;
               setDepartment(responseD)

                const unit = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`);
                const result = unit.data;
                setUint(result.data);

                const staff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const stafflist = staff.data;
                setStaff(stafflist.data);

            } catch (error) {
                console.log(error);
            }
        }
        fetchingData()
    },[])

    useEffect(() => {
  
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(categories.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(categories.length / itemsPerPage));
    
      }, [itemOffset, itemsPerPage, categories, searchField]);
    
      const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % categories.length;
        setItemOffset(newOffset);
    };
  
    const handleChange = (e) => {
        e.persist();
        setSearchField(e.target.value);
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })
        

    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await authAxios.delete(`${irsBaseUrl}/interdepartmental/ticket-category/${id}`);
            const result = removeDoc.data.body;
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            ) .then(() => {
            setTimeout(() => {
                window.location.reload();
            },1000)
        })

        } catch (error) {
            const apiMsg = error.response.data.message;
            toast.error(apiMsg)
        }  
    }

    const handleSearch = () => {
        const filteredList = categories.filter(item => item.name.toLowerCase().includes(searchField.toLowerCase()));
        setCurrentItems(filteredList)
    }
    
    const newStaffList = staff ? convertStaff(staff) : []

  return (
    <div className='all-categories casa-documentlist'>
        <Header />
        <div className="container_">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="document-type-list help-createdepartment p-3">
                    <ToastContainer closeButton={true} position="top-right" />
                    <h3>All Categories</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <div className="search-wrapper">
                            <input type="search" name='searchField' placeholder='filter' value={searchField} onChange={handleChange}/>
                            <img src={spyglass} alt="" onClick={handleSearch}/>
                        </div>
                        <Link to='/helpdesk/create-category'>New Category</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Category name</h5>
                            <h5 className='text-center'>Preferred Resolver</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            currentItems && currentItems.map(({ id, name, unit_id, preferred_resolver, department_id, status, tat }) => {

                                const preferredStaff = staff?.filter(item => item.id == preferred_resolver)[0]
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <p className='colored'>{name}</p>
                                            <p className='text-center'>{preferredStaff?.name}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                                <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target={`#edit-form${id}`}>
                                                    Edit
                                                </span>
                        
                                            </div>
                                        </div>
                                        <EditCategoryModal 
                                        id={id} unit={unit} 
                                        unit_id={unit_id}
                                        department_id={department_id}
                                        status={status}
                                        tat={tat}
                                        name={name}
                                        preferred_resolver={preferred_resolver}
                                        department={department} 
                                        authAxios={authAxios} 
                                        newStaffList={newStaffList}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< prev"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AllCategories