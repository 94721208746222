import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { Button, message, Spin } from 'antd';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import '../KYC/kyc.css';
import KYCTAB from '../KYC/KYCTAB';
import arrow from '../../assets/Vector (1).svg';
import { userSelector } from '../../feature/Login/loginSlice';
import axios from 'axios';
import { irsAuthBaseUrl } from '../../common/apis/userApi';
import { useNavigate } from 'react-router-dom';
import { getAxiosInstance } from '../../common/apis/createAxios';
import PersonalTags from './PersonalTags';
import PersonalInformationData from './PersonalInformationData';
import BusinessInformationData from './BusinessInformationData';
import ContactInformationData from './ContactInformationData';
import AuthorizingOfficers from './AuthorizingOfficer';
import OtherInformation from './OtherInformation';

const PersonalKYC = () => {

    const navigate = useNavigate()
    const [valid, setValid] = useState(false);
    const [current, setCurrent] = useState(0);
    const [staff, setStaff] = useState([]);
    const [opStaff, setOpStaff] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const [billUrl, setBillUrl] = useState();
    const [passport, setPassword] = useState();

    const { token, user } = useSelector(userSelector)

    const formik = useFormik({
        initialValues: {
            title: "",
            gender: "",
            account_name : "",
            business_profession : "",
            id_type : "",
            id_number : "",
            laundering_risk : "",
            pep : "",
            estimated_monthly_salary : "",
            customer_type : "",
            kyc_level : "",
            type_of_acct : "",
            documentation_checked : "",
            utility_bill : "",
            address_visited : "",
            comments_on_location_landmarks : "",
            color_of_building : "",
            building_description : "",
            name_of_visiting_staff: "",
            date_of_visit : "",
            sales_officer_id : "",
            supervisor_officer : "",
            estimated_annual_salary : "",
            bvn : "",
            kin_name : "",
            kin_phone : "",
            kin_address : "",
            kin_relationship : "",
            kin_occupation : "",
            bank_name : "",
            account_number : "",
            customer_phone : "",
            customer_email : "",
            employment_status : "",
        },

        validationSchema: Yup.object({
            title: Yup.string().required('Required'),
            gender: Yup.string().required('required.'), 
            account_name: Yup.string().required('Required.'), 
            business_profession: Yup.string().required('Required.'), 
            id_type: Yup.string().required('Required.'), 
            id_number: Yup.string().required('Required.'), 
            laundering_risk: Yup.string().required('Required.'), 
            pep: Yup.string().required('Required.'), 
            estimated_monthly_salary: Yup.string().required('Required.'), 
            customer_type: Yup.string().required('Required.'), 
            kyc_level: Yup.string().required('Required.'), 
            type_of_acct: Yup.string().required('Required.'), 
            documentation_checked: Yup.string().required('Required.'), 
            utility_bill: Yup.string().required('Required.'), 
            address_visited: Yup.string().required('Required.'), 
            comments_on_location_landmarks : Yup.string().required('Required.'), 
            color_of_building : Yup.string().required('Required.'), 
            building_description : Yup.string().required('Required.'), 
            name_of_visiting_staff: Yup.string().required('Required.'), 
            date_of_visit: Yup.string().required('Required.'), 
            sales_officer_id: Yup.string().required('Required.'), 
            supervisor_officer : Yup.string().required('Required.'), 
            estimated_annual_salary : Yup.string().required('Required.'), 
            bvn : Yup.string().required('Required.'), 
            kin_name : Yup.string().required('Required.'), 
            kin_phone : Yup.string().required('Required.'), 
            kin_address : Yup.string().required('Required.'), 
            kin_relationship : Yup.string().required('Required.'), 
            kin_occupation : Yup.string().required('Required.'), 
            bank_name : Yup.string().required('Required.'), 
            account_name : Yup.string().required('Required.'), 
            customer_phone : Yup.string().required('Required.'), 
            customer_email : Yup.string().required('Required.'), 
            employment_status : Yup.string().required('Required.'), 
        }),
        validateOnMount : false,     
        onSubmit : (values, onSubmitProps) => {
             handleSubmit(values, onSubmitProps)
            }
        }
    );

    useEffect(() => {
        const fetchingData = async () => {
            const allSupervisor = await axios.get(`${irsAuthBaseUrl}/kyc/corporate-account/supervisors`)
            const response = allSupervisor.data
            setStaff(response.data);

            const allStaff = await getAxiosInstance(token).get('/staff/active');
            const result = allStaff.data;
            setOpStaff(result.data)
        }
        fetchingData()
    }, [])

    console.log(opStaff)
    const handleSubmit = async (values, onSubmitProps) => {

        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("gender", values.gender)
        formData.append("account_name", values.account_name)
        formData.append("business_profession", values.business_profession)
        formData.append("id_type", values.id_type)
        formData.append("id_number", values.id_number)
        formData.append("laundering_risk", values.laundering_risk)
        formData.append("pep", values.pep)
        formData.append("estimated_monthly_salary", values.estimated_monthly_salary)
        formData.append("customer_type", values.customer_type)
        formData.append("kyc_level", values.kyc_level)
        formData.append("type_of_acct", values.type_of_acct)
        formData.append("documentation_checked", values.documentation_checked)
        formData.append("utility_bill", "newy")
        formData.append("utility_file", billUrl)
        formData.append("address_visited", values.address_visited)
        formData.append("comment_on_location_landmarks", values.comments_on_location_landmarks);
        formData.append("color_of_building", values.color_of_building)
        formData.append("building_description", values.building_description)
        formData.append("name_of_visiting_staff", values.name_of_visiting_staff)
        formData.append("date_of_visit", values.date_of_visit)
        formData.append("ro_id", user.id.toString())
        formData.append("ro_signature", imageUrl)
        formData.append("ro_comment", "Okay")
        formData.append("sales_officer_id", values.sales_officer_id)
        formData.append("supervisor_officer", values.supervisor_officer)
        formData.append("estimated_annual_salary", values.estimated_annual_salary)
        formData.append("bvn", values.bvn)
        formData.append("kin_name", values.kin_name)
        formData.append("kin_phone", values.kin_phone)
        formData.append("kin_address", values.kin_address)
        formData.append("kin_occupation", values.kin_occupation)
        formData.append("kin_relationship", values.kin_relationship)
        formData.append("bank_name", values.bank_name)
        formData.append("account_number", values.account_number)
        formData.append("customer_phone", values.customer_phone)
        formData.append("customer_email", values.customer_email)
        formData.append("employment_status", values.employment_status)
        formData.append("customer_passport", passport)

        try {
            const creatingKyc = await axios.post(`${irsAuthBaseUrl}/kyc/fixed-deposit/create`, formData);
            const response = creatingKyc.data;
            message.success(response.message);
            setTimeout(() => {
                navigate('/kyc/requester');
            },1500)
        } catch (error) {
            onSubmitProps.setSubmitting(false);
            const responseError = error.response.data.message
            if(responseError?.utility_bill){
                message.error(responseError?.utility_bill[0])
            }
            if(responseError?.comment_on_location_landmarks){
                message.error(responseError?.comment_on_location_landmarks[0])
            }
            if(responseError?.utility_file){
                message.error(responseError?.utility_file[0])
            }
            if(responseError?.id_number){
                message.error(responseError?.id_number[0])
            }
            if(responseError?.ro_signature){
                message.error(responseError?.ro_signature[0])
            }
        }
    }

    const steps = [
        {
           content : <PersonalInformationData formik={formik} setValid={setValid}/>
        },
        {
           content : <OtherInformation formik={formik} setValid={setValid}/>
        },
        {
           content : <BusinessInformationData formik={formik} setValid={setValid} setBillUrl={setBillUrl}/>
        },
        {
           content : <ContactInformationData formik={formik} setValid={setValid} setPassport={setPassword}/>
        },
        {
           content : <AuthorizingOfficers formik={formik} staff={staff} opStaff={opStaff} setImageUrl={setImageUrl}/>
        },
    ]

    const next = () => {
        // setCurrent(current + 1)
        if(valid){
          setCurrent(current + 1);
          setValid(false)
        }else{
          message.success('Complete the fields!')
        }
    };
    
    const prev = () => {
        setCurrent(current - 1);
    };

    console.log(formik.values)
  return (
    <div className='kyc'>
        <div className="kyc-wrapper d-flex">
            <PersonalTags />
            <div className="main-content">
                <KYCTAB menuFor={"Individual KYC"}/>
                <div className="breadcrumbs">
                    <h4>Staff 
                        <img src={arrow} alt="" width="10px" height="10px"/>
                        <span>Individual KYC Form</span>
                    </h4>
                    <p className='highlights'>
                        <span style={{color : current === 0 ? "blue" : "GrayText"}}>Personal Information</span>
                        <span style={{color : current === 1 ? "blue" : "GrayText"}}>Next of Kin</span>
                        <span style={{color : current === 2 ? "blue" : "GrayText"}}>More Information</span>
                        <span style={{color : current === 3 ? "blue" : "GrayText"}}>Contact Information</span>
                        <span style={{color : current === 4 ? "blue" : "GrayText"}}>Approvers</span>
                    </p>
                </div>
                <div className="steps-wrapper">
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} className="cancel" onClick={() => prev()}>
                                Previous
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary"  className='next' onClick={() => next()}>
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" className='next' onClick={() => formik.submitForm()} disabled={formik.isSubmitting}>
                                <div className="d-flex">
                                    {
                                        formik.isSubmitting && <Spin />
                                    }
                                    Submit
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default PersonalKYC