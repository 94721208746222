import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { casaBaseurl } from '../../../common/apis/userApi';
import { convertStaff } from '../../../utilities/convertArray';


const AddUsermodal = ({allStaff}) => {

  const [disabled, setDisabled] = useState(false);
  const [permission, setPermission] = useState([]);
  const [staffId, setStaffId] = useState(null);

  const handleRole = (e) => {
    const data = e.target.value;
    if(e.target.checked){
        setPermission([...permission, data]);
    }else{
        setPermission(permission.filter((item) => item !== data))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    const jsonData = {
      "irs_id": staffId.value,
      "permission": permission
    }
    try {
      const sendingData = await axios.post(`${casaBaseurl}addstaff`, jsonData);
      const response = sendingData.data;
      if(response.code === 200){
        toast.success("Staff added successfully")
        setTimeout(() => {
          window.location.reload();
        },3000)
      }
    } catch (error) {
      console.log(error)
      setDisabled(false)
    }

  }

  const data = allStaff?.data;
  const staffData = convertStaff(data)

  return (
    <div className="modal fade" id="add-user">
      <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add User Permission</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                  <div className="form-wrapper">
                      <form onSubmit={handleSubmit}>
                          <div className="form-data">
                              <div className="form-group">
                                  <label htmlFor="">Staff Name</label>
                                  <Select
                                    name="staffId"
                                    options={staffData}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(value) => setStaffId(value)}
                                  />
                              </div>
                              <div className="form-group">
                                  <label htmlFor="">Permission</label>
                                  <span className='d-flex align-items-center'>
                                      <input type="checkbox" name="permission" value="ro" onChange={handleRole}/>
                                      <span>RO</span>
                                  </span>
                                  <span className='d-flex align-items-center'>
                                      <input type="checkbox" name="permission" value="branch" onChange={handleRole}/>
                                      <span>Branch</span>
                                  </span>
                                  <span className='d-flex align-items-center'>
                                      <input type="checkbox" name="permission" value="authorizer" onChange={handleRole}/>
                                      <span>Authorizer</span>
                                  </span>
                              </div>
                              <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Add User</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default AddUsermodal