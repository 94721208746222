import React from 'react';
import '../profile/profile.css';
import Header from '../../components/header/header'
import UserProfile from '../../components/userProfile/userProfile'

const Profile = () => {
  return (
    <div className='profile'>
        <Header />
        <UserProfile />
    </div>
  )
}

export default Profile