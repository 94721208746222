import React, { useEffect, useState } from 'react';
import { casaBaseurl } from '../../../common/apis/userApi';
import Header from '../../../components/header/header';
import '../../../pages/TicketList/TicketList.css';
import axios from 'axios';
import CasaTags from '../CasaTags/CasaTags';
import '../CasaOperations/casaTransactions.css';
import view from '../../../assets/visibility.png';

const CasaTransactions = () => {

    const [transactions, setTransactions] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchingData = async () => {
            const query = await axios.get(`${casaBaseurl}transactions`);
            const result = query.data;
            setTransactions(result);
        }
        fetchingData();
    },[]);

    const handleFilterChange = (e) => {
        if(e.target.value !== ""){
            setFilter(e.target.value);
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleSearchChange = (e) => {
        if(e.target.value !== ""){
            setSearch( e.target.value)
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleFilter = async (e) => {
        e.preventDefault();

        const data = {
            "date" : filter
        }

        try {
            const query = await axios.post(`${casaBaseurl}transactions`, data);
            const response  = query.data;
            setTransactions(response)
        } catch (error) {
            console.log(error)
        }  

    }

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const query = await axios.put(`${casaBaseurl}transactions/${search}`);
            const response  = query.data;
            setTransactions(response)
        } catch (error) {
            console.log(error)
        }  

    }

    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : transactions.next,
        })
        const data = response.data;
        setTransactions(data);
        window.scrollTo(0, 0);
    }
    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : transactions.previous,
        })
        const data = response.data;
        setTransactions(data);
        window.scrollTo(0, 0);
    }

    const transactionList = transactions.results;

    let totalCounts = Math.ceil(transactions.count/10);
    let currentCount = ''
    if(transactions.next != null){
        let next = transactions.next;
        currentCount = next.split("=")[1] - 1;
    }else if(transactions.next == null){
        currentCount = totalCounts;
    }

  return (
    <div className='casa-transactions'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="inner-wrapper">
                <h4 className='mt-4'>All Transactions</h4>
                <div className="koins-heading">
                    <div className="search-transaction d-flex">
                        <div className="dates d-flex align-items-end">
                            <div className="form-group">
                                <label htmlFor="start" className='filter'>Filter by date</label>
                                <input type="date" name="filter" onChange={handleFilterChange}/>
                            </div>
                            <div className="form_group d-flex">
                                <button className='filterbtn' onClick={handleFilter}>Filter</button>
                                <button className='reset-btn' onClick={() => window.location.reload()}>Reset</button>
                            </div>
                        </div>
                        <div className="search-wrapper d-flex">
                            <input type="search" name="search" placeholder=' search by account number' onChange={handleSearchChange}/>
                            <button onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
                <div className="tickets-table">
                    <table className='border p-3'>
                        <thead>
                            <tr>
                                <td className='pr-3'>Tnx Type</td>
                                <td>Creditor Name</td>
                                <td>Creditor Account</td>
                                <td className='text-center'>Amount</td>
                                <td className='text-center'>Debitor Name</td>
                                <td>Debitor Account</td>
                                <td>View</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactionList &&
                                transactionList.map(({amount,trans_id, debitaccountname, creditaccountname, creditaccountno,debitaccountno, depositor_creditor, creditbankname, debitbankname, naration, sessionid, trans_type, debitbankcode, creditbankcode, date_created}, index) => {

                                    const formatedDate = date_created.split('T');
                                    const newDate = formatedDate[0];
                                    const newTime = formatedDate[1].split('.')[0];
                    
                                    return(
                                        <tr key={index}>
                                            <td className='pr-3'><p>{trans_type}</p></td>
                                            <td>
                                                <p>{creditaccountname}</p>
                                            </td>
                                            <td><p>{creditaccountno}</p></td>
                                            <td className='text-center'><p style={{color : trans_type === "CR" || trans_type === "FT/AC" ? "green" : "red"}}>{amount.toLocaleString()}</p></td>
                                            <td className='text-center'><p>{debitaccountname}</p></td>
                                            <td><p>{debitaccountno}</p></td>
                                            <td className=''>
                                                <span className='text-center' data-bs-toggle="modal" data-bs-target={`#transaction${index}`} role="button">
                                                    <img src={view} alt="" />
                                                </span>
                                            </td>

                                            <div className="modal fade" id={`transaction${index}`} tabIndex="-1" aria-labelledby="transactionLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="transactionLabel">Transaction Details</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Tnx Type</h5>
                                                                <p>{trans_type}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Transaction Date</h5>
                                                                <p>{`${newDate} || ${newTime}`}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Creditor Name</h5>
                                                                <p>{creditaccountname}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Creditor AccountNo</h5>
                                                                <p>{creditaccountno}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Creditor Bank Name</h5>
                                                                <p>{creditbankname}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Amount</h5>
                                                                <p style={{color : trans_type === "CR" || trans_type === "FT/AC" ? "green" : "red", fontWeight : "600"}}>{amount.toLocaleString()}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Debit Bankcode</h5>
                                                                <p>{debitbankcode}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Credit Bankcode</h5>
                                                                <p>{creditbankcode}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Deposit Account Name </h5>
                                                                <p>{debitaccountname}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Depositor AccountNo</h5>
                                                                <p>{debitaccountno}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Depositor</h5>
                                                                <p>{depositor_creditor}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Debit Bank Name</h5>
                                                                <p>{debitbankname}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Narration</h5>
                                                                <p>{naration}</p>
                                                            </div>
                                                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Session ID</h5>
                                                                <p>{sessionid}</p>
                                                            </div>
                                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                                <h5>Transaction ID</h5>
                                                                <p>{trans_id}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    )
                                }) 
                            }
                            {
                                transactionList?.length < 1 && <p className='mt-4 no-records'>No records found</p>
                            }
                        </tbody>                        
                    </table>
                    {
                        totalCounts?.length < 1 && <>
                            <div className="nav-btns mt-4">
                                <button onClick={fetchingPrev}>Prev</button>
                                <button onClick={fetchingNext}>Next</button>
                            </div>
                            <p className='pb-4 mt-3 text-center'>{`Page ${currentCount} of ${totalCounts}`}</p>
                        </>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaTransactions