import React, { useState, useEffect} from 'react'
import PlaceholderComponent from '../../components/PlaceholderComponent/PlaceholderComponent';
import { Link } from 'react-router-dom';
import speaker from '../../assets/image 4.svg';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../common/apis/createAxios';

const DashboardTabItem = ({item, title}) => {

    const { user, role, token } = useSelector(userSelector)
    const [permission, setPermission] = useState([]);
    const [services, setServices] = useState([]);

    const userPermission = role?.find(item => {
        const isAuthorized =  item?.user_id === user?.id;
        return isAuthorized;
    })

    const userPermissionId = userPermission?.role_id
   
    useEffect(() => {
        const fetchingData = async () => {
            const findPermission = await getAxiosInstance(token).get(`/roles-core/view/${userPermissionId}`)
            const result = findPermission.data;
            setPermission(result)

            const allPermission = await getAxiosInstance(token).get(`/microservice-permission-core`)
            const response = allPermission.data;
            setServices(response.data)
        }
        if(typeof userPermissionId !== 'undefined'){
            fetchingData();
        }
    },[userPermissionId])

    const staffPermission = permission?.find(item => {
        const permissionName = services?.filter(item => item.name.toLowerCase().includes("staff"))[0];
        const isAllowed = Number(item?.permission_id) === permissionName?.id
        // if permission contain id
        return isAllowed
    })

  return (
    <div className='dashboard-item'>
        <div className="heading">
            <h5>{title}</h5>
        </div>
        <div className="document-tab-wrapper">
            <div className="document-tabs">
                {
                    item && item.map(({title, route, is_external, child_order}, id) => {

                        return(
                            <div className='box-blue' key={id} style={{display : (child_order === 1 && staffPermission || child_order !== 1) ? "flex" : "none"}}>
                                {
                                    (is_external === 1) && <a href={route} target="_blank" rel="noreferrer">
                                        <p>{title.toUpperCase()}</p>
                                    </a>
                                }
                                {
                                    (is_external === 0) && <Link to={route}>
                                        <p>{title.toUpperCase()}</p>
                                    </Link>
                                }
                            </div>
                        )
                    })
                }
            </div>
            {
                !item && <div className="mb-5">
                    <PlaceholderComponent />
                </div>
            }
            <div className="lower-message d-flex align-items-center">
                <img src={speaker} width="70px" alt="loud speaker icon" />
                <p>Remember Primera's information and data security depends on you. Kindly ensure all data and information are properly handled with due diligence.</p>
            </div>
        </div>
    </div>
  )
}

export default DashboardTabItem