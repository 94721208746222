import React from 'react'
import { useNavigate } from 'react-router-dom';
import errorImg from '../../assets/404-Image.svg';
import '../404Page/PageNotFound.css';

const PageNotFound = () => {
    const navigate = useNavigate()
    
  return (
    <div className='page-not-found'>
        <div className="container">
            <h3>Page Not Found</h3>
            <img src={errorImg} alt="error-message" />
            <p>The page you’re looking for has been moved or does not exist. <br></br>And that’s okay. You can just return back to your <span onClick={() => navigate(-1)}>dashboard</span> and retrace your steps! 😉</p>
        </div>
    </div>
  )
}

export default PageNotFound