export const CorporateData = {
    title : "Corporate KYC",
    data : [
        {
            menu : "New KYC",
            link : "/kyc/home"
        },
        {
            menu : "All Kyc Requests",
            link : "/kyc/accounts"
        },
        {
            menu : "RO History",
            link : "/kyc/request-history"
        },
        {
            menu : "Supervisor Request",
            link : "/kyc/supervisor"
        },
        {
            menu : "Data Entry Request",
            link : "/kyc/data-entry-request"
        },
    ],

}
export const IndividualData = {
    title : "Individual KYC",
    data : [
        {
            menu : "New KYC",
            link : "/kyc-individual"
        },
        {
            menu : "All Kyc Requests",
            link : "/kyc/requester"
        },
        {
            menu : "My History",
            link : "/individual/my-history"
        },
        {
            menu : "Supervisor Request",
            link : "/individual/supervisorlist"
        },
        {
            menu : "Data entry request",
            link : "/individual/branchlist"
        },
    ]
}