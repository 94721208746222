import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { userSelector } from '../../../feature/Login/loginSlice';
import spyglass from '../../../assets/search.svg';
import './createuserrole.css';

const CreateUserRole = ({permission, handleRole, allRoles, setPermission, staff}) => {

    const roleList = allRoles?.data;
    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false)

    const handleInput = () => {
        var val = document.getElementById("inputData").value;
        let opts = document.getElementById('staff').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setPermission({...permission, user_id : dataId, name : data})
            break;
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { name, role_id, user_id } = permission;
        const status_data = roleList?.filter(item => item.id == permission.role_id)[0];

        const jsonData = JSON.stringify({
            "name" : name,
            "role_id" : role_id,
            "user_id" : user_id,
            "status" : status_data?.name
        })

        try {
            const creatingMicroservice = await getAxiosInstance(token).post(`/user-roles-core`, jsonData);
            const response = creatingMicroservice.data;
            if(response){
                toast.success('User role created successfully')
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        } catch (error) {
            console.log(error);
            setDisabled(false);
        }
    }

  return (
    <div className="modal fade user-role" id="add-role">
      <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add New User</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit}>
                            <div className="form-data">
                              <div className="form-group">
                                  <label htmlFor="">Staff Name</label>
                                  <div className="input-wrapper">
                                        <img src={spyglass} alt="spy glasss icon" width="20px" />
                                        <input list="staff" id='inputData' onInput={handleInput} placeholder="search staff"/>
                                    </div>
                                    <datalist id="staff">
                                        <option value="">select user</option>
                                        {staff.map(({id, name}) =>
                                            <option key={id} value={name} id={id}>{name}</option>
                                        )}
                                    </datalist>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Select Role</label>
                                    <select name="role_id" onChange={handleRole}>
                                        <option value="">Select service</option>
                                        {
                                            roleList && roleList.map(({id, name,}) => {
                                                return(
                                                    <option key={id} value={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                              
                              <div className="form-group">
                                  <label htmlFor="">Status</label>
                                  <select name="status" onChange={handleRole}>
                                      <option value="">Select permission</option>
                                      <option value="1">Active</option>
                                  </select>
                              </div>
                              <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Add Service</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default CreateUserRole