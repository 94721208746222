import React from 'react';
import '../Leave/Leave.css';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import '../SupervisorLeave/SupervisorLeave.css';
import Header from '../../components/header/header';
import SupervisorLeaveComponent from '../../components/SupervisorLeaveComponent/SupervisorLeaveComponent';
import Tag from '../../components/Tag/Tag';

const SupervisorLeave = () => {
  return (
    <div className='supervisor-leave leave'>
        <Header />
        <div className="container_">
            <div className="content">
                <Tag />
                <SupervisorLeaveComponent />
                {/* <BirthdayCard /> */}
            </div>
        </div>
    </div>
  )
}

export default SupervisorLeave