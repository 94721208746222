import React from 'react'
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import "react-toastify/dist/ReactToastify.css";
import { documentBaseUrl } from '../../common/apis/userApi';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;


const UploadSignature = () => {

    const { user } = useSelector(userSelector);

    const props = {
        name: 'signature',
        multiple: false,
        action: `${documentBaseUrl}/staffsignature/${user.id}`,
      
        onChange(info) {
          const { status } = info.file;
      
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
      
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error'){
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
    };
    

  return (
    <div className='supporting-doc'>
        <div className="content" style={{paddingBottom : "5rem"}}>
            <h4 style={{fontSize : "16px"}}>Upload Signature</h4>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for a single file upload</p>
            </Dragger>
        </div>
    </div>
  )
}

export default UploadSignature;