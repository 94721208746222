import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import Header from '../../components/header/header';
import '../dahsboardHome/dashboard.css';
import user2 from '../../assets/group copy.png';
import speaker from '../../assets/image 4.svg';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  userSelector } from '../../feature/Login/loginSlice';
import Valuecard from '../../components/valueCard/Valuecard';
import PlaceholderComponent from '../../components/PlaceholderComponent/PlaceholderComponent';
import { fetchingMenu, menuSelector } from '../../feature/Menus/Menus';
import HomeTags from '../../components/HomeTags/HomeTags';

const Dashboard = () => {

    const dispatch = useDispatch();
    const { user, token } = useSelector(userSelector);
    const { menu }  = useSelector(menuSelector);

    const [active] = useState({
        dashboard : true,
        myProfile : false,
        menus : false,
        permission : false,
        user2fa : false,
        oldirs : false
    })

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [token, dispatch])

    
    const parentMenus = menu && menu.filter(item => item.is_parent === 1);
    const reverseMenu = menu && [...parentMenus].reverse();

  return (
    <div className='dashboard_ no-header'>
            <div className="dashboard_">
                <Header />
                <div className="container___">
                    <div className="dashboard-wrapper">
                        <ToastContainer closeButton={true} position="top-right" />
                        <HomeTags active={active}/>
                        <div className="actions">
                            <div className="white_wrapper">
                                <h3>Welcome to IRS, <span>{user?.username}</span></h3>
                                {
                                    (reverseMenu) ? 
                                    <div className="action-btns">
                                        {
                                            
                                            reverseMenu.map(({ id, route, title, is_external}) => {
                                                return(
                                                    <div className="staff-wrapper" key={id}>
                                                        {
                                                            (is_external === 0) && <Link to={route}>
                                                                <img src={user2} alt="" />
                                                                <p>{title}</p>
                                                            </Link>
                                                        }
                                                        {
                                                            (is_external === 1) && <a href={route} target="_blank" rel="noreferrer">
                                                                <img src={user2} alt="" />
                                                                <p>{title}</p>
                                                            </a>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <PlaceholderComponent />
                                }
                            </div>
                            
                            <div className="info-text-card d-flex">
                                <div className="banner">
                                    <div className="megaphone">
                                        <img src={speaker} width="85px" alt="icon of megaphone" />
                                    </div>
                                    <div className="banner-text">
                                        <h4>PRIMERA INTERNAL RESOURCE STREAM</h4>
                                        <p>is a one stop solution for employees where all banking operations will be conducted. All the bank’s existing applications such as the core banking, communication, loan processing and disbursement will be assessed on IRS.</p>
                                    </div>
                                </div>
                                <div className="value-card">
                                    <Valuecard />
                                </div>
                            </div>
                        </div>
                        <div className="right-item">
                            <div className="user">
                                <img src={user?.avatar} alt="" className='profile-img'/>
                                <h4>{user?.name}</h4>
                                <p>{user?.designation}</p>
                            </div>
                            <BirthdayCard />
                        </div>
                    </div> 
                </div>
            </div>
        
    </div>
  )
}

export default Dashboard;