import React from 'react'
import usericon from '../../assets/user-img.png';
import '../KoinsUserPreview/KoinsUserPreview.css';
import wallet from '../../assets/wallet.png';
import naira from '../../assets/naira.png';
import KoinTableComponet from '../KoinsTableComponent/KoinTableComponet';

const KoinsUserPreview = () => {

    const data = JSON.parse(localStorage.getItem("tgtx"));
    console.log(data)
    
  return (
    <div className='koins-user-preview'>
        {
            data.map(({email, phone, profile}, index) => {
                const montlhy = Number(profile.fin_monthly_salary);
                const annual = Number(profile.fin_annual_salary);

                return <div className="koins-profile">
                <div className="top-card d-flex" key={index}>
                    <div className="image-card d-flex">
                        <img src={profile.avatar} alt="" />
                        <div className="name">
                            <h3>{`${profile.firstname} ${profile.lastname}`}</h3>
                            <div className="level">
                                <p>Level 1</p>
                            </div>
                            <p>{email}</p>
                            <p>{phone}</p>
                        </div>
                    </div>
                    <div className="transactions-overview d-flex">
                        <div className="savings d-flex">
                            <img src={naira} alt="" />
                            <div className="saving-text">
                                <h4>0</h4>
                                <p>savings balance</p>
                            </div>
                        </div>
                        <div className="wallet d-flex">
                            <img src={wallet} alt="" />
                            <div className="saving-text">
                                <h4>#500</h4>
                                <p>wallet balance</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="other-info">
                    <div className="group">
                        <div className="item">
                            <h5>Middle Name</h5>
                            <p>{profile.middlename} </p>
                        </div>
                        {/* <div className="item">
                            <h5>Contact Address</h5>
                            <p>{profile.contact_address} </p>
                        </div>
                        <div className="item">
                            <h5>Contact Lga</h5>
                            <p>{profile.contact_lga}</p>
                        </div> */}
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Contact Address</h5>
                            <p>{profile.contact_address} </p>
                        </div>
                        <div className="item">
                            <h5>Contact Lga</h5>
                            <p>{profile.contact_lga}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Landmark</h5>
                            <p>{profile.contact_landmark} </p>
                        </div>
                        <div className="item">
                            <h5>State</h5>
                            <p>{profile.contact_state}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Nationality</h5>
                            <p>{profile.nationality}</p>
                        </div>
                        <div className="item">
                            <h5>Marital Status</h5>
                            <p>{profile.marital_status}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>LGA of origin</h5>
                            <p>{profile.lga_of_origin}</p>
                        </div>
                        <div className="item">
                            <h5>State of origin</h5>
                            <p>{profile.state_of_origin}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Date of birth</h5>
                            <p>{profile.date_of_birth}</p>
                        </div>
                        <div className="item">
                            <h5>Gender</h5>
                            <p>{profile.gender} </p>
                        </div>
                    </div>
                    <div className="item">
                        <h5>NIN</h5>
                        <p>{profile.nin}</p>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Annual Salary</h5>
                            <p>{annual.toLocaleString()}</p>
                        </div>
                        <div className="item">
                            <h5>Monthly Salary</h5>
                            <p>{montlhy.toLocaleString()}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Employer</h5>
                            <p>{profile.emp_employer}</p>
                        </div>
                        <div className="item">
                            <h5>Employer Address</h5>
                            <p>{profile.emp_address}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Occupation</h5>
                            <p>{profile.emp_occupation}</p>
                        </div>
                        <div className="item">
                            <h5>Date Employed</h5>
                            <p>{profile.emp_date_employed}</p>
                        </div>
                    </div>
                    <h4>Next of kin:</h4>
                    <div className="group">
                        <div className="item">
                            <h5>Next of kin name</h5>
                            <p>{profile.nok_name}</p>
                        </div>
                        <div className="item">
                            <h5>Next of kin address</h5>
                            <p>{profile.nok_address}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Next of kin phone</h5>
                            <p>{profile.nok_phone}</p>
                        </div>
                        <div className="item">
                            <h5>Next of kin email</h5>
                            <p>{profile.nok_email}</p>
                        </div>
                    </div>
                    <div className="group">
                        <div className="item">
                            <h5>Next of kin dob</h5>
                            <p>{profile.nok_date_of_birth}</p>
                        </div>
                        <div className="item">
                            <h5>Next of kin relationship</h5>
                            <p>{profile.nok_relationship}</p>
                        </div>
                    </div>
                </div>
            </div>
            })
        }
        <table className="koins-transactions">
            <KoinTableComponet />
        </table>
    </div>
  )
}

export default KoinsUserPreview