import React from 'react'

export const PersonalInfoRender = ({newData}) => {
  return (
    <div>
        <div className="basic-info mb-4">
            <div className="title">
                <p>BASIC INFO</p>
            </div>
            <div className="info-details">
                <div className="item-row">
                    <div className='info-item'>
                        <h4>Title</h4>
                        <span>{newData?.title}</span>
                    </div> 
                    <div className='info-item'>
                        <h4>Full Name</h4>
                        <span>{`${newData?.firstname} ${newData?.lastname}`}</span>
                        </div> 
                    <div className='info-item'>
                        <h4>Initials</h4>
                        <span>{newData?.initials}</span>
                        </div> 
                    <div className='info-item'>
                        <h4>Gender</h4>
                        <span>{newData?.gender}</span>
                    </div> 
                </div>
                <div className="item-row">
                    <div className='info-item'>
                        <h4>Maiden Name</h4>
                        <span>{newData?.maiden_name}</span>
                    </div> 
                    <div className='info-item'>
                        <h4>Date of birth</h4>
                        <span>{newData?.dob}</span>
                    </div> 
                    <div className='info-item'>
                        <h4>Marital Status</h4>
                        <span>{newData?.marital_status}</span>
                    </div> 
                    <div className='info-item'>
                        <h4>Customer No</h4>
                        <span>{newData?.custno}</span>
                    </div> 
                </div>
            </div>
        </div>
        <div className="contact-info mb-4">
        <div className="title">
            <p>CONTACT INFO</p>
        </div>
        <div className="item-row">
            <div className='info-item'>
                <h4>Phone Number</h4>
                <span>{newData?.mobileno}</span>
            </div> 
            <div className='info-item'>
                <h4>Alt Phone Number</h4>
                <span>{newData?.alt_mobileno}</span>
            </div> 
            <div className='info-item'>
                <h4>State</h4>
                <span>{newData?.state}</span>
            </div> 
            <div className='info-item'>
                <h4>Email Address</h4>
                <span>{newData?.email_address}</span>
            </div> 
        </div>
        <div className="item-row">
            <div className='info-item'>
                <h4>Country</h4>
                <span>{newData?.country}</span>
            </div> 
            <div className='info-item'>
                <h4>LGA</h4>
                <span>{newData?.lga}</span>
                </div> 
            <div className='info-item'>
                <h4>Address</h4>
                <span>{newData?.address}</span>
            </div> 
        </div>
    </div>
    </div>
  )
}
