import React, { useEffect } from 'react';
import '../DocumentComponent/document.css';
import {  useSelector } from 'react-redux';
import {  userSelector } from '../../feature/Login/loginSlice';
import { menuSelector, fetchingMenu } from '../../feature/Menus/Menus';
import { useDispatch } from 'react-redux';
import DashBoardIRSComponent from '../../components/DashboardIRSComponent/DashBoardIRSComponent';

const Compliance = () => {

    const dispatch = useDispatch();
    const { menu } = useSelector(menuSelector)
    const { token } = useSelector(userSelector)

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [])

    const staffChildren = menu && menu.filter(item => item.parent_id === 9 && item.is_parent === 0);

  return (
    <div className='complaince'>
        <DashBoardIRSComponent title="COMPLIANCE" data={staffChildren}/>
    </div>
  )
}

export default Compliance