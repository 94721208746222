import React from 'react'

const Button = ({value, disabled, handleClick, type}) => {

  return (
    <div className='btn'>
        <button 
        style={{background : disabled ? "#083061" : "#083061"}}
        disabled={disabled} 
        onClick={handleClick}
        type={type}>
            {
              disabled ? 
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> : value
            }  
        </button>
    </div>
  )
}

export default Button