import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/header'
import Notification from '../../components/Notification/Notification';
import { toast, ToastContainer } from 'react-toastify';
import Swal  from 'sweetalert2';
import { getAxiosInstance } from '../../common/apis/createAxios';
import {  useSelector, useDispatch } from 'react-redux';
import {  userSelector, clearState } from '../../feature/Login/loginSlice';
import { menuSelector, fetchingMenu } from '../../feature/Menus/Menus';
import '../AddMenu/AddMenu.css';
import arrow from '../../assets/Arrow Forward.svg';

const AddMenu = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false)
    const { menu } = useSelector(menuSelector)
    const [menuData, setMenu] = useState({
        route: "",
        title: "",
        url: "",
        icon: "",
        is_external: "",
        is_parent: "",
        parent_id: "",
        parent_order: "",
        child_order: ""
    });

    const navigate = useNavigate()
    const { token } = useSelector(userSelector);
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [])

    const handleChange = (e) =>{
        setMenu({...menuData, [e.target.name] : e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { route, title, url, icon, is_external, is_parent, parent_id, parent_order, child_order } = menuData;

        const jsonData = {
            "route": route,
            "title": title,
            "url": url,
            "icon": icon,
            "is_external": is_external,
            "is_parent": is_parent,
            "parent_id": parent_id,
            "parent_order": parent_order,
            "child_order": child_order
        }

        try {
            const sendingdata = await getAxiosInstance(token).post('/menus', jsonData);
            const response = sendingdata.data;
            if(response){
                toast.success("Menu added successfully");
                setTimeout(() => {
                        history('/menus')
                    },2000)
            }
            
        } catch (error) {
            const apiMsg = error.response.data.response;
            toast.error("Process failed")
            setDisabled(false);
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(clearState());
        navigate('/');
    }
    const handleClose = () => {
        setNotification(!notification)
    }

    const parentList = menu && menu.filter(item => item.is_parent === 1);

  return (
    <div className='add-menus'>
        <Header />
        <div className="container___">
            <div className="dashboard-wrapper">
                <ToastContainer closeButton={true} position="top-right" />
                <Notification notification={notification} handleClose={handleClose}/>
                <div className="add-staff-form">
                    <ToastContainer closeButton={true} position="top-right" />
                    <div className="back-arrow" onClick={() => navigate(-1)}>
                        <img src={arrow} alt="" />
                    </div>
                    <div className="add-staff-wrapper">
                        <h4 className='mb-4'>Create Menu</h4>
                        <form action="" onSubmit={handleSubmit}>
                            <div className="form-data">
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <input type="text" placeholder='Document' name='title' value={menuData.title} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Is External</label>
                                    <select name="is_external" value={menuData.is_external} onChange={handleChange}>
                                        <option value="" disabled></option>
                                        <option value="1" >Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Route</label>
                                    <input type="text" placeholder='' name='route' value={menuData.route} onChange={handleChange}/>
                                </div>
                                {
                                    (menuData.is_external === "0") && <div className="form-group">
                                        <label htmlFor="">URL</label>
                                        <input type="text" placeholder='' name='url' value={menuData.url} onChange={handleChange}/>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="">Icon</label>
                                    <input type="file" name='icon' value={menuData.icon} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Is Parent</label>
                                    <select name="is_parent" value={menuData.is_parent} onChange={handleChange}>
                                        <option value="" disabled></option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                {
                                    (menuData.is_parent === "0") && <div className="form-group">
                                        <label htmlFor="">Select Parent</label>
                                        <select name="parent_id" value={menuData.parent_id} onChange={handleChange}>
                                            <option value="" disabled></option>
                                            {
                                                parentList && parentList.map(({ id, title}) => {
                                                    return(
                                                        <option value={id} key={id}>{title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                }
                                {
                                    (menuData.is_parent === "1") && <div className="form-group">
                                            <label htmlFor="">Parent Order</label>
                                            <input type="number" name='parent_order' value={menuData.parent_order} onChange={handleChange}/>
                                        </div>
                                }
                                
                                {
                                    (menuData.is_parent === "0") && <div className="form-group">
                                        <label htmlFor="">Is Restricted</label>
                                        <select name='child_order' value={menuData.child_order} onChange={handleChange}>
                                            <option value="" disabled></option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                }
                                
                                <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Create Menu</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddMenu