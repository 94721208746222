import React from 'react';
import '../header/header.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import logo from '../../assets/image 1.png';
import logout from '../../assets/Logout1.png';
import search from '../../assets/search.svg';
import Hamburger from '../Hamburger/Hamburger';
import { userSelector, clearState } from '../../feature/Login/loginSlice';

const Header = () => {

    const dispatch = useDispatch();
    const history = useNavigate();

    const { user } = useSelector(userSelector);

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(clearState());
        history('/');
    }

  return (
    <div className='header' id="no-print">
         <div className="container">
             <div className="outer-wrapper d-flex align-items-center justify-content-between">
                <div className="left-content d-flex">
                    <Link to='/dashboard'>
                        <img src={logo} width="110px" alt="primera logo" />
                    </Link>
                    <p>Welcome To IRS, <span>{user?.username}</span></p>
                </div>
                <div className="right-content d-flex align-items-center justify-content-between">
                    <div className="search-box">
                        <img src={search} alt="search spyglass icon" />
                        <input type="text"  placeholder='Search IRS'/>
                    </div>
                     <div className="logos d-flex align-items-center justify-content-between">
                        <Hamburger handleLogout={handleLogout}/>
                        <img src={logout} alt="logout" onClick={handleLogout} className="logout"/>

                    </div>
                </div>
             </div>
             <ToastContainer closeButton={false} position="top-right" />
         </div>
    </div>
  )
}

export default Header;