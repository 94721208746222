import React, { useEffect, useState } from 'react'
import { Popconfirm } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Image } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HelpDeskTags from '../HelpDeskTags/HelpDeskTags'
import iconuser from '../../assets/user (2).png';
import './helpdeskpreview.css';
import { userSelector } from '../../feature/Login/loginSlice';
import { irsBaseUrl } from '../../common/apis/userApi';
import pencil from '../../assets/pencil (1).png';
import EditTicketModal from '../EditTicketModal/EditTicketModal';
import ReassignTIcket from '../ReassignTicket/ReassignTIcket';
import Button from '../../common/button/button';
import loader from '../../assets/grey-loading.gif';

const HelpDeskTicketPreview = () => {

    const history = useNavigate();
    const { token, user } = useSelector(userSelector);
    const data = JSON.parse(sessionStorage.getItem("ticket_id"));
    
    const { id, unit, message, category, ticket_id, department, status_id, replies, created_at, status, solved_at, priority, solved_by, assigned_to, is_assigned, is_on_behalf, is_approved, closed_by, fileUrl, user_id, title } = data[0];

    console.log(data)
    const checklist = category?.checklist;

    const [disabled, setDisabled] = useState(false)
    const [alldepartment, setDepartment] = useState([]);
    const [units, setUnit] = useState([]);
    const [allcategory, setAllCategory] = useState([]);

    const [reply, setReply] = useState({
        ticket_id : id, 
        message : "",
        attachment : "", 
        sender_id : user.id, 
        receiver_id : department.id, 
        status : status_id, 
        is_agent : "0"
    });

    const [editTicket, setEditTicket] = useState({
        user_id : user.id,
        ticket_id : ticket_id,  
        title : "",
        dept : department.id,
        unit : unit.id,
        priority : priority,
        category :  category?.id,
        description : message,
        assigned_to: assigned_to,
        is_assigned: is_assigned,
        is_on_behalf: is_on_behalf,
        is_approved: is_approved,
        status_id: status_id,
        closed_by: closed_by,
        solved_at: solved_at
    })

    const [allStaff, setAllstaffs] = useState([]);

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 

    useEffect(() => {
        const fetchingUsers = async () => {
            const allStaffs = await authAxios.get(`${irsBaseUrl}/staff/all`);
            const results = allStaffs.data;
            setAllstaffs(results.data);

            const query = await authAxios.get(`${irsBaseUrl}/staff/department`);
            const data = query.data;
            setDepartment(data);

            const response = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`);
            const newData = response.data;
            setUnit(newData.data);

            const category = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-category`);
            const categoryResponse = category.data;
            setAllCategory(categoryResponse.data);
        }
        fetchingUsers()
    },[])


    const handleResolve = async (e) => {
        e.preventDefault();
        const jsonData = {
            "ticket_id": id,
            "solved_by": user.id
        }

        try {
            const postingData = await authAxios.post(`${irsBaseUrl}/interdepartmental/tickets/resolve`, jsonData);
            const response = postingData.data;
            if(response.status === "success"){
                toast.success(response.message);
                setTimeout(() => {
                    history(-1)
                }, 2000)
            }
            console.log(response);
        } catch (error) {
            console.log(error)
        }

    }
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    const newDate = new Date(created_at);

    const [month, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
    const [hour, minutes, seconds] = [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()];
    
    let resolver = "";
    let resolveTime = "";

    if(status === "solved"){
        resolver = solved_by;
        resolveTime = solved_at
    }
    const handleChange = (e) => {
        setReply({...reply, message : e.target.value})
    }
    const handleImage = (e) => {
        setReply({...reply, attachment : e.target.value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true)
        const { ticket_id, message, attachment, sender_id, receiver_id, status, is_agent} = reply;

        const formData = new FormData();
        formData.append("ticket_id", ticket_id )
        formData.append("message", message )
        formData.append("attachment", attachment )
        formData.append("sender_id", sender_id )
        formData.append("receiver_id", receiver_id )
        formData.append("status", status )
        formData.append("is_agent", is_agent )

        try {
            const sendingReply = await authAxios.post(`${irsBaseUrl}/interdepartmental/ticket-reply`, formData);
            const response = sendingReply.data;
            if(response.created_at){
                toast.success("Reply sent succesfully");
                setTimeout(() => {
                    history(-1);
                },3000)
            }
        } catch (error) {
            setDisabled(false)
            const errorMsg = error.response.data.errors.message[0];
            toast.error(errorMsg);
        }
    }   

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            const deletingTicket = await authAxios.delete(`${irsBaseUrl}/interdepartmental/inter-departmental-tickets/${id}`);
            const response = deletingTicket.data;
            if(response.status === "success"){
                toast.success("Ticket deleted successfully!!");
                setTimeout(() => {
                    history(-1);
                }, 2000)
            }
            console.log(response)
        } catch (error) {
            console.log(error);
        }
    }

    const resolvingStaff = allStaff?.filter(item => item.id == solved_by)[0];
    
    const fileType = fileUrl?.split(".")[3];
    const requestingStaff = allStaff?.filter(item => item.id === user_id)[0];
    
  return (
    <div className='ticket-preview'>
        <ToastContainer closeButton={true} position="top-right" autoClose={1000}/>
        <div className="container_">
            <div className="content">
                <HelpDeskTags />
                <div className="preview-wrapper">
                    <div className="d-flex justify-content-between">
                        <div className="ticket-top">
                            <h4>Ticket {`#${ticket_id}`}</h4>
                            <h5>{title}</h5>
                            <h6>Ticket Catergory: {`${category?.name}`}</h6>
                            <p className='date'>{`${weekday[dy]} ${day} ${months[month]} ${year} || ${hour}:${minutes}:${seconds}`}</p>
                            <p className='assigned_to'>Assigned to {`${unit.name}`}</p>
                        </div>
                        <div className="edit-btn">
                            {
                                user_id === user.id && <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target="#edit-ticket">
                                    <img src={pencil} alt="pencil-icon" width="20px" />
                                </span>
                            }
                        </div>
                    </div>
                    <EditTicketModal 
                    department={department}
                    editTicket={editTicket}
                    setEditTicket={setEditTicket}
                    allcategory={allcategory}
                    alldepartment={alldepartment}
                    units={units}
                    id={id}
                    authAxios={authAxios}
                    ticket_id={ticket_id}
                    />
                    <div className="status-alert mb-3" style={{display : status === "solved" ? "block" : "none"}}>
                        <p>This ticket is closed. You may reply to this ticket to reopen it</p>
                    </div>
                    <div className="response-btn align-items-between justify-content-end mt-3" style={{display : status === "solved" ? "none" : "flex"}}>
                        <div></div>
                        <div className="action-buttons">
                            <Popconfirm
                                title="Do you want to resolve this ticket?"
                                onConfirm={handleResolve}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button>Resolve</button>
                            </Popconfirm>
                            <button className='re-assign'>
                                <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target="#reassign-ticket">
                                    Reassign
                                </span>
                            </button>
                        </div>
                    </div>

                    <ReassignTIcket 
                    id={id}
                    allStaff={allStaff}
                    authAxios={authAxios}
                    />
                    <div className="ticket-details rounded">
                        <h5>Requester By: 
                            {
                                requestingStaff ? <span>{ requestingStaff?.name}</span> : <img src={loader} alt="loader" />
                            }
                        </h5>
                        <p className='date'>{`${weekday[dy]} ${day} ${months[month]} ${year} || ${hour}:${minutes}:${seconds}`}</p>

                        <div className="description">
                            <h5>Message :</h5>
                            <p>{message}</p>
                        </div>
                        <div className="resolver mt-4" style={{display : status === "solved" ? "block" : "none"}}>
                            <h5>Resolved By : 
                                {
                                    resolvingStaff ? <span>{`${resolvingStaff?.name}`}</span> : <img src={loader} alt="loader" />
                                }
                            </h5>
                            <p className='date'>{resolveTime}</p>
                        </div>
                    </div>
                    <div className="response-wrapper">
                        <div className="checklist mb-5">
                            <div className="information">
                                <h5>Check List</h5>
                                <p className='mb-3'>Please select all check list for this ticket</p>
                            </div>
                            <div className="all-checklist">
                                {
                                    checklist && checklist.map(({id, title, status }) => {
                                        return(
                                            <div className="form-group d-flex align-items-center" key={id}>
                                                <input type="checkbox" name="" id="" />
                                                <label htmlFor="">{title}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            fileUrl !== null && <div className="attachment mb-4">
                                <h5>Attachment</h5>
                                {
                                    (fileType === 'pdf' || fileType === "csv") && <div className="mt-3">
                                        <a href={fileUrl} target="_blank">View attachment</a>
                                    </div>
                                }
                                {
                                    (fileType !== 'pdf' || fileType !== "csv") && <Image width={200} src={fileUrl}/>
                                }
                                
                            </div>
                        }
                        
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="">RESPOND TO TICKET</label>
                            <textarea name="" cols="30" rows="10" value={reply.message} onChange={handleChange}></textarea>
                            <p className='attachment'>Attachment</p>
                            <input type="file" value={reply.attachment} onChange={handleImage}/>

                            <Button type='submit' value='Send Reply' disabled={disabled}/>
                        </form>
                    </div>

                    <div className="replies">
                        <div className="form-group">
                            <label htmlFor="">REPLIES</label>
                            {
                                replies && replies.map(({id, message, sender_id, created_at}) => {

                                    const replyDate = new Date(created_at);

                                    const [month, day, year, dy] = [replyDate.getMonth(), replyDate.getDate(), replyDate.getFullYear(), replyDate.getDay()];
                                    const [hour, minutes, seconds] = [replyDate.getHours(), replyDate.getMinutes(), replyDate.getSeconds()];
                                    
                                    let senderName = allStaff.filter(item => item.id == sender_id);

                                    return(
                                        <div className="reply-wrapper mb-3" key={id}>
                                            <div className="item">
                                                <div className="reply-person d-flex ">
                                                    <img src={iconuser} alt="" width="20px" height="20px" />
                                                    <div className="user-data">
                                                        <h5>{senderName[0]?.name}</h5>
                                                        <p>{`${weekday[dy]} ${day} ${months[month]} ${year} || ${hour}:${minutes}:${seconds}`}</p>
                                                    </div>
                                                </div>
                                                <div className="message-body">
                                                    <p>{message}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                replies.length === 0 && <p className='grey'>No replies</p>
                            }
                        </div>
                    </div>

                    {
                        user_id === user.id && <div className="modification-btns">
                                                    <Popconfirm
                                                        title="Are you sure to delete this ticket?"
                                                        onConfirm={handleDelete}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <button>Delete Ticket</button>
                                                    </Popconfirm>
                                                </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpDeskTicketPreview