import React, { useState } from 'react'
import menu1 from '../../assets/menu1.png'
import { NavLink } from 'react-router-dom'
import './kyc-tags.css';
import { CorporateData } from '../../utilities/menuList';

const KYCTags = () => {

    let activeStyle = {
        background : "white",
        color : "#093263",
        width : "100%",
    }


  return (
    <div className='kyc-tags'>
      {
        CorporateData.title === "Corportate KYC" && <>
          <h3>{CorporateData.title}</h3>
          {
            CorporateData.data.map(({menu, link}, index) => {
              return(
                <div className="list" key={index}>
                  <NavLink to={link}
                      style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                      }
                  >
                      <img src={menu1} alt="" />
                      {menu}
                  </NavLink>
                </div>
              )
            })
          }
        </>
      }
    </div>
  )
}

export default KYCTags