import  { gql } from '@apollo/client';

export const REGISTER_USER = gql `
    mutation createCode($email : String!){
        createCode(
            email: $email
        ){
            status
            statusCode
            message
            formErrors

        }
    }
`