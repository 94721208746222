import React from 'react'
import view from '../../../assets/visibility.png';

const DocumentCollapse = ({id, credentials}) => {
  return (
    <div className='collapse mt-4 mb-3' id={`edit-icon${id}`}>
        <div className="account-detail">
            <div className="account-headings">
                <h5>Document No</h5>
                <h5 className='text-center'>Document Type</h5>
                <h5 className='text-center'>Status</h5>
                <h5 className='text-center'>Action</h5>
            </div>
            {
                credentials.map(({id, identityimage, idno, mode_of_id, is_approve}) => {
                    return(
                        <div className="account-data" key={id}>
                            <p className='idno'>{idno}</p>
                            <p className='text-center'>{mode_of_id}</p>
                            <p className='text-center'>{`${is_approve ? "Approved" : "Pending Approval"}`}</p>
                            <a href={identityimage} target="_blank" rel="noreferrer" className='text-center'>
                                <img src={view} alt="" />
                            </a>
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default DocumentCollapse