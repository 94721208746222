import React, { useState, useEffect } from 'react'
import IndividualDataTable from './IndividualDataTable'
import PersonalTags from './PersonalTags'
import TopMenu from '../KYC/AllAccounts/TopMenu'
import KYCTAB from '../KYC/KYCTAB'
import '../KYC/AllAccounts/AllAccounts.css';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import axios from 'axios'
import { irsAuthBaseUrl } from '../../common/apis/userApi'

const PersonalBranchList = () => {

    const { user } = useSelector(userSelector);
    const [loading, setloading] = useState(false);
    const [allAccounts, setAccounts] = useState([]);
    const [pageCount, setpageCount] = useState(1);

    const fetchingData = async (page) => {
        try {
            setloading(true);
            const allAccount = await axios.get(`${irsAuthBaseUrl}/kyc/fixed-deposit/sales-officer/${user.id}?page=${page}`)
            const response = allAccount.data;
            setAccounts(response.data)
            setpageCount(response.count)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    useEffect(() => {
        fetchingData(1)
    },[])

    const url_link = `/${user.id}`

  return (
    <div className='all-accounts'>
        <PersonalTags />
        <div className="dash-wrapper">
            <KYCTAB menuFor={"Individual KYC"}/>
            <TopMenu setAccounts={setAccounts} setloading={setloading} type={"individual"}/>
            <div className="px-4">
                <IndividualDataTable 
                loading={loading}
                fetchingData={fetchingData}
                pageCount={pageCount}
                allData={allAccounts}
                user={url_link}
                officer={""}
                />
            </div>
        </div>
    </div>
  )
}

export default PersonalBranchList