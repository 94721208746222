import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'antd';
import { documentBaseUrl } from '../../common/apis/userApi';
import '../Preview/Preview.css';
import logo from '../../assets/primeralogo.jpeg';

const Preview = ({ values, current, setCurrent, user, formData, filedata }) => {

    const [disabled, setDisabled] = useState(false)
    const navigate = useNavigate();

    const approvingStaff = values.approvingData;
    const concurrence = values.concurrenceData;

    const documentDate = new Date().toLocaleDateString();

    const prev = () => {
        setCurrent(current - 1);
    };

    const uploadingDoc = async (memoID) => {
            
        const newForm = new FormData();
        newForm.append("document", filedata)
    
        try {
          const sendingImage = await axios.post(`${documentBaseUrl}/adddocument/${memoID}`, newForm );
          const result = sendingImage.data;
          if(result.code === 200){
            toast.success("Document submitted successfully")
          }
        } catch (error) {
          console.log(error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);
  
        const JsonData = JSON.stringify({
              "app_type": formData.docType,
              "dept_id": formData.from,
              "office_id": formData.to,
              "subject": formData.subject,
              "background": formData.background,
              "memo_request": formData.Request,
              "justification": formData.Justification,
              "requester_id" : user.id,
              "no_of_approval": formData.noOfApproval,
              "no_of_concurrence": formData.noOfconcurrence,
              "review_by_id": formData.reviewBy,
              "approvingStaffs": formData.approvingStaff,
              "concurrence": formData.concurrence,
              "watermark" : formData.memoType 
        })
        try {
            const query = await axios({
                method : "POST",
                url : `${documentBaseUrl}/memoapp`,
                data : JsonData,
                headers : {
                  'Content-Type': 'application/json'
                }
            })
            
            const response = query.data;
            if(response.code === 200){
              const memoID = response?.response?.data.applicationId;
              uploadingDoc(memoID);
              setTimeout(() => {
                navigate('/my-applications')
              }, 3000)
            }
          } catch (error) {
            setDisabled(false);
            const errorMsg = error.response.data
            if(errorMsg.code === 400){
              toast.error(errorMsg.response.data.message);
            }else{
              toast.error(errorMsg.response.data.message)
            }
            console.log(errorMsg)
        }
    }

  return (
    <div className='preview document-type'>
        <div className="content preview-content" id="section-to-print" >
            <div className="content-wrapper">
                <div className="logo-intro text-center">
                    <img src={logo} width="70px" alt="primera logo" />
                    <h3 className='mb-4'>Internal Memo</h3>
                </div>
                <div className="heading">
                    <div className="heading-item">
                        <h4>FROM</h4>
                        <input type="text" defaultValue={`${values.fromData}`}/>
                    </div>
                    <div className="heading-item">
                        <h4>TO</h4>
                        <input type="text" defaultValue={`${values.toData}`}/>
                    </div>
                    <div className="heading-item">
                        <h4>DATE</h4>
                        <input type="text" defaultValue={documentDate}/>
                    </div>
                    <div className="heading-item">
                        <h4>SUBJECT</h4>
                        <input type="text" defaultValue={values.subject}/>
                    </div>
                </div>
                <div className="body">
                    <div className="bg d-flex mb-3">
                        <h5>Background:</h5>
                        <p>{values.background}</p>
                    </div>
                    <div className="request d-flex mb-3">
                        <h5>Request:</h5>
                        <p>{values.Request}</p>
                    </div>
                    <div className="justification d-flex mb-3">
                        <h5>Justification:</h5>
                        <p>{values.Justification}</p>
                    </div>
                </div>
                <div className="requester">
                    <h5>Requester:</h5>
                    <div className="list-wrapper">
                        <div className="form_grouping">
                            <input type="text" defaultValue={user.name}/>
                            <label htmlFor="">Name</label>
                        </div>
                        <div className="form_grouping">
                            <input type="text" />
                            <label htmlFor="">e-signature</label>
                        </div>
                    </div>
                </div>
                <div className="requester">
                    <h5>Reviewed by:</h5>
                    <div className="list-wrapper">
                        <div className="form_grouping">
                            <input type="text" defaultValue={values.reviewData}/>
                            <label htmlFor="">Name</label>
                        </div>
                        <div className="form_grouping">
                            <input type="text" />
                            <label htmlFor="">e-signature</label>
                        </div>
                    </div>
                </div>
                <div className="approval d-flex">
                    <h5>Concurrence</h5>
                    <div className="list-wrapper">
                    {
                        concurrence.map(({staffid}, index) => {
                            return <div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffid}/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    <input type="text" />
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="approval d-flex">
                    <h5>Approval</h5>
                    <div className="list-wrapper">
                    {
                        approvingStaff.map(({staffid}, index) => {
                            return<div className='d' key={index}>
                                <div className="form_grouping">
                                    <input type="text" defaultValue={staffid}/>
                                    <label htmlFor="">Name</label>
                                </div>
                                <div className="form_grouping">
                                    <input type="text" />
                                    <label htmlFor="">e-signature</label>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="memo-footer">
                    <h4>PRIMERA MICROFINANCE BANK</h4>
                    <div className="address">
                        <p className='grey'>24, kofo Abayomi streeet, victoria Island, Lagos.</p>
                        <p className='grey'>T: +234 1 277 0827-32 </p>
                        <p className='website'>www.primerabank.com</p>
                    </div>
                </div>
            </div>
            <div className="water-mark">
                <p className='water-mark-text'>{values.memoType}</p>
            </div>
        </div>
        <div className="steps-actions mt-3">
            <Button style={{ width: '100%' }} disabled={disabled} loading={disabled} type='primary' onClick={handleSubmit}>Submit</Button>
            <Button style={{ width: '100%' }} onClick={() => prev()}>Previous</Button>
        </div>
    </div>
  )
}

export default Preview