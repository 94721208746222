import React, { useState } from 'react'
import '../Permissions/Permissions.css';
import speaker from '../../assets/image 4.svg';
import CASA from '../../assets/casa.png';
import { Link } from 'react-router-dom';
import HomeTags from '../../components/HomeTags/HomeTags';
import logo from '../../assets/image 1.png';

const PermissionMenu = () => {
    const [active] = useState({
        dashboard : false,
        myProfile : false,
        menus : false,
        permission : true,
        user2fa : false,
        oldirs : false
    })

  return (
    <div className='permissions'>
        <div className="permission-wrapper">
            <div className="inner-wrapper">
                <div className="list-wrapper">
                    <HomeTags active={active}/>
                    <div className="documents-wrapper">
                        <div className="heading">
                            <h5>PERMISSIONS</h5>
                        </div>
                        <div className="document-tab-wrapper">
                            <div className="document-tabs">
                                <div className='box-blue'>
                                    <Link to='/permission/roles' className='d-flex flex-column'>
                                        <img src={logo} alt="" width="80px"/>
                                        <p className='mt-2'>PERMISSIONS</p>
                                    </Link>
                                </div>
                                <div className='box-yellow'>
                                    <Link to='/permissions/all-users' className='d-flex flex-column'>
                                        <img src={CASA} alt="" width="80px" />
                                        <p className='mt-2'>PERMISSIONS</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="lower-message d-flex align-items-center">
                                <img src={speaker} width="70px" alt="loud speaker icon" />
                                <p>Remember Primera's information and data security depends on you. Kindly ensure all data and information are properly handled with due diligence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PermissionMenu;