import React, { useEffect, useState } from 'react'
import { Button, message, Spin, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import KYCTAB from './KYCTAB'
import KYCTags from './KYCTags'
import './EditKyc.css'
import backArrow from '../../assets/Vector (6).svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getAxiosInstance } from '../../common/apis/createAxios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import TextInputTwo from '../../components/TextInputTwo/TextInputTwo';


const EditKyc = () => {

    const history = useNavigate();
    const [data, setData] = useState([]);
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);
    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [dataEntryEmail, setDataEntryEmail] = useState('');
    const { token, user } = useSelector(userSelector);
    const { slug } = useParams();

    useEffect(() => {
        const getRecord = async() => {
            try {
                const kycRecords = await getAxiosInstance(token).get(`/kyc/corporate-account/view/${slug}`); 
                const results = kycRecords.data;
                setData(results[0]);    
                
                const allStaff = await getAxiosInstance(token).get(`/staff/active`);
                const response = allStaff.data;
                setStaff(response.data);
            } catch (error) {
                message.error('unable to fetch record... Please refresh page');
            }
        }
        getRecord();
    },[])

    const [formData, setFormdata] = useState({
        date_of_incorporation: data.date_of_incorporation,
        company_name: data.company_name || "",
        rc_number : data.rc_number || "",
        nature_of_business : data.nature_of_business || "",
        industry : data.industry || "",
        company_type : data.customer_type || "" ,
        sector : data.sector || "",
        tin : data.tin || "",
        certificate_type : data.certificate_type || "",
        nos_of_directors : data.nos_of_directors || "",
        directors_bvn : data.directors_bvn || "",
        director_bvn_two : data.director_bvn_two || "",
        directors_id_type : data.directors_id_type || "",
        politically_exposed_person : data.politically_exposed_person || "",
        company_address : data.company_address || "",
        telephone : data.telephone || "",
        source_of_funds : data.source_of_funds || "",
        authorized_capital : data.authorized_capital || "",
        estimated_monthly_turn_over: data.estimated_monthly_turn_over || "",
        method_of_initial_deposit : data.method_of_initial_deposit || "",
        antimoney_laundry_risk_rating : data.antimoney_laundry_risk_rating || "",
        customer_type : data.customer_type || "",
        address_visited : data.address_visited || "",
        comments_on_location : data.comments_on_location || "",
        name_of_visiting_staff : data.name_of_visiting_staff || "",
        account_introducer : data.account_introducer || "",
        supervisorName : data.officer_id || "",
        data_entry_officer : data.sales_officer || "",
        company_email : data.company_email || "",
        initial_deposit : data.initial_deposit || "",
        ro_signature : data.ro_signature || "",
        // company_others : "",
    })
    
    useEffect(() => {
        if(data.length !== 0){
            setFormdata({...formData, rc_number: data.rc_number, company_name : data.company_name, date_of_incorporation : data.date_of_incorporation, nature_of_business : data.nature_of_business, industry : data.industry, tin : data?.identification[0].tin, company_type : data.company_type, sector : data.sector, certificate_type : data?.identification[0].certificate_type, nos_of_directors : data?.identification[0].nos_of_directors, directors_bvn : data?.identification[0].directors_bvn, director_bvn_two : data?.identification[0].director_bvn_two, directors_id_type : data?.identification[0].directors_id_type, antimoney_laundry_risk_rating : data?.address[0].antimoney_laundry_risk_rating, estimated_monthly_turn_over : data?.address[0].estimated_monthly_turn_over, initial_deposit : data.initial_deposit, company_address : data?.address[0].company_address, telephone : data?.address[0].telephone, source_of_funds : data?.address[0].source_of_funds, authorized_capital : data?.address[0].authorized_capital, method_of_initial_deposit : data?.address[0].method_of_initial_deposit, customer_type : data?.address[0].customer_type, address_visited : data?.address[0].address_visited, comments_on_location : data?.address[0].comments_on_location, name_of_visiting_staff : data?.address[0].name_of_visiting_staff, account_introducer : data?.address[0].account_introducer, supervisorName : data.officer_id, data_entry_officer : data.sales_officer, company_email : data.company_email, ro_signature : data.ro_signature, politically_exposed_person : data?.identification[0].politically_exposed_person})
        }

    },[data])
    

    const handleChange = (e) => {
        setFormdata({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true)
        const values = formData;

        const formData2 = new FormData();
        formData2.append("date_of_incorporation", values.date_of_incorporation);
        formData2.append("company_name", values.company_name)
        formData2.append("rc_number", values.rc_number)
        formData2.append("nature_of_business", values.nature_of_business)
        formData2.append("industry", values.industry)
        formData2.append("company_type", values.company_type)
        formData2.append("sector", values.sector)
        formData2.append("certificate_type", values.certificate_type)
        formData2.append("tin", values.tin)
        formData2.append("nos_of_directors", values.nos_of_directors)
        formData2.append("directors_bvn", values.directors_bvn)
        formData2.append("director_bvn_two", values.director_bvn_two)
        formData2.append("directors_id_type", values.directors_id_type)
        formData2.append("politically_exposed_person", values.politically_exposed_person)
        formData2.append("company_address", values.company_address)
        formData2.append("telephone", values.telephone)
        formData2.append("source_of_funds", values.source_of_funds);
        formData2.append("authorized_capital", values.authorized_capital)
        formData2.append("estimated_monthly_turn_over", values.estimated_monthly_turn_over)
        formData2.append("method_of_initial_deposit", values.method_of_initial_deposit)
        formData2.append("antimoney_laundry_risk_rating", values.antimoney_laundry_risk_rating)
        formData2.append("customer_type", values.customer_type)
        formData2.append("address_visited", values.address_visited)
        formData2.append("comments_on_location", values.comments_on_location)
        formData2.append("name_of_visiting_staff", values.name_of_visiting_staff)
        formData2.append("account_introducer", values.account_introducer)
        formData2.append("requester_id", user.id.toString())
        formData2.append("officer_id", values.supervisorName)
        formData2.append("sales_officer", values.data_entry_officer)
        formData2.append("company_email", values.company_email)
        formData2.append("initial_deposit", values.initial_deposit)
        formData2.append("ro_signature", values.imageUrl)

        try {
            const editForm = await getAxiosInstance(token).post(`kyc/corporate-account/update/${slug}`, formData2);
            const result = editForm.data;
            if(result.status === 1){
                message.success(result.message)
                setTimeout(() => {
                    history(-1)
                },1500)
            }
        } catch (error) {
            setLoading(false)
            const responseError = error.response.data.message
            if(responseError?.company_name){
                message.error(responseError?.company_name[0])
            }
            if(responseError?.company_email){
                message.error(responseError?.company_email[0])
            }
            if(responseError?.initial_deposit){
                message.error(responseError?.initial_deposit[0])
            }
            if(responseError?.rc_number){
                message.error(responseError?.rc_number[0])
            }
            if(responseError?.telephone){
                message.error(responseError?.telephone[0])
            }
            if(responseError?.tin){
                message.error(responseError?.tin[0])
            }
            if(responseError?.ro_signature){
                message.error(responseError?.ro_signature[0])
            }
        } 
    }

    const uploadButton = (
        <div>
            <Button icon={<PlusOutlined />}></Button>
            <div style={{ marginTop: 8}}>
            Upload
            </div>
      </div>
    );

    const beforeUpload =  (file) => {
        setFormdata({...formData, ro_signature : file})
    }

  return (
    <div className='edit-kyc'>
        <KYCTags />
        <div className="kyc-wrapper">
            <KYCTAB />
            <div className="edit-main-wrapper">
                <div className="heading">
                    <div className="back-arrow d-flex align-items-center mb-3">
                        <img src={backArrow} alt=""  onClick={() => history(-1)}/>
                        <h3 className='mb-0 ml-3'>Edit Customer’s Data</h3>
                    </div>
                </div>
                <div className="kyc-editform-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Date of Incorporation'
                                    type="date" 
                                    placeholder='First name, Other name-Last name' 
                                    name='date_of_incorporation' 
                                    value={formData.date_of_incorporation} 
                                    onChange={handleChange} 
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='RC Number'
                                    type="text" 
                                    placeholder='' 
                                    name='rc_number' 
                                    value={formData.rc_number} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Company Name'
                                type="text" 
                                placeholder='Enter company name' 
                                name='company_name' 
                                value={formData.company_name} 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Certificate Type'
                                type="text" 
                                placeholder='' 
                                name='certificate_type' 
                                value={formData.certificate_type} 
                                onChange={handleChange} 
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Tin'
                                    type="text" 
                                    placeholder='' 
                                    name='tin' 
                                    value={formData.tin} 
                                    onChange={handleChange} 
                                
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Nature of business'
                                    type="text" 
                                    placeholder='' 
                                    name='nature_of_business' 
                                    value={formData.nature_of_business} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Industry'
                                    type="text" 
                                    placeholder='' 
                                    name='industry' 
                                    value={formData.industry} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Company Type</label>
                                    <select name="company_type" value={formData.company_type} onChange={handleChange} >
                                        <option value="" disabled selected>Select option</option>
                                        <option value="Limited Liability" >Limited Liability</option>
                                        <option value="Public Sector" >Public Sector</option>
                                        <option value="Business Enterprise" >Business Enterprise</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Sector'
                                    type="text" 
                                    placeholder='' 
                                    name='sector' 
                                    value={formData.sector} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        {/* <div className="w-100 mb-4" style={{display : formik.values.company_type === "others" ? "block" : "none"}}>
                            <TextInputTwo 
                                label='Specify Others'
                                type="text" 
                                placeholder='' 
                                name='company_others' 
                                value={formik.values.company_others} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                formik={formik}
                                style={{border : (formik.touched.company_others && formik.errors.company_others) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                            />
                        </div> */}
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Source of funds'
                                type="text" 
                                placeholder='' 
                                name='source_of_funds' 
                                value={formData.source_of_funds} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                label='Number of Directors'
                                type="number" 
                                placeholder='' 
                                name='nos_of_directors' 
                                value={formData.nos_of_directors} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Director 1 BVN'
                                    type="text" 
                                    placeholder='Seperate directors BVN by commas' 
                                    name='directors_bvn' 
                                    value={formData.directors_bvn} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Director 2 BVN'
                                    type="text" 
                                    placeholder='Seperate directors BVN by commas' 
                                    name='director_bvn_two' 
                                    value={formData.director_bvn_two} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Directors Id type'
                                    type="text" 
                                    placeholder='' 
                                    name='directors_id_type' 
                                    value={formData.directors_id_type} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="d-flex my-4">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Politically exposed person'
                                    type="text" 
                                    placeholder='' 
                                    name='politically_exposed_person' 
                                    value={formData.politically_exposed_person} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Anti money laundering risk rating</label>
                                    <select value={formData.antimoney_laundry_risk_rating} name="antimoney_laundry_risk_rating" onChange={handleChange}>
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="Low" >Low</option>
                                        <option value="Medium" >Medium</option>
                                        <option value="High" >High</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <TextInputTwo 
                                label='estimated_monthly_turn_over'
                                type="number" 
                                placeholder='' 
                                name='estimated_monthly_turn_over' 
                                value={formData.estimated_monthly_turn_over} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                label='Initial deposit'
                                type="number" 
                                placeholder='' 
                                name='initial_deposit' 
                                value={formData.initial_deposit} 
                                onChange={handleChange} 
                                 
                                />
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Company Email'
                                type="text" 
                                placeholder='' 
                                name='company_email' 
                                value={formData.company_email} 
                                onChange={handleChange} 
                                
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Telephone'
                                type="text" 
                                placeholder='' 
                                name='telephone' 
                                value={formData.telephone} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Customer Type</label>
                                    <select value={formData.customer_type} name="customer_type" onChange={handleChange}>
                                        <option value="" disabled selected>Select customer type</option>
                                        <option value="walk-in">Walk-in</option>
                                        <option value="marketed">Marketed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                            label='Company Address '
                            type="text" 
                            placeholder='' 
                            name='company_address' 
                            value={formData.company_address} 
                            onChange={handleChange} 
                            
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                label='Authorized capital '
                                type="text" 
                                placeholder='' 
                                name='authorized_capital' 
                                value={formData.authorized_capital} 
                                onChange={handleChange} 
                                
                                />
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Method of initial deposit</label>
                                    <select name="method_of_initial_deposit" value={formData.method_of_initial_deposit} onChange={handleChange}  >
                                    <option value="" disabled selected>Select initial deposit</option>
                                        <option value="cash">Cash</option>
                                        <option value="cheque">Cheque</option>
                                        <option value="transfer">Transfer</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Address Visited'
                                type="text" 
                                placeholder='' 
                                name='address_visited' 
                                value={formData.address_visited} 
                                onChange={handleChange} 
                                
                            />
                        </div>
                        <div className="w-100 mb-3">
                            <TextInputTwo 
                                label='Comments on location'
                                type="text" 
                                placeholder='' 
                                name='comments_on_location' 
                                value={formData.comments_on_location} 
                                onChange={handleChange} 
                                 
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Name of visiting staff'
                                    type="text" 
                                    placeholder='' 
                                    name='name_of_visiting_staff' 
                                    value={formData.name_of_visiting_staff} 
                                    onChange={handleChange} 
                
                                />
                            </div>
                            <div className="w-100">
                                <TextInputTwo 
                                    label='Account Introducer'
                                    type="text" 
                                    placeholder='' 
                                    name='account_introducer' 
                                    value={formData.account_introducer} 
                                    onChange={handleChange} 
                                    
                                />
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Supervisor Name</label>
                                    <select name="supervisorName" value={formData.supervisorName} onChange={handleChange}>
                                        <option value="" disabled selected>Select suspervisor</option>
                                        {
                                            staff && staff.map(({ staff }) => {
                                                const { name, id} = staff;

                                                return(
                                                    <option value={id} key={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Supervisor Email</label>
                                    <input type="text" disabled value={supervisorEmail} className="email_input"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Data Entry Officer</label>
                                    <select name="data_entry_officer" value={formData.data_entry_officer} onChange={handleChange}>
                                        <option value="" disabled selected>Select entry officer</option>
                                        {
                                            staff && staff.map(({ staff }) => {
                                                const { name, id} = staff;

                                                return(
                                                    <option value={id} key={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">Data Entry officer name</label>
                                    <input type="text" disabled value={dataEntryEmail} className="email_input"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <div className="form-group">
                                    <label htmlFor="">RO signature</label>
                                    <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    beforeUpload={beforeUpload}
                                    >
                                        {
                                            uploadButton
                                        }
                                    </Upload>
                                </div>
                            </div>
                            <div className="w-100"></div>
                        </div>
                        <button type='submit' disabled={loading} className="edit-kyc-btn w-100" style={{cursor : loading ? "not-allowed" : "pointer", opacity : loading ? "0.6" : "1"}}>
                            {
                                loading && <Spin />
                            }
                            <span>
                                Edit Request
                            </span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditKyc
