import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../Products/product.css'
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import Header from '../../components/header/header';
import speaker from '../../assets/image 4.svg';
import Tag from '../../components/Tag/Tag';
import coins from '../../assets/koins_logo_3.png';
import CASA from '../../assets/casa.png';
import { userSelector } from '../../feature/Login/loginSlice';
import coming from '../../assets/coming-soon.gif';

const Products = () => {

    const { role, user } = useSelector(userSelector);

    const permission = role?.find(item => {
        const isAuthorized =  item?.user_id === user?.id;
        return isAuthorized
    })

    const isPermitted = permission?.status === "Super Admin";
  return (
    <div className='products'>
        <div className="main_wrapper">
            <Header />
            <div className="dashboard-content">
                <div className="container_">
                    <div className="dashboard-inner-wrapper">
                        <Tag />
                        <div className="documents-wrapper">
                            <div className="heading">
                                <h5>PRODUCTS</h5>
                            </div>
                            <div className="document-tab-wrapper">
                                <div className="document-tabs">
                                    {
                                        isPermitted && <div className='box-yellow'>
                                                        <Link to='/koins/customers' className='d-flex flex-column'>
                                                            <img src={coins} alt="" width="80px" />
                                                            <p className='mt-2'>KOINS</p>
                                                        </Link>
                                                    </div>
                                    }
                                    {
                                        isPermitted && <div className='box-blue'>
                                                        <Link to='/casa' className='d-flex flex-column'>
                                                            <img src={CASA} alt="" width="100px" />
                                                            <p className='mt-2'>CASA</p>
                                                        </Link>
                                                    </div>
                                    }
                                    {
                                        !isPermitted && <img src={coming} alt="" width="250px" />
                                    }
                                    
                                </div>
                                <div className="lower-message d-flex align-items-center">
                                    <img src={speaker} width="70px" alt="loud speaker icon" />
                                    <p>Remember Primera's information and data security depends on you. Kindly ensure all data and information are properly handled with due diligence.</p>
                                </div>
                            </div>
                        </div>
                        <BirthdayCard />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Products