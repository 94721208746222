import React, { useEffect, useState } from 'react'
import Header from '../../components/header/header'
import '../AllDocumentApplication/alldocumentapplication.css';
import '../TicketList/TicketList.css';
import '../MyDocumentAppplications/myapplicationpreview.css';
import Tags from '../../components/Tags/Tags'
import { documentBaseUrl } from '../../common/apis/userApi'
import { useSelector } from 'react-redux'
import { userSelector } from '../../feature/Login/loginSlice'
import { ToastContainer } from 'react-toastify';
import view from '../../assets/visibility.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';

const ApprovalList = () => {

    const history = useNavigate();

    const { user } = useSelector(userSelector);
    const [allApplication, setAllApplication] = useState([]);
    const [filter, setFilter] = useState("");
    const [pageCount, setPageCount] = useState(1)

    useEffect(() => {
        fetchingData(1);
    }, []);

    const fetchingData = async (page) => {
        try {
            const query = await axios.get(`${documentBaseUrl}/myapprovalapplication/${user.id}?page=${page}`);
            const result = query.data;
            setAllApplication(result);
            setPageCount(result.count)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        setFilter(e.target.value)
    }

    const handleClick = async(e) => {
        e.preventDefault();
        let apiKeyword = "";

        if(filter === "reject"){
            apiKeyword = "reject"
        }else if(filter === "approve"){
            apiKeyword = "approved"
        }else if(filter === "pending"){
            apiKeyword = "pending"
        }

        try {
            const filterList = await axios.get(`${documentBaseUrl}/myapproval-filter/${apiKeyword}/${user.id}`);
            const result = filterList.data;
            setAllApplication(result);
        } catch (error) {
            console.log(error)
        }
    }

    const applicationList = allApplication.results

    const handlePreview = (e) => {
        const { id } = e.currentTarget;
        const targetItem = applicationList.filter(item => item.applicationId === id);
        sessionStorage.setItem('my-targetdoc', JSON.stringify(targetItem));
        history(`/preview`);
    }


  return (
    <div className='all-document'>
        <Header />
        <div className="container_">
            <Tags />
            <div className="application-wrapper p-5">
                <ToastContainer closeButton={false} position="top-right" />
                <div className="accounts-list">
                    <div className="tickets-table h-min pb-5">
                        <h3 className='mb-5'>My Approval List</h3>
                        <div className="filter-wrapper mb-3">
                            <form action="">
                            <div className="form-group d-flex align-items-center">
                                <select name="filter" value={filter} onChange={handleChange}>
                                    <option value="" disabled>Select type</option>
                                    <option value="reject">All Rejection</option>
                                    <option value="approve">All Approval</option>
                                    <option value="pending">All Pending</option>
                                </select>
                                <button onClick={handleClick}>Filter</button>
                            </div>
                            </form>
                            
                        </div>
                        <table className='rounded'>
                            <thead>
                                <tr>
                                    <th className='px-2'>Document ID</th>
                                    <th>Subject</th>
                                    <th>To</th>
                                    <th>Status</th>
                                    <th>Date created</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    applicationList && applicationList.map(({reviewerBy, applicationId, office_name, subject, dateCreated, staffConcur, approvingStaff}, id) => {

                                        const renderDate = dateCreated.split("T")[0];
                                        
                                        const concurStatus = staffConcur.filter(item => item.signed === false);
                                        const approvalStatus = approvingStaff.filter(item => item.signed === false);
                                        const reviewStatus = reviewerBy.filter(item => item.reviewed === false);

                                        const concurReject = staffConcur.filter(item => item.decline.length >= 1);
                                        const approvalReject = approvingStaff.filter(item => item.decline.length >= 1);

                                        console.log(concurReject)
                                        let authorizationStatus = "loading"
                                        if(reviewStatus && reviewStatus.length !== 0){
                                            authorizationStatus = <p style={{color : "gold"}}>awaiting review</p>
                                        }else if(reviewStatus.length === 0 && concurStatus && concurStatus.length !== 0 && concurReject.length === 0){
                                            authorizationStatus = <p style={{color : "#e8d892"}}>Pending Concurrence</p>
                                        }else if(concurReject && concurReject.length !== 0){
                                            authorizationStatus = <p style={{color : "red"}}>Rejected</p>
                                        }else if(concurStatus.length === 0 && approvalStatus && approvalStatus.length !== 0){
                                            authorizationStatus = <p style={{color : "#e8d892"}}>Pending Approval</p>
                                        }else if(approvalReject && approvalReject.length !== 0){
                                            authorizationStatus = <p style={{color : "red"}}>Rejected Approval</p>
                                        }else if(concurStatus.length  === 0 && approvalStatus.length === 0){
                                            authorizationStatus = <p style={{color : "green"}}>Approved</p>
                                        }

                                        return(
                                            <tr key={applicationId}>
                                                <td className='px-2'>{applicationId}</td>
                                                <td>{subject}</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <span>{office_name}</span>
                                                    </div>
                                                </td>
                                                <td className='medium'>
                                                    <span>{authorizationStatus}</span>
                                                </td>
                                                <td>{renderDate}</td>
                                                <td className='text-center'>
                                                    <img src={view} alt="eye icon" className="eye" id={applicationId} onClick={handlePreview}/>
                                                    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <Pagination 
                    pageSize={5}
                    total={pageCount}
                    onChange={(page) => fetchingData(page)}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ApprovalList