import React from 'react'
import '../KYC/Newkyc/personal-info.css';
import TextInput from '../../common/TextInput/TextInput';

const OtherInformation = ({ formik, setValid }) => {

    if(formik.values.bvn !== "" && formik.values.kin_name !== "" && formik.values.kin_phone !== "" && formik.values.kin_relationship !== "" && formik.values.kin_address !== "" && formik.values.kin_occupation !== "" && formik.values.bank_name && formik.values.account_number && formik.values.customer_phone ){
        setValid(true)
    }

  return (
    <div className='personal-info'>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='BVN'
                        type="number" 
                        placeholder='Enter BVN' 
                        name='bvn' 
                        value={formik.values.bvn} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.bvn && formik.errors.bvn) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Next of kin Name'
                        type="text" 
                        placeholder='Enter kin name' 
                        name='kin_name' 
                        value={formik.values.kin_name} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.kin_name && formik.errors.kin_name) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Next of Kin Number'
                        type="number" 
                        placeholder='Enter number' 
                        name='kin_phone' 
                        value={formik.values.kin_phone} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.kin_phone && formik.errors.kin_phone) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Next of kin relationship'
                        type="text" 
                        placeholder='Enter kin relationship' 
                        name='kin_relationship' 
                        value={formik.values.kin_relationship} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.kin_relationship && formik.errors.kin_relationship) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
        </div>
        <div className="w-100 mb-3">
            <div className="form-group">
                <TextInput 
                    label='Next of kin address'
                    type="text" 
                    placeholder='Enter kin address' 
                    name='kin_address' 
                    value={formik.values.kin_address} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.kin_address && formik.errors.kin_address) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Next of Kin Occupation'
                        type="text" 
                        placeholder='Enter occupation' 
                        name='kin_occupation' 
                        value={formik.values.kin_occupation} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.kin_occupation && formik.errors.kin_occupation) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Bank Name'
                        type="text" 
                        placeholder='Enter bank name' 
                        name='bank_name' 
                        value={formik.values.bank_name} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.bank_name && formik.errors.bank_name) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Account Number'
                        type="number" 
                        placeholder='Enter acccount number' 
                        name='account_number' 
                        value={formik.values.account_number} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.account_number && formik.errors.account_number) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <TextInput 
                        label='Phone Number'
                        type="number" 
                        placeholder='Enter phone number' 
                        name='customer_phone' 
                        value={formik.values.customer_phone} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        formik={formik}
                        style={{border : (formik.touched.customer_phone && formik.errors.customer_phone) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default OtherInformation