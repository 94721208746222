import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './addCustomer.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/header/header';
import CasaTags from '../CasaTags/CasaTags';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../../common/button/button';
import { stateandLga } from '../../../common/stateLgaList';

const AddCustomer = () => {

    const history = useNavigate();
    const [showModal, setShowModal] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [userBvn, setBvn] = useState([]);
    const [bvnData, setBvnData] = useState([]);

    const authAxios = axios.create({
        baseURL : casaBaseurl,
    }) 

    const formik = useFormik({
        initialValues: {
            maidenName: "",
            country: "",
            state: "",
            lga: "",
            address: "",
            altMobileNo: "",
            email: "",
            customerType: "",
            title: "",
            maritalStatus: "",
            initials: "",
            gender: "",
            idtype: "",
            idpassportNo:"",
            expireDate: "",
            employerName: "",
            employerAddress: ""
        },
        validationSchema: Yup.object({
            maidenName: Yup.string(),
            country: Yup.string().required('Required.'), 
            state: Yup.string().required('Required.'), 
            lga: Yup.string().required('Required.'), 
            address: Yup.string().required('Required.').max(26, 'Address cannot exceed 24 characters'), 
            altMobileNo: Yup.number(), 
            email: Yup.string().email('Invalid email address').required('Required'), 
            customerType: Yup.string().required('Required.'),
            title: Yup.string().required('Required.'),
            maritalStatus: Yup.string().required('Required.'),
            initials: Yup.string().required('Required.'),
            gender: Yup.string().required('Required.'),
            idtype: Yup.string(),
            idpassportNo: Yup.string(),
            expireDate: Yup.string(),
            employerName: Yup.string().required("Required"),
            employerAddress: Yup.string().required("Required"),
        }),
        isInitialValid : false,     
        onSubmit : (values, onSubmitProps) => {
             handleSubmit(values, onSubmitProps)
            }
        }
    );

    const handleCloseModal = () => {
        setShowModal(!showModal);
        history(-1)
    }

    const handleChangeModal = (e) => {
        setBvn(e.target.value)
    }

    //BVN VERIFICATION

    const handleVerify = async (e) => {
        e.preventDefault();
        setDisabled(true);
        try {
            const query = await authAxios.get(`/customer_record/${userBvn}`);
            const result = query.data;
            if(result.code === 200){
                setBvnData(result.body.data);
                setShowModal(false);
            }
        } catch (error) {
            setDisabled(false);
            const errorMessage = error.response.data.body.data.message;
            toast.error(errorMessage)
            setTimeout(() => {
                history(-1);
            }, 3000)
        }
    }

    const {firstname, lastname, middlename, mobileNo, dateOfBirth, bvn} = bvnData;

    const handleSubmit = async (values, onSubmitProps) => {

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        }
    
        const newDate = formatDate(dateOfBirth);

        const jsonData = {
            "firstName": firstname,
            "lastName": lastname,
            "middleName": middlename,
            "maidenName": values.maidenName,
            "country": values.country,
            "state": values.state,
            "lga": values.lga,
            "address": values.address,
            "bvn": bvn,
            "mobileNo": mobileNo,
            "altMobileNo": values.altMobileNo,
            "email": values.email,
            "customerType": values.customerType,
            "title": values.title,
            "maritalStatus": values.maritalStatus,
            "initials": values.initials,
            "gender": values.gender,
            "dob": newDate,
            "idtype": "001",
            "idpassportNo": values.idpassportNo,
            "expireDate": values.expireDate,
            "employerName": values.employerName,
            "employerAddress": values.employerAddress
        }
        
        try {
            const addingCustomer = await authAxios.post('/customer', jsonData);
            const response = addingCustomer.data;
            if(response.code === 200){
                toast.success(response.body.data, {
                    toastId : "addcusomter1"
                })
                setTimeout(() => {
                    history('/customer/all')
                }, 2000);
            }
            console.log(response)
        } catch (error) {
            const errMsg = error.response.data.body;
            toast.error(errMsg.data, {
                toastId : "error1"
            })
            onSubmitProps.setSubmitting(false)
        }
    }

    let selectedState = "";
    let lgas = "";
    if(formik.values.state !== ""){
        selectedState = stateandLga.filter(item => item.state === formik.values.state)
    }
    if(selectedState !== ""){
        lgas = selectedState[0].lgas
    }

  return (
    <div className='add-customer'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags/>
            <div className="add-customer-form">
                <div className={"modal fade" + (showModal ? " show d-block" : " d-none") } id='exampleModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Verify BVN</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                <form action="" onSubmit={handleVerify} >
                                    <div className="form-group">
                                        <label htmlFor="">Enter customer BVN</label><br></br>
                                        <input type="number" 
                                        value={userBvn}
                                        name="userBvn" 
                                        className='w-100 my-3'
                                        onChange={handleChangeModal}
                                        required
                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <Button value="Verify BVN" type="submit" disabled={disabled}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staff-form-wrapper">
                <ToastContainer closeButton={false} position="top-right" />
                    <form onSubmit={formik.handleSubmit}>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Basic Information</legend>
                            <div className="w-full py-5 grid lg:grid-columns-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" defaultValue={firstname || ""} readOnly />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" defaultValue={lastname || ""} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="middleName">Middle</label>
                                    <input type="text" defaultValue={middlename} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <select name="title" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Prof">Prof</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Barr">Barr</option>
                                    </select>
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className='error-message'>{formik.errors.title}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gender">Gender</label>
                                    <select name="gender" value={formik.values.gender} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Not applicable">Not applicable</option>
                                    </select>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div className='error-message'>{formik.errors.gender}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="dob">Date of birth</label>
                                    <input type="text" defaultValue={dateOfBirth} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Initial</label>
                                    <input type="text" placeholder='A.A' name="initials" value={formik.values.initials} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.initials && formik.errors.initials ? (
                                        <div className='error-message'>{formik.errors.initials}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Marital Status</label>
                                    <select name="maritalStatus" value={formik.values.maritalStatus} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select Marital status</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Widow">Widow</option>
                                        <option value="Widower">Widower</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Not applicable">Not applicable</option>
                                    </select>
                                    {formik.touched.maritalStatus && formik.errors.maritalStatus ? (
                                        <div className='error-message'>{formik.errors.maritalStatus}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Maiden Name</label>
                                    <input type="text" placeholder='' name="maidenName" value={formik.values.maidenName} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.maidenName && formik.errors.maidenName ? (
                                        <div className='error-message'>{formik.errors.maidenName}</div>
                                    ) : null}
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Contact Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select name="country" value={formik.values.country} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select country</option>
                                        <option value="Nigeria">Nigeria</option>
                                    </select>
                                    {formik.touched.country && formik.errors.country ? (
                                        <div className='error-message'>{formik.errors.country}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <select name="state" value={formik.values.state} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select state</option>
                                        {
                                            stateandLga.map(({state, lgas}, id) => {
                                               return <option key={id} value={state}>{state}</option>
                                            })
                                        }
                                    </select>
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className='error-message'>{formik.errors.state}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lga">Local Government</label>
                                    <select name="lga" value={formik.values.lga} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select local government area</option>
                                        {
                                           lgas && lgas.map((item, id) => {
                                               return <option key={id} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                    {formik.touched.lga && formik.errors.lga ? (
                                        <div className='error-message'>{formik.errors.lga}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input type="text" placeholder='24, kofo abayomi road, victoria Island, Lagos' name="address" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.address && formik.errors.address ? (
                                        <div className='error-message'>{formik.errors.address}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" name="email" placeholder='adajoe@gmail.com' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='error-message'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobileNo">Mobile no.</label>
                                    <input type="text" defaultValue={mobileNo} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Alt Mobile No. (optional)</label>
                                    <input type="text" placeholder='081 000 000 00' name="altMobileNo" value={formik.values.altMobileNo} onChange={formik.handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Identity Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="bvn">BVN</label>
                                    <input type="text" defaultValue={bvn} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Account Type</label>
                                    <select name="customerType" value={formik.values.customerType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select account type</option>
                                        <option value="individual">Individual</option>
                                        <option value="joint">Joint</option>
                                        <option value="minor">Minor</option>
                                    </select>
                                    {formik.touched.customerType && formik.errors.customerType ? (
                                        <div className='error-message'>{formik.errors.customerType}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">ID Type</label>
                                    <select name="idtype" value={formik.values.idtype} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                        <option value="">Select id type</option>
                                        <option value="001">Driver License</option>
                                        <option value="002">International Passport</option>
                                        <option value="003">National ID Card</option>
                                        <option value="004">Voters Card</option>
                                        <option value="005">Relaxed ID Card</option>
                                    </select>
                                    {formik.touched.idtype && formik.errors.idtype ? (
                                        <div className='error-message'>{formik.errors.idtype}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">ID Number</label>
                                    <input type="text" name="idpassportNo" value={formik.values.idpassportNo} onChange={formik.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expireDate">ID Expiry Date</label>
                                    <input type="date" name="expireDate" value={formik.values.expireDate} onChange={formik.handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Employee Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="employerName">Employer Name</label>
                                    <input type="text" name="employerName" value={formik.values.employerName} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.employerName && formik.errors.employerName ? (
                                        <div className='error-message'>{formik.errors.employerName}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Employer Address</label>
                                    <input type="text" name="employerAddress" value={formik.values.employerAddress} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.employerAddress && formik.errors.employerAddress ? (
                                        <div className='error-message'>{formik.errors.employerAddress}</div>
                                    ) : null}
                                </div>
                            </div>
                        </fieldset>
                        <button type='submit'>Create Customer</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddCustomer;