import React from 'react';
import menu1 from '../../assets/menu1.png'
import user from '../../assets/user (3).png'
import clipboard from '../../assets/clipboard.png';
import koinsLogo from '../../assets/koins_logo_2.png';
import ticket from '../../assets/list.png';
import '../HelpDeskTags/HelpDeskTags.css';
import { Link } from 'react-router-dom'

const KoinsTag = () => {
    const active = {
        background : "purple",
        color : "white",
        padding : "5px",
    }
  return (
    <div className='koinstag helpdesk-tags'>
        <div className="logo d-flex align-items-center mb-5">
            <img src={koinsLogo} alt="" width="50px"/>
            <h5>Koins Admin</h5>
        </div>
        <div className="content_">
            <div className="item" >
                <img src={user} alt="" />
                <Link to='/koins/customers'>Customers List</Link>
            </div>
            <div className="item">
                <img src={ticket} alt="" />
                <Link to='/koins/transactions'>All Transactions</Link>
            </div>
            <div className="item">
                <img src={ticket} alt="" />
                <Link to='/dashboard'>Go back to IRS</Link>
            </div>
            {/* 
            <div className="item">
                <img src={pending} alt="" />
                <Link to=''>Menu</Link>
            </div> */}
        </div>
    </div>
  )
}

export default KoinsTag