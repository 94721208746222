import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { toast, ToastContainer } from 'react-toastify';
import '../../CASA/updateStaff/updateStaff.css';
import './helpdeskdepartment.css';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import spyglass from '../../../assets/search.svg';
import axios from 'axios';
import { irsBaseUrl } from '../../../common/apis/userApi';
import { useNavigate } from 'react-router-dom';

const HelpdeskCreateDepartment = () => {

    const history = useNavigate();
    const { token, user} = useSelector(userSelector);

    const [staff, setStaff] = useState([]);
    const [department, setDepartment] = useState({
        name : "",
        team_lead_id : "",
        group_email : "", 
        hod : ""  
    });

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const staff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const stafflist = staff.data;
                setStaff(stafflist.data);

            } catch (error) {
                console.log(error);
            }
        }
        fetchingData()
    },[])

    const handleChange = (e) => {
        setDepartment({...department, [e.target.name] : e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, team_lead_id, group_email, hod } = department;
        const apiData = {
            "name" : name, 
            "team_lead_id" : team_lead_id,
            "group_email" : group_email, 
            "hod" : hod
        }

        try {
            const creatingDept = await authAxios.post(`${irsBaseUrl}/interdepartmental/ticket-department`, apiData);
            const response = creatingDept.data;
            if(response.created_at){
                toast.success("New department created successfully!");
                setTimeout(() => {
                    history('/helpdesk/department');
                }, 2000)
            }
            console.log(response)
        } catch (error) {
            console.log(error)
        }

    }

    const handleInputLead = () => {
        var val = document.getElementById("leadInput").value;
        let opts = document.getElementById('teamlead').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setDepartment({...department, team_lead_id : dataId})
            break;
            }
        }
    }
    const handleInputHod = () => {
        var val = document.getElementById("hodInput").value;
        let opts = document.getElementById('hod').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setDepartment({...department, hod : dataId})
            break;
            }
        }
    }

    console.log(department)
  return (
    <div className='helpdesk-department'>
        <Header />
        <div className="container__">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="help-createdepartment">
                    <div className="add-staff-form">
                        <ToastContainer closeButton={true} position="top-right" />
                        <div className="add-staff-wrapper">
                            <h4 className='mb-4'>Create Department</h4>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="form-data">
                                    <div className="form-group">
                                        <label htmlFor="">Name</label>
                                        <input type="text" placeholder='' name='name' value={department.name} onChange={handleChange} />
                                        
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Team Lead</label>
                                        <div className="input-wrapper w-100" >
                                            <img src={spyglass} alt="spy glasss icon" width="20px" />
                                            <input list="teamlead" id='leadInput' onInput={handleInputLead} placeholder="search staff" required/>
                                        </div>
                                        <datalist id="teamlead">
                                            {staff.map(({id, name}) =>
                                                <option key={id} value={name} id={id}>{name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Group Email</label>
                                        <input type="email" name="group_email" value={department.group_email} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">HOD</label>
                                        <div className="input-wrapper w-100" >
                                            <img src={spyglass} alt="spy glass icon" width="20px" />
                                            <input list="hod" id='hodInput' onInput={handleInputHod} placeholder="search staff" required/>
                                        </div>
                                        <datalist id="hod">
                                            {staff.map(({id, name}) =>
                                                <option key={id} value={name} id={id}>{name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                    <button type='submit' className='w-100 py-3'>Add Department</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpdeskCreateDepartment