import React, { useState, useEffect } from 'react'
import Header from '../../components/header/header'
import { toast, ToastContainer } from 'react-toastify';
import Swal  from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getAxiosInstance } from '../../common/apis/createAxios';
import {  useSelector, useDispatch } from 'react-redux';
import {  userSelector } from '../../feature/Login/loginSlice';
import { menuSelector, fetchingMenu } from '../../feature/Menus/Menus';
import '../CASA/CasaDocument/casaDocumentType.css';
import Notification from '../../components/Notification/Notification';
import '../dahsboardHome/dashboard.css';
import '../Menus/Menu.css';
import HomeTags from '../../components/HomeTags/HomeTags';

const Menus = () => {

    const dispatch = useDispatch();
    const { token } = useSelector(userSelector);
    const [disabled, setDisabled] = useState(false);
    const { menu } = useSelector(menuSelector);
    const [services, setServices] = useState([]);

    const [notification, setNotification] = useState(false);
    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [active] = useState({
        dashboard : false,
        myProfile : false,
        menus : true,
        permission : false,
        user2fa : false,
        oldirs : false
    })

    const [edit, setEdit] = useState({
        route: "",
        title: "",
        url: "",
        icon: "",
        is_external: "",
        is_parent: "",
        parent_id: "",
        parent_order: "",
        child_order: ""
    })

    useEffect(() => {
        dispatch(fetchingMenu(token))
    }, [])

    useEffect(() => {

        const fetchingPermission = async () => {
            const allPermission = await getAxiosInstance(token).get(`/microservice-permission-core`)
            const response = allPermission.data;
            setServices(response.data)
        }
        fetchingPermission()
    },[])

    console.log(services)
    const handleChange = (e) => {
        e.persist();
        setState({...state, search : e.target.value})
    }

    const handleEditChange = (e) => {
        setEdit({...edit, [e.target.name] : e.target.value})
    }

    // const handleEditImage = (e) => {
    //     setEdit({...edit, icon : e.target.value})
    // }

    const handleEdit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const { id } = e.currentTarget;

        const { route, title, url, icon, is_external, is_parent, parent_id, parent_order, child_order } = edit;

        const jsonData = {
            "route": route,
            "title": title,
            "url": url,
            "icon": icon,
            "is_external": is_external,
            "is_parent": is_parent,
            "parent_id": parent_id,
            "parent_order": parent_order,
            "child_order": child_order
        }

        try {
            const removeDoc = await getAxiosInstance(token).put(`/menus/${id}`, jsonData);
            const result = removeDoc.data;
            if(result){
                toast.success("Menu updated successfully");
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data.message;
            console.log(apiMsg)
            toast.error(apiMsg)
            
        }
    }

    const showAlert = (e) => {

        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })
    }

    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await getAxiosInstance(token).delete(`/menus/${id}`);
            const result = removeDoc.data;
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            ).then(() => {
                setTimeout(() => {
                    window.location.reload();
                },1500)
            })

        } catch (error) {
            const apiMsg = error.response.data.response;
            toast.error(apiMsg.data)
        }  
    }
    
    const handleClose = () => {
        setNotification(!notification)
    }

    const parentList = menu && menu.filter(item => item.is_parent === 1);

  return (
    <div className='menus dashboard_ no-header'>
        <Header />
        <div className="container___">
            <div className="dashboard-wrapper">
                <ToastContainer closeButton={true} position="top-right" />
                <HomeTags handleClose={handleClose} active={active}/>
                <Notification notification={notification} handleClose={handleClose}/>
                <div className="document-type-list">
                    <h3>Menu List</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <input type="search" placeholder='filter' value={state.search} onChange={handleChange}/>
                        <Link to='/menus/create'>New Menu</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Menu</h5>
                            <h5 className='text-center'>Route</h5>
                            <h5 className='text-center'>Is parent</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            menu && menu.map(({ id, title, route, is_parent }) => {
                                let parentText = ""
                                if(is_parent){
                                    parentText = "Yes"
                                }else{
                                    parentText = "No"
                                }
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <div className='colored'>
                                                <p>{title}</p>
                                            </div>
                                            <p className='text-center'>{route}</p>
                                            <p className='text-center'>{parentText}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                                <span className='edit text-center' type="button" data-bs-toggle="modal" data-bs-target={`#edit-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                    Edit
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal fade" id={`edit-form${id}`}>
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Edit Menu</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-wrapper">
                                                            <form action="" id={id} onSubmit={handleEdit}>
                                                                <div className="form-data">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Title</label>
                                                                        <input type="text" placeholder='Document' name='title' value={edit.title} onChange={handleEditChange}/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Is External</label>
                                                                        <select name="is_external" value={edit.is_external} onChange={handleEditChange}>
                                                                            <option value="" disabled></option>
                                                                            <option value="1" >Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Route</label>
                                                                        <input type="text" placeholder='' name='route' value={edit.route} onChange={handleEditChange}/>
                                                                    </div>
                                                                    {
                                                                        (edit.is_external === "0") && <div className="form-group">
                                                                            <label htmlFor="">URL</label>
                                                                            <input type="text" placeholder='' name='url' value={edit.url} onChange={handleEditChange}/>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Permission</label>
                                                                        <select name="icon" onChange={handleEditChange}>
                                                                            <option value="" selected disabled>Select Permission</option>
                                                                            {
                                                                                services && services.map(({id, name}) => {
                                                                                    return(
                                                                                        <option value={id} key={id}>{name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {/* <input type="file" name='icon' onChange={handleEditImage}/> */}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="">Is Parent</label>
                                                                        <select name="is_parent" value={edit.is_parent} onChange={handleEditChange}>
                                                                            <option value="" disabled></option>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        (edit.is_parent === "0") && <div className="form-group">
                                                                            <label htmlFor="">Select Parent</label>
                                                                            <select name="parent_id" value={edit.parent_id} onChange={handleEditChange}>
                                                                                <option value="" disabled></option>
                                                                                {
                                                                                    parentList && parentList.map(({ id, title}) => {
                                                                                        return(
                                                                                            <option value={id} key={id}>{title}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (edit.is_parent === "1") && <div className="form-group">
                                                                                <label htmlFor="">Parent Order</label>
                                                                                <input type="number" name='parent_order' value={edit.parent_order} onChange={handleEditChange}/>
                                                                            </div>
                                                                    }
                                                                    
                                                                    {
                                                                        (edit.is_parent === "0") && <div className="form-group">
                                                                            <label htmlFor="">Is Restricted</label>
                                                                            <select name='child_order' value={edit.child_order} onChange={handleEditChange}>
                                                                                <option value="" disabled>Select restriction</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>
                                                                            </select>
                                                                        </div>
                                                                    }
                                                                    
                                                                    <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Edit Menu</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Menus