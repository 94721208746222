import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../CasaDocument/casaDocumentType.css';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import axios from 'axios';
import { deferralBaseurl } from '../../../common/apis/userApi';
import { toast } from 'react-toastify';
import Swal  from 'sweetalert2';
import search from '../../../assets/search.svg';

const CasaDocumentType = () => {

    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [edit, setEdit] = useState({
        docName : "",
        days : ""
    })

    const [casaDoc, setCasaDoc] = useState([]);

    useEffect(() => {
        const fetchingData = async () => {
            const query = await axios.get(`${deferralBaseurl}/deferraldoctype`);
            const result = query.data;
            setCasaDoc(result);
        }
        fetchingData();
    },[])

    const handleChange = (e) => {
        e.persist();
        setState({...state, search : e.target.value})
    }

    const handleEditChange = (e) => {
        e.persist();
        setEdit({...edit, [e.target.name] : e.target.value})
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        if(edit.docName !== "" || edit.days !== ""){
            const { id } = e.currentTarget;
            const jsonData = {
                "docName": edit.docName,
                "days": edit.days
            }
    
            try {
                const removeDoc = await axios.patch(`${deferralBaseurl}/updatedoctype/${id}`, jsonData);
                const result = removeDoc.data.body;
                toast.success(result.data);
                setTimeout(() => {
                    window.location.reload();
                },2000)
    
            } catch (error) {
                const apiMsg = error.response.data.body;
                toast.error(apiMsg.data)
            }
        }
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })

    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await axios.delete(`${deferralBaseurl}/deletedoctype/${id}`);
            const result = removeDoc.data.body;
            console.log(result.data);
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            ).then(() => {
                setTimeout(() => {
                    window.location.reload();
                },2000)
            })

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error(apiMsg.data)
        }  
    }

    const AllDocs = casaDoc.results;

    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : casaDoc.next,
        })
        const data = response.data;
        setCasaDoc(data);
        window.scrollTo(0, 0);
    }

    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : casaDoc.previous,
        })
        const data = response.data;
        setCasaDoc(data);
        window.scrollTo(0, 0);
    }

    let totalCounts = Math.ceil(casaDoc.count/10);
    let currentCount = ''
    if(casaDoc.next != null){
        let next = casaDoc.next;
        currentCount = next.split("=")[1] - 1;
    }else if(casaDoc.next == null){
        currentCount = totalCounts;
    }

  return (
    <div className='casa-documentlist'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="document-type-list">
                <h3>All Document Types</h3>
                <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                    <form>
                        <input type="search" placeholder='filter' value={state.search} onChange={handleChange}/>
                        <img src={search} alt="" />
                    </form>
                    <Link to='/document/create'>Create Document Type</Link>
                </div>
                <div className="doc-wrapper p-4">
                    <div className="doc-titles">
                        <h5>Document Type</h5>
                        <h5 className='text-center'>Diferral Days</h5>
                        <h5 className='text-center'>Actions</h5>
                    </div>
                    {
                        AllDocs && AllDocs.map(({ id, doc_name, days, date_created }) => {
                            return( 
                                <div className="doc-details" key={id}>
                                    <div className="item">
                                        <p className='colored'>{doc_name}</p>
                                        <p className='text-center'>{days}</p>
                                        <div className="d-flex justify-content-center text-center">
                                            <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                            <span className='edit text-center' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                Edit
                                            </span>
                                        </div>
                                    </div>
                                    <div className="collapse" id={`edit-form${id}`}>
                                        <div className="form-wrapper">
                                            <form action="" onSubmit={handleEdit} id={id} className="d-flex flex-row align-items-center justify-content-between">
                                                <input type="text" placeholder='e.g Passport' value={edit.docName} name="docName" onChange={handleEditChange} required/>
                                                <input type="number" placeholder='e.g 12' value={edit.days} name="days" onChange={handleEditChange} required/>
                                                <button type='submit' id={id}>Edit Document Type</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        AllDocs?.length < 1 && <p className='mt-4 no-records'>No record found</p>
                    }
                    {
                        totalCounts > 1 && <div className="nav-btns my-5">
                                                <button onClick={fetchingPrev}>Prev</button>
                                                <button onClick={fetchingNext}>Next</button>
                                            </div>
                    }
                    {
                        totalCounts > 1 && <p className='text-center'>{`Page ${currentCount} of ${totalCounts}`}</p>    
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaDocumentType;