import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { casaBaseurl } from '../../../common/apis/userApi';

const EditPermission = ({ id, selectedId, staffname }) => {

    const [disabled, setDisabled] = useState(false);
    const [permission, setPermission] = useState([]);

    const handleRole = (e) => {
        const data = e.target.value;
        if(e.target.checked){
            setPermission([...permission, data]);
        }else{
            setPermission(permission.filter((item) => item !== data))
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const { id } = e.currentTarget;

        const jsonData = {
            "permission": permission,
            "irs_id" : selectedId
        }

        try {
            const removeDoc = await axios.put(`${casaBaseurl}upadatestaff/${id}`, jsonData);
            const result = removeDoc.data;
            console.log(result)
            if(result.code === 200){
                toast.success("User Permission updated successfully");
                setTimeout(() => {
                    window.location.reload();
                },3000)
            }
        } catch (error) {
            setDisabled(false)
            const apiMsg = error.response.data.body.data;
            toast.error(apiMsg)
            
        }
    }

  return (
    <div className="modal fade" id={`edit-form${id}`}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Permission</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form id={id} onSubmit={handleEdit}>
                            <div className="form-data">
                                <div className="form-group">
                                    <label htmlFor="">Staff Name</label>
                                    <input type="text" placeholder='Samuel Oki' value={staffname} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Permission</label>
                                    <span className='d-flex align-items-center'>
                                        <input type="checkbox" name="permission" value="ro" onChange={handleRole}/>
                                        <span>RO</span>
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <input type="checkbox" name="permission" value="branch" onChange={handleRole}/>
                                        <span>Branch</span>
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <input type="checkbox" name="permission" value="authorizer" onChange={handleRole}/>
                                        <span>Authorizer</span>
                                    </span>
                                </div>
                                <button type='submit' className='w-100' style={{cursor : disabled ? "not-allowed" : "pointer"}}>Edit Menu</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditPermission