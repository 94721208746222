import React from 'react'
import '../footer/footer.css';

const Footer = () => {
  return (
    <div className='footer'>
        <p> © 2022 Primera Microfinancebank. All rights reserved </p>
    </div>
  )
}

export default Footer;