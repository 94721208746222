import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { userSelector } from '../../../feature/Login/loginSlice';
import { irsBaseUrl } from '../../../common/apis/userApi';

const EditCategoryModal = ({id, name, unit_id, preferred_resolver, department_id, status, tat, unit, department, authAxios, newStaffList}) => {

    const { user } = useSelector(userSelector)
    const [editCategory, setEditCategory] = useState({
        unit_id : unit_id, 
        name : name,
        department_id : department_id, 
        preferred_resolver : preferred_resolver,
        tat : tat,
        status : status
    })

    const handleEdit = async (e) => {
        e.preventDefault();
        const { id } = e.currentTarget;

        const { unit_id, name, department_id, preferred_resolver, tat, status} = editCategory;
        const jsonData = {
            "unit_id" : unit_id, 
            "name" : name,
            "department_id" : department_id, 
            "created_by" : user.id,
            "preferred_resolver" : preferred_resolver.value,
            "tat" : tat,
            "status" : status
        }

        try {
            const removeDoc = await authAxios.put(`${irsBaseUrl}/interdepartmental/ticket-category/${id}`, jsonData);
            const result = removeDoc.data;
            console.log(result.created_at);
            if(result.created_at){
                toast.success("Updated Successfully");
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }
        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error("Updating Failed! Try again")
        }
    }

  return (
    <div className="modal fade" id={`edit-form${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Category</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="form-wrapper">
                        <form onSubmit={handleEdit} id={id} className="d-flex flex-column align-items-center">
                        <div className="form-group d-flex flex-column w-100">
                            <label htmlFor="">Name</label>
                            <input type="text" name="name" defaultValue={editCategory.name} onChange={(e) => setEditCategory({...editCategory, name : e.target.value})} className="w-100" required/>  
                        </div>
                            <div className="form-group d-flex flex-column w-100" >
                                <label htmlFor="" className='text-left'>Select Unit</label>
                                <select name="unit_id" defaultValue={editCategory.unit_id} onChange={(e) => setEditCategory({...editCategory, unit_id : e.target.value})} className="w-100" required>
                                    <option value="" disabled>Select Unit</option>
                                    {
                                        unit && unit.map(({id, name}) => {
                                            return(
                                                <option value={id} key={id}>{name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group d-flex flex-column w-100">
                                <label htmlFor="" className='text-left'>Select Department</label>
                                <select name="department_id" defaultValue={editCategory.department_id} onChange={(e) => setEditCategory({...editCategory, department_id : e.target.value})} className="w-100" required>
                                    <option value="" disabled>Select Department</option>
                                    {
                                        department && department.map(({id, name}) => {
                                            return(
                                                <option value={id} key={id}>{name}</option>   
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group d-flex flex-column w-100">
                                <label htmlFor="" className='text-left'>Preferred Resolver</label>
                                <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="preferred_resolver"
                                value={editCategory.preferred_resolver}
                                options={newStaffList}
                                onChange={value => setEditCategory({...editCategory, preferred_resolver : value})}
                                />
                            </div>
                            <div className="form-group d-flex flex-column w-100">
                                <label htmlFor="" className='text-left'>Tat</label>
                                <input type="text" placeholder='' defaultValue={editCategory.tat} name="tat" onChange={(e) => setEditCategory({...editCategory, name : e.target.value})} required className="w-100"/>
                            </div>
                            <div className="form-group d-flex flex-column  w-100">
                                <label htmlFor="" className='text-left'>Status</label>
                                <select name="status" defaultValue={editCategory.status} onChange={(e) => setEditCategory({...editCategory, status : e.target.value})} className="w-100" required>
                                    <option value="" disabled>Select status</option>
                                    <option value="Active">Active</option>
                                </select>
                            </div>
                            <button type='submit' id={id}>Edit category</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditCategoryModal