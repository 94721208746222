import React, { useState } from 'react'
import '../AddCustomer/addCustomer.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/header/header';
import CasaTags from '../CasaTags/CasaTags';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { stateList } from '../../../common/stateList';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../../common/button/button';

const EditCustomer = () => {

    const history = useNavigate();
    const targetData = JSON.parse(sessionStorage.getItem("targetData"));
    const newData = targetData[0];
    const {firstname, lastname, middlename, dob, mobileno, bvn} = newData

    const [data, setData] = useState({
        maidenName: newData.maiden_name,
        country: newData.country,
        state: newData.state,
        lga: newData.lga,
        address: newData.address,
        bvn: newData.bvn,
        altMobileNo: newData.alt_mobileno,
        email: newData.email_address,
        customerType: "",
        title: newData.title,
        maritalStatus: newData.marital_status,
        initials: newData.initials,
        gender: newData.gender,
        idtype: newData.idtype,
        idpassportNo:newData.idpassportno,
        expireDate: newData.expiredate,
        employerName: newData.employer_name,
        employerAddress: newData.employer_address
    })

    const authAxios = axios.create({
        baseURL : casaBaseurl,
    }) 

    const handleChange = (e) => {
        e.persist();
        setData({...data, [e.target.name] : e.target.value});
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        }
        const newDate = formatDate(dob);

        const jsonData = {
            "firstName": newData.firstname,
            "lastName": newData.lastname,
            "middleName": newData.middlename,
            "maidenName": data.maidenName,
            "country": data.country,
            "state": data.state,
            "lga": data.lga,
            "address": data.address,
            "bvn": newData.bvn,
            "mobileNo": newData.mobileno,
            "altMobileNo": data.altMobileNo,
            "email": data.email,
            "customerType": data.customerType,
            "title": data.title,
            "maritalStatus": data.maritalStatus,
            "initials": data.initials,
            "gender": data.gender,
            "dob": newDate,
            "idtype": data.idtype,
            "idpassportNo": data.idpassportNo,
            "expireDate": data.expireDate,
            "employerName": data.employerName,
            "employerAddress": data.employerAddress
        }

        try {
            const addingCustomer = await authAxios.patch(`/updatecustrecord/${newData.custno}`, jsonData);
            const response = addingCustomer.data;
            if(response.code === 200){
                toast.success(response.body.data)
                setTimeout(() => {
                    history('/customer/all')
                }, 2000);
            }
            console.log(response)
            console.log(response)
        } catch (error) {
            const errMsg = error.response.data.body;
            console.log(errMsg)
            toast.error(errMsg.data)
        }
    }

    console.log(data)
  return (
    <div className='add-customer'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags/>
            <div className="add-customer-form bg-gray">
                <div className="staff-form-wrapper bg-white p-4 rounded">
                <ToastContainer closeButton={false} position="top-right" />
                    <form action='' onSubmit={handleSubmit}>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Basic Information</legend>
                            <div className="w-full py-5 grid lg:grid-columns-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" defaultValue={firstname || ""} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" defaultValue={lastname || ""} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="middleName">Middle</label>
                                    <input type="text" defaultValue={middlename} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Title</label>
                                    <select name="title" value={data.title} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="Mr">Mr</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mrs">Mrs</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gender">Gender</label>
                                    <select name="gender" value={data.gender} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="dob">Date of birth</label>
                                    <input type="text" defaultValue={dob} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Initial</label>
                                    <input type="text" placeholder='A.A' name="initials" value={data.initials} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Marital Status</label>
                                    <select name="maritalStatus" value={data.maritalStatus} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Maiden Name</label>
                                    <input type="text" placeholder='' name="maidenName" value={data.maidenName} onChange={handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Contact Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="">Country</label>
                                    <select name="country" value={data.country} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="Nigeria">Nigeria</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <select name="state" value={data.state} onChange={handleChange}>
                                        <option value=""></option>
                                        {
                                            stateList.map(({name, code}) => {
                                               return <option key={code} value={name}>{name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lga">Local Government</label>
                                    <input type="text" placeholder='' name="lga" value={data.lga} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input type="text" placeholder='24, kofo abayomi road, victoria Island, Lagos' name="address" value={data.address} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" name="email" value={data.email} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobileNo">Mobile no.</label>
                                    <input type="text" defaultValue={mobileno} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Alt Mobile No. (optional)</label>
                                    <input type="text" placeholder='081 222 4444 01' name="altMobileNo" value={data.altMobileNo} onChange={handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Identity Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="bvn">BVN</label>
                                    <input type="text" defaultValue={bvn} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Account Type</label>
                                    <select name="customerType" value={data.customerType} required onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="individual">Individual</option>
                                        <option value="minor">Minor</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">ID Type</label>
                                    <select name="idtype" value={data.idtype} onChange={handleChange}>
                                        <option value=""></option>
                                        <option value="001">Driver License</option>
                                        <option value="002">International Passport</option>
                                        <option value="003">National ID Card</option>
                                        <option value="004">Voters Card</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">ID Number</label>
                                    <input type="text" name="idpassportNo" value={data.idpassportNo} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expireDate">ID Expiry Date</label>
                                    <input type="date" name="expireDate" value={data.expireDate} onChange={handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='border px-5 rounded-md mb-5'>
                            <legend className='float-none w-auto p-2'>Employee Information</legend>
                            <div className="w-full py-5 grid lg:grid-cols-2 lg:gap-x-5">
                                <div className="form-group">
                                    <label htmlFor="employerName">Employer Name</label>
                                    <input type="text" name="employerName" value={data.employerName} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Employer Address</label>
                                    <input type="text" name="employerAddress" value={data.employerAddress} onChange={handleChange}/>
                                </div>
                            </div>
                        </fieldset>
                        <button type='submit'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditCustomer;
