import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Spin, Image, Button, Modal } from 'antd';
import './AccountPreview.css';
import KYCTAB from '../KYCTAB'
import KYCTags from '../KYCTags'
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../../common/apis/createAxios';
import backArrow from '../../../assets/Vector (6).svg';
import SupervisorButton from './SupervisorButton';
import DataentryButton from './DataentryButton';
import { month, weekday } from '../../../common/months';
import tick from '../../../assets/Vector (3).svg';
import pending from '../../../assets/Vector (4).svg';
import decline from '../../../assets/Vector (5).svg';

const AccountPreview = () => {

    const {id, user_id, supervisor} = useParams();
    const [record, setRecords] = useState([])
    const { token, user} = useSelector(userSelector)
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState({
        supervisor_modal : false,
        data_entry_modal : false
    });
    const [staff, setStaff] = useState([]);

    const [visible, setVisible] = useState(false);
    const history = useNavigate();

    const fetchingData = async () => {
        setLoading(true)
        let data = "";
        if(user_id && typeof supervisor === "undefined"){
            const result1 = await getAxiosInstance(token).get(`/kyc/corporate-account/sales-officer/Preview/${id}/${user_id}`);
            data = result1?.data;
        }else if(user_id && supervisor){
            const result2 = await getAxiosInstance(token).get(`kyc/corporate-account/officer/Preview/${id}/${user_id}`)
            data = result2?.data;
        }else{
            const result3 = await getAxiosInstance(token).get(`kyc/corporate-account/view/${id}`);
            data = result3;
        }
        // const response = data.data
        console.log(data)
        setRecords(data)
        setLoading(false)

        const allStaff = await getAxiosInstance(token).get('/staff/active')
        const response2 = allStaff.data
        setStaff(response2.data);

    }

    useEffect(() => {
        fetchingData();
    },[id])

    const handleSupervisorOpen = () => {
        setOpen({...open, supervisor_modal : true});
    }
    const handleDataEntryOpen = () => {
        setOpen({...open, data_entry_modal : true});
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const data = record?.data?.[0];
    const identification  = data?.identification[0];
    const address = data?.address[0];
    const comments = data?.all_comments;

    const ro = staff?.filter(item => item.id === Number(data?.requester_id))[0];
    const Supervisor = staff?.filter(item => item.id === Number(data?.officer_id))[0];
    const data_entry = staff?.filter(item => item.id === Number(data?.sales_officer))[0];
    const supervisor_comment = comments?.filter(item => item.user_id == data?.officer_id)
    const data_entry_comment = comments?.filter(item => item.user_id == data?.sales_officer)


    const supervisorProps = {
        "api": 'kyc/corporate-account/officer/Process',
        "isIndividual" : false,
        "file_name" : "officer_signature",
        "status" : "officer_status",
        "comment" : "officer_comment",
        "commentApi" : "kyc/corporate-account/comments/create"
    }

    const officerProps = {
        "api": 'kyc/corporate-account/sales-officer/Process',
        "isIndividual" : true,
        "file_name" : "sales_officer_signature",
        "status" : "sales_officer_status",
        "comment" : "sales_officer_comment",
        "commentApi" : "kyc/corporate-account/comments/create",
        "actionBy" : "Data Entry Officer"
    }

  return (
    <div className='account-preview'>
        <KYCTags />
        <div className="account-wrapper">
            <KYCTAB />
            {
                loading && <div className="loader mt-5">
                    <Spin />
                </div>
            }
            {
                data && <div className="content p-4">
                <div className="back-arrow d-flex align-items-center mb-3">
                    <img src={backArrow} alt=""  onClick={() => history(-1)}/>
                    <h4 className='mb-0 ml-3'>Customer’s Profile</h4>
                </div>
                <div className="account-info-wrapper d-flex justify-content-between">
                    <div className="left-content">
                        <div className="data-info mb-4">
                            <h5 className='mb-3'>Business Information</h5>
                            <div className="item">
                                <span>Company Name</span>
                                <span>{data?.company_name}</span>
                            </div>
                            <div className="item">
                                <span>Date of Incorporation</span>
                                <span>{data?.date_of_incorporation}</span>
                            </div>
                            <div className="item">
                                <span>Company Type</span>
                                <span>{data?.company_type}</span>
                            </div>
                            <div className="item">
                                <span>Industry</span>
                                <span>{data?.industry}</span>
                            </div>
                            <div className="item">
                                <span>Nature of Business</span>
                                <span>{data?.nature_of_business}</span>
                            </div>
                            <div className="item">
                                <span>Sector</span>
                                <span>{data?.sector}</span>
                            </div>
                            <div className="item">
                                <span>RC Number</span>
                                <span>{data?.rc_number}</span>
                            </div>
                            <div className="item">
                                <span>Anti money Laundry risk rating</span>
                                <span>{address?.antimoney_laundry_risk_rating}</span>
                            </div>
                            <div className="item">
                                <span>Estimated Monthly turn over</span>
                                <span>{address?.estimated_monthly_turn_over?.toLocaleString()}</span>
                            </div>
                        </div>
                        <div className="data-info">
                            <h5 className='mb-3'>Identification Information</h5>
                            <div className="item">
                                <span>Certification Type</span>
                                <span>{identification?.certificate_type}</span>
                            </div>
                            <div className="item">
                                <span>Number of Directors</span>
                                <span>{identification?.nos_of_directors}</span>
                            </div>
                            <div className="item">
                                <span>Director(s) BVN</span>
                                <span>{identification?.directors_bvn}</span>
                            </div>
                            <div className="item">
                                <span>Director ID card</span>
                                <span>{identification?.directors_id_type}</span>
                            </div>
                            <div className="item">
                                <span>Politically Exposed</span>
                                <span>{identification?.politically_exposed_person}</span>
                            </div>
                            <div className="item">
                                <span>TIN</span>
                                <span>{identification?.tin}</span>
                            </div>
                            <div className="item">
                                <span>Customer type</span>
                                <span>{address?.customer_type}</span>
                            </div>
                            <div className="item">
                                <span>Source of funds</span>
                                <span>{address?.source_of_funds}</span>
                            </div>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="data-info">
                            <h5 className='mb-3'>Contact Information</h5>
                            <div className="item">
                                <span>Company Address</span>
                                <span>{address?.company_address}</span>
                            </div>
                            <div className="item">
                                <span>Comment on address visited</span>
                                <span>{address?.comments_on_location}</span>
                            </div>
                            <div className="item">
                                <span>Telephone</span>
                                <span>{address?.telephone}</span>
                            </div>
                            <div className="item">
                                <span>Initial Deposit</span>
                                <span>{address?.intial_deposit}</span>
                            </div>
                            <div className="item">
                                <span>Authorized capital</span>
                                <span>{address?.authorized_capital}</span>
                            </div>
                            <div className="item">
                                <span>Method of Initial Deposit</span>
                                <span>{address?.method_of_initial_deposit}</span>
                            </div>
                            <div className="item">
                                <span>Account Introducer</span>
                                <span>{address?.account_introducer}</span>
                            </div>
                            <div className="item">
                                <span>Address Visited</span>
                                <span>{address?.address_visited}</span>
                            </div>
                            <div className="item">
                                <span>Name of Visiting staff</span>
                                <span>{address?.name_of_visiting_staff}</span>
                            </div>
                        </div>
                        <div className="data-info mt-4">
                            <h5>Approving Officers</h5>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Relationship officer</span>
                                    <p>{ro?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>signed</p>
                                        <div>
                                            <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                view signature
                                            </a>
                                            <Image
                                                style={{
                                                display: 'none'
                                                }}
                                                src={data?.ro_signature}
                                                preview={{
                                                visible,
                                                onVisibleChange: (value) => {
                                                    setVisible(value);
                                                },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Comment</span>
                                    <p className='view_comment'>view comment</p>
                                </div>
                            </div>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Supervisor</span>
                                    <p>{Supervisor?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>{(data?.officer_status === "Approve") ? <img src={tick} alt="tick"/> : (data?.officer_status === "Pending") ? <img src={pending} alt="pending"/> : <img src={decline} alt="pending" />}</p>
                                        {
                                            (data?.officer_status === "Pending" || data?.officer_status === "Reject") && <p style={{color : (data?.officer_status === "Reject") ? "#E25353" : (data?.officer_status === "Pending") ? "#FFB519" : (data?.officer_status === "Approve") ? "#00A85A" : "GrayText" }}>{data?.officer_status}</p>
                                        }
                                        
                                        {
                                            (data?.officer_status === "Approve") && <div>
                                                <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                    show signature
                                                </a>
                                                <Image
                                                    style={{
                                                    display: 'none'
                                                    }}
                                                    src={data?.officer_signature}
                                                    preview={{
                                                    visible,
                                                    onVisibleChange: (value) => {
                                                        setVisible(value);
                                                    },
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Comment</span>
                                    <p
                                        onClick={handleSupervisorOpen}
                                        className="view_comment"
                                    >
                                        view comment
                                    </p>
                                </div>
                            </div>
                            <div className="border_bottom">
                                <div className="item">
                                    <span className='heading'>Data Entry officer</span>
                                    <p>{data_entry?.name}</p>
                                </div>
                                <div className="item">
                                    <span>Status</span>
                                    <div className="d-flex">
                                        <p>{(data?.sales_officer_status === "Approve") ? <img src={tick} alt="tick"/> : (data?.sales_officer_status === "Pending") ? <img src={pending} alt="pending"/> : <img src={decline} alt="pending" />}</p>
                                        {
                                            (data?.sales_officer_status === "Pending" || data?.sales_officer_status === "Reject") && <p style={{color : (data?.sales_officer_status === "Reject") ? "#E25353" : (data?.sales_officer_status === "Pending") ? "#FFB519" : (data?.sales_officer_status === "Approve") ? "#00A85A" : "GrayText" }}>
                                            {data?.sales_officer_status}
                                        </p>
                                        }
                                        {
                                            (data?.sales_officer_status === "Approve") && <div>
                                                <a style={{color : "blue"}} onClick={() => setVisible(true)}>
                                                    show signature
                                                </a>
                                                <Image
                                                    style={{
                                                    display: 'none'
                                                    }}
                                                    src={data?.sales_officer_signature}
                                                    preview={{
                                                    visible,
                                                    onVisibleChange: (value) => {
                                                        setVisible(value);
                                                    },
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <span>Comment</span>
                                    <p onClick={handleDataEntryOpen} className="view_comment">
                                        view comment
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (user.id == user_id && data?.officer_status !== "Approve" && data?.officer_status !== "Reject" && typeof supervisor !== "undefined") && <SupervisorButton id={id} fetchingData={fetchingData} supervisorProps={supervisorProps}/>
                }
                {
                    (user.id == user_id && data?.sales_officer_status !== "Approve" && data?.sales_officer_status !== "Reject" && typeof supervisor === "undefined") && <DataentryButton id={id} fetchingData={fetchingData} officerProps={officerProps}/>
                }
            </div>
            }
        </div>
        <Modal
            open={open.supervisor_modal}
            title="Supervisor's Comment"
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            ]}
        >
            <div className="comment-thread">
                {
                    supervisor_comment && supervisor_comment.map(({id, comment, created_at}) => {

                        const newDate = new Date(created_at);

                        const [months, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
                        return(
                            <div className="comment" key={id}>
                                <div className="sender">
                                    <img src="" alt="" />
                                    <h5>{Supervisor?.name}</h5>
                                    <span>{`${weekday[dy]} ${day} ${month[months]} ${year}`}</span>
                                </div>
                                <p>{comment}</p>
                            </div>
                        )
                    })
                }
                {
                    <p>{(data?.officer_comment !== "Nil") && data?.officer_comment}</p>
                }
            </div>
        </Modal>
        <Modal
            open={open.data_entry_modal}
            title="Data entry officer's Comment"
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            ]}
        >
            <div className="comment-thread">
                {
                    data_entry_comment && data_entry_comment.map(({ id, comment, created_at }) => {

                        const newDate = new Date(created_at);
                        const [months, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];
                        return(
                            <div className="comment" key={id}>
                                <div className="sender">
                                    <img src="" alt="" />
                                    <h5>{data_entry?.name}</h5>
                                    <span>{`${weekday[dy]} ${day} ${month[months]} ${year}`}</span>
                                </div>
                                <p>{comment}</p>
                            </div>
                        )
                    })
                }
                {
                    <p>{(data?.sales_officer_comment !== "Nil") && data?.sales_officer_comment}</p>
                }
            </div>
        </Modal>
    </div>
  )
}

export default AccountPreview