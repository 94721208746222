import React from 'react';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import Footer from '../../components/footer/footer';
import HandbookComponent from '../../components/HandbookComponent/HandbookComponent';
import Header from '../../components/header/header';
import '../StaffHandbook/Staffhandbook.css';

const StaffHandBook = () => {
  return (
    <div className='staff-handbook'>
        <Header />
        <div className="container">
            <div className="content">
                <HandbookComponent />
                <BirthdayCard />
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default StaffHandBook