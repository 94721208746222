import React, { useState } from 'react'
import HomeTags from '../../components/HomeTags/HomeTags'
import '../OldIRS/OldIRS.css';

const OldIRS = () => {

  const [active] = useState({
    dashboard : false,
    myProfile : false,
    menus : false,
    permission : false,
    user2fa : false,
    oldirs : true
  })

  return (
    <div className='old-irs d-flex'>
        <div className="tab-wrapper">
            <HomeTags active={active}/>
        </div>
        
        <div className="framer">
            <iframe src="https://irs.primeramfbank.com/app/appraisal" frameborder="0" style={{width : "100%", height : "100vh"}}></iframe>
        </div>
    </div>
  )
}

export default OldIRS