import React from 'react';
import '../CasaDashboard/casaDashboard.css';
import '../../TicketList/TicketList.css';
import userimg from '../../../assets/Vector.svg';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';

const CasaDashboard = ({customers,exemptionCount,transactionsCount,accountCount }) => {

    const customerCount = customers.count;
    const customerList = customers.results;
    const { user } = useSelector(userSelector);
    
  return (
    <div className='casa-dashboard'>
        <p className='welcome-msg'>Welcome back, <span>{user.name}</span></p>
        <div className="ds-content">
            <div className="tabs-wrapper">
                <div className="tabs cs">
                    <h5>{customerCount}</h5>
                    <p>Customers</p>
                </div>
                <div className="tabs sa">
                    <h5>{transactionsCount}</h5>
                    <p>Transactions</p>
                </div>
                <div className="tabs fa">
                    <h5>{accountCount.count}</h5>
                    <p>Accounts</p>
                </div>
                <div className="tabs sm">
                    <h5>{exemptionCount}</h5>
                    <p>Exempted Customers</p>
                </div>
            </div>
        </div>
        <div className="accounts-list">
            <div className="tickets-table">
                <h3 className='mb-3'>Recent Customers</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Customer No</td>
                            <td>Customer Name</td>
                            <td>Email</td>
                            <td>Mobile No</td>
                            <td>Marital</td>
                            <td className='text-center'>State</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            customerList && customerList.filter((item, index) => (index < 4))
                            .map(({custno, firstname, lastname, email_address, marital_status, state, mobileno}) => {
                                return(
                                    <tr key={custno}>
                                        <td>{custno}</td>
                                        <td>
                                            <div className="d-flex">
                                                <img src={userimg} alt="" />
                                                <span>{`${firstname} ${lastname}`}</span>
                                            </div>
                                        </td>
                                        <td>{email_address}</td>
                                        <td className='medium' >
                                            <p>{mobileno}</p>
                                        </td>
                                        <td>{marital_status}</td>
                                        <td><p className='text-center'>{state}</p></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default CasaDashboard