import React from 'react'
import { Table, Pagination, Dropdown, Badge, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';

const DataTable = ({ loading, allData, pageCount, fetchingData, user, officer}) => {

    const { user : currentUser } = useSelector(userSelector);
    const navigate = useNavigate();

    const handlePreview = async (slug) => {
  
        try {
            if(user && officer === ""){
                navigate('/kyc/accounts/' + slug + user)
            }else if(user && officer){
                navigate('/kyc/accounts/' + slug + user + officer)
            }else{
                navigate('/kyc/accounts/' + slug)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit =  (slug) => {
      navigate('/kyc/edit/' + slug)
    }

    const handleDelete = async (slug) => {
      
    }
    
    const columns = [
        {
          title: 'Customer Name',
          dataIndex: 'company_name',
          key: 'comapny_name',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
                    {item}
                </a>,
        },
        {
          title: 'Company email',
          key: 'company_email',
          dataIndex: 'company_email',
          render: (item, record) => {
            return(
                <a onClick={() => handlePreview(record.slug)} >
                  {item}
                </a>
            )
          },
        },
        {
          title: 'Company Type',
          dataIndex : "company_type",
          key: 'company_type',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
              {item}
          </a>,
        },
        {
          title: 'RC Number',
          dataIndex: 'rc_number',
          key: 'rc_number',
          render: (item, record) => <a onClick={() => handlePreview(record.slug)} >
              {item}
          </a>,
        },
        {
          title: 'Request Status',
          key: 'application_status',
          dataIndex: 'application_status',
          render: (item, record) => {

            let statusText = ""
            let textcolor = ""
            if(item === "Approved"){
              statusText = 'success'
              textcolor = "#00A85A"
            }
            if(item === "Rejected"){
              statusText = 'error'
              textcolor = '#E25353'
            }
            if(item === "Pending"){
              statusText = 'warning'
              textcolor = '#FFB519'
            }
          return(
                  <a onClick={() => handlePreview(record.slug)} >
                    <Badge status={statusText} />
                    <span style={{color : textcolor}}>
                      {item}
                    </span>
                  </a>
            )
          },
        },
        {
          title: 'Date of Inc.',
          key: 'date_of_incorporation',
          dataIndex: 'date_of_incorporation',
          render: (item, record) => {
            return(
              <span>
                <Space size="medium">
                {item}
                  <Dropdown.Button
                      overlay={
                        <div className='drop-down'>
                          <div className="dropdown-list">
                            <span onClick={() => handlePreview(record.slug)}>View</span>
                            <span 
                              style={{cursor : record.requester_id == currentUser.id ? "pointer" : "not-allowed", opacity : record.requester_id == currentUser.id ? "1" : "0.4"}}
                              onClick={
                                (record.requester_id == currentUser.id) ? 
                                () => handleEdit(record.slug) : ""
                                }>
                                Edit 
                            </span>
                            <span className='delete' onClick={() => handleDelete(record.slug)}>Delete</span>
                          </div>
                        </div>
                      }
                      trigger={['click']}
                    >
                    </Dropdown.Button>
                </Space>
              </span>
            )
          },
        },
    ];
    
    const data = allData;

  return (
    <div className='data-table'>
        <Table loading={loading} columns={columns} dataSource={data} pagination={false}/>
        <div className="pagination justify-content-end mt-3">
            <Pagination 
                pageSize={10}
                total={pageCount}
                onChange={(page) => fetchingData(page)}
            />
        </div>
    </div>
  )
}

export default DataTable