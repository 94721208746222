import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { irsBaseUrl } from '../../../common/apis/userApi';
import Swal  from 'sweetalert2';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useSelector } from 'react-redux';
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../HelpdeskCreateDepartment/helpdeskdepartment.css';
import { toast, ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import spyglass from '../../../assets/search.svg';

const AllChecklist = () => {

    const { token, user } = useSelector(userSelector);

    const [checklist, setChecklist] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(5);

    const [editChecklist, setEditchecklist] = useState({
        category_id : "", 
        title : "",
        staff_id : user.id, 
        status : ""
    })

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const departmentData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-checklist`);
                const response = departmentData.data;
                setChecklist(response.data);

                const categoryData = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-category`);
                const result = categoryData.data;
                setCategories(result); 

            } catch (error) {
                console.log(error)
            }
        }
        fetchingData()
    },[])

    useEffect(() => {

        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(checklist.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(checklist.length / itemsPerPage));
    
    }, [itemOffset, itemsPerPage, checklist, searchField]);
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % checklist.length;
        setItemOffset(newOffset);
    };

    const handleChange = (e) => {
        e.persist();
        setSearchField(e.target.value)
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        const { category_id, title, staff_id, status } = editChecklist;

        const jsonData = {
            "category_id" : category_id, 
            "title" : title,
            "staff_id" : staff_id, 
            "status" : status
        }
        
        try {
            const removeDoc = await authAxios.put(`${irsBaseUrl}/interdepartmental/ticket-checklist/${id}`, jsonData);
            const result = removeDoc.data;
                if(result.created_at){
                toast.success("Checklist updated successfully!");
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error("Failed update. Try again!")
        }
    }

    const handleEditChange = (e) => {
        e.persist();
        setEditchecklist({...editChecklist, [e.target.name] : e.target.value});
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })
    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await authAxios.delete(`${irsBaseUrl}/interdepartmental/ticket-checklist/${id}`);
            const result = removeDoc.data;
            
            if(result.status == "success"){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                ).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    },1000)
                })
            }
        } catch (error) {
            const apiMsg = error.response.data.message;
            toast.error("Removal Failed!")
        }  
    }

    const handleSearch = () => {
        const filteredList = checklist.filter(item => item.title.toLowerCase().includes(searchField.toLowerCase()));
        setCurrentItems(filteredList)
    }

  return (
    <div className='all-checklist casa-documentlist'>
        <Header />
        <div className="container_">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="document-type-list help-createdepartment p-3">
                    <ToastContainer closeButton={true} position="top-right" />
                    <h3>All Checklist</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <div className="search-wrapper">
                            <input type="search" name='searchField' placeholder='filter' value={searchField} onChange={handleChange}/>
                            <img src={spyglass} alt="" onClick={handleSearch}/>
                        </div>
                        <Link to='/helpdesk/create-checklist'>New Checklist</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Checklist name</h5>
                            <h5 className='text-center'>Checklist Id</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            currentItems && currentItems.map(({ id, title }) => {
                                
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <p className='colored'>{title}</p>
                                            <p className='text-center'>{id}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                                <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target={`#edit-checklist${id}`}>
                                                    Edit
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal fade" id={`edit-checklist${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Edit Checklist</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-wrapper">
                                                            <form action="" onSubmit={handleEdit} id={id} className="d-flex flex-column align-items-center">
                                                                <div className="form-group d-flex flex-column w-100">
                                                                    <label htmlFor="">Title</label>
                                                                    <input type="text" placeholder=''name="title" value={editChecklist.title} onChange={handleEditChange} className="w-100" required/>  
                                                                </div>
                                                                <div className="form-group d-flex flex-column  w-100">
                                                                    <label htmlFor="" className='text-left'>Select Category</label>
                                                                    <select name="category_id" value={editChecklist.category_id} onChange={handleEditChange} className="w-100" required>
                                                                        <option value="" disabled>Select category</option>
                                                                        {
                                                                            categories && categories.map(({id, name}) => {
                                                                                return(
                                                                                    <option value={id} key={id}>{name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group d-flex flex-column  w-100">
                                                                    <label htmlFor="" className='text-left'>Status</label>
                                                                    <select name="status" value={editChecklist.status} onChange={handleEditChange} className="w-100" required>
                                                                        <option value="" disabled>Select status</option>
                                                                        <option value="Active">Active</option>
                                                                    </select>
                                                                </div>
                                                                <button type='submit' id={id}>Edit checklist</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< prev"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AllChecklist