import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { toast, ToastContainer } from 'react-toastify';
import '../../CASA/updateStaff/updateStaff.css';
import '../HelpdeskCreateDepartment/helpdeskdepartment.css';
import spyglass from '../../../assets/search.svg';
import axios from 'axios';
import { irsBaseUrl } from '../../../common/apis/userApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';

const CreateUnit = () => {

    const history = useNavigate();
    const [unit, setUnit] = useState({
        name : "",
        department_id : "",
        team_lead_id : "",
        group_email : "", 
        hod : ""  
    });
    const [alldepartment, setAlldepartment] = useState([]);

    const { token, user} = useSelector(userSelector);
    const [staff, setStaff] = useState([]);

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const staff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const stafflist = staff.data;
                setStaff(stafflist.data);

                const departmentData = await authAxios.get(`${irsBaseUrl}/staff/department`);
                const response = departmentData.data;
                setAlldepartment(response);

            } catch (error) {
                console.log(error);
            }
        }
        fetchingData()
    },[])

    const handleChange = (e) => {
        setUnit({...unit, [e.target.name] : e.target.value});
    }

    const handleUnitInputLead = (e) => {
        const val = e.target.value;
        // var val = document.getElementById("leadInput").value;
        let opts = document.getElementById('unit-teamlead').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setUnit({...unit, team_lead_id : dataId})
            break;
            }
        }
    }
    const handleUnitInputHod = (e) => {
        const val = e.target.value;
        // var val = document.getElementById("hodInput").value;
        let opts = document.getElementById('unit-hod').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setUnit({...unit, hod : dataId})
            break;
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, team_lead_id, group_email, hod, department_id } = unit;
        const apiData = {
            "name" : name, 
            "team_lead_id" : team_lead_id,
            "department_id" : department_id,
            "group_email" : group_email, 
            "hod" : hod
        }

        try {
            const creatingDept = await authAxios.post(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`, apiData);
            const response = creatingDept.data;
            if(response.created_at){
                toast.success("New unit created successfully!");
                setTimeout(() => {
                    history('/helpdesk/sub-unit');
                }, 3000)
            }
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    console.log(unit)
  return (
    <div className='create-unit helpdesk-department'>
        <Header />
        <div className="container__">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="help-createdepartment">
                <ToastContainer closeButton={true} position="top-right" />
                    <div className="add-staff-form">
                        <div className="add-staff-wrapper">
                            <h4 className='mb-4'>Create Sub-Unit</h4>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="">Name</label>
                                    <input type="text" placeholder='' name='name' value={unit.name} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Department Id</label>
                                    <select name="department_id" value={unit.department_id} onChange={handleChange}>
                                        <option value="" disabled></option>
                                        {
                                            alldepartment && alldepartment.map(({ id, name }) => {
                                                return(
                                                    <option id={id} value={id} key={id}>{name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Team Lead</label>
                                    <div className="input-wrapper w-100" >
                                        <img src={spyglass} alt="spy glasss icon" width="20px" />
                                        <input list="unit-teamlead" id='unit-leadInput' onKeyUp={handleUnitInputLead} placeholder="search staff" required/>
                                    </div>
                                    <datalist id="unit-teamlead">
                                        {staff.map(({id, name}) =>
                                            <option key={id} value={name} id={id}>{name}</option>
                                        )}
                                    </datalist>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Group Email</label>
                                    <input type="email" name="group_email" value={unit.group_email} onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">HOD</label>
                                    <div className="input-wrapper w-100" >
                                        <img src={spyglass} alt="spy glass icon" width="20px" />
                                        <input list="unit-hod" id='hod-Input' onKeyUp={handleUnitInputHod} placeholder="search staff" required/>
                                    </div>
                                    <datalist id="unit-hod">
                                        {staff.map(({id, name}) =>
                                            <option key={id} value={name} id={id}>{name}</option>
                                        )}
                                    </datalist>
                                </div>
                                <button type='submit' className='w-100 py-3'>Add SubUnit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateUnit