import React from 'react'
// import donughGraph from '../../assets/donught-graph.gif'
import { Doughnut } from 'react-chartjs-2';


const AdminDoughnutGraph = ({ticketCounts, data}) => {
  return (
    <div className="graph-wrapper">
        {
            (ticketCounts === 0) ?  <div className="no-data-graph">
                <p>No transaction to render graph</p>
                {/* <img src={donughGraph} alt="" width="90%" height="350px" /> */}
            </div> : <div className="stats chart-container" style={{width: '90%', height: '320px'}}>
            <Doughnut 
            data={data} 
            options={{
                responsive : true,
                cutout : "90%",
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: true,
                        anchor: 'end',
                        align: 'top',
                        color: "black"
                    },
                    legend: {
                    display: true,
                    position: "top",
                    align : "left",
                    fullSize : false,
                        labels : {
                            boxWidth : 15,
                            boxHeight : 15,
                            marginBottom : 40,
                            font : {
                                weight : 500
                            }
                        }
                    },
                },
            }}
            />
        </div>
        }
    </div>
  )
}

export default AdminDoughnutGraph