import React from 'react'
import '../PlaceholderComponent/PlaceholderComponent.css';

const PlaceholderComponent = () => {
    const MenuData = [
        {
            title : "Opening Balance",
            count : "500,000"
        },
        {
            title : "Total Withdrawal",
            count : "252,750"
        },
        {
            title : "Total Profit Made",
            count : "24,150"
        },
        {
            title : "Bal. Cash at Hand",
            count : "227,250"
        },
    ]
  return (
    <div className='placeholder-component'>
        <div className="container">
                <div className="right-item w-100">
                    <div className="d-flex placeholder-wrapper placeholder-glow">
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                    </div>
                    <div className="d-flex placeholder-wrapper placeholder-glow mt-3">
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                        <p className='placeholder w-100 rounded' style={{height : "100px"}}></p>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default PlaceholderComponent