import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Button, message } from 'antd';
import '../KYC/Newkyc/personal-info.css';


const AuthorizingOfficers = ({formik, staff, opStaff, setImageUrl}) => {

    const [supervisor_email, setSupervisor_email] = useState("");
    const [dataEntryEmail, setDataEntryEmail] = useState('');

    // useEffect(() => {
    //     if(formik.values.supervisor_officer !== ""){
    //         const selectedStaff = opStaff.filter(item => item.id == formik.values.supervisor_officer)
    //         console.log(selectedStaff)
    //         setSupervisor_email(selectedStaff[0]?.staff?.email)
    //     }
    // },[formik.values.supervisor_officer])

    // useEffect(() => {
    //     if(formik.values.sales_officer_id !== ""){
    //         const selectedStaff = staff.filter(item => item.id == formik.values.sales_officer_id)
    //         setDataEntryEmail(selectedStaff[0]?.staff?.email)
    //     }
    // },[formik.values.sales_officer_id])

    const dummyRequest = async ({ file, onSuccess }) => {    
        setTimeout(() => {
           onSuccess("ok");
        }, 0);
    }

    const props = {
        name: 'file',
        multiple: false,
        customRequest: dummyRequest,
        onChange(info) {
            setImageUrl(info.file.originFileObj)
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file selected`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const uploadButton = (
        <div>
            <Button icon={<PlusOutlined />}></Button>
            <div style={{ marginTop: 8}}>
            Upload
            </div>
      </div>
    );

  return (
    <div className='personal-info officers'>
        <div className="d-flex mb-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Supervisor Name</label>
                    <select name="supervisor_officer" value={formik.values.supervisor_officer} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.supervisor_officer && formik.errors.supervisor_officer) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                        <option value="" disabled selected>Select suspervisor</option>
                        {
                            staff && staff.map(({ staff }) => {
                                const { name, id } = staff;

                                return(
                                    <option value={id} key={id}>{name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    {/* <label htmlFor="">Supervisor Email</label>
                    <input type="text" disabled value={supervisor_email} className="email_input"/> */}
                </div>
            </div>
        </div>
        <div className="d-flex mb-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Branch Officer</label>
                    <select name="sales_officer_id" value={formik.values.sales_officer_id} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.sales_officer_id && formik.errors.sales_officer_id) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                        <option value="" disabled selected>Select entry officer</option>
                        {
                            opStaff && opStaff.map(({ staff }) => {
                                const { name, id} = staff;

                                return(
                                    <option value={id} key={id}>{name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    {/* <label htmlFor="">Branch officer email</label>
                    <input type="text" disabled value={dataEntryEmail} className="email_input"/> */}
                </div>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">RO signature</label>
                    <Upload
                        name="file"
                        listType="picture-card"
                        {...props}
                        >
                        {
                            uploadButton
                        }
                    </Upload>
                </div>
            </div>
            <div className="w-100"></div>
        </div>
    </div>
  )
}

export default AuthorizingOfficers;