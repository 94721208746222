import React, { useState, useEffect } from 'react';
import TicketComponent from '../../components/TicketsComponent/TicketComponent';
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { irsBaseUrl } from '../../common/apis/userApi';
import axios from 'axios';

const TicketList = () => {

    const { token } = useSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [totalPage, setTotalPage] = useState(1);

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type" : "application/json",
        }
    }) 

    useEffect(() => {
        fetchingData(1);
    }, [token])

    const fetchingData = async (page) => {
        setLoading(true)
        const query = await authAxios.get(`${irsBaseUrl}/interdepartmental/tickets/paginate?page=${page}`);
        const data = query.data;
        if(query.status === 200){
            setTickets(data)
            setTotalPage(data.meta.total)
            setLoading(false)
        }else{
            console.log('error')
        }
    }

  return (
    <div className='ticket-list grey'>
        <TicketComponent 
        heading={"All Tickets"} 
        tickets={tickets} 
        loading={loading}
        totalPage={totalPage}
        fetchingData={fetchingData}
        />
    </div>
  )
}

export default TicketList