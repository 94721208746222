import React from 'react';
import '../CasaTags/casaTags.css';
import user from '../../../assets/user (2).png';
import addUser from '../../../assets/add-user.png';
import account from '../../../assets/credit-card.png';
import document from '../../../assets/document (1).png';
import next from '../../../assets/down-arrow.png';
import home from '../../../assets/home.png';
import { Link } from 'react-router-dom'

const CasaTags = () => {
  return (
    <div className='casa-tags'>
        <div className="content_">
            <div className="item home">
                <img src={home} alt="" />
                <Link to='/casa'>CASA Home</Link>
            </div>
            {/* <div className="staff">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#down-icon1" aria-expanded="false" aria-controls="collapseExample">
                    <div className="headline d-flex align-items-center justify-content-between">
                        <h4>Staffs</h4>
                        <img src={next} alt="" />
                    </div>
                </span>
                <div className="collapse" id="down-icon1">
                    <div className="item">
                        <img src={addUser} alt="" />
                        <Link to='/staff/new'>Add Staff</Link>
                    </div>
                    <div className="item">
                        <img src={user} alt="" />
                        <Link to='/staff/all'>Staff List</Link>
                    </div>
                </div>
            </div> */}
            <div className="staff">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#down-icon" aria-expanded="false" aria-controls="collapseExample">
                    <div className="headline d-flex align-items-center justify-content-between">
                        <h4>Customers</h4>
                        <img src={next} alt="" />
                    </div>
                </span>
                <div className='collapse' id="down-icon">
                    <div className="item">
                        <img src={addUser} alt="" />
                        <Link to='/customer/create'>Create new</Link>
                    </div>
                    <div className="item">
                        <img src={user} alt="" />
                        <Link to='/customer/all'>All Customers</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/customer/accounts'>Accounts</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/exemption-list'>Approval List</Link>
                    </div>
                </div>
            </div>
            <div className="staff">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#down-icon2" aria-expanded="false" aria-controls="collapseExample">
                    <div className="headline d-flex align-items-center justify-content-between">
                        <h4>Documents</h4>
                        <img src={next} alt="" />
                    </div>
                </span>
                <div className="collapse" id="down-icon2">
                    <div className="item">
                        <img src={user} alt="" />
                        <Link to='/document/document-types'>Document Types</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/document/customer-document'>KYC Documents</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/document/mypending-approval'>My Pending Approval</Link>
                    </div>
                    <div className="item">
                        <img src={document} alt="" />
                        <Link to='/document/pending-document'>Pending Deferral Approval</Link>
                    </div>
                    <div className="item">
                        <img src={document} alt="" />
                        <Link to='/document/approval-deferral'>Approved Deferrals</Link>
                    </div>
                </div>
            </div>
            <div className="staff last">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#down-icon3" aria-expanded="false" aria-controls="collapseExample">
                    <div className="headline d-flex align-items-center justify-content-between">
                        <h4>Operations</h4>
                        <img src={next} alt="" />
                    </div>
                </span>
                <div className="collapse" id="down-icon3">
                    <div className="item">
                        <img src={user} alt="" />
                        <Link to='/casa/transactions'>All Transactions</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/casa/deposit'>Deposit</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/casa/withdrawal'>Withdraw</Link>
                    </div>
                    <div className="item">
                        <img src={account} alt="" />
                        <Link to='/casa/transfer'>Fund Transfer</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaTags