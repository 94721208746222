import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../userProfile/userProfile.css';
import bg from '../../assets/citybg.png';
import editButton from '../../assets/Group 13.svg';
import {  userSelector} from '../../feature/Login/loginSlice';
import ValueInformation from '../ValueInformation/ValueInformation';
import HomeTags from '../HomeTags/HomeTags';
import BirthdayCard from '../BirthdayCard/BirthdayCard';

const UserProfile = () => {

    const { user } = useSelector(userSelector);
    const [active] = useState({
        dashboard : false,
        myProfile : true,
        menus : false,
        permission : false,
        user2fa : false,
        oldirs : false
    })

  return (
    <div className='user-profile dashboard_'>
        <div className="container_">
            <div className="wrapper">
                <HomeTags active={active}/>
                <div className="profile-card">
                    <div className="content">
                        <div className="image-card">
                            <div className="bg-cover">
                                <img src={bg} alt="a city in paris" />
                            </div>
                            <div className="user-image">
                                <img src={user?.avatar} alt="black woman smiling" />
                            </div>
                        </div>
                        <div className="info-card-wrapper rounded p-3 pb-5">
                            <div className="name d-flex align-items-center justify-content-between">
                                <h3>{user?.name}</h3>
                                <Link to='/edit-profile'><img src={editButton} alt="edit button icon" /></Link>
                            </div>
                            <div className="info-card">
                                <div className="portfolio">
                                    <ul>
                                        <li>{user?.designation}</li>
                                        <li>{user?.level}</li>
                                        <li>{user?.email}</li>
                                        <li>{user?.dob}</li>
                                        <li>{user?.old_phone}</li>
                                    </ul>
                                </div>
                                <div className="superior-officer">
                                    <p>{user?.sex}</p>
                                    <p>{user?.old_bio}</p>
                                    <p>{user?.old_department}</p>
                                    <p>{user?.old_marital}</p>
                                </div>
                            </div>
                            <button>
                                <Link to='/profile/edit-profile-picture'>Change Profile Image</Link>
                            </button>
                            <button style={{marginLeft : "15px"}}>
                                <Link to='/change-password'>Change Password</Link>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="right-item">
                    <ValueInformation />
                    <BirthdayCard />
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserProfile;