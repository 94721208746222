import React, { useEffect, useState } from "react";
// import text from '../../assets/file.png';
import "../Member/member.css";
import { useSelector } from "react-redux";
import { userSelector } from "../../feature/Login/loginSlice";
// import StaffSearchRender from '../StaffSearchRender/StaffSearchRender';
import axios from "axios";
import ranking from "../../assets/medal.png";
import avatar2 from "../../assets/avatar.png";
import emailicon from "../../assets/email.png";
import phone from "../../assets/phone.png";
import usericon from "../../assets/user.png";

const Member = () => {
  const [state, setState] = useState({
    search: [],
    searchField: "",
  });
  const [show, setShow] = useState(false);
  const [allUser, setallUser] = useState([]);
  const [showList, setShowList] = useState(true);
  const { token } = useSelector(userSelector);

  useEffect(() => {
    const fetchingdata = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: "https://irsapi.koinsbank.com/api/v3/staff/paginate",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setallUser(data);
        return data;
      } catch (error) {
        console.log("errror", error);
      }
    };
    fetchingdata();
  }, [token]);

  const newArray = allUser.data;
  const allLinks = allUser.links;
  const countNum = allUser.meta;

  const fetchingNext = async (e) => {
    e.preventDefault();
    const response = await axios({
      method: "GET",
      url: allLinks.next,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    setallUser(data);
    window.scrollTo(0, 0);
  };
  const fetchingPrev = async (e) => {
    e.preventDefault();
    const response = await axios({
      method: "GET",
      url: allLinks.prev,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    setallUser(data);
    window.scrollTo(0, 0);
  };
  const fetchingFirst = async (e) => {
    e.preventDefault();
    const response = await axios({
      method: "GET",
      url: allLinks.first,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    setallUser(data);
    window.scrollTo(0, 0);
  };
  const fetchingLast = async (e) => {
    e.preventDefault();
    const response = await axios({
      method: "GET",
      url: allLinks.last,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    setallUser(data);
    window.scrollTo(0, 0);
  };

  const handleSearch = (e) => {
    e.persist();
    setState({ ...state, searchField: e.target.value });
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    setShowList(false);
    setShow(true);
    try {
      const response = await axios({
        method: "POST",
        url: "https://irsapi.koinsbank.com/api/v3/user/search",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          keyword: state.searchField,
        },
      });
      const data = response.data;
      setState({ ...state, search: data.users });
    } catch (error) {
      console.log("errror", error);
    }
  };

  const handleList = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const { searchField, search } = state;

  function truncateString(str, num) {
    let val = "";
    if (str.length <= num) {
      val = str?.slice(0, num) + "...";
      return val;
    }
    return val;
  }

  console.log(search);

  return (
    <div className="member">
      <div className="search-wrapper d-flex">
        <input
          type="search"
          name="searchField"
          value={state.searchField}
          placeholder="seach staff by name, department"
          onChange={handleSearch}
        />
        <button onClick={submitSearch}>Search</button>
      </div>
      <div className="back-btn" style={{ display: show ? "block" : "none" }}>
        <p onClick={handleList}>Go back to all list</p>
      </div>
      <div
        className="member-wrapper"
        style={{ display: showList ? "grid" : "none" }}
      >
        {newArray ? (
          newArray.map(({ id, username, name, staff, profile_picture }) => {
            const nameInitials = staff.name.split(" ");
            const bioText = truncateString(staff?.old_bio, 40);
            return (
              <div className="member-card" key={id}>
                <img src={profile_picture} alt={username} />
                <div className="card_details">
                  <div className="user-name d-flex">
                    <div className="avatar-text">
                      <p>{`${nameInitials[0].split("")[0]}${
                        nameInitials[1] ? nameInitials[1].split("")[0] : ""
                      }`}</p>
                    </div>
                    <div className="name-text">
                      <h4>{name}</h4>
                      <p>{staff.designation}</p>
                    </div>
                  </div>
                  <div className="about">
                    <h5>Bio</h5>
                    <p>{`${bioText}`}</p>
                  </div>
                  <div className="contacts">
                    <div className="email d-flex">
                      <img src={emailicon} alt="email icon" />
                      <p>{staff.email}</p>
                    </div>
                    <div className="email d-flex">
                      <img src={ranking} alt="medal icon" />
                      <p>{staff.level}</p>
                    </div>
                    <div className="phone">
                      <img src={phone} alt="phone icon" />
                      <p>{staff.old_phone}</p>
                    </div>
                    <div className="user-icon">
                      <img src={usericon} alt="user icon" />
                      <p>{staff.sex}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="overlay">Loading</div>
        )}
      </div>
      <div
        className="pagination-count"
        style={{ display: showList ? "block" : "none" }}
      >
        {
          <div className="lists d-flex">
            <button onClick={fetchingFirst}>First</button>
            <button
              disabled={`${allLinks?.prev}` ? false : true}
              onClick={fetchingPrev}
            >
              Prev
            </button>
            <button onClick={fetchingNext}>Next</button>
            <button onClick={fetchingLast}>Last</button>
          </div>
        }
      </div>
      <p style={{ display: showList ? "block" : "none" }}>
        Results : {`${countNum?.current_page} / ${countNum?.last_page}`} of{" "}
        {`${countNum?.total}`}{" "}
      </p>

      <div
        className="searched-list member-wrapper"
        style={{ display: show ? "grid" : "none" }}
      >
        {search.length >= 1 ? (
          search.map(
            ({
              username,
              email,
              name,
              designation,
              level,
              sex,
              id,
              avatar,
            }) => {
              const nameInitials = name.split(" ");
              return (
                <div className="member-card" key={id}>
                  <img src={avatar} alt={username} />
                  <div className="card_details">
                    <div className="user-name d-flex">
                      <div className="avatar-text">
                        <p>{`${nameInitials[0].split("")[0]}${
                          nameInitials[1] ? nameInitials[1].split("")[0] : ""
                        }`}</p>
                      </div>
                      <div className="name-text">
                        <h4>{name}</h4>
                        <p>{designation}</p>
                      </div>
                    </div>
                    <div className="about">
                      <h5>Bio</h5>
                      <p>
                        I am a team player and I've always enjoyed writing and
                        public speaking
                      </p>
                    </div>
                    <div className="contacts">
                      <div className="email d-flex">
                        <img src={emailicon} alt="email icon" />
                        <p>{email}</p>
                      </div>
                      <div className="email d-flex">
                        <img src={emailicon} alt="email icon" />
                        <p>{level}</p>
                      </div>
                      <div className="phone">
                        <img src={phone} alt="phone icon" />
                        <p>08169114001</p>
                      </div>
                      <div className="user-icon">
                        <img src={usericon} alt="user icon" />
                        <p>{sex}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className="overlay">
            {/* <RotatingLines width="100" strokeColor="#083061" strokeWidth="2" /> */}
            loading
          </div>
        )}
      </div>
    </div>
  );
};

export default Member;
