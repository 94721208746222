import React, { useState } from 'react';
import './updateStaff.css';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import { toast, ToastContainer } from 'react-toastify';

const UpdateStaff = () => {

    const [formData, setFormData] = useState({
        staffName : "",
        permission : "",
    })
    const authAxios = axios.create({
        baseURL : casaBaseurl,
    })  

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name] : e.target.value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const jsonData = JSON.stringify({
                "firstName": formData.staffName,
                "permission": formData.permission,
                "email": formData.email
            })
            const postingData = await authAxios.patch('/staff', jsonData);
            const response = postingData.data.body;
            toast.success(response.data)
        } catch (error) {
            const errorMsg = error.response.data.body.data;
            toast.error(errorMsg);
        }
    }

    console.log(formData)
  return (
    <div className='add-staff'>
        <Header />
        <div className="content w-full d-flex">
            <CasaTags />
            <div className="add-staff-form">
                <ToastContainer closeButton={true} position="top-right" />
                <div className="add-staff-wrapper">
                    <h4 className='mb-4'>Edit Staff Details</h4>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-data">
                            <div className="form-group">
                                <label htmlFor="">Staff name</label>
                                <input type="text" placeholder='Enter staff name' name="staffName" value={formData.staffName} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Permission</label>
                                <input type="text" placeholder='Admin' name="permission" value={formData.permission} onChange={handleChange} required/>
                            </div>
                            <button type='submit'>Update Staff</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UpdateStaff;