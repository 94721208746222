import React from 'react';
import { Link } from 'react-router-dom';
import '../CreateLeaveForm/create-leave-form.css';
import arrowbtn from '../../assets/arrow.svg';
import '../LeaveDashboard/leavedashboard.css';
import group1 from '../../assets/Group 1.svg';
import group2 from '../../assets/Group 2.svg';
import group3 from '../../assets/Group 3.svg';

const CreateLeaveForm = () => {

    const addTask = (e) => {
        const formwrapper = document.getElementById("form-wrapper");
        e.preventDefault();
        
        const handover = formwrapper.getElementsByClassName("handover");
        console.log(handover)
        let firstForm = handover[0];
        let formClone = firstForm.cloneNode(true);
        formwrapper.appendChild(formClone);
    }
    const handleDelete = (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const parentNode = target.parentNode;
        console.log(parentNode)
        // parentNode.removeChild();
    }

    // comee
  return (
    <div className='leave-form leave-dashboard'>
        <div className="heading">
            <p>LEAVE</p>
        </div>
        <div className="grey-wrapper">
            <div className="top-tabs">
                <div className="leave-db">
                    <Link to='/leave'>
                        <img src={group1} alt="" />
                        <p>LEAVE DASHBOARD</p>
                    </Link>
                </div>
                <div className="apply-leave">
                    <Link to='/apply-for-leave'>
                        <img src={group2} alt="" />
                        <p>APPLY FOR LEAVE</p>
                    </Link>
                </div>
                <div className="leave-record">
                    <Link to='/leave/approve'>
                        <img src={group3} alt="" />
                        <p>SUPERVISOR'S LEAVE RECORD</p>
                    </Link>
                </div>
            </div>
            <div className="leave-form-wrapper">
                <h5>LEAVE REQUEST FORM</h5>
                <form action="" >
                    <div className="form-group">
                        <label htmlFor="">LEAVE CATEGORY </label>
                        <select name="" id="">
                            <option value="">Sick</option>
                            <option value="">Annual</option>
                            <option value="">Maternity</option>
                            <option value="">Examination</option>
                            <option value="">Paternity</option>
                            <option value="">Compassionate</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">LEAVE DATE</label>
                        <input type="date" name="" id="" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">RESUMPTION DATE</label>
                        <input type="date" name="" id="" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">LEAVE YEAR</label>
                        <select name="" id="">
                            <option value="">2021</option>
                            <option value="">2022</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">NAME OF SUPERVISOR</label>
                        <select name="" id="">
                            <option value="">select</option>
                            <option value="">Ayobami adeolu</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">NAME OF RELIEVER</label>
                        <select name="" id="">
                            <option value="">select</option>
                            <option value="">Ayobami adeolu</option>
                        </select>
                    </div>
                    <div className="form-group" id='form-wrapper'>
                        <div className="handover">
                            <label htmlFor="">HANDOVER NOTE</label>
                            <div className="note-item">
                                <div className="form-group">
                                    <label htmlFor="">TASK</label>
                                    <input type="text" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">STATUS</label>
                                    <textarea name="" id="" cols="10" rows="4"></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">CATERGORY</label>
                                    <select name="" id="">
                                        <option value="">SPECIFIC TASK</option>
                                        <option value="">OUTSTANDINGS OR DELIVERABLES</option>
                                        <option value="">ISSUES AWAITING REPLIES</option>
                                        <option value="">ISSUES APPROVED AWAITING CONFIRMATION</option>
                                        <option value="">OUTSTANDING LOANS - (Sales Staff)</option>
                                    </select>
                                </div>
                                <button type='text' onClick={handleDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                    <div className="add-btn">
                        <button onClick={addTask}>Add more task</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default CreateLeaveForm;