import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';
import Button from '../../../common/button/button'

const SelectAccountType = ({showModal, handleClose, submitAccountType, accountEntries, handleAccountType, disabled}) => {

    const [acctTypes, setaccountTypes] = useState([]);

    useEffect(() => {
        const fetchingData = async () => {
            const accountTypes = await axios.get(`${casaBaseurl}accttype`);
            const response = accountTypes.data;
            setaccountTypes(response.body.data)
        }
        fetchingData()
    },[])

  return (
    <div className={"modal fade"  + (showModal ? " show d-block" : " d-none")} id='exampleModal' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Account Type</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                    <form action="" onSubmit={submitAccountType} >
                        <div className="form-group">
                            <label htmlFor="">Select Account Type</label><br></br>
                            <select value={accountEntries.accountType} name="accountType" onChange={handleAccountType}
                            required className='w-100 mt-2'>
                                <option value="" disabled>Select account type</option>
                                {
                                    acctTypes && acctTypes?.map(({code, name}) => {
                                        return(
                                            <option value={code} key={code}>{name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="">Select Account officer</label><br></br>
                            <select value={accountEntries.accountOfficer} name="accountOfficer" onChange={handleAccountType}
                            required className='w-100 mt-2'>
                                <option value="" disabled>Select officer name</option>
                                <option value="200">Solomon Akinlade</option>
                            </select>
                        </div>
                        
                        <div className="form-group mt-3">
                            <Button value="Upload Account" type="submit" disabled={disabled}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SelectAccountType