import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../CasaAccounts/casaAccounts.css';
import '../CasaStaffList/casaStafflist.css';
import next from '../../../assets/down-arrow.png';
import search from '../../../assets/search.svg';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';

const CasaAccounts = () => {

    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [accountData, setAccountData] = useState([])
    const authAxios = axios.create({
        baseURL : casaBaseurl,
    }) 
    const [pageCount, setpageCount] = useState(1);

    useEffect(() => {
        fetchingData(1);
    },[])

    const fetchingData = async (page) => {
        const query = await authAxios.get(`/customerwithaccount?page=${page}`);
        const result = query.data;
        setAccountData(result);
        setpageCount(result.count);
    }

    const handleChange = (e) => {
        if(e.target.value !== ""){
            setState({...state, search : e.target.value})
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        const { search } = state;

        const query = await authAxios.get(`/filter/${search}`);
        const result = query.data;
        setAccountData(result);
    }

    const accountList = accountData.results;

  return (
    <div className='casa-account casa-stafflist'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="casa-account-wrapper casa-staff-wrapper">
                <div className="account-heading">
                    <h3 className='mb-3'>All Accounts</h3>
                    <div className="search-staff-box">
                        <form>
                            <input type="search" placeholder='search by name, account no or customer no' onChange={handleChange}/>
                            <img src={search} alt="search" onClick={handleSearchSubmit}/>
                        </form>
                    </div>
                    <div className="list-wrapper bg-white">
                        <div className="list-table">
                            <div className="titles p-3">
                                <span>Customer Name</span>
                                <span>Email</span>
                                <span className='text-center'>Gender</span>
                                <span className='text-center'>Account(s)</span>
                                <span className='text-center'>More info</span>
                            </div>
                            <div className="account-list mb-3">
                                {
                                    accountList && accountList.map(({id, custno, account, firstname, lastname, middlename, email_address, gender}) => {
                                        return (
                                            <div className="accounts" key={id}>
                                                <div className="grid px-3">
                                                    <div className="account-brief">
                                                        <div className="brief-details my-3">
                                                            <h4>{`${firstname} ${lastname}`}</h4>
                                                            <span className='email'>{email_address}</span>
                                                            <span className='text-center'>{gender}</span>
                                                            <span className='text-center count'>{`${account.length} account`}</span>
                                                            <span className='edit text-center' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-icon${id}`} aria-expanded="false" aria-controls="collapseExample">
                                                                <img src={next} alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='collapse mt-4 acct-list' id={`edit-icon${id}`}>
                                                        {
                                                            account.map(({id, accountno,accttype, balance, acct_level}) => {
                                                                return(
                                                                    <div className="account-detail mb-3" key={id}>
                                                                        <div className="account-headings">
                                                                            <h5>Account No</h5>
                                                                            <h5 className='text-center'>Account Type</h5>
                                                                            <h5 className='text-center'>Balance</h5>
                                                                            <h5 className='text-center'>Tier</h5>
                                                                        </div>
                                                                        <div className="account-data">
                                                                            <p>{accountno}</p>
                                                                            <p className='text-center'>{accttype}</p>
                                                                            <p className='text-center'>{balance.toLocaleString()}</p>
                                                                            <p className='text-center'>{acct_level}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }  
                                {
                                    accountList?.length < 1 && <p className='no-record'>No record found</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination 
                pageSize={10}
                total={pageCount}
                onChange={(page) => fetchingData(page)}
                />
            </div>
        </div>
    </div>
  )
}

export default CasaAccounts