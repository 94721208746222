import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../CasaAccounts/casaAccounts.css';
import '../CasaDocument/pendingApproval.css';
import axios from 'axios';
import next from '../../../assets/down-arrow.png';
import search from '../../../assets/search.svg';
import { deferralBaseurl } from '../../../common/apis/userApi';

const ApprovalDeferral = () => {

    const low = {
        backgroundColor : 'rgb(142 218 208)',
        color : "rgb(253 254 254)",
        borderRadius : "20px",
        textAlign : "center"
    }
    const high = {
        backgroundColor : 'rgb(242 215 224)',
        color : '#F094A9',
        borderRadius : "20px",
        textAlign : "center"
    }

    const [approveDef, setapproveDef] = useState([]);


    useEffect(() => {
        const fetchingData = async () => {
            const query = await axios.get(`${deferralBaseurl}/approvaldeferral`);
            const result = query.data;
            setapproveDef(result);
        }
        fetchingData();
    },[])

    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : approveDef.next,
        })
        const data = response.data;
        setapproveDef(data);
        window.scrollTo(0, 0);
    }
    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : approveDef.previous,
        })
        const data = response.data;
        setapproveDef(data);
        window.scrollTo(0, 0);
    }

    const approvalData = approveDef.results;

    //page count
    let totalCounts = Math.ceil(approveDef.count/10);
    let currentCount = ''
    if(approveDef.next != null){
        let next = approveDef.next;
        currentCount = next.split("=")[1] - 1;
    }else if(approveDef.next == null){
        currentCount = totalCounts;
    }

  return (
    <div className='approved pending-approval casa-account casa-stafflist'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="pending-list-wrapper">
                <h3 className='mb-3'>Approved Deferrals</h3>
                <div className="search-staff-box mb-3">
                    <form>
                        <input type="search" placeholder='filter' />
                        <img src={search} alt="" />
                    </form>
                </div>
                <div className="pending-list bg-white">
                    <div className="pending-table">
                        <div className="pending-titles p-3">
                            <h4>Document</h4>
                            <h4 className='text-center'>Approval Status</h4>
                            <h4 className='text-center'>Approved By</h4>
                            <h4 className='text-center'>Action</h4>
                        </div>
                    
                        {
                            approvalData && approvalData.map(({id, docname, approving_staffname, date_elapse, approved, customerInfo}) => {
                                
                                return(
                                    <div className="data-wrapper px-3" key={id}>
                                        <div className="pending-data">
                                            <p className='doc-name'>
                                                { docname } <br></br>
                                                {`for ${customerInfo[0].firstname} ${customerInfo[0].lastname}`}
                                            </p>
                                            <p className='text-center'>
                                                <span className='status' style={approved ? low : high}>{approved === true ? "Approved" : "pending approval"}</span>
                                            </p>
                                            <p className='text-center'>
                                                {approving_staffname}
                                            </p>
                                            <span 
                                            className='edit text-center' 
                                            type="button" 
                                            data-bs-toggle="collapse" 
                                            data-bs-target={`#edit-icon${id}`} 
                                            aria-expanded="false" aria-controls="collapseExample"
                                            >
                                                <img src={next} alt="" />
                                            </span>  
                                        </div>
                                        <div className='collapse mt-4' id={`edit-icon${id}`}>
                                            {
                                                customerInfo.map(({firstname, lastname, customer_type, bvn, mobileno}, index) => {
                                                    return(
                                                        <div className="account-detail" key={index}>
                                                            <div className="account-headings">
                                                                <h5>Customer</h5>
                                                                <h5 className='text-center'>Acct Type</h5>
                                                                <h5 className='text-center'>BVN</h5>
                                                                <h5 className='text-center'>Mobile No</h5>
                                                            </div>
                                                            <div className="account-data">
                                                                <p>{`${firstname} ${lastname}`}</p>
                                                                <p className='text-center'>{customer_type}</p>
                                                                <p className='text-center'>{bvn}</p>
                                                                <p className='text-center'>{mobileno}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            totalCounts.length < 1 && <>
                                <div className="nav-btns mt-5">
                                    <button onClick={fetchingPrev}>Prev</button>
                                    <button onClick={fetchingNext}>Next</button>
                                </div>
                                <p className='pb-4 text-center'>{`Page ${currentCount} of ${totalCounts}`}</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ApprovalDeferral;