import React, { useState, useEffect } from 'react'
import Header from '../../components/header/header'
import Tags from '../../components/Tags/Tags'
import '../CASA/CasaDocument/casaDocumentType.css';
import { documentBaseUrl } from '../../common/apis/userApi';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal  from 'sweetalert2';
import { Link } from 'react-router-dom';

const DocumentDepartment = () => {

    const [department, setDepartments] = useState([]);
    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [edit, setEdit] = useState({
        name : "",
    })


    useEffect(() => {
        const fetchingData = async () => {
            const query = await axios.get(`${documentBaseUrl}/department`);
            const result = query.data;
            setDepartments(result);
        }
        fetchingData();
    },[])

    const handleChange = (e) => {
        e.persist();
        setState({...state, search : e.target.value})
    }

    const handleEditChange = (e) => {
        e.persist();
        setEdit({...edit, [e.target.name] : e.target.value})
    }

    const handleEdit = async (e) => {
        e.preventDefault();

        if(edit.name !== ""){
            const { id } = e.currentTarget;
            const jsonData = {
                "name": edit.name,
            }
    
            try {
                const removeDoc = await axios.patch(`${documentBaseUrl}/department/${id}`, jsonData);
                const result = removeDoc.data.response;
                toast.success(result.data);
                setTimeout(() => {
                    window.location.reload();
                },2000)
    
            } catch (error) {
                const apiMsg = error.response.data.response;
                toast.error(apiMsg.data)
            }
        }
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })

    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await axios.delete(`${documentBaseUrl}/department/${id}`);
            const result = removeDoc.data.response;
            console.log(result.data);
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            ).then(() => {
                setTimeout(() => {
                    window.location.reload();
                },2000)
            })

        } catch (error) {
            const apiMsg = error.response.data.response;
            toast.error(apiMsg.data)
        }  
    }

    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : department.next,
        })
        const data = response.data;
        setDepartments(data);
        window.scrollTo(0, 0);
    }
    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : department.previous,
        })
        const data = response.data;
        setDepartments(data);
        window.scrollTo(0, 0);
    }
    
    const departmentList = department.results;

    let totalCounts = Math.ceil(department.count/5);
    let currentCount = ''
    if(department.next != null){
        let next = department.next;
        currentCount = next.split("=")[1] - 1;
    }else if(department.next == null){
        currentCount = totalCounts;
    }

  return (
    <div className='document-department casa-documentlist'>
        <Header />
        <div className="content d-flex w-100">
            <Tags />
            <div className="document-type-list">
                <h3>All Department</h3>
                <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                    <input type="search" placeholder='filter' value={state.search} onChange={handleChange}/>
                    <Link to='/document/new-department'>Create Department</Link>
                </div>
                <div className="doc-wrapper p-4">
                    <div className="doc-titles">
                        <h5>Department</h5>
                        <h5 className='text-center'>Department Id</h5>
                        <h5 className='text-center'>Actions</h5>
                    </div>
                    {
                        departmentList && departmentList.map(({ id, name, date_created }) => {
                            return( 
                                <div className="doc-details" key={id}>
                                    <div className="item">
                                        <p className='colored'>{name}</p>
                                        <p className='text-center'>{id}</p>
                                        <div className="d-flex justify-content-center text-center">
                                            <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                            <span className='edit text-center' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-form${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                Edit
                                            </span>
                                        </div>
                                    </div>
                                    <div className="collapse" id={`edit-form${id}`}>
                                        <div className="form-wrapper">
                                            <form action="" onSubmit={handleEdit} id={id} className="d-flex flex-row align-items-center justify-content-between">
                                                <input type="text" placeholder='e.g Passport' value={edit.name} name="name" onChange={handleEditChange} required/>
                                                <button type='submit' id={id}>Change Name</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="nav-btns my-5">
                        <button onClick={fetchingPrev}>Prev</button>
                        <button onClick={fetchingNext}>Next</button>
                    </div>
                    <p className='text-center'>{`${currentCount} / ${totalCounts}`}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DocumentDepartment