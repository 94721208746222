import React from 'react'
import TextInput from '../../../common/TextInput/TextInput'
import './personal-info.css'

const BusinessInformation = ({formik, setValid}) => {

    if(formik.values.company_address !== "" && formik.values.telephone !== "" && formik.values.authorized_capital !== "" && formik.values.method_of_initial_deposit !== "" && formik.values.customer_type !== "" && formik.values.address_visited !== "" && formik.values.comments_on_location !== ""){
        setValid(true)
    }
  return (
    <div className='personal-info'>
        <div className="">
            <div className="d-flex mb-3">
                <div className="w-100">
                    <TextInput 
                    label='Telephone'
                    type="text" 
                    placeholder='' 
                    name='telephone' 
                    value={formik.values.telephone} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.telephone && formik.errors.telephone) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Customer Type</label>
                        <select name="customer_type" onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.customer_type && formik.errors.customer_type) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                            <option value="" disabled selected>Select customer type</option>
                            <option value="walk-in">Walk-in</option>
                            <option value="marketed">Marketed</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                label='Company Address '
                type="text" 
                placeholder='' 
                name='company_address' 
                value={formik.values.company_address} 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                formik={formik}
                style={{border : (formik.touched.company_address && formik.errors.company_address) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="d-flex mb-3">
                <div className="w-100">
                    <TextInput 
                    label='Authorized capital '
                    type="text" 
                    placeholder='' 
                    name='authorized_capital' 
                    value={formik.values.authorized_capital} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.authorized_capital && formik.errors.authorized_capital) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                    />
                </div>
                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="">Method of initial deposit</label>
                        <select name="method_of_initial_deposit" onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.method_of_initial_deposit && formik.errors.method_of_initial_deposit) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                        <option value="" disabled selected>Select initial deposit</option>
                            <option value="cash">Cash</option>
                            <option value="cheque">Cheque</option>
                            <option value="transfer">Transfer</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                    label='Address Visited'
                    type="text" 
                    placeholder='' 
                    name='address_visited' 
                    value={formik.values.address_visited} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.address_visited && formik.errors.address_visited) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100 mb-3">
                <TextInput 
                    label='Comments on location'
                    type="text" 
                    placeholder='' 
                    name='comments_on_location' 
                    value={formik.values.comments_on_location} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.comments_on_location && formik.errors.comments_on_location) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
    </div>
  )
}

export default BusinessInformation