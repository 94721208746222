import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import bell from '../../assets/bell.svg';
import { clearState, userSelector } from '../../feature/Login/loginSlice';
import { Link, useNavigate } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu/MobileMenu';


const KYCTAB = ({menuFor}) => {

  const dispatch = useDispatch();
  const history = useNavigate();
  const { user } = useSelector(userSelector);

  const handleLogout = () => {
    dispatch(clearState);
    history('/');
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to='/dashboard'>
              Back to IRS
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={() => handleLogout()}>
              Logout
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <div className='kyc-tab'>
        <h4>Howdy, {user.username} </h4>
        <div className="user">
            <img src={bell} alt="notification-bell" />
            <Dropdown trigger={['click']} overlay={menu}>
              <span onClick={(e) => e.preventDefault()}>
                <Space>
                  <p>{user.name}</p> 
                  <DownOutlined />
                </Space>
              </span>
            </Dropdown>
        </div>
        <MobileMenu menuFor={menuFor}/>
    </div>
  )
}

export default KYCTAB