import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { documentBaseUrl } from '../../common/apis/userApi';
import '../EditDocumentApplication/EditDocumentApplication.css';
import spyglass from '../../assets/search.svg';
import { toast } from 'react-toastify';


const EditDocumentApplication = ({applicationId, subject, background, memo_request, justification, app_type, dept}) => {

    const [doctype, setDoctype] = useState([]);
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [edit, setEdit] = useState({
        app_type: "",
        dept_id: dept,
        office_id: "",
        subject: subject,
        background: background,
        memo_request: memo_request,
        justification: justification
    })

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const docs = await axios.get(`${documentBaseUrl}/doctype`);
                const response = docs.data;
                setDoctype(response.results)   

                const depts = await axios.options(`${documentBaseUrl}/department`);
                const deptResults = depts.data.response
                setDepartments(deptResults.data)

                const office = await axios.options(`${documentBaseUrl}/office`);
                const officeResult = office.data.response;
                setOffices(officeResult.data)

            } catch (error) {
                console.log(error)
            }
        }
        fetchingData();
    },[])

    const handleInput = () => {
        var val = document.getElementById("input").value;
        let opts = document.getElementById('data').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setEdit({...edit, dept_id : dataId})
            break;
            }
        }
    }

    const handleInput2 = () => {
        var val = document.getElementById("input2").value;
        let opts = document.getElementById('dataTo').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setEdit({...edit, office_id : dataId})
            break;
            }
        }
    }

    const handleChange = (e) => {
        e.persist();
        setEdit({...edit, [e.target.name] : e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { app_type, dept_id, office_id, subject, background, memo_request, justification } = edit

        const jsonData = {
            "app_type": app_type,
            "dept_id": dept_id,
            "office_id": office_id,
            "subject": subject,
            "background": background,
            "memo_request": memo_request,
            "justification": justification
        }

        try {
            const editingApplication = await axios.patch(`${documentBaseUrl}/memoapp/${applicationId}`, jsonData)
            const result = editingApplication.data;
            if(result.code === 200){
                toast.success(result.response.data)
            }  
        } catch (error) {
            console.log(error.response)
        }
    }

  return (
    <div className='edit-document-application'>
        <div className="modal fade" id="edit-doc" tabIndex="-1" aria-labelledby="docModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="docModalLabel">Edit Application</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="">Document Type</label>
                                <select name="app_type" onChange={handleChange}>
                                    <option value="">Select type </option>
                                    {
                                        doctype.map(({id, documentName}) => {
                                            return <option key={id} value={id}>{documentName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">From</label>
                                <div className="input-wrapper w-60">
                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                    <input list="data" id='input' required onInput={handleInput} placeholder="search department" className='' />
                                </div>
                                <datalist id="data">
                                    {departments.map(({id, name}) =>{
                                        return <option key={id} value={name} id={id}>{name}</option>
                                        }
                                    )}
                                </datalist>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">To</label>
                                <div className="input-wrapper w-60">
                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                    <input list="dataTo" id='input2' required onInput={handleInput2} placeholder="search offices" />
                                </div>
                                <datalist id="dataTo">
                                    {offices.map(({id, office_name}) =>
                                        <option key={id} value={office_name} id={id}>{office_name}</option>
                                    )}
                                </datalist>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Subject</label>
                                <input type="text" name='subject' value={edit.subject} onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Background</label>
                                <textarea name="background" value={edit.background} onChange={handleChange} cols="10" rows="3"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Memo Request</label>
                                <textarea name="memo_request" value={edit.memo_request} onChange={handleChange} cols="10" rows="3"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Justification</label>
                                <textarea name="justification" value={edit.justification} onChange={handleChange} cols="10" rows="3"></textarea>
                            </div>
                            <button type='submit'>Submit</button>
                        </form>
                    </div>
                </div>
            </div>               
        </div>
    </div>
  )
}

export default EditDocumentApplication