import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../../components/header/header';
import '../../TicketList/TicketList.css';
import '../CasaStaffList/casaStafflist.css';
import CasaTags from '../CasaTags/CasaTags'
import { Link } from 'react-router-dom';
import { casaBaseurl } from '../../../common/apis/userApi';
import search from '../../../assets/search.svg';

const CasaStaffList = () => {

    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [staff, setStaff] = useState([])
    const authAxios = axios.create({
        baseURL : casaBaseurl,
    })  

    useEffect(() => {
        const fetchingData = async() => {
            try {
                const fetching = await authAxios.get('/addstaff');
                const results = fetching.data;
                setStaff(results);
            } catch (error) {
                console.log(error);
            }
        }
        fetchingData();
    },[])

    const handleChange = (e) => {
        e.persist();
        setState({...state, search : e.target.value});
    }

    const handleSearch = (e) => {

    }
    
    const fetchingNext = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : staff.next,
        })
        const data = response.data;
        setStaff(data);
        window.scrollTo(0, 0);
    }
    const fetchingPrev = async (e) => {
        e.preventDefault();
        const response = await axios({
            method : "GET",
            url : staff.previous,
        })
        const data = response.data;
        setStaff(data);
        window.scrollTo(0, 0);
    }

    const staffList = staff.results;
    console.log(staffList);

    // const searchedArray = staff.filter(item => item.staff_profile.firstName)
    // console.log(searchedArray
  return (
    <div className='casa-stafflist'>
        <Header />
        <div className="content d-flex">
            <CasaTags />
            <div className="casa-staff-wrapper">
                <div className="tickets-table">
                    <h3 className='mb-3'>All Staffs</h3>
                    <div className="search-staff-box">
                        <form>
                            <input type="search" placeholder='filter' name="search" value={state.search} onChange={handleChange} />
                            <img src={search} alt="" onClick={handleSearch}/>
                        </form>
                        <button>
                            <Link to='/staff/edit'>Update Staff</Link>
                        </button>
                    </div>
                    <table className=''>
                        <thead>
                            <tr>
                                <td>Staff Name</td>
                                <td>Email</td>
                                <td className='wx-size'>Designation</td>
                                <td className='wx-size'>Level</td>
                                <td>Permission</td>
                            </tr>
                        </thead>
                        
                        <tbody>
                        {
                            staffList && staffList.map(({staffname, staffemail, designation, level, id, permission}) => {
                            
                                return <tr key={id}>
                                        <td>
                                            <div className="d-flex">
                                                <span>{staffname}</span>
                                            </div>
                                        </td>
                                        <td>{staffemail}</td>
                                        <td >
                                            <p className='text-left wx-size'>{designation}</p>
                                        </td>
                                        
                                        <td >
                                            <p className='text-left wx-size'>{level}</p>
                                        </td>
                                        <td>{permission}</td>
                                    </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="action-btns my-5">
                    <button onClick={fetchingPrev}>Prev</button>
                    <button onClick={fetchingNext}>Next</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaStaffList