import React, { useState } from 'react';
import '../Createoffice/CreateOffice.css';
import '../Register2FAUserList/RegisterNewUser.css';
import { toast, ToastContainer } from 'react-toastify';
import HomeTags from '../../components/HomeTags/HomeTags';
import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '../../graphql/mutations/RegisterUser';

const RegisterNewUser = () => {

    const [user, setUser] = useState('');

    const handleChange =(e) => {
        setUser(e.target.value)
    }

    const [active] = useState({
        dashboard : false,
        myProfile : false,
        menus : false,
        permission : false,
        user2fa : true,
        oldirs : false
    })

    const [createCode, { error }] = useMutation(REGISTER_USER);

    const handleSubmit = async (e) => {
        e.preventDefault();

        createCode({
            variables : {
                email : user
            }
        }).then(({data : { createCode }}) => {

            const { status, statusCode, message } = createCode;
            if(statusCode === 200 && status === true ){
                toast.success(message)
            }else if(statusCode === 400){
                toast.error(message)
            }
        })
        
        if(error){
            console.log(error);   
        }
    }

  return (
    <div className='register-user create-office'>
        <div className="content d-flex w-100">
            <HomeTags active={active}/>
            <div className="add-staff-form">
                <ToastContainer closeButton={true} position="top-right" />
                <div className="register-inner-wrapper">
                    <div className="add-staff-wrapper">
                        <h4 className='mb-4'>Registration for 2FA</h4>
                        <form onSubmit={handleSubmit}>
                            <div className="form-data">
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <input type="email" placeholder='staff@primerbank.com' name='user' value={user} onChange={handleChange}/>
                                </div>
                                <button type='submit'>Register staff</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RegisterNewUser