import React, { useState, useEffect } from 'react'
import DataTable from '../AllAccounts/DataTable'
import TopMenu from '../AllAccounts/TopMenu'
import KYCTAB from '../KYCTAB'
import KYCTags from '../KYCTags'
import '../AllAccounts/AllAccounts.css';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../../common/apis/createAxios';

const DataOfficerList = () => {

    const { token, user } = useSelector(userSelector);
    const [loading, setloading] = useState(false);
    const [allAccounts, setAccounts] = useState([]);
    const [pageCount, setpageCount] = useState(1);

    const fetchingData = async (page) => {
        try {
            setloading(true);
            const allAccount = await getAxiosInstance(token).get(`/kyc/corporate-account/sales-officer/${user.id}?page=${page}`)
            const response = allAccount.data;
            setAccounts(response.data)
            setpageCount(response.count)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    useEffect(() => {
        fetchingData(1)
    },[])

    const url_link = `/${user.id}`

  return (
    <div className='all-accounts'>
        <KYCTags />
        <div className="dash-wrapper">
            <KYCTAB menuFor={"Corporate KYC"}/>
            <TopMenu/>
            <div className="px-4">
                <DataTable 
                loading={loading}
                fetchingData={fetchingData}
                pageCount={pageCount}
                allData={allAccounts}
                user={url_link}
                officer={''}
                />
            </div>
        </div>
    </div>
  )
}

export default DataOfficerList