import React,  { useState } from 'react'
import { Button, Modal } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { irsAuthBaseUrl } from '../../../common/apis/userApi';

const DataentryButton = ({ id, fetchingData, officerProps }) => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setloading] = useState(false);
    const [messages, setMessages] = useState({
        reject_message : "",
        comment_message : ""
    })
    const [open, setOpen] = useState({
        approve2 : false,
        reject2 : false,
        comment2 : false
    });
    const { user } = useSelector(userSelector);

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('sales_officer_signature', file);
        });
        formData.append("sales_officer_status", 'Approve');

        setUploading(true);
        // fetch(`${irsAuthBaseUrl}/kyc/corporate-account/sales-officer/Process/${id}/${user.id}`, {
        //     method: 'POST',
        //     body: formData,
        // })
        fetch(`${irsAuthBaseUrl}/${officerProps.api}/${id}/${user.id}`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((result) => {
            setFileList([]);
            if(result.sales_officer_status === 'Approve'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.status === 0){
                message.error(result.message);
            }
        })
        .catch(() => {
            message.error('upload failed.');
        })
        .finally(() => {
            setUploading(false);
        });
    } ;
    const showApprovalModal2 = () => {
        setOpen({...open, approve2 : true});
    };
    const showRejectionModal2 = () => {
        setOpen({...open, reject2 : true});
    };
    const showCommentModal2 = () => {
        setOpen({...open, comment2 : true});
    };

    const handleReject2 = () => {

        setloading(true)
        const formData = new FormData();
        formData.append("sales_officer_status", 'Reject');
        formData.append("sales_officer_comment", messages.reject_message);
        // kyc/corporate-account/sales-officer/Process
        fetch(`${irsAuthBaseUrl}/${officerProps.api}/${id}/${user.id}`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((result) => {
            console.log(result )
            if(result.sales_officer_status === 'Reject'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.status === 0){
                message.error(result.message);
            }
        })
        .catch(() => {
            message.error('Reject failed.');
        })
        .finally(() => {
            setloading(false);
        });
    }

    const handleChange = (e) => {
        setMessages({...messages, [e.target.name] : e.target.value})
    }

    const handleComment = async () => {
        setloading(true)
        const postData = {
            "comment": messages.comment_message,
            "actionBy": officerProps.actionBy
        };
        
        try {
            // kyc/corporate-account/comments/create
            const postingComment = await axios.post(`${irsAuthBaseUrl}/${officerProps.commentApi}/${user.id}/${id}`, postData)
            const result = postingComment.data;
            if(result){
                message.success("Comment sent successfully!")
                setOpen(false);
                fetchingData();
                setloading(false)
            }
        } catch (error) {
         console.log(error)   
         message.error("Process failed!!")
         setloading(false)
        }
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };

  return (
    <div>
        <div className="action_btns">
            <button className='approve' onClick={showApprovalModal2}>Approve</button>
            <button className='reject' onClick={showRejectionModal2}>Reject</button>
            <button className='comment' onClick={showCommentModal2}>Query(Officer)</button>
        </div>
        <Modal
            open={open.approve2}
            title="Approve KYC"
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" disabled={fileList.length === 0} loading={uploading} onClick={handleUpload}>
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>,
            ]}
        >
            <Upload {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
        </Modal>
        <Modal
            open={open.reject2}
            title="Reject KYC"
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" disabled={messages.reject_message === ""} loading={loading} onClick={handleReject2}>
                {loading ? 'Submitting' : 'Submit'}
            </Button>,
            ]}
        >
            <textarea name="reject_message" className='modal-text-area' value={messages.reject_message} onChange={handleChange} cols="30" rows="10"></textarea>
        </Modal>
        <Modal
            open={open.comment2}
            title="Comment"
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
            <Button key="back" onClick={handleCancel}>
                Return
            </Button>,
            <Button key="submit" type="primary" disabled={messages.comment_message === ""} loading={loading} onClick={handleComment}>
                {loading ? 'Submitting' : 'Submit'}
            </Button>,
            ]}
        >
            <textarea name="comment_message" className='modal-text-area' value={messages.comment_message} onChange={handleChange} cols="30" rows="10"></textarea>
        </Modal>
    </div>
  )
}

export default DataentryButton