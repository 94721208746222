import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { deferralBaseurl } from '../../../common/apis/userApi';

const DefferalModal = ({id, custno, staff, doctype}) => {

    const [disabled, setDisabled] = useState(false);
    const [deferral, setDeferral] = useState({
        customerNo: "",
        teamleadId: "",
        doctypeid: ""
    })

    const handleChange2 = (e) => {
        e.persist();
        setDeferral({...deferral, [e.target.name] : e.target.value })
    }

    const handleDeferral = async (e) => {
        e.preventDefault();

        setDisabled(true);
        const {customerNo, teamleadId, doctypeid} = deferral;

        const newData = new FormData();
        newData.append("customerNo", customerNo);
        newData.append("teamleadId", teamleadId);
        newData.append("doctypeid", doctypeid);

        try {
            const uploadingDeferral = await axios.post(`${deferralBaseurl}/initiatedefer`, newData);
            const result = uploadingDeferral.data;
            const apiMsg = result.body.data;
            toast.success(apiMsg);
            setDeferral({customerNo: "", teamleadId: "", doctypeid: ""})
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            
        } catch (error) {
            const errorMsg = error.response.data.body;
            setDisabled(false);
            toast.error(errorMsg.data)
            setDeferral({customerNo: "", teamleadId: "", doctypeid: ""})
        }
    }

  return (
    <div className="modal fade" id={`exampleModal1${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">Initiate Deferral</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form action="" onSubmit={handleDeferral}>
                        <div className="form-group">
                            <label htmlFor="">Customer Number</label>
                            <input type="text" name="customerNo" defaultValue={custno} readOnly="readonly"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Select Document Type</label>
                            <select name="doctypeid" value={deferral.doctypeid} onChange={(e) => setDeferral({...deferral, customerNo : custno, doctypeid : e.target.value})}>
                                <option value=""></option>
                                {
                                    doctype.map(({id, doc_name, days}) => {
                                        return (
                                            <option key={id} id={id} value={id}>{`${doc_name} (${days})`}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Select staff to approve</label>
                            <select name="teamleadId" value={deferral.teamleadId} onChange={handleChange2}>
                                <option value=""></option>
                                {
                                    staff.map(({id, name}) => {
                                        return(
                                            <option key={id} value={id}>{name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="formm-group">
                            <button type='submit' disabled={disabled}>Initiate Deferral</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DefferalModal