import React,  { useState } from 'react'
import { Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { irsAuthBaseUrl } from '../../../common/apis/userApi';
import axios from 'axios';

const SupervisorButton = ({id, fetchingData, supervisorProps}) => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setloading] = useState(false);
    const [messages, setMessages] = useState({
        reject_message : "",
        comment_message : ""
    })
    const [open, setOpen] = useState({
        approve : false,
        reject : false,
        comment : false
    });

    const { user } = useSelector(userSelector);

    const handleUpload2 = () => {

        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append(supervisorProps.file_name, file);
        });
        formData.append(supervisorProps.status, 'Approve');
        setUploading(true);
        fetch(`${irsAuthBaseUrl}/${supervisorProps.api}/${id}/${user.id}`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((result) => {
            setFileList([]);
            if(result.supervisor_status === 'Approve'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.officer_status === 'Approve'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.status === 0){
                message.error(result.message);
            }
        })
        .catch(() => {
            message.error('upload failed.');
        })
        .finally(() => {
            setUploading(false);
        });
    } ;

    const handleReject = () => {

        setloading(true)
        const formData = new FormData();
        formData.append(supervisorProps.status, 'Reject');
        formData.append(supervisorProps.comment, messages.reject_message);

        fetch(`${irsAuthBaseUrl}/${supervisorProps.api}/${id}/${user.id}`, {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((result) => {
            if(result.officer_status === 'Reject'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.supervisor_status === 'Reject'){
                message.success(result.message);
                setOpen(false);
                fetchingData();
            }
            if(result.status === 0){
                message.error(result.message);
            }
        })
        .catch(() => {
            message.error('Reject failed.');
        })
        .finally(() => {
            setloading(false);
        });
    }

    const handleComment = async () => {
        setloading(true)
        console.log("clikced")
        const postData = {
            "comment": messages.comment_message,
            "actionBy": "Supervisor"
        };
        
        try {
            const postingComment = await axios.post(`${irsAuthBaseUrl}/${supervisorProps.commentApi}/${user.id}/${id}`, postData)
            const result = postingComment.data;
            if(result){
                message.success("Comment sent successfully!")
                setOpen(false);
                fetchingData();
                setloading(false)
            }
            console.log(result)
        } catch (error) {
         console.log(error)   
         message.error("Process failed!!")
         setloading(false)
        }
    }

    const showApprovalModal = () => {
        setOpen({...open, approve : true});
    };
    const showRejectionModal = () => {
        setOpen({...open, reject : true});
    };
    const showCommentModal = () => {
        setOpen({...open, comment : true});
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const handleChange = (e) => {
        setMessages({...messages, [e.target.name] : e.target.value})
    }

    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };
    
  return (
      <div className="">
            <div className="action_btns">
                <button className='approve' onClick={showApprovalModal}>Approve</button>
                <button className='reject' onClick={showRejectionModal}>Reject</button>
                <button className='comment' onClick={showCommentModal}>Query(supervisor)</button>
            </div>
            <Modal
                open={open.approve}
                title="Approve KYC"
                onCancel={handleCancel}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" disabled={fileList.length === 0} loading={uploading} onClick={handleUpload2}>
                    {uploading ? 'Uploading' : 'Start Upload'}
                </Button>,
                ]}
            >
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </Modal>

            <Modal
                open={open.reject}
                title="Reject KYC"
                onCancel={handleCancel}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" disabled={messages.reject_message === ""} loading={loading} onClick={handleReject}>
                    {loading ? 'Submitting' : 'Submit'}
                </Button>,
                ]}
            >
                <textarea name="reject_message" className='modal-text-area' value={messages.reject_message} onChange={handleChange}cols="30" rows="10"></textarea>
            </Modal>
            <Modal
                open={open.comment}
                title="Supervisor Comment"
                onCancel={handleCancel}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" disabled={messages.comment_message === ""} loading={loading} onClick={handleComment}>
                    {loading ? 'Submitting' : 'Submit'}
                </Button>,
                ]}
            >
                <textarea name="comment_message" className='modal-text-area' value={messages.comment_message} onChange={handleChange} cols="30" rows="10"></textarea>
            </Modal>
      </div>
  )
}

export default SupervisorButton;