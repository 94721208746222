import React from 'react'

const ImagePopUpModal = ({identityimage, id}) => {
  return (
    // <div className='image-pop-modal'>
        <div className="modal fade" id={`image-modal${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Image View</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <img src={identityimage} alt="" />
                    </div>
                </div>
            </div>
        </div>
    // </div>
  )
}

export default ImagePopUpModal