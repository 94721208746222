import React from "react";
import "../forget-password/forget-password.css";
import logo from "../../assets/logo.svg";
import primeraBackground from "../../assets/image 2.png";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TextInput from "../../common/TextInput/TextInput";
import FormButton from "../../common/FormButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import arrowBack from "../../assets/left-arrow.png";
import { useSelector } from "react-redux";
import { userSelector } from "../../feature/Login/loginSlice";

const ChangePassword = () => {
  const { token } = useSelector(userSelector);
  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_newPassword: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Password is required"),
      password: Yup.string().required("Password is required"),
      confirm_newPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required(),
    }),
    isInitialValid: false,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    },
  });

  const handleSubmit = async (values, onSubmitProps) => {
    try {
      const response = await fetch(
        "https://irsapi.koinsbank.com/api/v3/update-password",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            old_password: values.old_password,
            password: values.password,
            password_confirmation: values.confirm_newPassword,
          }),
        }
      );
      const data = await response.json();

      if (data.status == 1) {
        toast.success(data.message);
        setTimeout(() => {
          history("/");
        }, 3000);
      } else {
        return;
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      onSubmitProps.setSubmitting(false);
    }
  };
  return (
    <div className="reset-password forget-password">
      <div className="main-wrapper">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-8">
            <h3>Change Password</h3>
            <div className="white-wrapper">
              <ToastContainer closeButton={true} position="top-right" />
              <form action="" onSubmit={formik.handleSubmit}>
                <div className="logo">
                  <img src={logo} alt="primera logo" />
                </div>
                <p className="mb-4">
                  Forgot your password? enter your email and we'll send you a
                  link you can use to pick a new password.
                </p>

                <TextInput
                  type="password"
                  placeholder="old password"
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  style={{
                    border:
                      formik.touched.old_password && formik.errors.old_password
                        ? "1px solid rgb(245, 102, 102)"
                        : "initial",
                  }}
                />
                <TextInput
                  type="password"
                  placeholder="new password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  style={{
                    border:
                      formik.touched.password && formik.errors.password
                        ? "1px solid rgb(245, 102, 102)"
                        : "initial",
                  }}
                />
                <TextInput
                  type="password"
                  placeholder="confirm new password"
                  name="confirm_newPassword"
                  value={formik.values.confirm_newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  style={{
                    border:
                      formik.touched.confirm_newPassword &&
                      formik.errors.confirm_newPassword
                        ? "1px solid rgb(245, 102, 102)"
                        : "initial",
                  }}
                />
                <FormButton
                  type="submit"
                  buttonText="Change Password"
                  loading={formik.isSubmitting}
                  disable={!formik.isValid || formik.isSubmitting}
                />

                <div className="return-to-home">
                  <img src={arrowBack} alt="arrow-left" />
                  <Link to="/">Back to login</Link>
                </div>
              </form>
            </div>
            <div className="copyright">
              <p> © 2022 Primera Microfinancebank. All rights reserved </p>
            </div>
          </div>
        </div>
        <div className="outer-bg align-items-center">
          <div className="bg-container">
            <div className="blank"></div>
            <div className="prim-logo">
              <img src={primeraBackground} alt="primera logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
