import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd';
import axios from 'axios';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../CasaAccounts/casaAccounts.css';
import '../CasaStaffList/casaStafflist.css';
import '../CasaDocument/casaAllDocument.css';
import { toast, ToastContainer } from 'react-toastify';
import { deferralBaseurl, casaBaseurl } from '../../../common/apis/userApi';
import next from '../../../assets/down-arrow.png';
import tick from '../../../assets/read (1).png';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useSelector } from 'react-redux';
import search from '../../../assets/search.svg';

const low = {
    backgroundColor : 'rgb(142 218 208)',
    color : "rgb(253 254 254)",
    borderRadius : "20px",
    textAlign : "center"
}

const MyPendingApprovals = () => {

    const { user, } = useSelector(userSelector);
    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [pageCount, setpageCount] = useState(1);
    const [allDocs, setAllDocs] = useState([])

    useEffect(() => {
        fetchingData(1);
    },[])

    const fetchingData = async (page) => {
        const docs = await axios.get(`${deferralBaseurl}/mydeferral/${user.id}?page=${page}`);
        const docResult = docs.data;
        setAllDocs(docResult);
        setpageCount(docResult.count)
    }
    const handleSearch = (e) => {
        if(e.target.value !== ""){
            setState({...state, search : e.target.value})
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        const { search } = state;

        const query = await axios.get(`${casaBaseurl}filter/${search}`);
        const result = query.data;
        // setAllDocs(result);
    }

    const approveDeferral = async (e) => {
        const { id } = e.currentTarget;
        try {
            const query = await axios.get(`${deferralBaseurl}/approvedefer/${id}/${user.id}`);
            const response = query.data;
            toast.success(response.body.data);
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            
        } catch (error) {
            console.log(error)
            const errorMsg = error.response.data.body;
            toast.error(errorMsg.data);
        }
        
    }

    const docsData = allDocs.results;

  return (
    <div className='casa-allDocument casa-account casa-stafflist'>
        <Header />
        <div className="content w-100 d-flex">
            <CasaTags />
            <div className="all-document casa-account-wrapper casa-staff-wrapper">
                <div className="account-heading">
                    <ToastContainer closeButton={false} position="top-right" />                    
                    <h3 className='mb-3'>My Pending Approval</h3>
                    <div className="search-staff-box">
                        <form>
                            <input type="search" placeholder='filter' name='search'
                            onChange={handleSearch}
                            />
                            <img src={search} alt="" onClick={handleSearchSubmit} />
                        </form>
                    </div>
                    <div className="list-wrapper bg-white">
                        <div className="list-table">
                            <div className="titles p-3">
                                <span>Document Name</span>
                                <span className='text-center'>Document Status</span>
                                <span className='text-center'>Document Timeline</span>
                                <span className='text-center'>Deferral</span>
                                <span className='text-center'>Action</span>
                            </div>
                            <div className="account-list px-3 mb-4">
                                <div className="accounts">
                                    {
                                        docsData && docsData.map(({ id, docname, timeline, approved, customerInfo }) => {
                                             let status = 'Not approved';
                                             let statusAction = 'approve deferral';

                                             if(approved){
                                                status = <img src={tick} alt="" width="20px"/>  
                                                statusAction = <p style={low} className="py-2">approved</p> 
                                             }else if(!approved){
                                                 status = "Not approved"
                                                 statusAction = <p className='text-decoration-underline text-primary'>approve deferral</p>
                                             }
                                             return(
                                                <div className="grid" key={id}>
                                                    <div className="account-brief">
                                                        <div className="brief-details mt-3">
                                                            <p className='doc-name' style={{color : '#003258', fontWeight : "500", fontSize : "13px"}}>
                                                                { docname } <br></br>
                                                                {`for ${customerInfo[0].firstname} ${customerInfo[0].lastname}`}
                                                            </p>
                                                            <span className='status text-center'>{status}</span>
                                                            <span className='text-center'>{`${timeline}days`}</span>
                                                            <span className='text-center' 
                                                            id={id} onClick={approveDeferral} 
                                                            role="button" 
                                                            >
                                                                {statusAction}
                                                            </span>
                                                            <span className='edit text-center' type="button" data-bs-toggle="collapse" data-bs-target={`#edit-icon${id}`}aria-expanded="false" aria-controls="collapseExample">
                                                                <img src={next} alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='collapse mt-3' id={`edit-icon${id}`}>
                                                        {
                                                            customerInfo.map(({firstname, lastname, customer_type, bvn, mobileno}, index) => {
                                                                return(
                                                                    <div className="account-detail" key={index}>
                                                                        <div className="account-headings">
                                                                            <h5>Customer</h5>
                                                                            <h5 className='text-center'>Acct Type</h5>
                                                                            <h5 className='text-center'>BVN</h5>
                                                                            <h5 className='text-center'>Mobile No</h5>
                                                                        </div>
                                                                        <div className="account-data">
                                                                            <p>{`${firstname} ${lastname}`}</p>
                                                                            <p className='text-center'>{customer_type}</p>
                                                                            <p className='text-center'>{bvn}</p>
                                                                            <p className='text-center'>{mobileno}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                             )
                                         })
                                    }
                                    {
                                        docsData?.length < 1 && <p className='mt-4 no-records'>No record found</p>
                                    }
                                </div>
                            </div>
                            <Pagination 
                                pageSize={10}
                                total={pageCount}
                                onChange={(page) => fetchingData(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyPendingApprovals