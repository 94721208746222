import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import '../../CASA/CasaDocument/casaDocumentType.css';
import '../../dahsboardHome/dashboard.css';
import '../../Menus/Menu.css';
import '../../Permissions/Permissions.css'
import { getAxiosInstance } from '../../../common/apis/createAxios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import searchimg from '../../../assets/search.svg';
import CreateMircroservice from './CreateMircroservice';
import PermissionTags from '../PermissionTags/PermissionTags';
import loader from '../../../assets/insider-loading.gif';
import EditMicroservice from './EditMicroservice';
import Swal  from 'sweetalert2';
import Header from '../../../components/header/header';

const Mircoservice = () => {

    const { token } = useSelector(userSelector);
    const [allServices, setAllServices] = useState([]);
    const [allRoles, setAllRoles] = useState([]);

    const [search, setSearch] = useState("");

    const [active] = useState({
        role : false,
        service : true,
        user : false
    })

    const [permission, setPermission] = useState({
        name : "",
        role_id : "",
        create : "",
        read : "",
        update : "",
        deleteItem : "",
        menu : "",
        other : "",
        status : ""
    })

    useEffect(() => {

        const fetchingData = async () => {
            try {
                const serivces = await getAxiosInstance(token).get(`/microservice-permission-core`)
                const result = serivces.data;
                setAllServices(result)

                const roles = await getAxiosInstance(token).get(`roles-core`);
                const response = roles.data;
                setAllRoles(response);
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchingData();

     }, [token])

    const handleChange = (e) => {
        e.persist();
        setSearch(e.target.value)
    }

    const handleRole = (e) => {
        setPermission({...permission, [e.target.name] : e.target.value})
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
            }
        })
    }

    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await getAxiosInstance(token).delete(`/microservice-permission-core/${id}`);
            if(removeDoc.status === 204){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                ).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    },1000)
                })
            }
        } catch (error) {
            const apiMsg = error.response.data.message;
            console.log(apiMsg)
            toast.error("Removal Failed!")
        }  
    }

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const serviceList = allServices.data;
    const roleList = allRoles.data;
    // const filterArray = userList?.filter(item => item.staffname.toLowerCase().includes(search.toLowerCase()))

    // console.log(allRoles)
    console.log(allServices)
  return (
    <div className='menus dashboard_ permissionlist'>
        <Header />
        <div className="container___" style={{paddingTop : "80px"}}>
            <ToastContainer closeButton={true} position="top-right" />
            <div className="dashboard-wrapper">
                <PermissionTags active={active}/>
                <div className="document-type-list roles">
                    <h3>Mircoservices List</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <form>
                            <input type="search" placeholder='filter' value={search} onChange={handleChange}/>
                            <img src={searchimg} alt="" />
                        </form>
                        <span 
                            className='edit text-center' 
                            type="button" 
                            data-bs-toggle="modal" 
                            data-bs-target="#add-role"
                            aria-expanded="false" 
                            aria-controls="collapseExample" 
                        >
                            New Service
                        </span>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Name</h5>
                            <h5 className=''>Role</h5>
                            <h5 className=''>Status</h5>
                            <h5 className=''>Created At</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            typeof serviceList === 'undefined' && <div className='w-100 text-center'>
                                <img src={loader} alt="" width="80px"/>
                             </div>
                        }
                        {
                            serviceList && serviceList?.map(({ id, name, role_id, menu, status, created_at }) => {
                               
                                const newDate = new Date(created_at);
                                const [month, day, year, dy] = [newDate.getMonth(), newDate.getDate(), newDate.getFullYear(), newDate.getDay()];

                                const targetRole = roleList?.filter(item => item.id === role_id)[0]

                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <div className='colored'>
                                                <p>{name}</p>
                                            </div>
                                            <p className='text-ce'>{targetRole?.name}</p>
                                            <p className='text-ce'>{status}</p>   
                                            <p>{`${day} ${months[month]} ${year}`}</p>                                         
                                            <div className="d-flex justify-content-center">
                                                <span 
                                                className='edit text-center' 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target={`#edit-form${id}`}
                                                aria-expanded="false" 
                                                aria-controls="collapseExample" 
                                                >
                                                    Edit
                                                </span>
                                                <span 
                                                id={id}
                                                onClick={showAlert}
                                                style={{marginLeft: "10px"}}
                                                > 
                                                    Remove
                                                </span>
                                            </div>
                                        </div>
                                        <EditMicroservice 
                                        id={id}
                                        name={name}
                                        roleList={roleList}
                                        menu={menu}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <button>Prev</button>
                            <button>Next</button>
                        </div>
                    </div>
                </div>
                <CreateMircroservice 
                allRoles={allRoles}
                permission={permission}
                handleRole={handleRole}
                />
            </div>
        </div>
    </div>
  )
}

export default Mircoservice;