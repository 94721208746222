import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { documentBaseUrl } from '../../common/apis/userApi';
import '../SupportingDocumentModal/SupportingDocumentModal.css';

const SupportingDocumentModal = ({ documentId }) => {

    const navigate = useNavigate();
    const [filedata, setfileData] = useState([]);

    const handleChange = (e) => {
        setfileData(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newForm = new FormData();
        newForm.append("document", filedata)

        try {
            const sendingdata = await axios.post(`${documentBaseUrl}/adddocument/${documentId}`, newForm);
            const response = sendingdata.data;
            toast.success(response.response.data.message);
            setTimeout(() => {
                navigate(-1);
            }, 3000)

        } catch (error) {
            
            const errormsg = error.response.data.response.data.message;
            toast.error(errormsg);
        }
    }

  return (
      <div className="supporting-doc-modal">
        <div className="modal fade" id="add-doc-modal" tabIndex="-1" aria-labelledby="docModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="docModalLabel">Add Supporting Document</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <input type="file" onChange={handleChange}  />
                            <button>Add document</button>
                        </form>
                    </div>
                </div>
            </div>               
        </div>
      </div>
  )
}

export default SupportingDocumentModal