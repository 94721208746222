import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import { toast, ToastContainer } from 'react-toastify';
import spyglass from '../../../assets/search.svg';
import { irsBaseUrl } from '../../../common/apis/userApi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import { useNavigate } from 'react-router-dom';

const CreateCategory = () => {

    const history = useNavigate();
    const { token, user } = useSelector(userSelector);

    const [department, setDepartment] = useState([])
    const [unit, setUint] = useState([])
    const [staff, setStaff] = useState([]);

    const [category, setCategory] = useState({
        unit_id : "", 
        name : "",
        department_id : "", 
        preferred_resolver : "",
        tat : "",
        status : "active"
    })

    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 

    useEffect(() => {
        const fetchingData = async () => {
            try {
                const department = await authAxios.get(`${irsBaseUrl}/staff/department`);
                const response = department.data;
               setDepartment(response)

                const unit = await authAxios.get(`${irsBaseUrl}/interdepartmental/ticket-sub-unit`);
                const result = unit.data;
                setUint(result.data);

                const staff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const stafflist = staff.data;
                setStaff(stafflist.data);

            } catch (error) {
                console.log(error)
            }
            
        }
        fetchingData();
    },[authAxios])

    const handleChange = (e) => {
        setCategory({...category, [e.target.name] : e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { unit_id, name, department_id, preferred_resolver, tat} = category;

        const jsonData = {
            "unit_id" : unit_id, 
            "name" : name,
            "department_id" : department_id, 
            "created_by" : user.id,
            "preferred_resolver" : preferred_resolver,
            "tat" : tat,
            "status" : "active"
        }
        try {
            const query = await authAxios.post(`${irsBaseUrl}/interdepartmental/ticket-category`, jsonData);
            const response = query.data;
            console.log(response);   
            if(response.created_at){
                toast.success("Updated Successfully");
                setTimeout(() => {
                    history('/helpdesk/category')
                },3000)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleInput = () => {
        var val = document.getElementById("input").value;
        let opts = document.getElementById('data').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            // const data = opts[i].value;
            setCategory({...category, preferred_resolver : dataId})
            break;
            }
        }
    }

  return (
    <div className='create-category helpdesk-department'>
        <Header />
        <div className="container__">
            <div className="content d-flex">
                <HelpDeskTags />
                <div className="help-createdepartment">
                    <ToastContainer closeButton={true} position="top-right" />
                    <div className="add-staff-form">
                        <div className="add-staff-wrapper">
                            <h4 className='mb-4'>Ticket Category</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="form-data">
                                    <div className="form-group">
                                        <label htmlFor="">Name</label>
                                        <input type="text" placeholder=''name="name" value={category.name} onChange={handleChange} />  
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Select Department</label>
                                        <select name="department_id" value={category.department_id} onChange={handleChange}>
                                            <option value="" disabled>Select Department</option>
                                            {
                                                department && department.map(({id, name}) => {
                                                    return(
                                                        <option value={id} key={id}>{name}</option>   
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Select Unit</label>
                                        <select name="unit_id" value={category.unit_id} onChange={handleChange}>
                                            <option value="" disabled>Select Unit</option>
                                            {
                                                unit && unit.map(({id, name}) => {
                                                    return(
                                                        <option value={id} key={id}>{name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Preferred Resolver</label>
                                        <div className="input-wrapper">
                                            <img src={spyglass} alt="spy glasss icon" width="20px" />
                                            <input list="data" id='input' onInput={handleInput} placeholder="search staff" />
                                        </div>
                                        <datalist id="data">
                                            <option value="">Solomon</option>
                                            {staff.map(({id, name}) =>
                                                <option key={id} value={name} id={id}>{name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">TAT (days)</label>
                                        <input type="number" placeholder='2 day' name='tat' value={category.tat} onChange={handleChange}/>
                                    </div>
                                    <button type='submit' className='w-100'>Add Category</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateCategory