import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/header'
import HelpDeskTags from '../../../components/HelpDeskTags/HelpDeskTags'
import '../../CASA/CasaDocument/casaDocumentType.css';
import './helpdeskdepartment.css';
import { Link } from 'react-router-dom';
import { irsBaseUrl } from '../../../common/apis/userApi';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../feature/Login/loginSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal  from 'sweetalert2';
import spyglass from '../../../assets/search.svg';
import ReactPaginate from 'react-paginate';


const Alldepartments = () => {
    const { token } = useSelector(userSelector);

    const [searchField, setSearchField] = useState("")

    const [staff, setStaff] = useState([]);
    const [allDepartments, setaAlldepartment] = useState([]);
    const [editDepartment, setEditDepartment] = useState({
        name : "",
        team_lead_id : "",
        group_email : "", 
        hod : "" 
    });

    //pagination

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(5);
  
    useEffect(() => {
  
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(allDepartments.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(allDepartments.length / itemsPerPage));
  
    }, [itemOffset, itemsPerPage, allDepartments, searchField]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % allDepartments.length;
      setItemOffset(newOffset);
    };
    const authAxios = axios.create({
        baseURL : irsBaseUrl,
        headers : {
            Authorization : `Bearer ${token}`,
        }
    }) 
    
    useEffect(() => {

        const fetchingData = async () => {
            try {
                const departmentData = await authAxios.get(`${irsBaseUrl}/staff/department`);
                const response = departmentData.data;
                setaAlldepartment(response)

                const allStaff = await authAxios.get(`${irsBaseUrl}/staff/all`);
                const result = allStaff.data;
                setStaff(result.data);

            } catch (error) {
                console.log(error)
            }
        }
        fetchingData()
    },[])

    const handleChange = (e) => {
        e.persist();
        setSearchField( e.target.value)
    }

    const handleEditChange = (e) => {
        e.persist();
        setEditDepartment({...editDepartment, [e.target.name] : e.target.value});
    }

    const handleInputTL = (e) => {
        const val = e.target.value;
        let opts = document.getElementById('editTL').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            console.log(dataId)
            setEditDepartment({...editDepartment, team_lead_id : dataId})
            break;
            }
        }
    }
    const handleInputHOD = (e) => {
        const val = e.target.value;
        let opts = document.getElementById('editHOD').childNodes;
        for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
            const dataId = opts[i].id;
            const data = opts[i].value;
            setEditDepartment({...editDepartment, hod : dataId})
            break;
            }
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault();

        const { name, team_lead_id, hod, group_email} = editDepartment
        const { id } = e.currentTarget;
        const jsonData = {
            name : name,
            team_lead_id : team_lead_id,
            group_email : group_email, 
            hod : hod
        }

        try {
            const removeDoc = await authAxios.put(`${irsBaseUrl}/interdepartmental/ticket-department/${id}`, jsonData);
            const result = removeDoc.data;
            if(result.created_at){
                toast.success("Department updated successfully!");
                setTimeout(() => {
                    window.location.reload();
                },2000)
            }
            console.log(result)

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error(apiMsg.data)
        }
    }

    const showAlert = (e) => {
        const { id } = e.currentTarget;

        Swal.fire({
            title: 'Remove Document Type',
            text: "Are you sure you want to remove this document type? This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then((result) => {
            if (result.isConfirmed) {
              handleRemove(id)
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
        }).then(() => {
            setTimeout(() => {
                window.location.reload();
            },2000)
        })

    }
    const handleRemove = async (id) => {
        
        try {
            const removeDoc = await authAxios.delete(`${irsBaseUrl}/interdepartmental/ticket-department/${id}`);
            const result = removeDoc.data.body;
            console.log(result.data);

        } catch (error) {
            const apiMsg = error.response.data.body;
            toast.error(apiMsg.data)
        }  
    }

    const handleSearch = () => {
        const filteredList = allDepartments.filter(item => item.name.toLowerCase().includes(searchField.toLowerCase()));
        setCurrentItems(filteredList)
    }

  return (
    <div className='helpdesk-department casa-documentlist'>
        <Header />
        <div className="container_">
            <div className="content pt-0 d-flex">
                <HelpDeskTags />
                <div className="document-type-list help-createdepartment p-3">
                    <h3>All Department</h3>
                    <div className="doc-search bg-white p-4 mb-3 rounded d-flex align-items-center justify-content-between">
                        <div className="search-wrapper">
                            <input type="search" name='searchField' placeholder='filter' value={searchField} onChange={handleChange}/>
                            <img src={spyglass} alt="" onClick={handleSearch}/>
                        </div>
                        <Link to='/helpdesk/create-department'>New Department</Link>
                    </div>
                    <div className="doc-wrapper p-4">
                        <div className="doc-titles">
                            <h5>Department name</h5>
                            <h5 className='text-center'>Department Id</h5>
                            <h5 className='text-center'>Actions</h5>
                        </div>
                        {
                            currentItems && currentItems.map(({ id, name }) => {
                                return( 
                                    <div className="doc-details" key={id}>
                                        <div className="item">
                                            <p className='colored'>{name}</p>
                                            <p className='text-center'>{id}</p>
                                            <div className="d-flex justify-content-center text-center">
                                                <span className='remove' id={id} onClick={showAlert}>Remove</span>
                                                <span className='edit text-center' role="button" data-bs-toggle="modal" data-bs-target={`#edit-form${id}`}>
                                                    Edit
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal fade" id={`edit-form${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Edit Department</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-wrapper">
                                                            <form action="" onSubmit={handleEdit} id={id} className="d-flex flex-column align-items-center">
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="">Name</label>
                                                                <input type="text" placeholder='' name="name" value={editDepartment.name} onChange={handleEditChange} className="w-100" required/>  
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>Team Lead</label>
                                                                <div className="input-wrapper w-100" >
                                                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                                                    <input list="editTL" id='inputTL' onKeyUp={handleInputTL} placeholder="search staff" required/>
                                                                </div>
                                                                <datalist id="editTL">
                                                                    {staff.map(({id, name}) =>
                                                                        <option key={id} value={name} id={id}>{name}</option>
                                                                    )}
                                                                </datalist>
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>Group Email</label>
                                                                <input type="email" placeholder='' value={editDepartment.group_email} name="group_email" onChange={handleEditChange} required className="w-100"/>
                                                            </div>
                                                            <div className="form-group d-flex flex-column w-100">
                                                                <label htmlFor="" className='text-left'>HOD</label>
                                                                <div className="input-wrapper w-100" >
                                                                    <img src={spyglass} alt="spy glasss icon" width="20px" />
                                                                    <input list="editHOD" id='inputHOD' onInput={handleInputHOD} placeholder="search staff" required/>
                                                                </div>
                                                                <datalist id="editHOD">
                                                                    {staff.map(({id, name}) =>
                                                                        <option key={id} value={name} id={id}>{name}</option>
                                                                    )}
                                                                </datalist>
                                                            </div>
                                                            <button type='submit' id={id}>Edit department</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="nav-btns my-5">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< prev"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Alldepartments