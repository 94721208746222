import React, { useState } from 'react';
import '../HandbookComponent/handbookcomponent.css';
import logo from '../../assets/image 1.png';
import homework from '../../assets/homework.png';

const HandbookComponent = () => {

    const [show, setShow] = useState({
        description : true,
        curriculum : false,
        faq : false,
        announcement : false,
        reviews : false
    });
    const styles = {
        background : "white",
    }
    const handleClick = (e) => {
        e.preventDefault();
        setShow({description : true, curriculum : false, faq : false, announcement : false, reviews : false})
    }
    const handleCurriculum = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : true, faq : false, announcement : false, reviews : false})
    }
    const handleFaq = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : true, announcement : false, reviews : false})   
    }
    const handleAnnouncement = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : false, announcement : true, reviews : false})
    }
    const handleReviews = (e) => {
        e.preventDefault();
        setShow({description : false, curriculum : false, faq : false, announcement : false, reviews : true})
    }
  return (
    <div className='handbook-component'>
        <h4>Primera Staff Handbook</h4>
        <p>is a one stop solution for employees where all banking operations will be conducted. All the bank’s existing applications such as the core banking, communication, loan processing and disbursement will be assessed on IRS.</p>
        <div className="lectures">
            <img src={homework} alt="" />
            <p>15 lectures</p>
        </div>
        <button>Start course</button>

        <div className="section-heading">
            <ul>
                <li onClick={handleClick} style={show.description ? styles : {}}>Description</li>
                <li onClick={handleCurriculum}>Curriculum</li>
                <li onClick={handleFaq}>FAQ</li>
                <li onClick={handleAnnouncement}>Announcement</li>
                <li onClick={handleReviews}>Reviews</li>
            </ul>
        </div>
        <div className="section-content">
            <div className="description" style={{display : show.description ? "block" : "none"}}>
                <div className="inner-content">
                    <img src={logo} alt="primera MFB logo" width='120px'/>
                    <h2>STAFF HANDBOOK</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, quod! Eaque optio odio dignissimos harum modi quos odit culpa cum, earum possimus consectetur cumque eveniet ducimus fugit itaque molestias architecto.</p>
                </div>
            </div>
            <div className="curriculum" style={{display : show.curriculum ? "block" : "none"}}>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Welcome Message</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Company profile</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Vision Mission, Core Values & Code of Conduct</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Customer Reception</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Recruitment Process & Health and Safety</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Standards of Performance and Behaviour at Work (Part 1)</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Standards of Performance and Behaviour at Work (Part 2)</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Disciplinary and Grievance Procedure</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Appeals & Conflict Resolution</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Recognise: Compensation & Leave</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Other benefits – Pension, Health, Insurance</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Develop: Training & Development</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Move on</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Staff Handbook Attestation</p>
                </div>
                <div className="item">
                    <img src={homework} alt="" />
                    <p>Staff Handbook Quiz</p>
                </div>
            </div>
            <div className="faq" style={{display : show.faq ? "block" : "none"}}>
                <p>No item</p>
            </div>
            <div className="announcement" style={{display : show.announcement ? "block" : "none"}}>
                <p>Nothing for now</p>
            </div>
            <div className="review" style={{display : show.reviews ? "block" : "none"}}>
                <p>Be the first to add a review</p>
                <button>Add review</button>
            </div>
        </div>
    </div>
  )
}

export default HandbookComponent