import React from 'react';
import '../Tag/tag.css';
import { Link } from 'react-router-dom';
import helpDesk from '../../assets/customer-service.png';
import worktool from '../../assets/customer-support.png';
import academy from '../../assets/graduated.png';
import document from '../../assets/document.png';
import gallery from '../../assets/gallery.png';
import compliance from '../../assets/law.png';
import user2 from '../../assets/group copy.png';

const Tag = () => {
  return (
    <div className='tag'>
        <div className="staff_wrapper">
            <Link to='/staff'>
                <img src={user2} alt="" />
                <p>Staff</p>
            </Link>
        </div>
        <div className="help_wrapper">
            <Link to='/prime-serve/dashboard'>
                <img src={helpDesk} alt="" />
                <p>Prime Serve</p>
            </Link>
        </div>
        <div className="work_wrapper">
            <Link to='/worktool'>
                <img src={worktool} alt="" />
                <p>Work Tool</p>
            </Link>
        </div>
        <div className="academy_wrapper">
            <Link to='/primera-academy'>
                <img src={academy} alt="" />
                <p>Primera Academy</p>
            </Link>
        </div>
        <div className="document_wrapper">
            <Link to='/document'>
                <img src={document} alt="" />
                <p>Document</p>
            </Link>
        </div>
        <div className="compliance_wrapper">
            <Link to='/complaince'>
                <img src={compliance} alt="" />
                <p>Compliance</p>
            </Link>
        </div>
        <div className="gallery_wrapper">
            <Link to='/products'>
                <img src={gallery} alt="" />
                <p>Products</p>
            </Link>
        </div>
        <div className="coins_wrapper">
            <a href='https://irs.primeramfbank.com/gallery/?gg_cid=187' target="_blank">
                <img src={gallery} alt="" />
                <p>Gallery</p>
            </a>
        </div>
    </div>
  )
}

export default Tag