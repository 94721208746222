import React from 'react';
import '../CreateLeave/CreateLeave.css';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import CreateLeaveForm from '../../components/CreateLeaveForm/CreateLeaveForm';
import Header from '../../components/header/header';
import Tag from '../../components/Tag/Tag';

const CreateLeave = () => {
  return (
    <div className='create-leave'>
        <Header />
        <div className="container_">
            <div className="content">
                <Tag />
                <CreateLeaveForm />
                {/* <BirthdayCard /> */}
            </div>
        </div>
    </div>
  )
}

export default CreateLeave