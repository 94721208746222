import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/header'
import CasaTags from '../CasaTags/CasaTags'
import '../../TicketList/TicketList.css';
import '../CasaStaffList/casaStafflist.css';
import user from '../../../assets/Vector.svg';
import next from '../../../assets/navigation.png';
import { casaBaseurl } from '../../../common/apis/userApi';
import axios from 'axios';
import search from '../../../assets/search.svg';

const CasaCustomerList = () => {

    const [state, setState] = useState({
        search : "",
        searchField : []
    })
    const [customers, setCustomer] = useState([]);
    const [pageCount, setpageCount] = useState(1);

    const authAxios = axios.create({
        baseURL : casaBaseurl,
    }) 

    useEffect(() => {
        fetchingData(1);
    },[])

    const fetchingData = async (page) => {
        const query = await authAxios.get(`/customer?page=${page}`);
        const result = query.data;
        setCustomer(result);
        setpageCount(result.count)
    }

    const handleSearch = (e) => {
        if(e.target.value !== ""){
            setState({...state, search : e.target.value})
        }else if(e.target.value === ""){
            window.location.reload();
        }
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        const { search } = state;

        const query = await authAxios.get(`/filter/${search}`);
        const result = query.data;
        setCustomer(result);
    }

    const customerResult = customers.results

    const previewHandle = (e) => {
        const { id } = e.currentTarget;
        const targetData = customerResult.filter(item => item.id === id);
        sessionStorage.setItem("targetData", JSON.stringify(targetData));
    }

  return (
    <div className='casa-customerlist casa-stafflist'>
        <Header />
        <div className="content d-flex">
            <CasaTags />
            <div className="casa-customer-wrapper casa-staff-wrapper">
                <h3 className='mb-3'>All Customers</h3>
                <div className="search-staff-box">
                    <form onSubmit={handleSearchSubmit}>
                        <input type="search" placeholder='search by first name, customer no, account number' onChange={handleSearch} value={state.search} />
                        <img src={search} alt="" />
                    </form>
                    <button>
                        <Link to='/customer/create'>Create Customer</Link>
                    </button>
                </div>
                <div className="tickets-table">
                    <table>
                        <thead>
                            <tr className=''>
                                <td className='px-4'>Customer No</td>
                                <td style={{paddingLeft : "40px"}}> Name</td>
                                <td>Email</td>
                                <td>Mobile No</td>
                                <td>Date Registered</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customerResult && customerResult?.map(({id, custno, firstname, lastname, email_address, mobileno, date_created}) => {
                                    const datee = date_created.split("T")[0];

                                    return <tr key={id}>
                                            <td className='p-4 cus-no'>{custno}</td>
                                            <td className='name'>
                                                <div className="d-flex">
                                                    <img src={user} alt="" />
                                                    <span>{`${firstname} ${lastname}`}</span>
                                                </div>
                                            </td>
                                            <td>{email_address}</td>
                                            <td>{mobileno}</td>
                                            <td className='text-center'>{datee}</td>
                                            <td className='next text-center'>
                                                <Link to='/customer/preview' id={id} onClick={previewHandle}><img src={next} alt="" /></Link>
                                            </td>
                                        </tr>
                                })
                            }
                            {
                                customerResult?.length < 1 && <p className='mt-5 no-records'> No records found</p>
                            }
                        </tbody>
                    </table>
                    <Pagination 
                    pageSize={10}
                    total={pageCount}
                    onChange={(page) => fetchingData(page)}
                    />
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CasaCustomerList