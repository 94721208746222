import React, { useState, useEffect } from 'react';
import menu1 from '../../assets/menu1.png'
import clipboard from '../../assets/clipboard.png'
import sign from '../../assets/signature (1).png'
import plus from '../../assets/plus.png';
import ticket from '../../assets/list.png';
import briefcase from '../../assets/briefcase.png';
import worker from '../../assets/construction-worker (2).png';
import './Tags.css';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { userSelector } from '../../feature/Login/loginSlice';
import { getAxiosInstance } from '../../common/apis/createAxios';

const Tags = () => {

    const { user, role, token } = useSelector(userSelector);
    const [permission, setPermission] = useState([]);

    const userPermission = role?.find(item => {
        const isAuthorized =  item?.user_id === user?.id;
        return isAuthorized;
    })

    const userPermissionId = userPermission?.role_id

    useEffect(() => {
        const fetchingData = async () => {
            const allPermission = await getAxiosInstance(token).get(`/roles-core/view/${userPermissionId}`)
            const result = allPermission.data;
            setPermission(result)
        }

        if(typeof userPermissionId !== 'undefined'){
            fetchingData();
        }
    },[userPermissionId])


    const isPermitted = permission?.find(item => {
        return item.permission_id.toLowerCase().includes("admin")
    })

  return (
    <div className='helpdesk-tags doc_approval' id='no-print'>
        <div className="content_">
            <div className="item_">
                <h4>Document Approval</h4>
            </div>
            <div className="item">
                <img src={menu1} alt="" />
                <Link to='/document-approval'>New Document</Link>
            </div>
            <div className="item">
                <img src={clipboard} alt="" />
                <Link to='/my-applications'>My history</Link>
            </div>
            <div className="item">
                <img src={sign} alt="" />
                <Link to='/signature/add'>Attach Signature</Link>
            </div>
            <div className="item">
                <img src={ticket} alt="" />
                <Link to='/document/concurrence-list'>Concurrence List</Link>
            </div>
            <div className="item">
                <img src={ticket} alt="" />
                <Link to='/document/approval-list'>Approval List</Link>
            </div>
            {
                isPermitted && <>
                    <h4 style={{color : "white"}}>Admin</h4>
                    <div className="item">
                        <img src={plus} alt="" />
                        <Link to='/create-document-name'>Create Document Type</Link>
                    </div>
                    <div className="item">
                        <img src={briefcase} alt="" />
                        <Link to='/edit-document-name'>Document Types</Link>
                    </div>
                    <div className="item">
                        <img src={ticket} alt="" />
                        <Link to='/applications'>All Applications</Link>
                    </div>
                    <div className="item">
                        <img src={worker} alt="" />
                        <Link to='/document/department'>All Departments</Link>
                    </div>
                    <div className="item">
                        <img src={ticket} alt="" />
                        <Link to='/document/office'>All Offices</Link>
                    </div>
                </>
            }
        </div>
    </div>
  )
}

export default Tags;