import React from 'react'

const IdentityRender = ({newData}) => {
  return (
    <div>
        <div className="identity-info mt-3 mb-5">
            <div className="title">
                <p>IDENTITY INFO</p>
            </div>
            <div className="item-row">
                <div className='info-item'>
                    <h4>Customer ID</h4>
                    <span>{newData?.custno}</span>
                </div> 
                <div className='info-item'>
                    <h4>BVN</h4>
                    <span>{newData?.bvn}</span>
                </div> 
                <div className='info-item'>
                    <h4>ID Type</h4>
                    <span>{newData?.idtype}</span>
                </div> 
            </div>
        </div>
        <div className="employer-info mb-5">
            <div className="title">
                <p>EMPLOYER INFO</p>
            </div>
            <div className="item-row">
                <div className='info-item'>
                    <h4>ID Number</h4>
                    <span>{newData?.idpassportno}</span>
                </div> 
                <div className='info-item'>
                    <h4>CIF</h4>
                    <span>221134</span>
                </div> 
                <div className='info-item'>
                    <h4>Employer Name</h4>
                    <span>{newData?.employer_name}</span>
                </div> 
                <div className='info-item'>
                    <h4>Employer Address</h4>
                    <span>{newData?.employer_address}</span>
                </div> 
            </div>
        </div>
    </div>
  )
}

export default IdentityRender