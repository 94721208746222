import React,{ useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { casaBaseurl } from '../../../common/apis/userApi';

const UploadDocumentModal = ({id, custno}) => {

    const [disabled, setDisabled] = useState(false);
    const [imageUrl, setimageUrl] = useState(null);
    const [docUpload, setDocUpload] = useState({
        modeOfId : "",
        iDNo : "",
        custNo : "",
    })

    const handleChange = (e) => {
        e.persist();
        setDocUpload({...docUpload, [e.target.name] : e.target.value})
    }

    const handleImage = (e) => {
        setimageUrl(e.target.files[0])
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        const { modeOfId, iDNo, custNo } = docUpload;
        
        const newform = new FormData();
        newform.append("custno" , custNo);
        newform.append("modeOfId", modeOfId);
        newform.append("iDNo", iDNo);
        newform.append("identityImage", imageUrl);
        
        try {
            const uploadingDoc = await axios.put(`${casaBaseurl}updatecustomer/${custNo}`, newform );
            const response = uploadingDoc.data;
            const apiMessage = response.body.data;
            toast.success(apiMessage);
            setDocUpload({modeOfId : "", iDNo : "",})
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            console.log(response)
            
        } catch (error) {
            const errorMesssage = error.response.data.body.data;
            toast.error(errorMesssage)
            setDocUpload({modeOfId : "", iDNo : "",})
            setDisabled(false);
            setimageUrl(null);
        } 
    }

  return (
    <div className="modal fade" id={`exampleModal${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Upload Document</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="">Customer Number</label>
                            <input type="text" 
                            value={custno}
                            readOnly="readonly" 
                            name="custno" 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Mode of Identity</label>
                            <select name="modeOfId" 
                            value={docUpload.modeOfId} 
                            onChange={(e) => setDocUpload({...docUpload, custNo : custno, modeOfId : e.target.value  })} required>
                                <option value=""></option>
                                <option value="Driver license">Driver's License</option>
                                <option value="Int'l Passport">International passport</option>
                                <option value="National ID card">National ID Card</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">ID Number</label>
                            <input type="text" 
                            value={docUpload.iDNo} 
                            name="iDNo" 
                            onChange={handleChange} 
                            required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Upload Document</label>
                            <input type="file" 
                            onChange={handleImage} 
                            name="imageUrl" 
                            accept="image/png, image/jpeg" 
                            required/>
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={disabled}>Upload Document</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UploadDocumentModal