import React from 'react';
import '../Leave/Leave.css';
import Header from '../../components/header/header'
import LeaveDashboard from '../../components/LeaveDashboard/LeaveDashboard';
import BirthdayCard from '../../components/BirthdayCard/BirthdayCard';
import Tag from '../../components/Tag/Tag';

const Leave = () => {
  return (
    <div className='leave'>
        <Header />
        <div className="container_">
            <div className="content">
                <Tag />
                <LeaveDashboard />
                {/* <BirthdayCard /> */}
            </div>
        </div>
    </div>
  )
}

export default Leave;