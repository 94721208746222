import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import {  Upload, Button } from 'antd';
import TextInput from '../../../common/TextInput/TextInput'
import './personal-info.css'


const Officers = ({formik, staff, opStaff, setImageUrl}) => {

    const [supervisorEmail, setSupervisorEmail] = useState('');
    const [dataEntryEmail, setDataEntryEmail] = useState('');

    useEffect(() => {
        if(formik.values.supervisorName !== ""){
            const selectedStaff = staff.filter(item => item.id == formik.values.supervisorName)
            setSupervisorEmail(selectedStaff[0]?.staff?.email)
        }
    },[formik.values.supervisorName])
    
    useEffect(() => {
        if(formik.values.data_entry_officer !== ""){
            const selectedStaff = opStaff.filter(item => item.id == formik.values.data_entry_officer)
            setDataEntryEmail(selectedStaff[0]?.staff?.email)
        }
    },[formik.values.data_entry_officer])

    const uploadButton = (
        <div>
            <Button icon={<PlusOutlined />}></Button>
            <div style={{ marginTop: 8}}>
            Upload
            </div>
      </div>
    );

    const beforeUpload =  (file) => {
        setImageUrl(file)
    }


  return (
    <div className='personal-info officers'>
        <div className="d-flex mb-3">
            <div className="w-100">
                <TextInput 
                    label='Name of visiting staff'
                    type="text" 
                    placeholder='' 
                    name='name_of_visiting_staff' 
                    value={formik.values.name_of_visiting_staff} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.name_of_visiting_staff && formik.errors.name_of_visiting_staff) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
            <div className="w-100">
                <TextInput 
                    label='Account Introducer'
                    type="text" 
                    placeholder='' 
                    name='account_introducer' 
                    value={formik.values.account_introducer} 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    formik={formik}
                    style={{border : (formik.touched.account_introducer && formik.errors.account_introducer) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} 
                />
            </div>
        </div>
        <div className="d-flex mb-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Supervisor Name</label>
                    <select name="supervisorName" onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.supervisorName && formik.errors.supervisorName) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                        <option value="" disabled selected>Select suspervisor</option>
                        {
                            staff && staff.map(({ staff }) => {
                                const { name, id} = staff;

                                return(
                                    <option value={id} key={id}>{name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Supervisor Email</label>
                    <input type="text" disabled value={supervisorEmail} className="email_input"/>
                </div>
            </div>
        </div>
        <div className="d-flex mb-4">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Data Entry Officer</label>
                    <select name="data_entry_officer" onChange={formik.handleChange} onBlur={formik.handleBlur} style={{border : (formik.touched.data_entry_officer && formik.errors.data_entry_officer) ? "1px solid rgb(245, 102, 102)" : "1px solid #A1A1A1"}} >
                        <option value="" disabled selected>Select entry officer</option>
                        {
                            opStaff && opStaff.map(({ staff }) => {
                                const { name, id} = staff;

                                return(
                                    <option value={id} key={id}>{name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">Data Entry officer email</label>
                    <input type="text" disabled value={dataEntryEmail} className="email_input"/>
                </div>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="">RO signature</label>
                    <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={beforeUpload}
                    >
                        {
                            uploadButton
                        }
                    </Upload>
                </div>
            </div>
            <div className="w-100"></div>
        </div>
    </div>
  )
}

export default Officers